import { COLORS } from 'assets/constants/theme';
import hexToRgba from 'helpers/hexToRgba';
import React from 'react';

const CustomTag = ({ children, color, onClick = undefined }) => {
  const backgroundStyle = {
    background: hexToRgba(color, 0.8),
    border: `1px solid ${color}`,
    borderRadius: '8px',
    padding: '0px 6px',
    display: 'inline-block',
    margin: '4px',
    color: COLORS.neutral,
    textAlign: 'center',
    height: 'auto',
    cursor: onClick ? "pointer" : "default"
  };

  return <div onClick={onClick} style={backgroundStyle}>{children}</div>;
};

export default CustomTag;
