import { useState, useEffect } from "react";
import {
  Input,
  Col,
  Badge,
  Row,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import { message } from "antd";
import { StringManipulator } from "@fineo-io/main-javascript-library";
import { useTranslation } from "react-i18next";
import { COLORS } from "assets/constants/theme";
import SubscriptionPlan from "../Stripe/SubscriptionPlan";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import ReactDatetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import CreatableSelect from "react-select/creatable";
import FormatCurrencyColored from "../Tools/Currency/FormatCurrencyColored";
import useKeyboardShortcut from "use-keyboard-shortcut";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import SelectBudget from "../TransactionComponents/SelectBudget";
import SelectPayee from "../TransactionComponents/SelectPayee";
import SelectAccount from "../TransactionComponents/SelectAccount";
import SelectDate from "../TransactionComponents/SelectDate";
import PayeeInfo from "../TransactionComponents/fieldsInfos/PayeeInfo";
import CategoryInfo from "../TransactionComponents/fieldsInfos/CategoryInfo";
import EditPayeeList from "../Randoms/EditPayeeList";
import formatDate from "helpers/formatDate";
import noTransactionsIllustration from "../../assets/4x/4x/no-files-1.svg";
import "../../assets/css/inputStyle.css";
import AccountInfo from "../TransactionComponents/fieldsInfos/AccountInfo";
import DefaultSelect from "components/inputs/DefaultSelect";
import { STYLES } from "assets/constants/theme";

export default function TransactionTable(props) {
  const { t } = useTranslation();
  const [refreshPeriodicties, setRefreshPeriodicties] = useState(false);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [openBeneficiaireModal, setOpenBeneficiaireModal] = useState(false);

  useEffect(() => {
    if (props.periodicitesData?.length === 0) setRefreshPeriodicties(false);
    if (props.periodicitesData?.length !== 0) setRefreshPeriodicties(true);
  }, [props.periodicitesData]);

  useKeyboardShortcut(
    ["Escape"],
    async () => {
      try {
        if (props.onAddMode === true) props.CancelAdd();
      } catch (error) { }
    },

    { overrideSystem: true }
  );

  // Object to handle add a new transaction
  let newTransaction = {
    key: "-1",
    categorie: "",
    account:
      props.transactionData.filter((tr) => tr.key == -1).length !== 0
        ? props.transactionData.filter((tr) => tr.key == -1)[0].account
        : "",
    date: formatDate(new Date(), false, false, "DD/MM/YYYY"),
    memo: "",
    payee: "",
    amount: 0,
    isIncome: false,
    added: false,
  };

  const HandleSaveNewTransaction = async () => {
    if (newTransaction.date === "" || newTransaction.account === "") {
      return message.error(
        t(
          "Transaction_Veuillez_remplir_les_champs_date_et_compte,_avant_d'enregistrer_la_nouvelle_transaction"
        ),
        5
      );
    }

    document.getElementById(`enableSelectCategorie`).style.display = "inline";
    document.getElementById(`disabledSelectCategorie`).style.display = "none";
    if (newTransaction.added) return;
    newTransaction.added = true;
    await props.handleSaveTransactionToDb(
      {
        ...newTransaction,
        date: String(newTransaction.date).split("/").reverse().join("-"),
        andAddNewOne: false,
      },
      () => (newTransaction.added = false)
    );
  };

  // Function to handle seting a periodicity to selecetd transaction
  const handlePeriodicType = async (type, isActive, transaction_id) => {
    await props.getPeriodiciteType(transaction_id, type, isActive);
    await props.getTransaction(true, t);
  };

  // Render the Periodicties menu
  const menu = (id) => (
    <DropdownMenu>
      <DropdownItem disabled={true}>
        {StringManipulator.capitalize(t("global.recurrence._"))}:
      </DropdownItem>
      {props.periodicitesData.map((elment) => (
        <DropdownItem
          onClick={async () => {
            if (props.authState.user.subscription_id !== 2) {
              if (blockEdtingOnAddingNewTransactionMode() === false) return;
              await handlePeriodicType(elment.periodicite_id, true, id);
              await props.filterTransaction(
                props.filterOptions,
                sessionStorage.getItem("SESSION_SPACE")
              );
            } else {
              setShowPlanModal(true);
            }
          }}
          key={elment.periodicite_id}
        >
          {t(`Transaction_${elment.periodicite_label}`)}
        </DropdownItem>
      ))}
    </DropdownMenu>
  );

  // Render a select of budget in the add new transaction row
  const RenderSelectBudget = () => {
    let options = [];
    props.BudgetsData.forEach((group) => {
      if (group.label !== t("to_budget")) {
        let obj = {};
        let tab = [];
        group.options.forEach((val) => {
          let varb = `${val.label} ${new Intl.NumberFormat(
            props.authState.user.currency_locales,
            {
              style: "currency",
              currency: props.authState.user.currency_code,
            }
          ).format(val.amount)}`;
          tab.push({
            key: val.key,
            value: val.value,
            label: varb,
          });
        });

        obj = {
          key: Math.random() * Math.random(),
          label: group.label,
          options: tab,
        };
        options.push(obj);
      }
    });

    options.unshift({
      label: t("to_budget"),
      options: [
        {
          key: -1,
          value: -1,
          label: t(`to_budget`),
        },
      ],
    });

    options = options.filter(
      (group) =>
        group.label !== t("budget_get_all") && group.label !== undefined
    );

    return (
      <>
        <Input
          id="disabledSelectCategorie"
          disabled={true}
          className="customInputStyle"
          style={{ display: "none" }}
        />
        <span id="enableSelectCategorie">
          <DefaultSelect
            options={options}
            isClearable
            placeholder={t("Transaction_Sélectionner")}
            onChange={(option) => {
              if (option !== null && option !== undefined) {
                const updatedTransaction = {
                  ...newTransaction,
                  categorie: option.key,
                };
                newTransaction = updatedTransaction;
                if (option.value === -1) {
                  document.getElementById("newTrOut").disabled = true;
                } else {
                  document.getElementById("newTrOut").disabled = false;
                }
              } else {
                document.getElementById("newTrOut").disabled = false;
              }
            }}
          />
        </span>
      </>
    );
  };

  // Render an autoComplete of payees in the add new transaction row
  const RenderAutoCompletePayee = () => {
    const mainOptions = [];
    const accountOptions = [];
    props.accountsData
      .filter((account) => account?.is_it_closed === false)
      .forEach((account) => {
        accountOptions.push({
          key: account.key,
          value: account.account,
          label: `${account.account}`,
        });
      });

    mainOptions.push({
      key: 1,
      label: t("Transfertvers"),
      options: accountOptions,
    });

    const payeeOptions = [];
    props.beneficiaryData.forEach((payee) => {
      payeeOptions.push({
        key: `${payee.value} ${payee.key}`,
        value: payee.value,
        label: `${payee.value}`,
      });
    });

    mainOptions.push({
      key: Math.random() * Math.random(),
      label: (
        <span
          className="ml-1"
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
          }}
          onClick={() => {
            setOpenBeneficiaireModal(true);
          }}
        >
          <span>{t("Beneficiaire")}</span>
          <i class="fas fa-cog"></i>
        </span>
      ),
      options: payeeOptions,
    });

    return (
      <>
        <CreatableSelect
          key={Math.random()}
          className="customInputStyle"
          styles={{
            control: (styles) => ({
              ...styles,
              ...STYLES.selectStyle,
            }),
            option: (styles) => ({ ...styles, textAlign: "left" }),
          }}
          placeholder={t("Transaction_Sélectionner")}
          formatCreateLabel={(inputValue) => (
            <span>
              {t("Create")}: {inputValue}
            </span>
          )}
          options={mainOptions}
          isClearable
          onChange={(option) => {
            if (option === null || option === undefined) {
              document.getElementById(`disabledSelectCategorie`).style.display =
                "none";
              document.getElementById(`enableSelectCategorie`).style.display =
                "inline";
              return (newTransaction = {
                ...newTransaction,
                payee: "",
              });
            }

            if (option !== undefined && option !== null) {
              const isItAnAccount = props.accountsData.filter(
                (account) => account.account === option.value
              );
              if (isItAnAccount.length > 0) {
                document.getElementById(`enableSelectCategorie`).style.display =
                  "none";
                document.getElementById(
                  `disabledSelectCategorie`
                ).style.display = "inline";
                return (newTransaction = {
                  ...newTransaction,
                  payee: isItAnAccount[0].key,
                });
              }
              document.getElementById(`enableSelectCategorie`).style.display =
                "inline";
              document.getElementById(`disabledSelectCategorie`).style.display =
                "none";
              newTransaction = { ...newTransaction, payee: option.value };
            }
          }}
        />
      </>
    );
  };

  // Render a select of accounts in the add new transaction row
  const RenderSelectAccount = (account_) => {
    let selectedAccountName = "";
    const accountDataSource = [];
    props.accountsData
      .filter((account) => account?.is_it_closed === false)
      .map((account) => {
        if (account_ !== undefined) {
          if (account.key === account_) {
            selectedAccountName = account.account;
          }
        }
        accountDataSource.push({
          value: account.key,
          label: account.account,
        });
      });
    return (
      <DefaultSelect
        className="customInputStyle"
        options={accountDataSource}
        isClearable
        placeholder={t("Transaction_Sélectionner")}
        onChange={(option) => {
          if (option === undefined || option === null) return;
          const updatedTransaction = {
            ...newTransaction,
            account: option.value,
          };
          newTransaction = updatedTransaction;
        }}
      />
    );
  };

  // Render a datePicker in the add new transaction row
  const RenderSelectDate = () => (
    <ReactDatetime
      timeFormat={false}
      dateFormat="YYYY/MM/DD"
      locale={t("Budget_ReactDatetime")}
      inputProps={{
        style: {
          ...STYLES.selectStyle,
        },
      }}
      onChange={(value) => {
        newTransaction = {
          ...newTransaction,
          date: formatDate(value, false, false, "DD/MM/YYYY"),
        };
      }}
      onBlur={(value) => {
        newTransaction = {
          ...newTransaction,
          date: formatDate(value, false, false, "DD/MM/YYYY"),
        };
      }}
    />
  );

  // Render the save, save and set new and cancel buttons
  const renderSaveNewTransactionButtons = () => (
    <Row className="float-right mt-3 mr-3">
      <Col>
        <h5
          className="align-items-center text-default"
          onClick={() => HandleSaveNewTransaction()}
        >
          <span style={{ cursor: "pointer" }}>
            {t("Transaction_Enregistrer")}
          </span>
        </h5>
      </Col>
      <Col>
        <h5
          className="align-items-center text-grey"
          onClick={() => {
            props.CancelAdd();
          }}
        >
          <span style={{ cursor: "pointer" }}>{StringManipulator.capitalize(t("global.cancel._"))}</span>
        </h5>
      </Col>
    </Row>
  );

  // Function to check if the view is on add mode or not
  const blockEdtingOnAddingNewTransactionMode = () =>
    props.transactionData.filter((tr) => tr.key === "-1").length !== 1;

  const columns = [
    {
      dataField: "date",
      text: "Date",
      align: "center",
      headerAlign: "center",
      sort: true,
      style: {
        width: "10%",
      },
      headerStyle: {
        width: "10%",
      },
      sortFunc: (a, b, order) => {
        a = formatDate(new Date(a), false, false, "DD/MM/YYYY");
        b = formatDate(new Date(b), false, false, "DD/MM/YYYY");
        if (order === "asc") {
          return new Date(b) - new Date(a);
        }
        return new Date(a) - new Date(b);
      },
      classes: function callback(cell, row) {
        if (row.key !== "-1") {
          return "transactionTableCellCustomStyle";
        }
      },
      editable: (content, row) =>
        row.is_it_closed || row.memo == "transaction de clôture du compte"
          ? false
          : blockEdtingOnAddingNewTransactionMode() &&
          row.is_it_imported !== true,
      editorRenderer: (editorProps, value, row) => (
        <SelectDate
          {...editorProps}
          row={row}
          handleSave={props.handleSave}
          defaultValue={value}
          value={value}
          locale={t("Budget_ReactDatetime")}
        />
      ),
      formatter: (cell, row) =>
        row.key !== "-1" ? formatDate(cell) : RenderSelectDate(),
      validator: (newValue, row) => {
        if (newValue === "")
          return {
            valid: false,
            message: "champ invalide",
          };
        props.handleSave({
          ...row,
          date: formatDate(new Date(newValue), false, false, "DD/MM/YYYY"),
          transaction_amount: parseFloat(row.transaction_amount) / 100,
        });
        return true;
      },
    },
    {
      text: "",
      dataField: "",
      align: "center",
      headerAlign: "center",
      style: {
        width: "3%",
      },
      headerStyle: {
        width: "3%",
      },
      editable: false,
      formatter: (cell, row) =>
        row.key !== "-1" ? (
          <>
            {row.is_it_closed ? (
              <Badge color="danger" className="rounded">
                {t("Transaction_Compte_fermé")}
              </Badge>
            ) : row.periodic_type === 0 ||
              new Date(row.dateTemp) <= new Date() ? (
              row.memo !== "Solde initial" ? (
                <UncontrolledDropdown group>
                  <DropdownToggle tag="span">
                    <i
                      className="fa fa-ellipsis-v secondary"
                      style={{ cursor: "pointer" }}
                      title={"planifier une transaction periodique."}
                    />
                  </DropdownToggle>
                  {menu(row.key)}
                </UncontrolledDropdown>
              ) : (
                <span>-</span>
              )
            ) : (
              <Badge color="primary" className="rounded">
                <UncontrolledDropdown group>
                  <DropdownToggle tag="span">
                    <span style={{ cursor: "pointer" }}>
                      {t(`Transaction_${row.periodicite_label}`)}{" "}
                    </span>
                  </DropdownToggle>
                  {menu(row.key)}
                </UncontrolledDropdown>
              </Badge>
            )}
          </>
        ) : (
          <i
            className="fa fa-ellipsis-v secondary"
            title={"planifier une transaction periodique."}
          ></i>
        ),
    },
    {
      dataField: "account",
      text: t("Transaction_Compte"),
      headerFormatter: function titleFormatter(column, colIndex) {
        return (
          <span
            className="ml-1"
            style={{
              cursor: "pointer",
            }}
            onClick={() => props.setShowAccountInfo(true)}>
            {column.text}&nbsp;
            <i id="showAccountInfo" className="fas fa-info-circle fa-1x mr-1" />
          </span>
        );
      },
      align: "center",
      headerAlign: "center",
      style: {
        width: "15%",
      },
      headerStyle: {
        width: "15%",
      },
      classes: function callback(cell, row) {
        if (row.key !== "-1") {
          return "transactionTableCellCustomStyle";
        }
      },
      editable: (content, row) =>
        row.is_it_closed || row.memo == "transaction de clôture du compte"
          ? false
          : blockEdtingOnAddingNewTransactionMode() &&
          row.is_initial !== true &&
          row.is_it_imported !== true,
      editorRenderer: (editorProps, value, row) => {
        return (
          <SelectAccount
            {...editorProps}
            t={t}
            row={row}
            handleSave={props.handleSave}
            accountsData={props.accountsData}
            value={value}
          />
        )
      },
      formatter: (cell, row) =>
        row.key !== "-1" ? (
          cell !== "Compte à définir" && cell !== "Compte sans nom" ? (
            <>
              {row.is_it_imported === true ? (
                <>
                  <i
                    className="fas fa-university mr-1"
                    style={{ color: COLORS.secondary }}
                  ></i>{" "}
                  {cell === "Compte importé" ? t("Compte importé") : cell}
                </>
              ) : (
                cell
              )}
            </>
          ) : cell === "Compte sans nom" ? (
            <>
              {row.is_it_imported === true ? (
                <>
                  <i
                    className="fas fa-university mr-1"
                    style={{ color: COLORS.secondary }}
                  ></i>{" "}
                  <Badge color="warning" className="rounded">
                    {t(cell)}
                  </Badge>
                </>
              ) : (
                <Badge color="warning" className="rounded">
                  {t(cell)}
                </Badge>
              )}
            </>
          ) : (
            <Badge color="danger" className="rounded">
              {t(cell)}
            </Badge>
          )
        ) : (
          RenderSelectAccount(row.account)
        ),
    },
    {
      dataField: "payee",
      headerFormatter: function titleFormatter(column, colIndex) {
        return (
          <span
            className="ml-1"
            style={{
              cursor: "pointer",
            }}
            onClick={() => props.setShowPayeeInfo(true)}>
            {column.text}&nbsp;
            <i id="showPayeeInfo" className="fas fa-info-circle fa-1x mr-1" />
          </span>
        );
      },
      text: t("Transaction_Contrepartie"),
      align: "center",
      headerAlign: "center",
      style: {
        width: "15%",
      },
      headerStyle: {
        width: "15%",
      },
      classes: function callback(cell, row) {
        if (row.key !== "-1") {
          return "transactionTableCellCustomStyle";
        }
      },
      editable: (content, row) =>
        row.is_it_closed || row.memo == "transaction de clôture du compte"
          ? false
          : blockEdtingOnAddingNewTransactionMode() && row.is_initial !== true,
      validator: (newValue, row) => {
        if (row.key !== "-1")
          props.handleSave({
            row,
            payee: newValue,
            transaction_amount: parseFloat(row.transaction_amount) / 100,
          });
        return true;
      },
      editorRenderer: (editorProps, value, row) => (
        <SelectPayee
          {...editorProps}
          setOpenBeneficiaireModal={setOpenBeneficiaireModal}
          row={row}
          t={t}
          handleSave={props.handleSave}
          beneficiaryData={props.beneficiaryData}
          accountsData={props.accountsData}
          value={value}
        />
      ),
      formatter: (text, record) => record.key !== "-1" ? text : RenderAutoCompletePayee(),
    },
    {
      dataField: "categorie",
      text: t("Transaction_Catégorie"),
      headerFormatter: function titleFormatter(column, colIndex) {
        return (
          <span
            className="ml-1"
            style={{
              cursor: "pointer",
            }}
            onClick={() => props.setShowCategoryInfo(true)}>
            {column.text}&nbsp;
            <i id="showCategoryInfo" className="fas fa-info-circle fa-1x mr-1" />
          </span>
        );
      },
      align: "center",
      headerAlign: "center",
      style: {
        width: "18%",
      },
      headerStyle: {
        width: "18%",
      },
      classes: function callback(cell, row) {
        if (row.key !== "-1") {
          return "transactionTableCellCustomStyle";
        }
      },
      editable: (content, row) =>
        row.is_it_closed || row.memo == "transaction de clôture du compte"
          ? false
          : blockEdtingOnAddingNewTransactionMode() &&
          row.is_transfer !== true &&
          row.is_initial !== true,
      editorRenderer: (editorProps, value, row) => (
        <SelectBudget
          {...editorProps}
          row={row}
          t={t}
          authState={props.authState}
          handleSave={props.handleSave}
          BudgetsData={props.BudgetsData}
          value={value}
        />
      ),
      formatter: (text, record) =>
        record.key !== "-1" ? (
          record.is_transfer === true || record.is_initial === true ? (
            <></>
          ) : text !== "-" ? (
            text
          ) : (
            <Badge
              className="rounded"
              color={
                text === "-" && record.out !== "0" && record.in === ""
                  ? "warning"
                  : text === "-" && record.in !== "0" && record.in !== ""
                    ? "success"
                    : record.out === "0" && record.in === "" && record.out !== ""
                      ? "success"
                      : ""
              }
            >
              {text === "-" && record.in !== "0" && record.out === "0" ? t("Transaction_À_budgéter") : ""}
              {text === "-" && record.out !== "0" && record.in === "" ? t("Transaction_Categorie_à_définir") : ""}
            </Badge>
          )
        ) : (
          RenderSelectBudget()
        ),
    },
    {
      dataField: "out",
      text: t("Transaction_Dépense"),
      align: "center",
      headerAlign: "center",
      sort: true,
      style: {
        width: "10%",
      },
      headerStyle: {
        width: "10%",
      },
      sortFunc: (a, b, order) => {
        if (order === "asc") {
          return b - a;
        }
        return a - b;
      },
      classes: function callback(cell, row) {
        if (row.key !== "-1") {
          return "transactionTableCellCustomStyle";
        }
      },
      editable: (content, row) =>
        row.is_it_closed || row.memo == "transaction de clôture du compte"
          ? false
          : blockEdtingOnAddingNewTransactionMode() &&
          row.is_it_imported !== true &&
          row.is_initial !== true,
      formatter: (text, record) =>
        record.key !== "-1" ? (
          <span>
            {text !== undefined && text !== "" && text !== "0" &&
              FormatCurrencyColored(-1 * text, props.authState.user.currency_code, props.authState.user.currency_locales)
            }
          </span>
        ) : (
          <Input
            className="ourCustomInputStyle"
            id="newTrOut"
            min={0}
            onKeyPress={(target) =>
              target.charCode === 13 && HandleSaveNewTransaction()
            }
            onChange={(e) => {
              if (e.target.value !== "")
                document.getElementById("newTrIn").disabled = true;
              if (e.target.value === "")
                document.getElementById("newTrIn").disabled = false;
              newTransaction = {
                ...newTransaction,
                amount: e.target.value,
                isIncome: false,
              };
            }}
          />
        ),
      validator: (newValue, row, column) => {
        if (newValue === "") return true;
        newValue = String(newValue).replace(",", ".");
        if (isNaN(newValue))
          return {
            valid: false,
          };

        if (row.key !== "-1")
          props.handleSave({
            ...row,
            transaction_amount: parseFloat(newValue) * -1,
          });

        return true;
      },
    },
    {
      dataField: "in",
      text: `${t("Transaction_Entrée")} `,
      align: "center",
      headerAlign: "center",
      sort: true,
      style: {
        width: "10%",
      },
      headerStyle: {
        width: "10%",
      },
      sortFunc: (a, b, order) => {
        if (order === "asc") {
          return b - a;
        }
        return a - b;
      },
      classes: function callback(cell, row) {
        if (row.key !== "-1") {
          return "transactionTableCellCustomStyle";
        }
      },
      editable: (content, row) =>
        row.is_it_closed || row.memo == "transaction de clôture du compte"
          ? false
          : blockEdtingOnAddingNewTransactionMode() &&
          row.is_it_imported !== true &&
          row.is_initial !== true,
      formatter: (text, record) =>
        record.key !== "-1" ? (
          <span>
            {text !== undefined && text !== "" && text !== "0" &&
              FormatCurrencyColored(text, props.authState.user.currency_code, props.authState.user.currency_locales)
            }
          </span>
        ) : (
          <Input
            className="ourCustomInputStyle"
            id="newTrIn"
            onKeyPress={(target) =>
              target.charCode === 13 && HandleSaveNewTransaction()
            }
            onChange={(e) => {
              if (
                e.target.value === "" &&
                parseInt(newTransaction.categorie) !== -1
              )
                document.getElementById("newTrOut").disabled = false;

              if (e.target.value !== "")
                document.getElementById("newTrOut").disabled = true;

              newTransaction = {
                ...newTransaction,
                amount: e.target.value,
                isIncome: true,
              };
            }}
          />
        ),
      validator: (newValue, row) => {
        if (newValue === "") return true;
        newValue = String(newValue).replace(",", ".");
        if (isNaN(newValue))
          return {
            valid: false,
          };
        if (row.key !== "-1")
          props.handleSave({
            ...row,
            transaction_amount: newValue,
          });
        return true;
      },
    },
    {
      dataField: "memo",
      text: t("Transaction_Mémo"),
      align: "center",
      headerAlign: "center",
      style: {
        width: "19%",
      },
      headerStyle: {
        width: "19%",
      },
      classes: function callback(cell, row) {
        if (row.key !== "-1") {
          return "transactionTableCellCustomStyle";
        }
      },
      editable: (content, row) =>
        row.is_it_closed || row.memo == "transaction de clôture du compte"
          ? false
          : blockEdtingOnAddingNewTransactionMode() && row.is_initial !== true,
      formatter: (text, record) =>
        record.key !== "-1" ? (
          text == "transaction de clôture du compte" ?
            t("account_closing_transaction") :
            text == "Solde initial" ? t("Transaction_Solde_Initial") : text
        ) : (
          <>
            <Input
              onKeyPress={(target) =>
                target.charCode === 13 && HandleSaveNewTransaction()
              }
              className="ourCustomInputStyle"
              onChange={(e) => {
                newTransaction = {
                  ...newTransaction,
                  memo: e.target.value,
                };
              }}
            />
            {renderSaveNewTransactionButtons()}
          </>
        ),
      validator: (newValue, row) => {
        if (row.key !== "-1") {
          props.handleSave({
            ...row,
            memo: newValue,
            transaction_amount: parseFloat(row.transaction_amount) / 100,
          });
        }
        return true;
      },
    },
  ];

  const nonSelectableRows = () => {
    const data = [];
    props.transactionData.forEach((row) => {
      if (
        row.is_initial === true ||
        row.is_it_closed ||
        row.memo == "transaction de clôture du compte"
      ) {
        data.push(row.key);
      }
    });
    return data;
  };

  const defaultSorted = [
    {
      dataField: "date",
      order: "asc",
    },
  ];

  // A function to handle the select rows of the table in remove mode
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    hideSelectAll: false,
    nonSelectable: nonSelectableRows(),
    onSelect: (data) => {
      props.rowSelection(data);
    },
    onSelectAll: (data) => {
      props.rowSelection(data);
    },
  };

  // Set a style of background with the gray color for the futur transaction in the table
  const rowStyle = (row) => {
    const style = {};
    if (new Date() <= new Date(row.dateTemp)) {
      style.backgroundColor = COLORS.emptiness;
      style.color = COLORS.glyphSecondary;
    }
    if (row.key === "-1") style.backgroundColor = COLORS.highlight;
    return style;
  };

  const paginationOption = {
    sizePerPageList: [
      {
        text: "25",
        value: 25,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "75",
        value: 75,
      },
      {
        text: "100",
        value: 100,
      },
    ],
    onPageChange: (page) => {
      props.setUserCurrentTablePage(page);
    },
  };

  return (
    refreshPeriodicties === true && (
      <div style={{ height: "60vh" }}>
        <Row className="h-100">
          <EditPayeeList
            handleFilter={() => props?.handleFilter()}
            beneficiaries={props?.beneficiaryData}
            t={t}
            setOpenBeneficiaireModal={setOpenBeneficiaireModal}
            openBeneficiaireModal={openBeneficiaireModal}
            props={{ ...props }}
          />
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            className="bg-white h-auto p-0"
          >
            <BootstrapTable
              responsive
              noDataIndication={props.transactionData.length === 0 ? <img src={noTransactionsIllustration} width={"250px"} /> : null}
              keyField="key"
              columns={columns}
              bootstrap4
              bordered={false}
              striped={true}
              hover={true}
              data={props.transactionData}
              rowStyle={rowStyle}
              selectRow={selectRow}
              defaultSorted={defaultSorted}
              cellEdit={cellEditFactory({ mode: "click", blurToSave: true })}
            />
          </Col>
        </Row>
        <AccountInfo
          setShowAccountInfo={props.setShowAccountInfo}
          showAccountInfo={props.showAccountInfo}
        />
        <PayeeInfo
          setShowPayeeInfo={props.setShowPayeeInfo}
          showPayeeInfo={props.showPayeeInfo}
        />
        <CategoryInfo
          setShowCategoryInfo={props.setShowCategoryInfo}
          showCategoryeInfo={props.showCategoryeInfo}
        />
        <SubscriptionPlan
          showModal={showPlanModal}
          setShowModal={() => {
            setShowPlanModal(false);
          }}
        />
      </div>
    )
  );
}
