import React from "react";
import { Helmet } from 'react-helmet-async';
import { Row, Col } from "reactstrap";
import { COLORS } from "assets/constants/theme";

export default function MainHeader({ title }) {
  return (
    <div>
      <Helmet>
        <title>Fineo | {title}</title>
      </Helmet>
      <Row className="align-items-center pt-5 pb--1 m-1">
        <Col>
          <h1 style={{ color: COLORS.glyphPrimary, fontFamily: "poppins", fontSize: "20px" }}>
            {sessionStorage.getItem("SESSION_SPACE_NAME") + ' > ' + title}
          </h1>
        </Col>
        <Col md="12" xs="12" xl="12" xm="12" className="text-black">
          <hr />
        </Col>
      </Row>
    </div>
  );
}
