import {
  GET_ACCOUNT_REPORT,
  GET_CHART_ENTRIES,
  GET_CHART_DEPENSE,
  GET_CHART_BUDGET,
  CLEAN_RAPPORT,
} from "../actions/types";

const initialState = {
  chartEntries: {
    data: {
      labels: [],
      datasets: [
        {
          label: "Sales",
          backgroundColor: "black",
          data: [],
          maxBarThickness: 10,
        },
      ],
    },
  },
  entriesCompte: [],
  depenseCompte: [],
  chartDepense: {
    data: {
      labels: [],
      datasets: [
        {
          label: "Sales",
          backgroundColor: "black",
          data: [],
          maxBarThickness: 10,
        },
      ],
    },
  },
  chartBudget: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ACCOUNT_REPORT:
      return {
        ...state,
        entriesCompte: [{ account_id: -1, account_name: "All Accounts" }, ...payload,],
        depenseCompte: [{ account_id: -1, account_name: "All Accounts" }, ...payload],
      };
    case GET_CHART_ENTRIES:
      return {
        ...state,
        chartEntries: payload,
      };
    case GET_CHART_DEPENSE:
      return {
        ...state,
        chartDepense: payload,
      };
    case GET_CHART_BUDGET:
      return {
        ...state,
        chartBudget: payload,
      };
    case CLEAN_RAPPORT:
      return {
        chartEntries: {
          data: {
            labels: [],
            datasets: [
              {
                label: "Sales",
                backgroundColor: "black",
                data: [],
                maxBarThickness: 10,
              },
            ],
          },
        },
        entriesCompte: [],
        depenseCompte: [],
        chartDepense: {
          data: {
            labels: [],
            datasets: [
              {
                label: "Sales",
                backgroundColor: "black",
                data: [],
                maxBarThickness: 10,
              },
            ],
          },
        },
        chartBudget: [],
      };
    default:
      return state;
  }
}
