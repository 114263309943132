import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet-async';
import { useTranslation } from "react-i18next";
import { getUserAccountInfo } from "../actions/Auth";
import { getListPrice, createSubscription } from "../actions/Stripe";
import { useHistory } from "react-router-dom";
import { CardBody, Container, Row, Col } from "reactstrap";
import { COLORS } from "assets/constants/theme";
import PropTypes from "prop-types";
import PaymentComponent from "../components/Stripe/PaymentC";
import { StringManipulator } from "@fineo-io/main-javascript-library";

function Payment({
  authState,
  stripeState,
  getListPrice,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const [listOfPrices, setListOfPrices] = useState([]);
  const [activePlan, setActivePlan] = useState({
    index: 0,
    currencySymbol: "EUR",
    unit_amount_decimal: 0
  });

  useEffect(() => {
    if (authState?.user?.subscription_id !== 2) {
      history.push("/app/billing")
    }
  }, [authState?.user?.subscription_id]);

  const subscribe = async () => {
    try {
      const subscriptionResponse = await createSubscription({
        price: activePlan?.id,
        currency: activePlan?.currency,
      });
      if (subscriptionResponse) {
        window.location.href = subscriptionResponse.url
      } else {
        setShowErrorModal(true);
      }
    } catch (error) {
      setPayemntProcessing(false);
      setShowErrorModal(true);
    }
  };

  return authState?.user?.subscription_id !== undefined && (
    <CardBody>
      <Helmet>
        <title>Fineo | {StringManipulator.capitalize(t("payment_title"))}</title>
      </Helmet>
      <div
        className="d-flex align-items-center mb--4 mt--5"
        style={{
          minHeight: "200px",
          color: COLORS.glyphPrimary,
          width: "90%",
          marginLeft: "auto",
          marginRight: "auto"
        }}
      >
        <Container className="align-items-center" fluid>
          <Row>
            <Col lg="10" md="10">
              <h5
                className="display-4"
                style={{
                  color: COLORS.glyphPrimary,
                  fontWeight: "bolder"
                }}
              >
                {t("Subscription Settings")}
              </h5>
              <p className="mt-0">
                {t("Unlock access to premium features of Fineo and get everything you need to take back the control of your finances.")}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <PaymentComponent
        t={t}
        history={history}
        setActivePlan={setActivePlan}
        activePlan={activePlan}
        listOfPrices={listOfPrices}
        setListOfPrices={setListOfPrices}
        authState={{
          ...authState,
          refresh_token: localStorage.getItem("REFRESH_TOKEN"),
          access_token: sessionStorage.getItem("ACCESS_TOKEN")
        }}
        stripeState={stripeState}
        getListPrice={getListPrice}
        subscribe={subscribe}
      />
    </CardBody>
  )
}

Payment.prototype = {
  authState: PropTypes.object.isRequired,
  stripeState: PropTypes.object.isRequired,
  getUserAccountInfo: PropTypes.func.isRequired,
  getListPrice: PropTypes.func.isRequired,
  createSubscription: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authState: state.auth,
  stripeState: state.Stripe,
});

export default connect(mapStateToProps, {
  getUserAccountInfo,
  getListPrice,
})(Payment);
