import { StringManipulator } from "@fineo-io/main-javascript-library";
import { STYLES } from "assets/constants/theme";
import { COLORS } from "assets/constants/theme";
import StandardButton from "components/Buttons/standardButton/StandardButton";
import DefaultSelect from "components/inputs/DefaultSelect";
import React, { useState } from "react";
import ReactDatetime from "react-datetime";
import { Col, Form, Input, Row } from "reactstrap";
import moment from "moment";

export default function WidgetNewAccount({
  allAccountType,
  handleAddNewAccountToDb,
  onCancel,
  translate,
}) {
  // Object: to handle the new account infos.
  const [newAccount, setNewAccount] = useState({
    accountName: "",
    accountType: allAccountType[0]?.id,
    accountTypeName: allAccountType[0]?.accountType,
    accountDate: moment(new Date()),
    accountInitalSold: 0,
    accountABudgeter: true,
    account_note: "",
  });

  // On submiting the add new account form:
  const handleAddNewAccount = () => {
    if (newAccount.accountName === "") return;
    // Send Data To account component, then send it to actions, then to backEnd
    handleAddNewAccountToDb(newAccount);
    // Clear the form inputs
    setNewAccount({
      accountName: "",
      accountType: allAccountType[0].id,
      accountTypeName: allAccountType[0].accountType,
      accountDate: moment(new Date()),
      accountInitalSold: "",
      accountABudgeter: false,
      account_note: "",
    });
  };

  // Initial the new account name, initial balance and if the account is to budget or not.
  const changeHandler = (e) => {
    const value = e.target.value,
      id = e.target.id;
    if (id === "accountName")
      setNewAccount({ ...newAccount, accountName: value });
    if (id === "accountBalance")
      setNewAccount({ ...newAccount, accountInitalSold: value });
    if (id === "toBudget")
      setNewAccount({ ...newAccount, accountABudgeter: e.target.checked });
    if (id === "accountNote")
      setNewAccount({ ...newAccount, account_note: value });
  };

  // Initial the new account type.
  const changeAccountTypeHandler = (accountTypeId) => {
    let accountType = allAccountType.filter(
      (accountType) => accountType.id === accountTypeId.value
    );
    setNewAccount({
      ...newAccount,
      accountType: accountTypeId.value,
      accountTypeName: accountType[0].accountType,
    });
  };

  // Initial the new account open date.
  const changeDateHandler = (date) => {
    setNewAccount({ ...newAccount, accountDate: date });
  };

  return (
    <Form>
      <Row className="mt-3 mb-3">
        <Col
          className="form-control-label mb-2"
          md="12"
          xs="12"
          xl="12"
          lg="12"
          sm="12"
        >
          {translate("Account_Nom_du_compte")}
        </Col>
        <Col md="12" xs="12" xl="12" lg="12" sm="12">
          <Input
            name="accountName"
            id="accountName"
            className="ourCustomInputStyle"
            type="text"
            value={newAccount.accountName}
            onChange={changeHandler}
          />
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col
          className="form-control-label mb-2"
          md="12"
          xs="12"
          xl="12"
          lg="12"
          sm="12"
        >
          {translate("Account_Type_de_compte")}
        </Col>
        <Col md="12" xs="12" xl="12" lg="12" sm="12">
          <DefaultSelect
            name="accountType"
            id="accountType"
            onChange={changeAccountTypeHandler}
            options={allAccountType.map((account) => ({
              value: account.id,
              label: translate(`${account.accountType}`),
            }))}
          />
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col
          className="form-control-label mb-2"
          md="12" xs="12" xl="12" lg="12" sm="12"
        >
          {translate("Account_Date_ouverture")}
        </Col>
        <Col md="12" xs="12" xl="12" lg="12" sm="12">
          <ReactDatetime
            id="accountDate"
            value={newAccount.accountDate}
            onChange={changeDateHandler}
            inputProps={{
              placeholder: translate("Select a date",),
              style: {
                ...STYLES.selectStyle,
              },
            }}
            timeFormat={false}
            closeOnSelect={true}
            dateFormat="DD/MM/YYYY"
            locale={translate("Account_lang")}
          />
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col
          className="form-control-label mb-2"
          md="12" xs="12" xl="12" lg="12" sm="12"
        >
          {translate("Account_Solde_de_départ")}
        </Col>
        <Col md="12" xs="12" xl="12" lg="12" sm="12">
          <Input
            defaultValue="0.00"
            placeholder="0.00"
            name="accountBalance"
            id="accountBalance"
            type="number"
            step="0.01"
            className="ourCustomInputStyle"
            onChange={changeHandler}
          />
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col
          className="form-control-label mb-2"
          md="12"
          xs="12"
          xl="12"
          lg="12"
          sm="12"
        >
          Notes
        </Col>
        <Col md="12" xs="12" xl="12" lg="12" sm="12">
          <Input
            rows="3"
            id="accountNote"
            name="accountNote"
            type="textarea"
            defaultValue={newAccount.account_note}
            className="bg-transparent ourCustomTextAreaStyle"
            onChange={changeHandler}
          />
        </Col>
      </Row>
      <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "30px" }}>
        <div style={{ flex: 1 }}>
          <StandardButton
            style={{
              fontSize: "15px",
            }}
            className="w-100"
            onClick={handleAddNewAccount}
            text={translate("Save")}
          />
        </div>
        <div style={{ flex: 1, marginLeft: "10px" }} >
          <StandardButton
            color={COLORS.glyphSecondary}
            style={{
              fontSize: "15px",
            }}
            className="w-100"
            onClick={onCancel}
            text={StringManipulator.capitalize(translate("global.cancel._"))}
          />
        </div>
      </div>
    </Form>
  );
}
