import {
  GET_BUDGETS,
  GET_BUDGET_HEADER,
  SET_BUDGET_GROUP,
  SET_BUDGET_CATEGORIE,
  UPDATE_BUDGET_CATEGORIE,
  DELETE_BUDGET_CATEGORIE,
  FETCH_BUDGET_REQUEST,
  DEFINE_BUDGET_TRANSACTIONS,
  DEFINE_BUDGET_GROUP_ORDER,
  SET_CATEGORY_ROW,
  HANDEL_SAVE_CATEGORY_ROW,
  DEFINE_BUDGET_CATEGORY_ORDER,
  CLEAN_BUDGET,
  RUN_BUDGET_ONBOARDING,
  HIDE_TRANSACTIONS_WITHOUT_CATS,
  SET_BDATE,
  SET_CATEGORY_FILTER
} from "../actions/types";
import update from "immutability-helper";
import moment from "moment";

const initialState = {
  // All budgets State:
  budgets: {},
  // The data has been loaded or not:
  loading: true,
  globalValues: {},
  // All Transaction for the budget_category_id State:
  budgetMouvement: [],
  mouvementTotalAmount: 0,
  transactionsWithoutCats: 0,
  showTransactionsWithoutCats: true,
  onBoardingModeBudget: false,
  budgetCategoryId: null,
  selectedBudgetCategoryId: null,
  selectedBudgetCategoryDate: null,
  bDate: moment(new Date()).format("YYYY-MM-01")
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_BUDGET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SET_BDATE:
      return {
        ...state,
        bDate: payload
      }
    // Get all budgets:
    case GET_BUDGETS:
      return {
        ...state,
        budgets: payload,
        transactionsWithoutCats: action.transactionsWithoutCats,
        loading: false,
      };
    // Get all Transaction for the budget_category_id
    case DEFINE_BUDGET_TRANSACTIONS:
      return {
        ...state,
        budgetMouvement: payload.budgetMouvement,
        mouvementTotalAmount: payload.budgetMouvement.reduce((accumulator, current) => accumulator + current.transaction_amount, 0),
        budgetCategoryId: payload.budgetCategoryId
      };
    // Get calcul of budgetwidget:
    case GET_BUDGET_HEADER:
      return {
        ...state,
        globalValues: payload,
      };
    // Set new budgetGroup:
    case SET_BUDGET_GROUP:
      state.budgets.budgetData[payload.budgetTypeName][
        payload.newBudgetGroup.budget_group_name
      ] = [];
      state.budgets.budgetData[payload.budgetTypeName][
        payload.newBudgetGroup.budget_group_name
      ].push(payload.newBudgetGroup);

      return {
        ...state,
        budgets: state.budgets,
      };
    // Set new budgetCategory:
    case SET_BUDGET_CATEGORIE:
      return state;
    // Update budgetCategory:
    case UPDATE_BUDGET_CATEGORIE:
      return state;
    // Delete budgetCategory:
    case DELETE_BUDGET_CATEGORIE:
      return state;
    // Udate budget group order:
    case DEFINE_BUDGET_GROUP_ORDER:
      const newArray = [];
      let newObject1 = {
        groupName: payload.id,
        order: payload.startIndex,
      },
        newObject2;

      Object.keys(state.budgets.budgetData[payload.category]).map(
        (element, index) => {
          state.budgets.budgetData[payload.category][element].forEach(
            (element1) => {
              if (element1.groupOrder === payload.startIndex) {
                return (newObject2 = {
                  groupName: element,
                  order: payload.endIndex,
                });
              }
            }
          );
        }
      );

      Object.keys(state.budgets.budgetData[payload.category]).map(
        (element, index) => {
          newArray.push({
            groupName: element,
            groupIndex: index,
            groupContent: state.budgets.budgetData[payload.category][element],
          });
        }
      );
      const newArray2 = [];
      if (payload.startIndex - payload.endIndex > 1) {
        for (let i = 0; i < newArray.length; i++) {
          if (newArray[i].groupName !== newObject1.groupName && newArray[i].groupIndex <= newObject1.order && newArray[i].groupIndex > newObject2.order) {
            newArray2[newArray[i].groupIndex - 1] = {
              ...newArray[i],
              groupIndex: newArray[i].groupIndex - 1,
            };

          }

          if (newArray[i].groupName === newObject1.groupName) {
            newArray2[newObject1.order] = {
              ...newArray[i],
              groupIndex: newObject1.order,
            };
          }

          if (newArray[i].groupName !== newObject1.groupName && newArray[i].groupIndex > newObject1.order) {
            newArray2.push(newArray[i]);
          }

          if (newArray[i].groupName !== newObject1.groupName && newArray[i].groupIndex < newObject2.order) {
            newArray2.push(newArray[i]);
          }
        }
      } else {
        for (let i = 0; i < newArray.length; i++) {
          if (newArray[i].groupName === newObject1.groupName) {
            newArray2[newObject1.order] = {
              ...newArray[i],
              groupIndex: newObject1.order,
            };
          } else if (newArray[i].groupName === newObject2.groupName) {
            newArray2[newObject2.order] = {
              ...newArray[i],
              groupIndex: newObject2.order,
            };

          } else {
            newArray2.push(newArray[i]);
          }
        }
      }
      const newArray3 = [];

      for (let i = 0; i < newArray2.length; i++) {
        if (typeof newArray2[i] !== typeof undefined) {
          if (typeof newArray2[i] !== typeof undefined) {
            newArray3[`${newArray2[i].groupName}`] = newArray2[i].groupContent;
          } else {
            newArray3[`${newArray2[i].groupName}`] = newArray2[i].groupContent;
          }
        }
      }

      let newstateT = state.budgets.budgetData;
      newstateT[`${payload.category}`] = newArray3;
      return {
        ...state,
        budgets: {
          ...state.budgets,
          budgetData: newstateT,
        },
      };

    // Add new budget row to the state
    case SET_CATEGORY_ROW:
      let newData =
        state.budgets.budgetData[`${payload.budget_type_name}`][
        `${payload.budget_group_name}`
        ];

      newData = [...newData, payload];

      let newTypeData = state.budgets.budgetData[`${payload.budget_type_name}`];
      newTypeData[`${payload.budget_group_name}`] = [...newData];

      let newState = state.budgets.budgetData;
      newState[`${payload.budget_type_name}`] = newTypeData;

      return {
        ...state,
        budgets: {
          ...state.budgets,
          budgetData: newState,
        },
      };
    // Handel save new Budget row
    case HANDEL_SAVE_CATEGORY_ROW:
      let group =
        state.budgets.budgetData[`${payload.budget_type_id}`][
        `${payload.budget_group_name}`
        ];

      let index = group.findIndex((item) => payload.key === item.key);

      group.splice(index, 1, payload);

      let new_type = state.budgets.budgetData[`${payload.budget_type_id}`];
      new_type[`${payload.budget_group_name}`] = [...group];

      let newstate = state.budgets.budgetData;
      newstate[`${payload.budget_type_id}`] = new_type;
      return {
        ...state,
        budgets: {
          ...state.budgets,
          budgetData: newstate,
        },
      };
    // Update the budget order
    case DEFINE_BUDGET_CATEGORY_ORDER:
      let newOrderGroup =
        state.budgets.budgetData[`${payload.budget_type_id}`][
        `${payload.budget_group_name}`
        ];
      const dragRow = newOrderGroup[payload.order1];
      newOrderGroup = update(newOrderGroup, {
        $splice: [
          [payload.order1, 1],
          [payload.order2, 0, dragRow],
        ],
      });

      let newOrderType = state.budgets.budgetData[`${payload.budget_type_id}`];
      newOrderType[`${payload.budget_group_name}`] = [...newOrderGroup];

      let new_state = state.budgets.budgetData;
      new_state[`${payload.budget_type_id}`] = newOrderType;

      return {
        ...state,
        budgets: {
          ...state.budgets,
          budgetData: new_state,
        },
      };
    case HIDE_TRANSACTIONS_WITHOUT_CATS:
      return {
        ...state,
        showTransactionsWithoutCats: false,
      };
    case RUN_BUDGET_ONBOARDING: return {
      ...state,
      onBoardingModeBudget: payload
    }
    case CLEAN_BUDGET:
      return {
        budgets: {},
        loading: true,
        globalValues: {},
        budgetMouvement: [],
      };
    case SET_CATEGORY_FILTER:
      return {
        ...state,
        selectedBudgetCategoryId: payload?.categoryId,
        selectedBudgetCategoryDate: payload?.date,
      };
    default:
      return state;
  }
}
