import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import { isBrowser } from 'react-device-detect';
import { message } from "antd";
import {
  FormGroup,
  Input,
  FormFeedback,
  InputGroup,
  Row,
  Col,
  Label,
} from "reactstrap";
// core components
import { connect } from "react-redux";
import { logIn, resendValidationEmail } from "../actions/Auth";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';
import sideImage from "../assets/img/Views/Login.png";
import Logo from "../assets/img/brand/fineo-login-logo.png";
import StandardButton from "components/Buttons/standardButton/StandardButton";
import { COLORS } from "assets/constants/theme";
import hexToRgba from "helpers/hexToRgba";
import { StringManipulator } from "@fineo-io/main-javascript-library";

function Login({ logIn, history, logInState, resendValidationEmail }) {
  const { t } = useTranslation();
  ReactGA.set({userType: 'guest'})

  const [styleState, setStyleState] = useState({
    email: {
      input: {
        paddingLeft: "15px"
      }, icon: {}
    },
    password: {
      input: {
        paddingLeft: "15px"
      }, icon: {}
    },
  });

  const [invalid, setInvalid] = useState({});
  const [validate, setvalidate] = useState(false);
  const [alertHide, setAlertHide] = useState({ display: "none" });

  const [loginAlertMessage, setLoginAlertMessage] = useState(
    t("Login_Votre_adresse_email_ou_mot_de_passe_est_incorrect")
  );

  const [loginObject, setLoginObject] = useState({
    signInEmail: "",
    signInPassword: "",
  });

  const [rememberMe, setRememberMe] = useState(false);
  const [inputType, setInputType] = useState("password");

  useEffect(() => {
    setAlertHide({ display: "none" });
    if (logInState.isLogin !== "") {
      if (logInState.isLogin === false) {
        if (logInState.is_valid === false) {
          setLoginAlertMessage(t("Login_Verifier_votre_adresse_email"));
          setAlertHide({
            display: "block",
            backgroundColor: hexToRgba(COLORS.cash),
            border: `1px solid ${COLORS.cash}`,
            fontWeight: "400",
          });
        } else {
          if (logInState.isNotStillLogedIn === true) return;
          setLoginAlertMessage(
            t("Login_Votre_adresse_email_ou_mot_de_passe_est_incorrect")
          );
          setAlertHide({
            display: "block",
            backgroundColor: hexToRgba(COLORS.cash),
            border: `1px solid ${COLORS.cash}`,
            fontWeight: "400",
          });
        }
      }
    }
  }, [logInState.isLogin, logInState.is_valid]);

  //validate the email Adress
  function ValidateEmail(inputText) {
    var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (inputText.value.match(mailformat)) {
      setvalidate(true);
      return true;
    } else {
      setvalidate(false);
      return false;
    }
  }

  const onSubmitSignIn = async (e) => {
    e.preventDefault();
    if (validate) {
      setInvalid({ ...invalid, email: false });
      setStyleState({
        ...styleState,
        email: {
          icon: { border: `${COLORS.white} solid 2px` },
          input: { border: `${COLORS.white} solid 2px` },
        },
      });

      //login in or throw an alert
      let login = async () => {
        return await logIn({ ...loginObject, rememberMe }, history);
      };
      await login();
      setAlertHide({
        display: "block",
        backgroundColor: hexToRgba(COLORS.cash),
        border: `1px solid ${COLORS.cash}`,
        fontWeight: "400",
      });
    } else {
      setInvalid({ ...invalid, email: true });
      setStyleState({
        ...styleState,
        email: {
          icon: { border: `${COLORS.warning} solid 2px`, borderRight: "none" },
          input: { border: `${COLORS.warning} solid 2px`, borderLeft: "none" },
        },
      });
    }
  };

  //Lower case  field values of email
  const onEmailChange = (event) => {
    ValidateEmail(event.target);
    setLoginObject({
      ...loginObject,
      signInEmail: event.target.value.toLowerCase(),
    });
  };

  //recover field values of password
  const onPasswordChange = (event) => {
    setLoginObject({
      ...loginObject,
      signInPassword: event.target.value,
    });
  };

  return (
    <Row className="d-flex align-items-center" style={{
      height: "100vh"
    }}>
      <Helmet>
        <title>Fineo | {StringManipulator.capitalize(t("Login"))}</title>
      </Helmet>
      {isBrowser && (
        <Col xl="6" lg="6" md="6" xs="12" sm="12" className="d-flex align-items-center" style={{
          height: "100%",
          textAlign: "center",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center"
        }}>
          <div style={{
            backgroundColor: COLORS.glyphLight,
            height: "100%",
            width: "100%",
            borderRadius: "8px",
          }}>
            <div style={{
              height: "auto",
              marginTop: "10%",
              marginBottom: "10%",
            }}>
              <img
                alt="..."
                style={{
                  minHeight: "150px",
                  minWidth: "150px",
                  maxWidth: "450px",
                  maxHeight: "400px"
                }}
                src={sideImage}
              />
              <div style={{
                fontWeight: "bolder"
              }}>
                <h1 style={{
                  fontWeight: "bolder"
                }}>
                  {t("Join Us At Fineo !")}
                </h1>
                <h1 style={{
                  width: "90%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  fontWeight: "800"
                }}>
                  {t("Manage all your money from one single app and take back control of your finances")}
                </h1>
              </div>
            </div>
          </div>
        </Col>
      )}

      <Col xl="6" lg="6" md="6" xs="12" sm="12">
        <div style={{
          width: isBrowser ? "60%" : "80%",
          fontWeight: "bolder",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "50px"
        }}>
          <img src={Logo} style={{
            height: "65px",
            width: "190px",
            marginBottom: "15px",
            marginTop: "10px",
            marginLeft: "-20px"
          }} />
          <h2>
            {t("Welcome Back !")}
          </h2>
          <h3>
            {t("New here ?")}
            <Link to="/reg/signup">
              <span style={{ fontWeight: "bold", color: COLORS.primary }} className="ml-1">
                {t("Create an account")}
              </span>
            </Link>
          </h3>
        </div>
        <div style={{
          width: isBrowser ? "60%" : "80%",
          marginLeft: "auto",
          marginRight: "auto"
        }}>
          <form onSubmit={onSubmitSignIn}>
            <FormGroup>
              <Label for="Email" style={{
                color: COLORS.glyphSecondary,
                fontWeight: "300"
              }}>{t("Email")}</Label>
              <InputGroup className="input-group-merge input-group-alternative">
                <Input
                  invalid={invalid.email}
                  name="Email"
                  id="Email"
                  required
                  onChange={onEmailChange}
                  style={styleState.email.input}
                />
                <FormFeedback style={{ fontSize: "inherit" }}>
                  &nbsp;&nbsp;{t("Login_Adresse_Email_Invalid")} !
                </FormFeedback>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label for="password" style={{
                color: COLORS.glyphSecondary,
                fontWeight: "300"
              }}>{t("Login_Mot_de_passe")}</Label>
              <InputGroup className="input-group-merge input-group-alternative">
                <Input
                  invalid={invalid.password}
                  name="password"
                  id="password"
                  type={inputType}
                  onChange={onPasswordChange}
                  style={styleState.password.input}
                />
                {
                  inputType === "password" ? (<span style={{
                    padding: "12px",
                    alignContent: "center",
                    alignItems: "center",
                    cursor: "pointer"
                  }}
                    onClick={() => {
                      setInputType("text")
                    }}>
                    <i className="fas fa-eye fa-1x" ></i>
                  </span>) : (<span style={{
                    padding: "12px",
                    alignContent: "center",
                    alignItems: "center",
                    cursor: "pointer"
                  }}
                    onClick={() => {
                      setInputType("password")
                    }}
                  >
                    <i className="fas fa-eye-slash fa-1x" ></i>
                  </span>)
                }
                <FormFeedback></FormFeedback>
              </InputGroup>
            </FormGroup>
            <span style={alertHide}>
              {loginAlertMessage ===
                t("Login_Verifier_votre_adresse_email") ? (
                <span>
                  {loginAlertMessage}
                  <a
                    href="#"
                    onClick={() => {
                      resendValidationEmail(
                        loginObject.signInEmail,
                        message,
                        t
                      );
                    }}
                  >
                    {t("Login_Renvoyer_a_nouveau")}
                  </a>
                </span>
              ) : (
                loginAlertMessage
              )}
            </span>
            <FormGroup className="d-flex flex-row mt--1">
              <span className="mr-1" style={{ marginTop: "2px", cursor: "pointer" }}>
                <input
                  id="customCheckLogin"
                  value={rememberMe}
                  type="checkbox"
                  onChange={() => {
                    setRememberMe(!rememberMe);
                  }}
                />
              </span>
              <span>
                <label>
                  <span style={{
                    color: COLORS.glyphSecondary,
                    cursor: "pointer",
                    fontWeight: "300"
                  }}
                    onClick={() => {
                      setRememberMe(!rememberMe);
                    }}>
                    {t("LoginSe_rappeler_de_moi")}
                  </span>
                </label>
              </span>
            </FormGroup>
            <div className="text-center">
              <StandardButton
                className="my-3 mt--2"
                type="submit"
                style={{
                  width: "100%"
                }}
                text={t("Login_Se_connecter")}
              />
            </div>
          </form>
          <Row>
            <Col xs="6">
              <Link to="/reg/forgotpassword">
                <span style={{
                  color: COLORS.glyphSecondary,
                  fontWeight: "300"
                }}>
                  {t("Login_Mot_de_passe_oublie")}?
                </span>
              </Link>
            </Col>
            <Col className="text-right" xs="6">
              <Link to="/reg/signup">
                <span style={{
                  color: COLORS.glyphSecondary,
                  fontWeight: "300"
                }}>
                  {t("Login_Creer_un_nouveau_compte")}
                </span>
              </Link>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}

Login.prototype = {
  logIn: PropTypes.func.isRequired,
  resendValidationEmail: PropTypes.func.isRequired,
  logInState: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  logInState: state.auth,
});

export default connect(mapStateToProps, {
  logIn,
  resendValidationEmail,
})(Login);
