import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import localEnglishDictionary from "./assets/locals/en/translation.json";
import localFrenchDictionary from "./assets/locals/fr/translation.json";
import { i18n as mainDictionary } from "@fineo-io/main-javascript-library";

// Load translation dictionaries from different sources
const resources = {
  en: {
    translation: {
      ...mainDictionary.store.data.en.translation,
      ...localEnglishDictionary
    },
  },
  fr: {
    translation: {
      ...mainDictionary.store.data.fr.translation,
      ...localFrenchDictionary
    },
  },
};

// Configure i18next with custom backend and language detector
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: resources,
    fallbackLng: ["en"],
    whitelist: ["en", "fr"],
    detection: { checkWhitelist: true },
    react: { useSuspense: false },
    debug: false,
  });
