const BASE_COLORS = {
  LIGHT_BLUE: '#26C1FD',
  ROYAL_BLUE: '#5b86e5',
  HIGHLIGHT_BLUE: "#d8eefa",
  GREEN: '#14C48B',
  YELLOW: '#FFBF53',
  ORANGE: '#FF7F50',
  RED: '#FF4D4D',
  DARK_GRAY: '#354655',
  GRAY: '#A4B0BE',
  CLOUD_GRAY: '#f5f7f9',
  LIGHT_GRAY: '#f5f7fb',
  WHITE: '#FFFFFF',
  TRANSPARENT_WHITE: 'rgba(255,255,255,0.25)',
};

export const COLORS = {
  emptiness: BASE_COLORS.CLOUD_GRAY,
  primary: BASE_COLORS.LIGHT_BLUE,
  secondary: BASE_COLORS.ROYAL_BLUE,
  highlight: BASE_COLORS.HIGHLIGHT_BLUE,
  success: BASE_COLORS.GREEN,
  warning: BASE_COLORS.ORANGE,
  danger: BASE_COLORS.RED,
  neutral: BASE_COLORS.WHITE,
  cash: BASE_COLORS.YELLOW,
  glyphPrimary: BASE_COLORS.DARK_GRAY,
  glyphSecondary: BASE_COLORS.GRAY,
  glyphLight: BASE_COLORS.LIGHT_GRAY,
  glyphOverlay: BASE_COLORS.TRANSPARENT_WHITE,
  gradient: [BASE_COLORS.LIGHT_BLUE, BASE_COLORS.ROYAL_BLUE],
};

export const CHART_COLORS = {
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529"
  },
  theme: {
    default: BASE_COLORS.ROYAL_BLUE,
    primary: BASE_COLORS.LIGHT_BLUE,
    secondary: BASE_COLORS.LIGHT_GRAY,
    info: "#11cdef",
    success: BASE_COLORS.GREEN,
    danger: BASE_COLORS.RED,
    warning: BASE_COLORS.ORANGE
  },
  black: BASE_COLORS.DARK_GRAY,
  white: BASE_COLORS.WHITE,
  transparent: "transparent"
};

export const REPORT_COLORS = [
  "#6495ED",
  "#DFFF00",
  '#E91E63',
  "#9FE2BF",
  "#40E0D0",
  "#DE3163",
  "#FFBF00",
  "#FF7F50",
  "#CCCCFF",
  '#D32F2F',
  '#C62828',
  '#B71C1C',
  '#FF8A80',
  '#FF5252',
  '#FF1744',
  '#D50000',
];

export const STYLES = {
  selectStyle: {
    height: "38px",
    borderRadius: '5px',
    borderColor: COLORS.glyphSecondary,
    fontSize: '12px',
    color: COLORS.glyphPrimary,
    fontFamily: 'poppins',
    fontWeight: '200',
  },
}
