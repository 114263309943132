import React, { useState } from "react";
// node.js library that concatenates classes (strings)
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Col,
  Row,
  CardBody,
  Card,
} from "reactstrap";
import FormatCurrency from "../Tools/Currency/Currency";
import { getBudget } from "../../actions/Budget";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { COLORS } from "assets/constants/theme";
import { StringManipulator } from "@fineo-io/main-javascript-library";
function BudgetTotalWidget({ authState, budgetState }) {
  const [activeTotal, setActiveTotal] = useState("budgetAmount");
  const [totalAmount, setTotalAmount] = useState("total");
  const { t } = useTranslation();
  const getbudgetAmount = (dataset) => {
    let budgetAmounts = 0;
    let activitie = 0;
    let disponibles = 0;
    dataset.forEach(({ budgetAmount, activities, disponible }) => {
      budgetAmounts += budgetAmount;
      activitie += activities;
      disponibles += disponible;
    });
    return [
      {
        budgetAmount: budgetAmounts,
        activities: activitie,
        disponible: disponibles,
      },
    ];
  };
  return (
    <>
      <Row style={{
        fontWeight: "200"
      }}>
        <Col>
          <div className="d-flex justify-content-center mx-4">
            <span style={{
              cursor: "pointer",
              fontWeight: "200",
              paddingBottom: "8px",
              color: `${activeTotal === "budgetAmount" ? COLORS.secondary : COLORS.glyphSecondary}`
            }}
              className="px-4 mx-1"
              onClick={() => {
                setActiveTotal("budgetAmount")
                setTotalAmount("total")
              }}
            >
              {StringManipulator.capitalize(t("global.budgeted._"))}
            </span>
            <span style={{
              cursor: "pointer",
              fontWeight: "200",
              paddingBottom: "8px",
              color: `${activeTotal === "activities" ? COLORS.secondary : COLORS.glyphSecondary}`
            }}
              className="px-4 mx-1"
              onClick={() => {
                setActiveTotal("activities")
                setTotalAmount("activities")
              }}
            >
              {StringManipulator.capitalize(t("global.consumed._"))}
            </span>
            <span style={{
              cursor: "pointer",
              paddingBottom: "8px",
              fontWeight: "200",
              color: `${activeTotal === "disponible" ? COLORS.secondary : COLORS.glyphSecondary}`
            }}
              className="px-4 mx-1"
              onClick={() => {
                setActiveTotal("disponible")
                setTotalAmount("disponible")
              }}
            >
              {StringManipulator.capitalize(t("global.available._"))}
            </span>
          </div>
        </Col>
      </Row>
      <Card style={{ padding: "-25px", borderRadius: "16px" }}>
        <CardBody className="budget-amount-to-budget" style={{ padding: "-25px", borderRadius: "16px" }}>
          {budgetState.budgets.TotalAmountType !== undefined && budgetState.budgets.TotalAmountType.map((data, indexT) => (
            <div key={Math.random()} >
              <div style={{
                borderBottom: "1px solid lightgray",
                paddingBottom: "10px",
                marginBottom: "15px"
              }}>
                <Row className="justify-content-between align-item-center ml-2 mt-1 mr-1" key={Math.random()}>
                  <Col className="justify-content-left">
                    {data.type === "1" ? (
                      <span style={{
                        fontWeight: "200",
                        color: COLORS.primary
                      }}>{StringManipulator.capitalize(t("global.monthly._"))}</span>
                    ) : data.type === "2" ? (
                      <span style={{
                        fontWeight: "200",
                        color: COLORS.primary
                      }}>{StringManipulator.capitalize(t("global.provision.s"))}</span>
                    ) : (
                      data.type === "3" &&
                      <span style={{
                        fontWeight: "200",
                        color: COLORS.primary
                      }}>{StringManipulator.capitalize(t("global.saving.s"))}</span>
                    )}
                  </Col>
                  <Col className="col-auto">
                    <span
                      style={
                        data.type === "1" ?
                          { fontWeight: "200", color: COLORS.primary } :
                          data.type === "2" ?
                            { fontWeight: "200", color: COLORS.primary } :
                            { fontWeight: "200", color: COLORS.primary }
                      }
                    >
                      {FormatCurrency(
                        data[totalAmount],
                        authState.user.currency_code,
                        authState.user.currency_locales
                      )}
                    </span>
                  </Col>
                </Row>
              </div>
              {Object.keys(budgetState.budgets.budgetData[data.type]).map(
                (category, index) => (
                  <div key={Math.random()} style={{
                    borderBottom: indexT + 1 === budgetState.budgets.TotalAmountType.length
                      && index + 1 === Object.keys(budgetState.budgets.budgetData[data.type]).length ? "" : "1px solid lightgray",
                    paddingBottom: "10px",
                    marginBottom: "15px"
                  }}>
                    <Row className="justify-content-between align-item-center ml-2 mt-2 mr-1">
                      <Col className="justify-content-left" style={{
                        fontWeight: "200"
                      }}>
                        {'> ' + category}
                      </Col>
                      <Col className="col-auto text-muted">
                        <span style={{
                          fontWeight: "200"
                        }}>
                          {FormatCurrency(
                            getbudgetAmount(budgetState.budgets.budgetData[data.type][category])[0][activeTotal],
                            authState.user.currency_code,
                            authState.user.currency_locales
                          )}
                        </span>
                      </Col>
                    </Row>
                  </div>
                )
              )}
            </div>
          ))}
        </CardBody>
      </Card>
    </>
  );
}

BudgetTotalWidget.prototype = {
  authState: PropTypes.object.isRequired,
  getBudget: PropTypes.func.isRequired,
  budgetState: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authState: state.auth,
  budgetState: state.budget,
});

export default connect(mapStateToProps, {
  getBudget,
})(BudgetTotalWidget);
