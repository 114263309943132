import {
  GET_ACCOUNT,
  GET_ACCOUNTS,
  GET_ACCOUNT_TYPES,
  GET_ACCOUNT_HEADER,
  GET_NORDIGEN_BANKS,
  SET_TO_IMPORT_ACCOUNT_NORDIGEN,
  GET_NORDIGEN_ACCOUNTS,
  SET_NAME_TO_IMPORT_ACCOUNT_NORDIGEN,
  SET_STARTING_DATE_TO_IMPORT_ACCOUNT_NORDIGEN,
  SET_ADD_OR_IMPORT_ACCOUNT_MODAL,
  DELETE_ACCOUNT,
  CLEAN_ACCOUNT,
  GET_ACCOUNTS_FOR_SELECT
} from "../actions/types";

const initialState = {
  // All accounts - used for displaying and filtering the list of accounts
  accounts: [],
  // All accounts - used for populating the select dropdown menus (to void being affected by account filters)
  selectAccounts: [],
  // All account types
  accountTypes: [],
  // Account Header
  accountHeader: [],
  // One Account
  accountDetail: {},
  // Show/Hide add or import new account modal
  add_or_import_account_modal: false,
  // Nordigen List Of Banks
  nordigen_list_of_banks: [],
  // Nordigen List Of Banks
  nordigen_list_of_accounts: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    // Get one account:
    case GET_ACCOUNT:
      return {
        ...state,
        accountDetail: payload,
      };

    // Get all accounts (for list display):
    case GET_ACCOUNTS:
      return {
        ...state,
        accounts: [...payload.account],
      };

    // Get all accounts (for dropdown selections):
    case GET_ACCOUNTS_FOR_SELECT:
      return {
        ...state,
        selectAccounts: [...payload.selectAccounts],
      };

    // Get all account types (for filters):
    case GET_ACCOUNT_TYPES:
      return {
        ...state,
        accountTypes: payload,
      };

    // Get acccounts header
    case GET_ACCOUNT_HEADER:
      return {
        ...state,
        accountHeader: payload,
      };

    // Delete one account:
    case DELETE_ACCOUNT:
      state.accounts = state.accounts.filter(
        (account) => account.key !== payload
      );
      return {
        ...state,
        accounts: state.accounts,
      };

    // Get list of banks
    case GET_NORDIGEN_BANKS:
      return {
        ...state,
        nordigen_list_of_banks: payload.data,
      };

    // Get list of account for a selected bank
    case GET_NORDIGEN_ACCOUNTS:
      return {
        ...state,
        nordigen_list_of_accounts:
          payload.data.map((account, index) => ({
            ...account,
            newName: account.account.name === "" ? `Account + ${index}` : account.account.name,
            newDate: account.account.starting_date,
            account: {
              ...account.account,
              name: account.account.name === "" ? `Account + ${index}` : account.account.name
            }
          })),
      };

    // Set to be imported accounts
    case SET_TO_IMPORT_ACCOUNT_NORDIGEN:
      state.nordigen_list_of_accounts.map((element) => {
        if (element.account.resourceId === payload.account_id)
          element.selected = payload.value;
      });
      return {
        ...state,
        nordigen_list_of_accounts: [...state.nordigen_list_of_accounts],
      };

    // Change name for to be imorted accounts
    case SET_NAME_TO_IMPORT_ACCOUNT_NORDIGEN:
      state.nordigen_list_of_accounts.map((account) => {
        if (account.account.resourceId === payload.account_id) {
          account.account.oldName = account.account.name;
          account.account.name = payload.value;
        }
      });
      return {
        ...state,
      };

    // Change the import from date for to be imported accounts
    case SET_STARTING_DATE_TO_IMPORT_ACCOUNT_NORDIGEN:
      state.nordigen_list_of_accounts.map((account) => {
        if (account.account.resourceId === payload.account_id) {
          account.account.starting_date = payload.value;
        }
      });
      return {
        ...state,
      };

    // Show/Hide import new account modal
    case SET_ADD_OR_IMPORT_ACCOUNT_MODAL:
      return {
        ...state,
        add_or_import_account_modal: payload
      }

    // Clean account state
    case CLEAN_ACCOUNT:
      return {
        accounts: [],
        selectAccounts: [],
        accountTypes: [],
        accountHeader: [],
        accountDetail: {},
        nordigen_list_of_accounts: [],
        nordigen_list_of_banks: [],
      };
    default:
      return state;
  }
}
