import { useState, useEffect } from "react";
import { message } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import useKeyboardShortcut from "use-keyboard-shortcut";
import LanguageSelect from "../components/Randoms/SelectLan";
import { useHistory } from "react-router-dom";
import {
  Card,
  FormGroup,
  FormFeedback,
  Input,
  Row,
  Label,
  Col,
  InputGroup,
  UncontrolledCollapse,
} from "reactstrap";
import {
  updateUserName,
  updateUserAccountPassword,
  getUserAccountInfo,
  logOut,
  cleanAuth,
} from "../actions/Auth";
import { getCurrency, updateCurrency, cleanProfile } from "../actions/Profile";
import { cleanBudget } from "../actions/Budget";
import { cleanTransaction } from "../actions/Transaction";
import { cleanReport } from "../actions/Reports";
import { getBudget } from "../actions/Budget";
import { useTranslation } from "react-i18next";
import ReactGAEvent from "../components/Tools/ReactGAEvent";
import StandardButton from "components/Buttons/standardButton/StandardButton";
import DefaultModal from "components/Modals/DefaultModal";
import { COLORS } from "assets/constants/theme";
import DefaultSelect from "components/inputs/DefaultSelect";
import { StringManipulator } from "@fineo-io/main-javascript-library";
import MainHeader from "components/Headers/MainHeader.jsx";

function ProfileSettings({
  authState,
  profilState,
  updateUserName,
  updateUserAccountPassword,
  getUserAccountInfo,
  getCurrency,
  updateCurrency,
  getBudget,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  //Old email and old name
  const [oldInformations, setOldInformations] = useState({});
  //new email and new name
  const [newInformations, setNewInformations] = useState({});
  //to know wich task is selected is it to change password or currency or other
  const [selectedTask, setSelectedTask] = useState("changePersonalInfos");

  const [currencyInfo, setCurrenyInfo] = useState({
    keyCurrency: "",
    label: "",
    selectedCurrency: -1,
    type: "",
  });

  //if the input is incorect then we set state to true
  const [invalidName, setInvalidName] = useState({ name: false });
  const [invalidEmail, setInvalidEmail] = useState({ email: false });
  const [invalidAncienPassword, setInvalidAncienPassword] = useState({
    ancienPassword: false,
  });
  const [invalidNewPassword1, setInvalidNewPassword1] = useState({
    newPassword1: false,
  });
  const [invalidNewPassword2, setInvalidNewPassword2] = useState({
    newPassword2: false,
  });
  //to display messages for each case
  const [messageName, setMessageName] = useState("");
  const [messageEmail, setMessageEmail] = useState("");
  const [messageAncienPassword, setMessageAncienPassword] = useState("");
  const [messageNewnPassword1, setMessageNewPassword1] = useState("");
  const [messageNewnPassword2, setMessageNewPassword2] = useState("");
  const [confirmDeletUserAccount, setConfirmDeletUserAccount] = useState("");
  const [rendrDeleteUserAccount, setRendrDeleteUserAccount] = useState(false);
  const [delete_after_30_days, setDelete_after_30_days] = useState(false);
  const [reasonOfDeletingAccount, setReasonOfDeletingAccount] = useState("");
  const [renderConfirmByPassword, setRenderConfirmByPassword] = useState(false);
  const [confirmByPassword, setConfirmByPassword] = useState("");
  const [newEmailState, setNewEmailState] = useState(authState.user.email);
  const [inputType1, setInputType1] = useState("password")
  const [inputType2, setInputType2] = useState("password")
  //mail format regex
  var mailformat =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  //password regex
  var passwordFormat =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,30}$/;

  const { REACT_APP_SERVER_URL } = process.env;

  useEffect(() => {
    getCurrency();
    refresh();
    if (
      window.location.href.split("/")[5] !== undefined &&
      window.location.href.split("/")[5].length > 15
    ) {
      message.success(t("Profil_Votre_email_a_modifié_avec_succès"), 8);
    }
  }, []);

  useEffect(() => {
    if (profilState.currency.length === 0) {
      getCurrency();
    }
  }, [profilState.currency.length]);

  //keyboard shortcuts ------------------
  useKeyboardShortcut(
    ["Escape"],
    () => {
      HandleUpdateInfoAnnuler();
      onHide();
    },
    { overrideSystem: false }
  );

  useKeyboardShortcut(
    ["Enter"],
    () => {
      if (selectedTask === "changePassword") {
        return HandleUpdatePassword();
      } else if (selectedTask === "changeCurrency") {
        return handleUpdateCurrency(currencyInfo.selectedCurrency);
      } else if (selectedTask === "changePersonalInfos") {
        HandleUpdate();
      }
    },
    { overrideSystem: true }
  );

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (selectedTask === "changePassword") {
        return HandleUpdatePassword();
      } else if (selectedTask === "changeCurrency") {
        return handleUpdateCurrency(currencyInfo.selectedCurrency);
      } else if (selectedTask === "changePersonalInfos") {
        HandleUpdate();
      }
    }
  };

  //--------------------------------------
  const refresh = async () => {
    try {
      await getUserAccountInfo();
      // This is a temporary solution
      var nom = document.getElementById("input-username").value;
      var email = document.getElementById("input-email").value;
      setOldInformations({
        oldName: nom,
        oldEmail: email,
      });
      setNewInformations({
        newName: nom,
        newEmail: email,
      });
    } catch (error) { }
  };

  // Update of the email and name
  const HandleUpdate = async () => {
    if (newInformations.newName !== authState.user.name) {
      const newName = newInformations.newName.replace(/\s+/g, "");
      if (newName.length < 3) {
        setInvalidName({ name: true });
        setMessageName(t("Profil_Le_nom_doit_contenir_au_moins_3_caractères!"));
      }
      await updateUserName(
        oldInformations.oldName,
        newInformations.newName,
        message,
        t,
        async () => {
          await getUserAccountInfo();
        }
      );
    }

    if (newInformations.newEmail !== authState.user.email) {
      if (newInformations.newEmail.match(mailformat) != null) {
        setInvalidEmail({ email: false });
        setInvalidName({ name: false });
        await UpdateEmail();
        await getUserAccountInfo();
      } else {
        setInvalidEmail({ email: true });
        setMessageEmail(t("InvalidEmail"));
      }
    }
  };

  //recover field values of name & email
  const handleChange = (e) => {
    e.preventDefault();
    var nom = document.getElementById("input-username").value;
    var email = document.getElementById("input-email").value;
    setNewInformations({
      newName: nom,
      newEmail: email,
    });
  };

  //Update password
  const HandleUpdatePassword = () => {
    var OldPassword = document.getElementById("input-OldPassword").value;
    var NewPassword = document.getElementById("input-NewPassword").value;
    var NewPasswordConf = document.getElementById(
      "input-NewPasswordConf"
    ).value;
    if (OldPassword.length === 0) {
      setInvalidAncienPassword({ ancienPassword: true });
      setMessageAncienPassword(
        t("Profil_Veuillez_entrer_l'ancien_mot_de_passe.")
      );
    } else setInvalidAncienPassword({ ancienPassword: false });
    if (NewPassword.length === 0) {
      setInvalidNewPassword1({ newPassword1: true });
      setMessageNewPassword1(
        t("Profil_Veuillez_entrer_le_nouveau_mot_de_passe.")
      );
    } else setInvalidNewPassword1({ newPassword1: false });
    if (NewPasswordConf.length === 0) {
      setInvalidNewPassword2({ newPassword2: true });
      setMessageNewPassword2(
        t(
          "Profil_Veuillez_entrer_votre_nouveau_mot_de_passe_une_deuxième_fois."
        )
      );
    } else setInvalidNewPassword2({ newPassword2: false });

    if (NewPassword.match(passwordFormat) == null) {
      setMessageNewPassword1(
        t(
          "Profil_Entrer_plus_de_6_caractères_alphanumériques,_avec_au_moins_un_caractère_majuscule,_un minuscule_et_un_caractère_spécial."
        )
      );
      setInvalidNewPassword1({ newPassword1: true });
    } else {
      setInvalidNewPassword1({ newPassword1: false });
      if (NewPassword !== NewPasswordConf) {
        setMessageNewPassword1(t("Profil_Mots_de_passes_non_identiques!"));
        setInvalidNewPassword1({ newPassword1: true });
        setMessageNewPassword2(t("Profil_Mots_de_passes_non_identiques!"));
        setInvalidNewPassword2({ newPassword2: true });
      } else {
        updateUserAccountPassword(
          {
            NewPassword,
            OldPassword,
            email: authState.user.email,
          },
          message,
          (Error) => {
            if (Error) {
              setInvalidAncienPassword({ ancienPassword: true });
              setMessageAncienPassword(
                t("Profil_Ancien_mot_de_passe_est_incorrecte")

              );
            } else {
              setInvalidAncienPassword({ ancienPassword: false });
            }
          },
          onHide,
          t
        );
      }
    }
  };

  const UpdateEmail = async () => {
    await fetch(`https://${REACT_APP_SERVER_URL}/UpdateEmail`, {
      method: "post",
      headers: {
        "content-Type": "application/json",
        origin: `https://${REACT_APP_SERVER_URL}`
      },
      credentials: "include",
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN"),
        access_token: sessionStorage.getItem("ACCESS_TOKEN"),
        name: oldInformations.oldName,
        oldemail: oldInformations.oldEmail,
        email: document.getElementById("input-email").value,
        language: t("Transaction_lang"),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.messages.confirm === false) {
          return message.error(t("Profil_Votre_nouveau_email_existe_déja"), 10);
        }
        if (response.success === true) {
          return message.success(
            t(
              "Profil_Merci_de_consulter_votre_boîte_mail_pour_confirmer_le_changement_du_email"
            ),
            10
          );
        }
      });
  };

  // Cancel the modifications
  const HandleUpdateInfoAnnuler = () => {
    //disable red message
    setInvalidEmail({ email: false });
    setInvalidName({ name: false });
    var x = document.getElementById("btnEnregistrer");
    var y = document.getElementById("btnAnnuler");
    var z = document.getElementById("btnEnregistrerEmail");

    x.style.display = "none";
    y.style.display = "none";
    z.style.display = "none";
    document.getElementById("input-username").disabled = true;
    document.getElementById("input-email").disabled = true;
    document.getElementById("input-username").value = authState.user.name;
    document.getElementById("input-email").value = authState.user.email;
  };

  //Cancel modification
  const onHide = () => {
    //disable red message
    setInvalidNewPassword1({ newPassword1: false });
    setInvalidNewPassword2({ newPassword2: false });
    setInvalidAncienPassword({ ancienPassword: false });
  };

  //Update of currency
  const handleUpdateCurrency = async (value) => {
    try {
      message.success(t("Profil_La_devise_a_été_changée_avec_succès"));
      await updateCurrency(value, message, onHide);
      await getUserAccountInfo();
      await getBudget();
    } catch (error) { }
  };

  //selected Currency
  function selectedCurrency(selected) {
    setCurrenyInfo({
      ...currencyInfo,
      label: selected.label,
      selectedCurrency: selected.value,
      keyCurrency: selected.key,
    });
    handleUpdateCurrency(selected.value);
  }

  const renderDeleteUserAccount = () => (
    <DefaultModal
      size={"modal-lg"}
      isOpen={renderConfirmByPassword}
      color={COLORS.danger}
      btnStyle={{ width: "100%" }}
      toggle={async () => {
        setRenderConfirmByPassword(false);
      }}
      title={t("Profil_DeleteUserAccount")}
      onClick={async () => {
        await fetch(
          `https://${REACT_APP_SERVER_URL}/deleteuseraccount`,
          {
            method: "delete",
            headers: {
              "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}`,
            },
            credentials: "include",
            body: JSON.stringify({
              refresh_token: localStorage.getItem("REFRESH_TOKEN"),
              access_token: sessionStorage.getItem("ACCESS_TOKEN"),
              delete_after_30_days: delete_after_30_days,
              reason: reasonOfDeletingAccount,
              confirm_by_password: confirmByPassword,
            }),
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.success === true) {
              ReactGAEvent("Delete", "Delete user account");
              setRendrDeleteUserAccount(false);
              setRenderConfirmByPassword(false);
              setRendrDeleteUserAccount(false);
              message.info(t("check_delete_email"), 10);
            } else {
              message.error(t("Profil_Invalid_Password"), 5);
            }
          });
      }}
      btnText={t("Profil_Confirm")}
      disabled={confirmByPassword === ""}
    >

      <div className="w-100 my-4">

        <h4 style={{ textAlign: "start", color: COLORS.glyphPrimary }}>
          {t(
            "Profil_Enter_Your_Password_To_Confirm_Deleting_This_Account"
          )}
        </h4>
        <Input
          placeholder={t("Profil_Password")}
          type="password"
          value={confirmByPassword}
          onChange={(e) => {
            setConfirmByPassword(e.target.value);
          }}
          className="ourCustomInputStyle"
        />
      </div>
    </DefaultModal>

  );

  const renderConfirmPasswordBeforDeleteUserAccount = () => (
    <DefaultModal
      size={"modal-lg"}
      color={COLORS.danger}
      btnStyle={{ width: "100%" }}
      isOpen={rendrDeleteUserAccount}
      style={{ paddingTop: "100px" }}
      toggle={async () => {
        setRendrDeleteUserAccount(false);
      }}
      title={t("Profil_DeleteUserAccount")}
      onClick={() => {
        setRendrDeleteUserAccount(false);
        setRenderConfirmByPassword(true);
      }}
      btnText={t("Profil_Submit")}
      disabled={confirmDeletUserAccount !== t("Profil_DELETE_MY_ACCOUNT")}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Row className="mb-4">
          <Col className="mx-4 px-4">{t("Profil_Delet_my_user_account")}</Col>
        </Row>
        <Row className="mb-4">
          <Col className="mx-4 px-4">
            <Card className="p-4">
              {" "}
              <Row>
                <Col md="12" lg="12" xs="12" sm="12" className="w-100 mt-2" style={{ textAlign: "start" }}>
                  {t("Profil_Please_enter")} "
                  <span className="text-danger">
                    {t("Profil_DELETE_MY_ACCOUNT")}
                  </span>
                  " :
                  <Input
                    placeholder={t("Profil_DELETE_MY_ACCOUNT")}
                    type="text"
                    value={confirmDeletUserAccount}
                    onChange={(e) => {
                      setConfirmDeletUserAccount(e.target.value);
                    }}
                    className="ourCustomInputStyle"
                  />
                  <Input
                    rows="7"
                    type="textarea"
                    value={reasonOfDeletingAccount}
                    className="mt-3 ourCustomTextAreaStyle"
                    placeholder={t(
                      "Profil_Please_can_you_tell_us_the_reason_why_you_want_to_delete_your_account_from_fineo"
                    )}
                    onChange={(e) => {
                      setReasonOfDeletingAccount(e.target.value);
                    }}
                  />
                  <div className="custom-control custom-control-alternative custom-checkbox mt-3">
                    <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                      onChange={() => {
                        setDelete_after_30_days(!delete_after_30_days);
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor=" customCheckLogin"
                    >
                      <span className="text-muted">
                        {t("Profil_Wait_for_30_days_then_delete_my_account")}
                      </span>
                    </label>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>

    </DefaultModal>
  );

  return (
    <>
      <MainHeader title={StringManipulator.capitalize(t("global.setting.s"))} />
      <Row>
        <Card
          style={{
            padding: "20px",
            width: "90%",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <Row
            style={{ width: "90%", marginRight: "auto", marginLeft: "auto", cursor: "pointer" }}
            id="linkToggler4"
            className="align-items-center"
          >
            <Col className="col-auto">
              <a className="avatar" style={{ backgroundColor: COLORS.glyphLight }}>
                <i className="fas fa-user text-default"></i>
              </a>
            </Col>
            <div className="col ml--2">
              <h4 className="mb-0">{authState.user.name}</h4>
              <p className="text-sm text-muted mb-0">{authState.user.email}</p>
            </div>
            <Col className="col-auto">
              <h4
                className="mb-0"
                style={{ cursor: "pointer" }}
              >
                <span>
                  {t("Change Settings")}
                  <i
                    className="fas fa-angle-down ml-2"
                    style={{ fontSize: 15, marginTop: 10 }}
                  ></i>
                </span>
              </h4>
            </Col>
          </Row>
          <UncontrolledCollapse toggler="#linkToggler4">
            <div
              style={{ width: "90%", marginRight: "auto", marginLeft: "auto" }}
            >
              <Row
                className="my-3"
                style={{
                  marginLeft: "5px",
                }}
              >
                <Col lg="12" md="12" xl="12" sm="12">
                  <FormGroup>
                    <Label for="input-username">
                      <span
                        style={{
                          fontWeight: "bold",
                          color: COLORS.glyphPrimary,
                        }}
                      >
                        {t("Full Name")}
                      </span>
                    </Label>

                    <Input
                      defaultValue={authState.user.name}
                      invalid={invalidName.name}
                      id="input-username"
                      placeholder={t("Profil_Information_utilisateur")}
                      type="text"
                      onBlur={handleChange}
                      onKeyPress={handleKeyPress}
                      className="ourCustomInputStyle"
                    />
                    <FormFeedback style={{ fontSize: "inherit" }}>
                      {messageName}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col lg="12" md="12" xl="12" sm="12">
                  <FormGroup>
                    <Label for="input-username">
                      <span
                        style={{
                          fontWeight: "bold",
                          color: COLORS.glyphPrimary,
                        }}
                      >
                        {t("Email Address")}
                      </span>
                    </Label>
                    <Input
                      defaultValue={authState.user.email}
                      invalid={invalidEmail.email}
                      id="input-email"
                      value={newEmailState}
                      onChange={(e) => {
                        setNewEmailState(e.target.value);
                        setOldInformations({
                          oldName: authState.user.name,
                          oldEmail: authState.user.email,
                        });
                      }}
                      placeholder="email@example.com"
                      type={t("Profil_Email")}
                      onBlur={handleChange}
                      onKeyPress={handleKeyPress}
                      className="ourCustomInputStyle"
                    />
                    <FormFeedback style={{ fontSize: "inherit" }}>
                      {messageEmail}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col
                  lg="12"
                  md="12"
                  xl="12"
                  sm="12"
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <StandardButton
                    id="linkToggler4"
                    type="button"
                    className="w-auto px-6"
                    onClick={HandleUpdate}
                    text={StringManipulator.capitalize(t("global.save._"))}
                  />
                  <StandardButton
                    outline={false}
                    id="linkToggler4"
                    type="button"
                    className="w-auto px-6"
                    text={StringManipulator.capitalize(t("global.cancel._"))}
                  />
                </Col>
              </Row>
            </div>
          </UncontrolledCollapse>
          <hr
            style={{ width: "90%", marginRight: "auto", marginLeft: "auto" }}
            className=" mt-4 my-1"
          />
          <Row
            style={{
              width: "90%", marginRight: "auto", marginLeft: "auto", cursor: "pointer"
            }}
            className="mt-4 align-items-center"
            id="linkToggler"
          >
            <Col className="col-auto">
              <a className="avatar" style={{ backgroundColor: COLORS.glyphLight }}>
                <i className="fas fa-unlock-alt text-default"></i>
              </a>
            </Col>
            <div className="col ml--2">
              <h4 className="mb-0">{t("Security settings")}</h4>
              <p className="text-sm text-muted mb-0">
                {t("You can modify your password")}
              </p>
            </div>
            <Col className="col-auto">
              <h4
                className="mb-0"
                style={{ cursor: "pointer" }}
              >
                <span>
                  {t("Change Settings")}
                  <i
                    className="fas fa-angle-down ml-2"
                    style={{ fontSize: 15, marginTop: 10 }}
                  ></i>
                </span>
              </h4>
            </Col>
          </Row>
          <UncontrolledCollapse toggler="#linkToggler">
            <div
              style={{ width: "90%", marginRight: "auto", marginLeft: "auto" }}
            >
              <Row
                className="my-3"
                style={{
                  marginLeft: "5px",
                }}
              >
                <Col lg="12" md="12" xl="12" sm="12">
                  <FormGroup>
                    <Label for="input-OldPassword">
                      <span
                        style={{
                          fontWeight: "bold",
                          color: COLORS.glyphPrimary,
                        }}
                      >
                        {t("Current Password")}
                      </span>
                    </Label>

                    <Input
                      invalid={invalidAncienPassword.ancienPassword}
                      id="input-OldPassword"
                      placeholder={t("Type your current password here...")}
                      type="password"
                      className="ourCustomInputStyle"
                      onKeyPress={handleKeyPress}
                    />
                    <FormFeedback style={{ fontSize: "inherit" }}>
                      {messageAncienPassword}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col lg="12" md="12" xl="12" sm="12">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="input-NewPassword">
                          <span
                            style={{
                              fontWeight: "bold",
                              color: COLORS.glyphPrimary,
                            }}
                          >
                            {t("New Password")}
                          </span>
                        </Label>
                        <InputGroup>
                          <Input
                            invalid={invalidNewPassword1.newPassword1}
                            id="input-NewPassword"
                            placeholder={t("Type new password here...")}
                            type={inputType1}
                            className="ourCustomInputStyle"
                            onKeyPress={handleKeyPress}
                          />
                          {
                            inputType1 === "password" ? (<span style={{
                              padding: "12px",
                              alignContent: "center",
                              alignItems: "center",
                              cursor: "pointer"
                            }}
                              onClick={() => {
                                setInputType1("text")
                              }}>
                              <i className="fas fa-eye fa-1x" ></i>
                            </span>) : (<span style={{
                              padding: "12px",
                              alignContent: "center",
                              alignItems: "center",
                              cursor: "pointer"
                            }}
                              onClick={() => {
                                setInputType1("password")
                              }}
                            >
                              <i className="fas fa-eye-slash fa-1x" ></i>
                            </span>)
                          }
                        </InputGroup>
                        <FormFeedback style={{ fontSize: "inherit" }}>
                          {messageNewnPassword1}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for="input-NewPasswordConf">
                          <span
                            style={{
                              fontWeight: "bold",
                              color: COLORS.glyphPrimary,
                            }}
                          >
                            {t("Confirm Password")}
                          </span>
                        </Label>
                        <InputGroup>
                          <Input
                            invalid={invalidNewPassword2.newPassword2}
                            id="input-NewPasswordConf"
                            placeholder={t("Re-type new password here...")}
                            type={inputType2}
                            className="ourCustomInputStyle"
                            onKeyPress={handleKeyPress}
                          />
                          {
                            inputType2 === "password" ? (<span style={{
                              padding: "12px",
                              alignContent: "center",
                              alignItems: "center",
                              cursor: "pointer"
                            }}
                              onClick={() => {
                                setInputType2("text")
                              }}>
                              <i className="fas fa-eye fa-1x" ></i>
                            </span>) : (<span style={{
                              padding: "12px",
                              alignContent: "center",
                              alignItems: "center",
                              cursor: "pointer"
                            }}
                              onClick={() => {
                                setInputType2("password")
                              }}
                            >
                              <i className="fas fa-eye-slash fa-1x" ></i>
                            </span>)
                          }
                        </InputGroup>
                        <FormFeedback style={{ fontSize: "inherit" }}>
                          {messageNewnPassword2}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col
                  lg="12"
                  md="12"
                  xl="12"
                  sm="12"
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <StandardButton
                    type="button"
                    className="w-auto px-6"
                    onClick={() => HandleUpdatePassword()}
                    text={StringManipulator.capitalize(t("global.save._"))}
                  />
                  <StandardButton
                    outline={false}
                    id="linkToggler"
                    type="button"
                    className="w-auto px-6"
                    onClick={onHide}
                    text={StringManipulator.capitalize(t("global.cancel._"))}
                  />
                </Col>
              </Row>
            </div>
          </UncontrolledCollapse>
          <hr
            style={{ width: "90%", marginRight: "auto", marginLeft: "auto" }}
            className=" mt-4 my-1"
          />
          <Row
            style={{ width: "90%", marginRight: "auto", marginLeft: "auto", cursor: "pointer" }}
            id="linkToggler1"
            className="mt-4 align-items-center"
          >
            <Col className="col-auto">
              <a className="avatar" style={{ backgroundColor: COLORS.glyphLight }}>
                <i className="fas fa-dollar-sign text-default"></i>
              </a>
            </Col>
            <div className="col ml--2">
              <h4 className="mb-0">{t("Currency change")}</h4>
              <p className="text-sm text-muted mb-0">
                {t("You can modify the currency of the application")}
              </p>
            </div>
            <Col
              className="col-auto"
              style={{ cursor: "pointer" }}
            >
              <h4 className="mb-0">
                <span>
                  {t("Change Settings")}
                  <i
                    className="fas fa-angle-down ml-2"
                    style={{ fontSize: 15, marginTop: 10 }}
                  ></i>
                </span>
              </h4>
            </Col>
          </Row>
          <UncontrolledCollapse toggler="#linkToggler1">
            <div
              style={{ width: "90%", marginRight: "auto", marginLeft: "auto" }}
            >
              <Row
                className="my-3"
                style={{
                  marginLeft: "5px",
                }}
              >
                <Col>
                  <h4>
                    {" "}
                  </h4>
                </Col>
              </Row>
              <Row
                style={{
                  marginLeft: "5px",
                }}
              >
                <Col>
                  <DefaultSelect
                    onChange={selectedCurrency}
                    placeholder={
                      currencyInfo.selectedCurrency === -1
                        ? profilState.currency.length !== undefined &&
                        profilState?.currency.map(
                          (element) =>
                            element.currency_code === authState.user.currency_code &&
                            t(element.currency_label)
                        )
                        : currencyInfo.label
                    }
                    options={
                      profilState.currency.length !== undefined &&
                      profilState?.currency.map((element) => ({
                        value: element.currency_id,
                        label: t(element.currency_label),
                        key: element.currency_code,
                      }))
                    }
                  />
                </Col>
              </Row>
            </div>
          </UncontrolledCollapse>
          <hr
            style={{ width: "90%", marginRight: "auto", marginLeft: "auto" }}
            className=" mt-4 my-1"
          />
          <Row
            style={{ width: "90%", marginRight: "auto", marginLeft: "auto", cursor: "pointer" }}
            className="mt-4 align-items-center"
            id="linkToggler2"
          >
            <Col className="col-auto">
              <a className="avatar" style={{ backgroundColor: COLORS.glyphLight }}>
                <i className="fas fa-globe text-default"></i>
              </a>
            </Col>
            <div className="col ml--2">
              <h4 className="mb-0">{t("Change Language")}</h4>
              <p className="text-sm text-muted mb-0">
                {t("You can modify the Language of the application")}
              </p>
            </div>
            <Col
              className="col-auto"
              style={{ cursor: "pointer" }}
            >
              <h4 className="mb-0">
                <span>
                  {t("Change Settings")}
                  <i
                    className="fas fa-angle-down ml-2"
                    style={{ fontSize: 15, marginTop: 10 }}
                  ></i>
                </span>
              </h4>
            </Col>
          </Row>
          <UncontrolledCollapse toggler="#linkToggler2">
            <div
              style={{ width: "90%", marginRight: "auto", marginLeft: "auto" }}
            >
              <Row
                className="my-3"
                style={{
                  marginLeft: "5px",
                }}
              >
                <Col>
                  <h4>
                    {" "}
                  </h4>
                </Col>
              </Row>
              <Row
                style={{
                  marginLeft: "5px",
                }}
              >
                <Col>
                  <LanguageSelect />
                </Col>
              </Row>
            </div>
          </UncontrolledCollapse>
          <hr
            style={{ width: "90%", marginRight: "auto", marginLeft: "auto" }}
            className=" mt-4 my-1"
          />
          <i class=""></i>
          <Row
            style={{ width: "90%", marginRight: "auto", marginLeft: "auto", cursor: "pointer" }}
            className="mt-4 align-items-center"
            onClick={() => {
              history.push("/app/billing")
            }}
          >
            <Col className="col-auto">
              <a className="avatar" style={{ backgroundColor: COLORS.glyphLight }}>
                <i className="fas fa-euro-sign text-default"></i>
              </a>
            </Col>
            <div className="col ml--2">
              <h4 className="mb-0">{t("Billing and subscription")}</h4>
              <p className="text-sm text-muted mb-0">
                {t("Change your billing and subscription plan")}{" "}
              </p>
            </div>
            <Col
              className="col-auto"
            >
              <h4 className="mb-0">
                <span>
                  {t("Change Settings")}
                  <i
                    className="fas fa-angle-right ml-2"
                    style={{ fontSize: 15, marginTop: 10 }}
                  ></i>
                </span>
              </h4>
            </Col>
          </Row>
          <hr
            style={{ width: "90%", marginRight: "auto", marginLeft: "auto" }}
            className=" mt-4 my-1"
          />
          <Row
            style={{ width: "90%", marginRight: "auto", marginLeft: "auto", cursor: "pointer" }}
            className="mt-4 align-items-center"
            id="linkToggler3"
          >
            <Col className="col-auto">
              <a className="avatar" style={{ backgroundColor: COLORS.glyphLight }}>
                <i className="far fa-trash-alt text-default"></i>
              </a>
            </Col>
            <div className="col ml--2">
              <h4 className="mb-0">{t("Delete my account")}</h4>
              <p className="text-sm text-muted mb-0">
                {t("You can delete your user account")}{" "}
              </p>
            </div>
            <Col
              className="col-auto"
            >
              <h4 className="mb-0">
                <span>
                  {t("Change Settings")}
                  <i
                    className="fas fa-angle-down ml-2"
                    style={{ fontSize: 15, marginTop: 10 }}
                  ></i>
                </span>
              </h4>
            </Col>
          </Row>
          <UncontrolledCollapse toggler="#linkToggler3">
            <div
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Row className="mt-3 mb-3">
                <Col>
                  <h4>{t("To delete your account click on")}</h4>
                </Col>
              </Row>
              <Row>
                <Col>
                  <StandardButton
                    color={COLORS.danger}
                    onClick={() => {
                      setRendrDeleteUserAccount(true);
                    }}
                    type="button"
                    className="w-auto px-6"
                    text={t("Delete")}
                  />
                  <StandardButton
                    outline={false}
                    id="linkToggler3"
                    type="button"
                    className="w-auto px-6"
                    text={StringManipulator.capitalize(t("global.cancel._"))}
                  />
                </Col>
              </Row>
            </div>
          </UncontrolledCollapse>
        </Card>
      </Row>
      {renderDeleteUserAccount()}
      {renderConfirmPasswordBeforDeleteUserAccount()}
    </>
  );
}

ProfileSettings.prototype = {
  authState: PropTypes.object.isRequired,
  accountState: PropTypes.object.isRequired,
  profilState: PropTypes.object.isRequired,
  updateUserName: PropTypes.func.isRequired,
  getUserAccountInfo: PropTypes.func.isRequired,
  updateUserAccountPassword: PropTypes.func.isRequired,
  getCurrency: PropTypes.func.isRequired,
  updateCurrency: PropTypes.func.isRequired,
  getBudget: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
  cleanAccount: PropTypes.func.isRequired,
  cleanAuth: PropTypes.func.isRequired,
  cleanBudget: PropTypes.func.isRequired,
  cleanReport: PropTypes.func.isRequired,
  cleanTransaction: PropTypes.func.isRequired,
  cleanProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authState: state.auth,
  accountState: state.account,
  profilState: state.profile,
});

export default connect(mapStateToProps, {
  updateUserName,
  getUserAccountInfo,
  updateUserAccountPassword,
  getCurrency,
  updateCurrency,
  logOut,
  cleanAuth,
  cleanBudget,
  cleanReport,
  cleanTransaction,
  cleanProfile,
  getBudget,
})(ProfileSettings);
