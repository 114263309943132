import React from "react";
import PropTypes from "prop-types";
import DefaultSelect from "components/inputs/DefaultSelect";

class SelectAccount extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    onUpdate: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    accountsData: PropTypes.array.isRequired,
  };

  static defaultProps = {
    value: "",
  };

  getValue(option, value) {
    if (typeof option === undefined) return value;
    if (this.props.row.key !== "-1" && option !== undefined) {
      this.props.handleSave({
        ...this.props.row,
        account: option.value,
        transaction_amount: parseFloat(this.props.row.transaction_amount) / 100,
      });
      return option.label;
    }
    return value;
  }

  cancel(value) {
    return value;
  }

  render() {
    const { value, onUpdate, accountsData, ...rest } = this.props;
    const accountDataSource = [];
    accountsData
      .filter((account) => account.is_it_closed === false)
      .map((account) => {
        accountDataSource.push({
          value: account.key,
          label: account.account,
        });
      });

    return [
      <DefaultSelect
        autoFocus
        {...rest}
        key={Math.random()}
        defaultInputValue={value}
        placeholder={this.props.t("Transaction_Sélectionner")}
        options={accountDataSource}
        isClearable
        onKeyDown={(event) => {
          if (event.key === 'Escape') {
            onUpdate(this.cancel(value));
            return;
          }
          if (event.key === 'Enter') {
            onUpdate(this.getValue(undefined, value));
          }
        }}
        onBlur={() => {
          onUpdate(this.getValue(undefined, value));
        }}
        ref={(node) => (this.value = node)}
        onChange={(option) => {
          if (option === undefined || option === null) return;
          onUpdate(this.getValue(option, value));
        }}
      />

    ];
  }
}

export default SelectAccount;
