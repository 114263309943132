import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet-async';
import { CardBody, Row, Col} from "reactstrap";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { getTransaction } from "../actions/Transaction";
import { getAccessToken } from "../actions/Auth";
import AccountViewWidget from "../components/Widgets/AccountViewWidget";
import WidgetNewAccount from "../components/Widgets/WidgetNewAccount";
import AccountCard from "../components/cards/AccountCard.js";
import AcccountHeader from "../components/Widgets/AccountHeaderWidget";
import ReactGAEvent from "../components/Tools/ReactGAEvent";
import LoadingOverlay from 'react-loading-overlay';
import {
  addNewAccount, updateAccount, deleteAccount,
  getAccount, getAccounts, closeAccount,
  getAccountHeader, getAccountTypes, filterAccountByType,
  setAddOrImportAccountMoadl, syncImportedAccountsFromNordigen
} from "../actions/Account";
import PropTypes from "prop-types";
import useKeyboardShortcut from "use-keyboard-shortcut";
import DefaultModal from "components/Modals/DefaultModal";
import { StringManipulator } from "@fineo-io/main-javascript-library";
import AccountSelect from "components/inputs/AccountSelect";
import AddAccountModal from "components/Modals/AddAccountModal";
import StandardButton from "components/Buttons/standardButton/StandardButton";
import MainHeader from "components/Headers/MainHeader.jsx";

function Accounts({
  accountState,
  getAccount,
  getAccounts,
  getAccountTypes,
  addNewAccount,
  authState,
  updateAccount,
  deleteAccount,
  filterAccountByType,
  closeAccount,
  getAccountHeader,
  getTransaction,
  syncImportedAccountsFromNordigen,
  transactionState
}) {
  const { t } = useTranslation();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const param = useQuery();
  const [isInAddMode, setIsInAddMode] = useState(false);
  const [isInDetailMode, setIsInDetailMode] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [addOrImportAcountModal, setAddOrImportAcountModal] = useState(false);
  const [loadAccounts, setLoadAccounts] = useState(false);
  const [viewAffichage, setViewAffichage] = useState({ type: -1 });

  useEffect(() => {
    getAccountTypes();
    getAccountHeader();
    getAccounts();
  }, [
    getAccountTypes,
    getAccountHeader,
    getAccounts,
    sessionStorage.getItem("SESSION_SPACE")
  ]);

  useKeyboardShortcut(
    ["Escape"],
    () => {
      setIsInDetailMode(false);
      setIsInAddMode(false);
    },
    { overrideSystem: false }
  );

  //  Handle add new account:
  const handleAddNewAccountToDb = async (newAccount) => {
    if (newAccount.accountName === "") {
      return message.warning(
        t("Account_The_name_field_must_not_be_empty"),
        5
      );
    }
    await addNewAccount(newAccount);
    await getTransaction(true, t);
    setIsInAddMode(false);
    ReactGAEvent("Add", "Created new account");
  };

  // Handle edit account
  const handleEdit = async (updateAccountObject) => {
    try {
      await updateAccount(updateAccountObject);
      ReactGAEvent("Update", "Update an account");
    } catch (error) { }
  };

  const removeParam = (key, sourceURL) => {
    var rtn = sourceURL.split("?")[0],
      param,
      params_arr = [],
      queryString =
        sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
      params_arr = queryString.split("&");
      for (var i = params_arr.length - 1; i >= 0; i -= 1) {
        param = params_arr[i].split("=")[0];
        if (param === key) {
          params_arr.splice(i, 1);
        }
      }
      rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
  };

  useEffect(() => {
    if (param.get("error") != null) {
      window.history.pushState({}, null, removeParam("details", window.location.href));
      window.history.pushState({}, null, removeParam("error", window.location.href));
      localStorage.removeItem("ref");
      setLoadAccounts(false);
      return;
    }
    if (param.get("ref") != null && localStorage.getItem("ref") != null) {
      if (param.get("error") != null) {
        window.history.pushState({}, null, removeParam("details", window.location.href));
        window.history.pushState({}, null, removeParam("ref", window.location.href));
        localStorage.removeItem("ref");
        setLoadAccounts(false)
        return;
      }
      setLoadAccounts(true)
    } else if (param.get("ref") != null && localStorage.getItem("ref") == null) {
      window.history.pushState({}, null, removeParam("ref", window.location.href));
    }
    if (localStorage.getItem("Success") === null && param.get("oauth_state_id") !== null) {
      if (showConfirmationModal === false && authState.link_token !== "") {
        setShowConfirmationModal(true);
      }
    } else {
      setShowConfirmationModal(false);
      localStorage.removeItem("Success");
      window.history.pushState({}, null, removeParam("oauth_state_id", window.location.href));
    }
  }, [showConfirmationModal, authState.link_token]);

  useEffect(() => {
    if (localStorage.getItem("reloadAfterReconnect") == "true") {
      syncNordigenAccounts();
    }
  }, [localStorage.getItem("reloadAfterReconnect")]);

  const syncNordigenAccounts = async () => {
    await syncImportedAccountsFromNordigen({}, async (data) => {
      localStorage.setItem("reloadAfterReconnect", "false");
      await getAccounts();
    });
  }

  // Handle remove account
  const handleDelete = async (e) => {
    try {
      setIsInDetailMode(false);
      setIsInAddMode(false);
      await deleteAccount(e.id, e.keepTransactions);
      ReactGAEvent("Delete", "Delete an account");
    } catch (error) { }
  };

  /* @Standerd View Mode (Budget Table*/
  const changeDetailViewMode = async (e) => {
    try {
      setIsInDetailMode(false);
      setIsInAddMode(false);
    } catch (error) { }
  };

  /* @Add New Account View Mode */
  const changeAddMode = async (e) => {
    setAddOrImportAcountModal(false);
    e.preventDefault();
    setIsInAddMode(true);
    setIsInDetailMode(false);
  };

  /* @Detail/Edit Account View Mode */
  const changeDetailMode = async (account_id) => {
    try {
      await getAccount(account_id);
      setIsInDetailMode(true);
      setIsInAddMode(false);
    } catch (error) { }
  };

  const handelCloseAccount = async (accountId, transferToAccountId) => {
    try {
      await closeAccount(accountId, transferToAccountId);
      ReactGAEvent("Close", "Close a  account");
    } catch (error) { }
  };

  const filterAccountByTypeAction = (target) => {
    setViewAffichage({
      ...viewAffichage,
      type: target.value,
    });
    filterAccountByType(
      target.value,
    );
  };

  const renderDefaultView = () => {
    return (
      <div>
        <AcccountHeader translate={t} />
        <CardBody>
          <Row>
            <Col xl="4" lg="4" md="6" sm="6" xs="12" className="float-left">
              <StandardButton text="" onClick={() => setAddOrImportAcountModal(true)}>
                <i className="fas fa-plus-circle" />
                <span>{StringManipulator.titleize(t("global.new.account"))}</span>
              </StandardButton>
            </Col>
            <Col xl="4" lg="4" md="12" sm="12" xs="12">
              <AccountSelect className="mb-2" data={accountState.accountTypes} onChange={filterAccountByTypeAction} />
            </Col>
          </Row>
          <Row>
            <Col lg="12" xl="12">
              <AccountCard
                dataSource={accountState.accounts.sort(compare)}
                changeDetailMode={changeDetailMode}
                authState={authState}
                translate={t}
              />
              {renderAddAccountModal()}
              {renderAccountDetailModal()}
            </Col>
          </Row>
        </CardBody>
      </div>
    );
  };

  function compare(a, b) {
    if (a.solde > b.solde) return -1;
    if (a.solde < b.solde) return 1;
    return 0;
  }

  const renderAddAccountModal = () => (
    <DefaultModal
      title={t("global.new.account")}
      size={"modal-md"}
      style={{ paddingTop: "100px" }}
      isOpen={isInAddMode}
      hasNoButtons={true}
      bodyStyle={{ textAlign: "start" }}
      toggle={async () => {
        setIsInAddMode(false);
      }}
    >
      <div className="modal-body">
        <WidgetNewAccount
          allAccountType={accountState.accountTypes}
          handleAddNewAccountToDb={handleAddNewAccountToDb}
          changeAddMode={changeAddMode}
          onCancel={CancelCRUDEvent}
          translate={t}
        />
      </div>
    </DefaultModal>
  );

  const renderAccountDetailModal = () => {
    if (accountState.accountDetail.account === undefined) return;
    return (
      <DefaultModal
        size={"modal-lg"}
        style={{ paddingTop: "100px", scale: '0.9' }}
        hasNoButtons={true}
        bodyStyle={{ textAlign: "start" }}
        isOpen={isInDetailMode}
        title={t("Account_Informations_Compte")}
        toggle={async () => {
          setIsInDetailMode(false);
        }}
      >
        <div className="modal-body">
          <AccountViewWidget
            detailAccount={accountState.accountDetail}
            allAccountType={accountState.accountTypes}
            allAccount={accountState.selectAccounts}
            changeDetailMode={changeDetailMode}
            closeAccount={handelCloseAccount}
            changeDetailViewMode={changeDetailViewMode}
            handleDelete={handleDelete}
            CancelCRUDEvent={CancelCRUDEvent}
            handleEdits={handleEdit}
            toggle={() => setIsInDetailMode(false)}
            translate={t}
            getAccount={getAccount}
            getAccounts={getAccounts}
            getTransaction={getTransaction}
            authState={authState}
            transactionState={transactionState}
            syncImportedAccountsFromNordigen={syncImportedAccountsFromNordigen}
          />
        </div>
      </DefaultModal>
    );
  };

  // Cancel any CRUD event
  const CancelCRUDEvent = () => {
    setIsInAddMode(false);
    setIsInDetailMode(false);
  };

  return (
    <LoadingOverlay
      active={loadAccounts}
      spinner
      text={t("Loading your accounts, please wait...")}
    >
      <MainHeader title={StringManipulator.capitalize(t("global.account.s"))} />
      <div className="w-auto mx-3" style={{ height: "100vh" }}>
        <Row className="mt--5">
          <Col>{renderDefaultView()}</Col>
        </Row>
      </div>
      <AddAccountModal setLoadAccounts={setLoadAccounts} loadAccounts={loadAccounts} addOrImportAcountModal={addOrImportAcountModal} setAddOrImportAcountModal={setAddOrImportAcountModal} />
    </LoadingOverlay >
  );
}

Accounts.prototype = {
  authState: PropTypes.object.isRequired,
  getAccount: PropTypes.func.isRequired,
  getAccounts: PropTypes.func.isRequired,
  getAccountTypes: PropTypes.func.isRequired,
  accountState: PropTypes.object.isRequired,
  addNewAccount: PropTypes.func.isRequired,
  updateAccount: PropTypes.func.isRequired,
  closeAccount: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  filterAccountByType: PropTypes.func.isRequired,
  getAccountHeader: PropTypes.func.isRequired,
  getTransaction: PropTypes.func.isRequired,
  getAccessToken: PropTypes.func.isRequired,
  tobudgetAccounts: PropTypes.array.isRequired,
  notTobudgetAccounts: PropTypes.array.isRequired,
  closedAccounts: PropTypes.array.isRequired,
  setAddOrImportAccountMoadl: PropTypes.func.isRequired,
  syncImportedAccountsFromNordigen: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  accountState: state.account,
  tobudgetAccounts: state.account.tobudgetAccounts,
  notTobudgetAccounts: state.account.notTobudgetAccounts,
  closedAccounts: state.account.closedAccounts,
  authState: state.auth,
  transactionState: state.transaction.transactions
});

export default connect(mapStateToProps, {
  getAccount,
  getAccounts,
  getAccountTypes,
  getTransaction,
  addNewAccount,
  deleteAccount,
  filterAccountByType,
  closeAccount,
  getAccessToken,
  updateAccount,
  getAccountHeader,
  setAddOrImportAccountMoadl,
  syncImportedAccountsFromNordigen
})(Accounts);
