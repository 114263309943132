import React, { Fragment } from "react";
import BudgetTable from "../Tables/BudgetTable.js";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CardHeader, Row, Card, Col } from "reactstrap";
import { PlusSquareOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { COLORS } from "assets/constants/theme.js";
import { StringManipulator } from "@fineo-io/main-javascript-library";

export default function BudgetType({
  type,
  handleAddgroup,
  onDragEnd,
  data,
  bDate,
  getBudget,
  budgetState,
  editMode,
  setEditMode,
}) {
  const { t } = useTranslation();

  const getSummary = (dataset) => {
    let available = 0;
    let budgetAmounts = 0;
    let totalmouvement = 0;
    dataset.forEach(({ disponible, activities, budgetAmount }) => {
      available += parseFloat(disponible);
      budgetAmounts += parseFloat(budgetAmount);
      totalmouvement += parseFloat(activities);
    });
    return [
      {
        key: -2,
        disponible: available,
        budgetAmount: budgetAmounts,
        activities: totalmouvement,
      },
    ];
  };

  return (
    <div key={type} id={type}>
      <CardHeader className="bg-transparent" style={{ border: "none" }}>
        <Row className="align-items-center" style={{
          marginLeft: "0.1px"
        }}>
          {type === "1" ? (
            <h2 className="mb-0" style={{ color: COLORS.primary }}>
              <span className="budget-type-1">
                <i className="far fa-calendar-alt"></i>
                &nbsp;
                {t(`Budget_${budgetState.budgets.BudgetTypeName[type][0].budget_type_name}`)}
              </span>
            </h2>
          ) : type === "2" ? (
            <h2 className="mb-0" style={{ color: COLORS.primary }}>
              <span className="budget-type-2">
                <i className="far fa-calendar-check"></i>
                &nbsp;{" "}
                {t(`Budget_${budgetState.budgets.BudgetTypeName[type][0].budget_type_name}`)}
              </span>
            </h2>
          ) : (
            <div>
              <h2 className="mb-0" style={{ color: COLORS.primary }}>
                <span className="budget-type-3">
                  <i className="fas fa-piggy-bank"></i>
                  &nbsp;
                  {t(`Budget_${budgetState.budgets.BudgetTypeName[type][0].budget_type_name}`)}
                </span>
              </h2>
            </div>
          )}
          <div className="col text-right">
            <i
              className="cursor-pointer far fa-plus-square"
              style={{ color: COLORS.glyphPrimary, fontSize: "20px" }}
              title={t("Budget_Ajouter_un_groupe")}
              onClick={() => handleAddgroup(type)}
            ></i>
          </div>
        </Row>
      </CardHeader>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {Object.keys(data[type]).map((element, index) =>
                element !== "null" ? (
                  <Draggable
                    key={data[type][element][0].key}
                    draggableId={JSON.stringify({
                      id: element,
                      category: type,
                    })}
                    index={data[type][element][0].groupOrder}
                  >
                    {(provided) => (
                      <div ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <PlusSquareOutlined
                          {...provided.dragHandleProps}
                          hidden={true}
                        />
                        <BudgetTable
                          key={element}
                          index={index}
                          typeB={type}
                          groupB={element}
                          BudgetGroupName={element}
                          datasource={data[type][element]}
                          groupId={data[type][element][0].groupId}
                          typeId={data[type][element][0].budget_type_id}
                          date={bDate}
                          data={getSummary(data[type][element])}
                          getSummaryData={getSummary}
                          dataFetch={getBudget}
                          budgetState={budgetState}
                          updated={budgetState.updated}
                          provided={provided.dragHandleProps}
                          editMode={editMode}
                          setEditMode={setEditMode}
                          t={t}
                        />
                      </div>
                    )}
                  </Draggable>
                ) : (
                  <Card
                    className=" mt-2 mx-3"
                    style={{
                      backgroundColor: COLORS.emptiness,
                      cursor: "pointer",
                    }}
                  >
                    <h3>
                      <Row className="align-items-center">
                        <Col
                          className="text-center"
                          md="10" lg="10" xs="10" xl="10" sm="10"
                          style={{ color: COLORS.glyphSecondary }}>
                          <span className="ml-6">
                            {
                              StringManipulator.capitalize(t("global.add.a.new.group"))
                            }
                          </span>
                        </Col>
                        <Col md="2" lg="2" xs="2" xl="2" sm="2" style={{ color: COLORS.glyphSecondary }}>
                          <i className="fas fa-long-arrow-alt-up float-right mr-2 arrow2 fa-2x"></i>
                        </Col>
                      </Row>
                    </h3>
                  </Card>
                )
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
