import {
  SET_LOGIN,
  SET_USER_ACCOUNT_INFO,
  SET_LOGOUT,
  CLEAN_AUTH,
  SET_FIRST_LOGIN
} from "../actions/types";

const initialState = {
  user: {
    subscription_id: 2,
  },
  userGA_id: 0,
  link_token: "",
  isLogin: false,
  loading: false,
  isNotStillLogedIn: true,
  is_valid: true,
  // current_space: -1,
  current_space_name: "",
  // current_creation_date: null,
  firstLogin: false
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_LOGIN:
      return {
        ...state,
        user: payload.user,
        userGA_id: payload.userGA_id,
        link_token: payload.user.link_token,
        isLogin: payload.isLogin,
        isNotStillLogedIn: false,
        is_valid: payload.is_valid,
        // current_space: payload.current_space,
        current_space_name: payload.current_space_name,
        // current_creation_date: payload.current_creation_date
      };

    case SET_USER_ACCOUNT_INFO:
      let activeSpace = payload.space_ids.find(space => space.is_active == true);
      return {
        ...state,
        user: payload,
        // current_space: activeSpace.space_id,
        current_space_name: payload.space_ids[0].space_name,
        // current_creation_date: activeSpace.space_creation_date,
        link_token: payload.link_token,
      };

    case SET_LOGOUT:
      return {
        ...state,
        user: {},
        isLogin: false,
        isNotStillLogedIn: true,
        is_valid: true,
        loading: false,
      };

    case SET_FIRST_LOGIN:
      return {
        ...state,
        firstLogin: true
      }

    case CLEAN_AUTH:
      return {
        user: {},
        link_token: "",
        isLogin: "",
        loading: false,
      };

    default:
      return state;
  }
}
