// Account api endpoints:
export const GET_LIST_OF_ACCOUNTS_API = "v2/account/all";
export const GET_ONE_ACCOUNT_API = "v2/account/one";
export const GET_ACCOUNT_TYPES_API = "v2/account/types";
export const GET_ACCOUNT_HEADER_API = "v2/account/statistics";
export const ADD_NEW_ACCOUNT_API = "v2/account/add";
export const UPDATE_ACCOUNT_API = "v2/account/update";
export const CLOSE_ACCOUNT_API = "v2/account/close";
export const DELETE_ACCOUNT_API = "v2/account/delete";

