import {
  GET_TRANSACTIONS,
  GET_PAYEES,
  GET_TR_ACCOUNTS,
  GET_CATEGORIES,
  GET_ALL_CATEGORIES,
  GET_PERIODICITE,
  GET_TRANSACTIONS_NOT_FUTURS,
  SET_TRANSACTION,
  SET_TRANSACTION_TO_DB,
  DELETE_TRANSACTIONS,
  GET_PLAID_TRANSACTIONS,
  CLEAR_PLAID_TRANSACTIONS,
  CLEAN_TRANSACTION,
  GET_TRANSACTIONS_WITHOUT_CAT,
  RUN_TRANSACTION_VIEW_ONBOARDING
} from "../actions/types";

const initialState = {
  // All transactions State:
  transactions: [],
  futurTransactions: [],
  // Plaid transactions State:
  plaidTransactions: [],
  // All payees State:
  payees: [],
  // Active (unhidden) categories State:
  categories: [],
  // All (hidden & unhidden) categories State:
  filterCategories: [],
  // All accounts State:
  accounts: [],
  // All futur transactions State:
  Futurs: [],
  // All repetition State:
  periodicite: [],
  // The data has been loaded or not:
  loading: false,
  //
  hideFutureTransaction: false,
  //
  getTrnsactionWithout: false,
  //
  runTransactionViewOnBoarding: false,
  //
  t: () => { }
};

export default function (state = initialState, action) {
  let { transactions, accounts, t } = state;
  let { type, payload } = action;
  switch (type) {
    // Get all transactions:
    case GET_TRANSACTIONS:
      let targetAccountName = "";
      let newArray = [];
      payload.Transactions.forEach((tr) => {
        // Check if this tr (transaction) is a transfer transaction or not.
        if (tr.is_transfer === true) {
          // Set the accounts names, and transfer text:
          targetAccountName = accounts.filter((acc) => parseInt(acc.key) === parseInt(tr.transfert_to_account_id));
          if (parseFloat(tr.out) !== 0 && parseFloat(tr.in) === 0 && targetAccountName.length !== 0) {
            newArray.push({ ...tr, payee: `${payload.t("Transfertvers")} ${targetAccountName[0].account}` });
          } else if (parseFloat(tr.in) !== 0) {
            if (targetAccountName[0] !== undefined) {
              newArray.push({ ...tr, payee: `${payload.t("transferred_from")}: ${targetAccountName[0].account}` });
            } else {
              newArray.push({ ...tr, payee: `${payload.t("transferred_from")}: ${payload.t("account_deleted")}` });
            }
          } else if (parseFloat(tr.out) !== 0) {
            if (targetAccountName[0] !== undefined) {
              newArray.push({ ...tr, payee: `${payload.t("transferred_from")}: ${targetAccountName[0].account}` });
            } else {
              newArray.push({ ...tr, payee: `${payload.t("transferred_from")}: ${payload.t("account_deleted")}` });
            }
          }
        }
        // If not a transfer, do nothing and add transaction.
        else {
          newArray.push({ ...tr, in: parseInt(tr.in) === 0 ? "" : tr.in });
        }
      });

      let theNewState = {
        ...state,
        transactions: newArray,
        transactionsFiltersHandlers: newArray,
        getTrnsactionWithout: false,
        futurTransactions: newArray.filter((tr) => new Date() < new Date(tr.dateTemp) && tr.transaction_amount < 0),
      };

      if (state.hideFutureTransaction === true) {
        theNewState = {
          ...state,
          transactions: newArray.filter((tr) => new Date() >= new Date(tr.dateTemp)),
          transactionsFiltersHandlers: newArray,
        };
      }

      if (state.hideFutureTransaction === false) {
        theNewState = { ...theNewState };
      }

      return theNewState;

    // Get all transactions:
    case GET_TRANSACTIONS_WITHOUT_CAT:
      let targetAccountName2 = "";
      let newArray2 = [];
      let newArray3 = [];

      newArray3 = payload.Transactions.filter(
        (tr) =>
          tr.is_initial !== true && tr.is_transfer !== true && tr.categorie_key === "-" && tr.in === "0" && parseInt(tr.transaction_amount) !== 0
      );
      newArray3.forEach((tr) => {
        // Check if this tr (transaction) is a transfer transaction or not.
        if (tr.is_transfer === true) {
          // If true:
          // Set the accounts names, and transfer text:
          targetAccountName2 = accounts.filter(
            (acc) => parseInt(acc.key) === parseInt(tr.transfert_to_account_id)
          );

          if (
            parseFloat(tr.out) !== 0 &&
            parseFloat(tr.in) === 0 &&
            targetAccountName2.length !== 0
          ) {
            newArray2.push({
              ...tr,
              payee: `${payload.t("Transfertvers")} ${targetAccountName2[0].account}`,
            });
          } else if (parseFloat(tr.in) !== 0) {
            if (targetAccountName2[0] !== undefined) {
              newArray2.push({
                ...tr,
                payee: `${payload.t("transferred_from")}: ${targetAccountName2[0].account}`,
              });
            } else {
              newArray2.push({
                ...tr,
                payee: `${payload.t("transferred_from")}: ${payload.t("account_deleted")}`,
              });
            }
          }
        }

        // If not: do nothing and add transaction.
        else {
          newArray2.push({ ...tr, in: parseInt(tr.in) === 0 ? "" : tr.in });
        }
      });
      let theNewState2 = {
        ...state,
        transactions: newArray2,
        transactionsFiltersHandlers: newArray2,
        getTrnsactionWithout: true
      };

      return theNewState2;

    case SET_TRANSACTION_TO_DB:
      return {
        ...state,
        transactions: transactions.filter(
          (transaction) => transaction.key !== -1
        ),
      };

    // Get all accounts for transaction features and selects:
    case GET_TR_ACCOUNTS:
      return { ...state, accounts: payload };

    // Said: i don't have any idea for now about this:
    case GET_PERIODICITE:
      return { ...state, periodicite: payload };

    // Get all categories for transaction features and selects:
    case GET_ALL_CATEGORIES:
      const data = [];
      data.push({
        label: payload.t("budget_get_all"),
        options: [
          {
            key: -2,
            value: -2,
            label: payload.t(`budget_get_all`),
          },
        ],
      });

      data.push({
        label: payload.t("to_budget"),
        options: [
          {
            key: -1,
            value: -1,
            label: payload.t(`to_budget`),
          },
        ],
      });
      payload.data.forEach((element) => {

        let obj = {};
        let tab = [];

        element.values.forEach((val) => {
          tab.push({
            key: val.Budget,
            value: val.Budget,
            label: val.Budget_Name,
            amount: val.Amount,
          });
        });

        obj = {
          label: element.key,
          options: tab,
        };
        data.push(obj);
      });
      return { ...state, filterCategories: data }

    // Get unhidden categories for transaction features and selects:
    case GET_CATEGORIES:
      const options = [];
      options.push({
        label: payload.t("budget_get_all"),
        options: [
          {
            key: -2,
            value: -2,
            label: payload.t(`budget_get_all`),
          },
        ],
      });

      options.push({
        label: payload.t("to_budget"),
        options: [
          {
            key: -1,
            value: -1,
            label: payload.t(`to_budget`),
          },
        ],
      });
      payload.data.forEach((element) => {

        let obj = {};
        let tab = [];

        element.values.forEach((val) => {
          tab.push({
            key: val.Budget,
            value: val.Budget,
            label: val.Budget_Name,
            amount: val.Amount,
          });
        });

        obj = {
          label: element.key,
          options: tab,
        };
        options.push(obj);
      });
      return { ...state, categories: options }

    // Get all payees for transaction features and selects:
    case GET_PAYEES:
      return {
        ...state,
        payees: payload.filter((payee) => payee.value !== "-"),
      };

    // Set new transaction:
    case SET_TRANSACTION:
      return {
        ...state,
        transactions: [payload, ...transactions],
      };

    // Delete one or many transactions:
    case DELETE_TRANSACTIONS:
      return {
        ...state,
        transactions: transactions,
      };

    // Filter only not futur transactions:
    case GET_TRANSACTIONS_NOT_FUTURS:
      if (payload.hideFutureTransaction === true) {
        return {
          ...state,
          hideFutureTransaction: true,
          transactions: transactions.filter(
            (tr) => new Date() >= new Date(tr.dateTemp)
          ),
        };
      }

      if (payload.hideFutureTransaction === false) {
        return {
          ...state,
          hideFutureTransaction: false,
        };
      }
      return {
        ...state,
        hideFutureTransaction: true,
        transactions: transactions.filter(
          (tr) => new Date() >= new Date(tr.dateTemp)
        ),
      };

    // get plaid transactions
    case GET_PLAID_TRANSACTIONS:
      return {
        ...state,
        plaidTransactions: payload,
      };

    // clear plaid transactions
    case CLEAR_PLAID_TRANSACTIONS:
      return {
        ...state,
        plaidTransactions: payload,
      };

    //
    case RUN_TRANSACTION_VIEW_ONBOARDING:
      return {
        ...state,
        runTransactionViewOnBoarding: payload
      }

    case CLEAN_TRANSACTION:
      return {
        transactions: [],
        plaidTransactions: [],
        limit: 20000,
        payees: [],
        categories: [],
        accounts: [],
        Futurs: [],
        periodicite: [],
        loading: false,
      };
    default:
      return state;
  }
}
