import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import FormatCurrency from "../Tools/Currency/Currency";
// Icons: 
import AccountIcons from "../Randoms/AccountIcons";
import { COLORS } from "assets/constants/theme";
const { moneyBank, moneyBankWhite, moneyCashCoinsStack, moneyCashCoinsStackWhite, moneySafeVault, moneySafeVaultWhite, moneyWallet, moneyWalletWhite } = AccountIcons;

export default function TransactionWidgetSosComponent(props) {
  useEffect(() => {
    if (props.selected === false) {
      setClicked(props.selected)
    }
  }, [props.selected])
  const [clicked, setClicked] = useState(false)
  return (
    <Row className="justify-content-md-around px-1"
      onClick={() => {
        props.filterTransactionByAccount({ value: props.item.key })
        if (props.item.key !== "Tous les comptes") setClicked(!clicked)
      }}
    >
      <Col md="12" lg="12" xl="12" xs="12" sm="12"
        style={clicked ? { backgroundColor: COLORS.primary, color: COLORS.neutral } : { backgroundColor: COLORS.neutral, color: COLORS.glyphPrimary }}
        className={`p-3 mb-2 rounded d-flex p-2 d-flex justify-content-between`}>
        <span className={`mr-2 transactionTableCellCustomStyle3`}>
          {props.item.accountTypeId === -1 && (
            <img
              src={clicked === true ? moneyWalletWhite : moneyWallet}
              height="21px"
              style={{
                cursor: "pointer"
              }}
              width="21px"
            />
          )}
          {props.item.accountTypeId === 1 && (
            <img
              src={clicked === true ? moneyBankWhite : moneyBank}
              height="21px"
              style={{
                cursor: "pointer"
              }}
              width="21px"
            />
          )}
          {props.item.accountTypeId === 2 && (
            <img
              src={clicked === true ? moneyCashCoinsStackWhite : moneyCashCoinsStack}
              height="21px"
              style={{
                cursor: "pointer"
              }}
              width="21px"
            />
          )}
          {props.item.accountTypeId === 3 && (
            <img
              src={clicked === true ? moneySafeVaultWhite : moneySafeVault}
              height="21px"
              style={{
                cursor: "pointer"
              }}
              width="21px"
            />
          )}
          <span className="ml-3 mt--1">
            {props.item.account}
          </span>
        </span>
        <span className="float-right">
          {FormatCurrency(
            props.item.solde,
            props.authState.user.currency_code,
            props.authState.user.currency_locales
          )}
        </span>
      </Col>
    </Row>
  );
}
