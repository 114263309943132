function hexToRgba(hexColor, alpha = 0.25) {
  // Remove the leading '#' if present
  const hex = hexColor.startsWith('#') ? hexColor.slice(1) : hexColor;

  // Convert the hex color to RGB values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Construct the RGBA string with the specified alpha value
  const rgba = `rgba(${r}, ${g}, ${b}, ${alpha})`;
  
  return rgba;
}

export default hexToRgba;