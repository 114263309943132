import { SET_GLOBAL_VALUES, GLOBAL_OPEN_ADD_TR_MODAL, GLOBAL_SYNC_MY_ACCOUNTS, SET_BDATE } from "./types";
import moment from "moment";

const { REACT_APP_SERVER_URL } = process.env;

export const getGlobalValues = (selectedMonth) => async (dispatch) => {
  try {
    dispatch({
      type: SET_BDATE,
      payload: selectedMonth
    })
    await fetch(`https://${REACT_APP_SERVER_URL}/globalvalues`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      credentials: "include",
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
        access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
        space_id: sessionStorage.getItem("SESSION_SPACE"),
        budget_dateend: moment(selectedMonth).endOf("month").format("YYYY-MM-DD"),
        b_date: selectedMonth,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.messages === undefined) return;
        dispatch({
          type: SET_GLOBAL_VALUES,
          payload: {
            // The budget amount
            budgetedCurrentMonth: response.messages.payload.budgetedSelectedMonth,
            // The total amount the user can budget this month
            totalToBudgetCurrentMonth: response.messages.payload.totalToBudgetCurrentMonth,
            // The available amount the user can still budget
            availableCurrentMonth: response.messages.payload.availableToBudgetCurrentMonth,
            // The entries of the current month
            entriesCurrentMonth: response.messages.payload.totalEntriesSelectedMonth,
            // The expenses of the current month
            expensesCurrentMonth: response.messages.payload.totalExpensesUpToSeloctedMonth,
            // The available amount from the last month that the user can still budget
            availableFromMonthBefore: response.messages.payload.availableFromPreviousMonths,
            // The Available amount the user can spent (From all entries - all expenses)
            availableAmountToSpent: response.messages.payload.cashflowUpToSelectedMonth,
            // Saving this month
            EpargneCurrentMonth: response.messages.payload.savingSelectedMonth,
          },
        });
      });
  } catch (err) { }
};

export const openAddTransactionModal = (value) => async (dispatch) => {
  dispatch({
    type: GLOBAL_OPEN_ADD_TR_MODAL,
    payload: value,
  });
};

export const startSyncingAccounts = (value) => async (dispatch) => {
  dispatch({
    type: GLOBAL_SYNC_MY_ACCOUNTS,
    payload: value,
  });
};
