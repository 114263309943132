import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import routes from "../routes.js";
import { getUserSession } from "actions/Auth.js";

class Auth extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  updateState = () => {
    this.setState({ loading: !this.state.loading });
  }

  async componentWillMount() {
    const { history } = this.props;

    await getUserSession((response) => {
      if (response?.success === true) {
        history.push("/app/dashboard");
      } else {
        this.updateState();
        localStorage.removeItem("REFRESH_TOKEN");
        sessionStorage.removeItem("ACCESS_TOKEN");
        history.push("/auth/login-page");
      }
    });
  }

  async componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
    document.body.classList.add("bg-white");
  }

  componentWillUnmount() {
    document.body.classList.remove("bg-white");
  }

  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/auth" && prop.path === "/login") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      if (prop.layout === "/auth" && prop.path === "/redirect") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      else {
        if (prop.layout === "/auth" && prop.path === "/confirmationemail/:id") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        else {
          if (prop.layout === "/auth" && prop.path === "/confirmationdelete") {
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          }
        }
        if (prop.layout === "/auth" && prop.path === "/payment") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
      }
      return null;
    });
  };

  render() {
    return (
      <span ref="mainContent">
        {/* <AuthNavbar /> */}
        {this.state.loading ?
          <div />
          :
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="*" to="/auth/login" />
          </Switch>
        }
      </span>
    );
  }
}

export default Auth;
