import React from 'react';
import { COLORS } from 'assets/constants/theme';

export default function TwoButtonSet({
  activeButton,
  setActiveButton,
  button1Text,
  button2Text
}) {
  const handleClick = (state) => {
    setActiveButton(state);
  };

  const getButtonStyles = (state) => {
    const baseStyles = {
      flex: 1,
      marginLeft: '-2px',
      border: '1px solid',
      color: COLORS.primary,
      backgroundColor: 'transparent',
      transition: 'color 0.3s, background-color 0.3s',
      height: "45px"
    };

    if (state === activeButton) {
      return {
        ...baseStyles,
        borderColor: 'transparent',
        color: 'white',
        backgroundColor: COLORS.primary,
        borderBottomLeftRadius: !state ? "8px" : "0px",
        borderTopLeftRadius: !state ? "8px" : "0px",
        borderTopRightRadius: !state ? "0px" : "8px",
        borderBottomRightRadius: !state ? "0px" : "8px",
      };
    }

    if (!state) {
      return {
        ...baseStyles,
        borderColor: COLORS.primary,
        borderBottomLeftRadius: "8px",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
      };
    }

    if (state) {
      return {
        ...baseStyles,
        borderColor: COLORS.primary,
        borderBottomRightRadius: "8px",
        borderTopRightRadius: "8px",
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
      };
    }

    return baseStyles;
  };

  return (
    <div style={{ display: 'flex' }}>
      <button
        type="button"
        style={getButtonStyles(false)}
        onClick={() => handleClick(false)}
      >
        {button1Text}
      </button>
      <button
        type="button"
        style={getButtonStyles(true)}
        onClick={() => handleClick(true)}
      >
        {button2Text}
      </button>
    </div>
  );
};
