import moment from 'moment';

const formatDate = (date, formatDatetime = false, inputFormat = "DD/MM/YYYY", outputFormat = 'YYYY/MM/DD') => {
  let momentDate;

  if (inputFormat) {
    momentDate = moment(date, inputFormat);
  } else {
    momentDate = moment(date);
  }

  if (!momentDate.isValid()) {
    return 'Invalid date';
  }
  if (formatDatetime) {
    return momentDate.format(outputFormat + ' HH:mm:ss');
  }

  return momentDate.format(outputFormat);
};
export default formatDate;
