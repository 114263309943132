import Austria from "../../assets/img/countries/at.svg";
import Belguim from "../../assets/img/countries/be.svg";
import Bulgaria from "../../assets/img/countries/bg.svg";
import Croatia from "../../assets/img/countries/hr.svg";
import Cyprus from "../../assets/img/countries/cy.svg";
import Czechia from "../../assets/img/countries/cz.svg";
import Denmark from "../../assets/img/countries/dk.svg";
import Estonia from "../../assets/img/countries/ee.svg";
import Finland from "../../assets/img/countries/fi.svg";
import France from "../../assets/img/countries/fr.svg";
import Germany from "../../assets/img/countries/de.svg";
import Greece from "../../assets/img/countries/gr.svg";
import Hungary from "../../assets/img/countries/hu.svg";
import Iceland from "../../assets/img/countries/is.svg";
import Ireland from "../../assets/img/countries/ie.svg";
import Italy from "../../assets/img/countries/it.svg";
import Latvia from "../../assets/img/countries/lv.svg";
import Liechtenstein from "../../assets/img/countries/li.svg";
import Lithuania from "../../assets/img/countries/lt.svg";
import Luxembourg from "../../assets/img/countries/lu.svg";
import Malta from "../../assets/img/countries/mt.svg";
import Netherlands from "../../assets/img/countries/nl.svg";
import Norway from "../../assets/img/countries/no.svg";
import Poland from "../../assets/img/countries/pl.svg";
import Portugal from "../../assets/img/countries/pt.svg";
import Romania from "../../assets/img/countries/ro.svg";
import Slovakia from "../../assets/img/countries/sk.svg";
import Slovenia from "../../assets/img/countries/si.svg";
import Spain from "../../assets/img/countries/es.svg";
import Sweden from "../../assets/img/countries/se.svg";
import UnitedKingdom from "../../assets/img/countries/gb.svg";


const countries = [
  {
    name: "Austria",
    code: "AT",
    flag: "../assets/img/countries/at.svg",
    att: Austria
  },
  {
    name: "Belguim",
    code: "BE",
    flag: "../assets/img/countries/be.svg",
    att: Belguim
  },
  {
    name: "Bulgaria",
    code: "BG",
    flag: "../assets/img/countries/bg.svg",
    att: Bulgaria
  },
  {
    name: "Croatia",
    code: "HR",
    flag: "../assets/img/countries/hr.svg",
    att: Croatia
  },
  {
    name: "Cyprus",
    code: "CY",
    flag: "../assets/img/countries/cy.svg",
    att: Cyprus
  },
  {
    name: "Czechia",
    code: "CZ",
    flag: "../assets/img/countries/cz.svg",
    att: Czechia
  },
  {
    name: "Denmark",
    code: "DK",
    flag: "../assets/img/countries/dk.svg",
    att: Denmark
  },
  {
    name: "Estonia",
    code: "EE",
    flag: "../assets/img/countries/ee.svg",
    att: Estonia
  },
  {
    name: "Finland",
    code: "fi",
    flag: "../assets/img/countries/fi.svg",
    att: Finland
  },
  {
    name: "France",
    code: "FR",
    flag: "../assets/img/countries/fr.svg",
    att: France
  },
  {
    name: "Germany",
    code: "DE",
    flag: "../assets/img/countries/DE.svg",
    att: Germany
  },
  {
    name: "Greece",
    code: "GR",
    flag: "../assets/img/countries/gr.svg",
    att: Greece
  },
  {
    name: "Hungary",
    code: "HU",
    flag: "../assets/img/countries/hu.svg",
    att: Hungary
  },
  {
    name: "Iceland",
    code: "IS",
    flag: "../assets/img/countries/is.svg",
    att: Iceland
  },
  {
    name: "Ireland",
    code: "IE",
    flag: "../assets/img/countries/ie.svg",
    att: Ireland
  },
  {
    name: "Italy",
    code: "IT",
    flag: "../assets/img/countries/it.svg",
    att: Italy
  },
  {
    name: "Latvia",
    code: "LV",
    flag: "../assets/img/countries/lv.svg",
    att: Latvia
  },
  {
    name: "Liechtenstein",
    code: "LI",
    flag: "../assets/img/countries/li.svg",
    att: Liechtenstein
  },
  {
    name: "Lithuania",
    code: "LT",
    flag: "../assets/img/countries/lt.svg",
    att: Lithuania
  },
  {
    name: "Luxembourg",
    code: "LU",
    flag: "../assets/img/countries/lu.svg",
    att: Luxembourg
  },
  {
    name: "Malta",
    code: "MT",
    flag: "../assets/img/countries/mt.svg",
    att: Malta
  },
  {
    name: "Netherlands",
    code: "NL",
    flag: "../assets/img/countries/nl.svg",
    att: Netherlands
  },
  {
    name: "Norway",
    code: "NO",
    flag: "../assets/img/countries/no.svg",
    att: Norway
  },
  {
    name: "Poland",
    code: "PL",
    flag: "../assets/img/countries/pl.svg",
    att: Poland
  },
  {
    name: "Portugal",
    code: "PT",
    flag: "../assets/img/countries/pt.svg",
    att: Portugal
  },
  {
    name: "Romania",
    code: "RO",
    flag: "../assets/img/countries/ro.svg",
    att: Romania
  },
  {
    name: "Slovakia",
    code: "SK",
    flag: "../assets/img/countries/sk.svg",
    att: Slovakia
  },
  {
    name: "Slovenia",
    code: "SI",
    flag: "../assets/img/countries/si.svg",
    att: Slovenia
  },
  {
    name: "Spain",
    code: "ES",
    flag: "../assets/img/countries/es.svg",
    att: Spain
  },
  {
    name: "Sweden",
    code: "SE",
    flag: "../assets/img/countries/se.svg",
    att: Sweden
  },
  {
    name: "United Kingdom",
    code: "GB",
    flag: "../assets/img/countries/gb.svg",
    att: UnitedKingdom
  },
];

export default countries;