import {
  SET_LOGIN,
  SET_USER_ACCOUNT_INFO,
  SET_LOGOUT,
  SELECTED_PLAID_ACCOUNT,
  CLEAN_AUTH,
  SET_FIRST_LOGIN,
} from "./types";

const { REACT_APP_SERVER_URL } = process.env;

//Action:create a user account
export const register =
  (
    { name, email, password, Privacy_Policy, acceptTermsAndConditions },
    message,
    history,
    translation
  ) =>
    async () => {
      if (Privacy_Policy == false || acceptTermsAndConditions == false) return;
      try {
        fetch(`https://${REACT_APP_SERVER_URL}/register`, {
          method: "post",
          headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
          body: JSON.stringify({
            email: email,
            password: password,
            name: name,
            language: translation("Transaction_lang"),
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success === true) {
              return history.push("/reg/EmailVerification");
            } else {
              return message.error(translation("Resgister_Email_Existe_Deja"));
            }
          });
      } catch (err) {
      }
    };

export const logIn = ({ signInEmail, signInPassword, rememberMe }, history) =>
  async (dispatch) => {
    try {
      await fetch(`https://${REACT_APP_SERVER_URL}/login`, {
        method: "post",
        headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
        body: JSON.stringify({
          email: signInEmail,
          password: signInPassword,
          rememberMe: rememberMe,
        }),
        credentials: "include",
      })
        .then((response) => response.json())
        .then(async (response) => {
          if (response.success === true) {
            if (
              response.messages.loginState === "email doesn't exist" ||
              response.messages.loginState === "invalid password"
            ) return dispatch({
              type: SET_LOGIN,
              payload: {
                user: {},
                isLogin: false,
                is_valid: true,
              },
            });

            if (response.messages.loginState === "email is not verified") return dispatch({
              type: SET_LOGIN,
              payload: {
                user: {},
                isLogin: false,
                is_valid: false,
              },
            });

            localStorage.setItem(
              "REFRESH_TOKEN",
              `Bearer ${response.messages.loginState.refereshToken}`
            );

            sessionStorage.setItem(
              "ACCESS_TOKEN",
              `Bearer ${response.messages.loginState.accessToken}`
            );

            sessionStorage.setItem(
              "SESSION_SPACE",
              response.messages.loginState.spaceIds
            );

            sessionStorage.setItem(
              "SESSION_SPACE_NAME",
              response.messages.loginState.user.space_ids[0].space_name,
            );

            dispatch({
              type: SET_LOGIN,
              payload: {
                user: response.messages.loginState.user,
                userGA_id: response.messages.loginState.user.id,
                isLogin: response.success,
                is_valid: true,
                current_space_name:
                  response.messages.loginState.user.space_ids[0].space_name,
              },
            });
            return history.push("/app/dashboard");
          }
        });
    } catch (err) { }
  };

export const loginFromRedirection = (redirectionToken, callback) =>
  async (dispatch) => {
    try {
      await fetch(`https://${REACT_APP_SERVER_URL}/loginfromredirection`, {
        method: "post",
        headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
        body: JSON.stringify({
          token: redirectionToken,
        }),
        credentials: "include",
      })
        .then((response) => response.json())
        .then(async (response) => {
          if (response?.user?.status === true) {
            localStorage.setItem(
              "REFRESH_TOKEN",
              `Bearer ${response?.refereshToken}`
            );

            sessionStorage.setItem(
              "ACCESS_TOKEN",
              `Bearer ${response?.accessToken}`
            );

            sessionStorage.setItem(
              "SESSION_SPACE",
              response?.spaceIds
            );

            dispatch({
              type: SET_LOGIN,
              payload: {
                user: response?.user,
                isLogin: response?.user?.status,
                is_valid: true,
                current_space_name:
                  response?.user?.space_ids[0].space_name,
              },
            });
            return callback(true)
          } else {
            return callback(false);
          }
        });
    } catch (err) { }
  };

export const getUserAccountInfo = () => async (dispatch) => {
  try {
    await fetch(`https://${REACT_APP_SERVER_URL}/useraccount`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN"),
        access_token: sessionStorage.getItem("ACCESS_TOKEN"),
      }),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        if (
          sessionStorage.getItem("SESSION_SPACE") == null ||
          sessionStorage.getItem("SESSION_SPACE_NAME") == null
        ) {
          sessionStorage.setItem(
            "SESSION_SPACE",
            response.messages.info.spaceId
          );
          sessionStorage.setItem(
            "SESSION_SPACE_NAME",
            response.messages.info.space_ids[0].space_name
          );
        }
        return dispatch({
          type: SET_USER_ACCOUNT_INFO,
          payload: response.messages.info,
        });
      });
  } catch (err) { }
};

export const logOut = (history) => async (dispatch) => {
  let logoutRequest;
  try {
    await fetch(`https://${REACT_APP_SERVER_URL}/logout`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN"),
        access_token: sessionStorage.getItem("ACCESS_TOKEN"),
      }),
      credentials: "include",
    }).then((response) => response.json())
      .then(async (response) => {
        if (response.success === true) {
          localStorage.clear();
          sessionStorage.clear();
          dispatch({ type: SET_LOGOUT, payload: {} });
          history.push("/auth/login");
        }
      })
  } catch ({ request }) {
    logoutRequest = request;
  }
};

// Action:update email & name for the current user
export const updateUserName =
  (oldName, newName, message, t, cb) =>
    async () => {
      try {
        fetch(`https://${REACT_APP_SERVER_URL}/UpdateInformation`, {
          method: "post",
          headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
          body: JSON.stringify({
            refresh_token: localStorage.getItem("REFRESH_TOKEN"),
            access_token: sessionStorage.getItem("ACCESS_TOKEN"),
            oldname: oldName,
            Newname: newName,
          }),
          credentials: "include",
        })
          .then((response) => response.json())
          .then((response) => {
            if (parseInt(response.messages.UpdateInformations) === 1) {
              cb();
              return message.success(t("Profil_Modification_faite_avec_succès"));
            }
            message.error(t("Profil_Votre_nouveau_email_est_deja_trouvée"));
          });
      } catch (err) { }
    };

// Action:update password for the current user
export const updateUserAccountPassword =
  ({ NewPassword, OldPassword, email }, message, Error, onHide, t) =>
    async () => {
      try {
        fetch(`https://${REACT_APP_SERVER_URL}/UpdatePassword`, {
          method: "post",
          headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
          body: JSON.stringify({
            refresh_token: localStorage.getItem("REFRESH_TOKEN"),
            access_token: sessionStorage.getItem("ACCESS_TOKEN"),
            email: email,
            oldpassword: OldPassword,
            NewPassword: NewPassword,
          }),
          credentials: "include",
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.success === false) {
              Error(true);
            } else {
              message.success(t("Profil_Changement_de_mot_de_passe_avec_succès"));
              onHide();
              Error(false);
            }
          });
      } catch (err) { }
    };

// Action: Generate the link token from Plaid
export const getAccessToken = (public_token) => async (dispatch) => {
  await fetch(`https://${REACT_APP_SERVER_URL}/get_access_token`, {
    method: "post",
    headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
    credentials: "include",
    body: JSON.stringify({
      refresh_token: localStorage.getItem("REFRESH_TOKEN"),
      access_token: sessionStorage.getItem("ACCESS_TOKEN"),
      public_token: public_token,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      return dispatch({
        type: SELECTED_PLAID_ACCOUNT,
        payload: {
          accounts: response.messages,
          access_token: response.access_token,
        },
      });
    });
};

export const resendValidationEmail = (email, message, t) => async () => {
  return await fetch(
    `https://${REACT_APP_SERVER_URL}/auth/resendvalidationemail`,
    {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      credentials: "include",
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN"),
        access_token: sessionStorage.getItem("ACCESS_TOKEN"),
        email: email,
      }),
    }
  )
    .then((response) => response.json())
    .then((response) => {
      if (
        response.messages.confirmigEmailStatus ===
        "The validation email has been ended successfully"
      ) {
        message.success(
          t(
            "Confirm_Email_Merci_de_vérifier_votre_boîte_mail_pour_obtenir_un_lien_dans_le_but_de_valider_cette_adresse_mail"
          )
        );
      }
    });
};

export const setFirstLoginState = (value) => async (dispatch) => {
  dispatch({
    type: SET_FIRST_LOGIN,
    payload: value,
  });
};

export const cleanAuth = () => (dispatch) => {
  return dispatch({
    type: CLEAN_AUTH,
    payload: {},
  });
};

export const getClientIpAddress = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error('Error fetching IP address:', error);
    return null;
  }
}

export const getUserSession = async (callback) => {
  const clientIp = await getClientIpAddress();
  try {
    await fetch(`https://${REACT_APP_SERVER_URL}/get-session`, {
      method: "post",
      headers: {
        "content-Type": "application/json",
        origin: `https://${REACT_APP_SERVER_URL}`,
      },
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN"),
        access_token: sessionStorage.getItem("ACCESS_TOKEN"),
        client_ip: clientIp
      }),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success === true) {
          if (response.newAccessToken !== null) {
            sessionStorage.setItem("ACCESS_TOKEN", `Bearer ${response.newAccessToken}`);
          }
          callback(response);
          return;
        }
        callback(response);
      });
  } catch (error) {
    console.log(error);
    return false;
  }
}
