// Import all required transaction acction types:
import {
  GET_TRANSACTIONS,
  GET_PAYEES,
  GET_TR_ACCOUNTS,
  GET_CATEGORIES,
  GET_TRANSACTIONS_NOT_FUTURS,
  GET_PERIODICITE,
  SET_TRANSACTION,
  CLEAN_TRANSACTION,
  SET_LOGOUT,
  GET_TRANSACTIONS_WITHOUT_CAT,
  RUN_TRANSACTION_VIEW_ONBOARDING,
  GET_ALL_CATEGORIES
} from "./types";
import ReactGAEvent from "../components/Tools/ReactGAEvent";
import { getAccounts } from "./Account";

// Import the Api Url:
const { REACT_APP_SERVER_URL } = process.env;

// Action: Get all transactions for the current user.
export const getTransaction = (nonFuture = true, t = () => {}) =>
  async (dispatch) => {
    try {
      const loadTransactions = await fetch(
        `https://${REACT_APP_SERVER_URL}/transaction`,
        {
          method: "post",
          headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
          body: JSON.stringify({
            refresh_token: localStorage.getItem("REFRESH_TOKEN"),
            access_token: sessionStorage.getItem("ACCESS_TOKEN"),
            get_payees_only: false,
            space_id: sessionStorage.getItem("SESSION_SPACE")
          }),
          credentials: "include",
        }
      );
      const JSON_loadTransactions = await loadTransactions.json();
      isStillLogedIn(JSON_loadTransactions, dispatch);
      const { messages, success } = JSON_loadTransactions;
      if (success === true) {
        const { Transactions } = messages;
        // Dispatch: GET_TRANSACTIONS Reducer.
        // Pass data to reducer in the payload.`
        if (Transactions.length !== 0 && Transactions !== undefined) {
          return dispatch({
            type: GET_TRANSACTIONS,
            payload: { Transactions, nonFuture, t },
          });
        } else if (Transactions.length === 0) {
          return dispatch({
            type: GET_TRANSACTIONS,
            payload: { Transactions: [], nonFuture: false },
          });
        }
      }
    } catch (err) { }
  };

// Action: Get all transactions for the current user.
export const getTransactionWithoutCats = (t = () => { }) => async (dispatch) => {
  try {
    const loadTransactions = await fetch(
      `https://${REACT_APP_SERVER_URL}/transaction`,
      {
        method: "post",
        headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
        body: JSON.stringify({
          refresh_token: localStorage.getItem("REFRESH_TOKEN"),
          access_token: sessionStorage.getItem("ACCESS_TOKEN"),
          get_payees_only: false,
          space_id: sessionStorage.getItem("SESSION_SPACE")
        }),
        credentials: "include",
      }
    );
    const JSON_loadTransactions = await loadTransactions.json();
    isStillLogedIn(JSON_loadTransactions, dispatch);
    const { messages, success } = JSON_loadTransactions;
    if (success === true) {
      const { Transactions } = messages;
      // Dispatch: GET_TRANSACTIONS Reducer.
      // Pass data to reducer in the payload.
      if (Transactions.length != 0 && Transactions !== undefined) {
        return dispatch({
          type: GET_TRANSACTIONS_WITHOUT_CAT,
          payload: { Transactions, t }
        });
      }
    }
  } catch (err) { }
};

// Action: Filter transactions for the current user.
// Filter by: (account, catgeorie, in or out, date).
export const filterTransaction = (filters, translate) => async (dispatch) => {
  try {
    const loadTransactions = await fetch(
      `https://${REACT_APP_SERVER_URL}/transaction/filter`,
      {
        method: "post",
        headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
        credentials: "include",
        body: JSON.stringify({
          refresh_token: localStorage.getItem("REFRESH_TOKEN"),
          access_token: sessionStorage.getItem("ACCESS_TOKEN"),
          account_ids: filters.account_ids === "Tous les comptes" ? (filters.account_ids = -1) : filters.account_ids,
          budget_category_id: parseInt(filters.budget_category_id),
          in_out_filter: filters.inOutFilters,
          date_filter: filters.dateFilter,
          space_id: sessionStorage.getItem("SESSION_SPACE")
        }),
      }
    );
    const JSON_loadTransactions = await loadTransactions.json();
    const { messages, success } = JSON_loadTransactions;
    isStillLogedIn(JSON_loadTransactions, dispatch);
    if (success === true) {
      // Dispatch: GET_TRANSACTIONS Reducer.
      // Pass data to reducer in the payload.
      return dispatch({
        type: GET_TRANSACTIONS,
        payload: { Transactions: messages.payload, t: translate },
      });
    }
  } catch (err) { }
};


// Action: Get all the categories for the current user.
// Those categories are used to fill the select in transaction filters.
export const getCategories = (t = (data) => { }, isActive = true) => async (dispatch) => {
  try {
    const loadBudgets = await fetch(
      `https://${REACT_APP_SERVER_URL}/transaction/categories`,
      {
        method: "post",
        headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
        body: JSON.stringify({
          refresh_token: localStorage.getItem("REFRESH_TOKEN"),
          access_token: sessionStorage.getItem("ACCESS_TOKEN"),
          space_id: sessionStorage.getItem("SESSION_SPACE"),
          isActive
        }),
        credentials: "include",
      }
    );
    const JSON_loadBudgets = await loadBudgets.json();
    isStillLogedIn(JSON_loadBudgets, dispatch);
    const { messages, success } = JSON_loadBudgets;
    if (success === true) {
      // Dispatch: GET_CATEGORIES Reducer.
      // Pass data to reducer in the payload.
      return dispatch({
        type: GET_CATEGORIES,
        payload: { data: messages.payload, t },
      });
    }
  } catch (err) { }
};

export const getAllCategories = (t = (data) => { }, isActive = false) => async (dispatch) => {
  try {
    const loadBudgets = await fetch(
      `https://${REACT_APP_SERVER_URL}/transaction/categories`,
      {
        method: "post",
        headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
        body: JSON.stringify({
          refresh_token: localStorage.getItem("REFRESH_TOKEN"),
          access_token: sessionStorage.getItem("ACCESS_TOKEN"),
          space_id: sessionStorage.getItem("SESSION_SPACE"),
          isActive
        }),
        credentials: "include",
      }
    );
    const JSON_loadBudgets = await loadBudgets.json();
    isStillLogedIn(JSON_loadBudgets, dispatch);
    const { messages, success } = JSON_loadBudgets;

    if (success === true) {
      // Dispatch: GET_ALL_CATEGORIES Reducer.
      // Pass data to reducer in the payload.
      return dispatch({
        type: GET_ALL_CATEGORIES,
        payload: { data: messages.payload, t },
      });
    }
  } catch (err) { }
};

// Action: Get all the payees for the current user.
export const getPayees = () => async (dispatch) => {
  try {
    const loadPayees = await fetch(
      `https://${REACT_APP_SERVER_URL}/transaction?payee=true`,
      {
        method: "post",
        headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
        body: JSON.stringify({
          refresh_token: localStorage.getItem("REFRESH_TOKEN"),
          access_token: sessionStorage.getItem("ACCESS_TOKEN"),
          get_payees_only: true,
          space_id: sessionStorage.getItem("SESSION_SPACE")
        }),
        credentials: "include",
      }
    );
    const JSON_loadPayees = await loadPayees.json();
    isStillLogedIn(JSON_loadPayees, dispatch);
    const { messages, success } = JSON_loadPayees;
    if (success === true)
      // Dispatch: GET_PAYEES Reducer.
      // Pass data to reducer in the payload.
      return dispatch({
        type: GET_PAYEES,
        payload: messages.Payees,
      });
  } catch (err) { }
};

// Action: Set Temp transactions to allow user to create a new real transaction.
// This transaction is a temporary transaction, which it will disappear at the moment when the user saves changes.
export const setNewTransactionRow = (account) => async (dispatch) => {
  try {
    // Set a fake transaction in the transaction state with key of -1.
    // Dispatch: SET_TRANSACTION Reducer.
    // Pass data to reducer in the payload.
    return dispatch({
      type: SET_TRANSACTION,
      payload: {
        key: "-1",
        date: "",
        account: account !== undefined ? account : "",
        categorie: "",
        out: 0,
        in: 0,
        transfer_transaction_id: null,
        payee: "",
        memo: "-",
      },
    });
  } catch (err) { }
};

// Action: Set, send and save a new transaction to DB.
export const setTransaction =
  (newTransaction, message, t) => async (dispatch) => {
    try {
      const addNewTransaction = await fetch(
        `https://${REACT_APP_SERVER_URL}/transaction/add`,
        {
          method: "post",
          headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
          body: JSON.stringify({
            refresh_token: localStorage.getItem("REFRESH_TOKEN"),
            access_token: sessionStorage.getItem("ACCESS_TOKEN"),
            transaction_date: newTransaction.date,
            account_id: newTransaction.account,
            budget_id: newTransaction.categorie,
            transaction_amount: String(newTransaction.amount).replace(",", "."),
            transaction_account_in: newTransaction.isIncome,
            transaction_note: newTransaction.memo,
            payee: newTransaction.payee,
            space_id: sessionStorage.getItem("SESSION_SPACE")
          }),
          credentials: "include",
        }
      );
      const JSON_response = await addNewTransaction.json();
      isStillLogedIn(JSON_response, dispatch);
      const { success } = JSON_response;
      if (success === true) {
        message.success(
          t("AddTrModal_La_nouvelle_transaction_a_été_ajoutée_avec_succès"),
          3
        );
      } else {
        message.success(t("AddTrModal_Erreur"), 5);
      }

    } catch (err) { }
  };

// Action: Set, send and save a new transaction to DB.
export const setTransactionDoc =
  (transaction_id, file, message, t, cb) => async () => {
    try {
      const formData = new FormData();
      formData.append(
        "doc",
        file,
        `FileRealatedToTransactionWithId${transaction_id}.${file.name.split(".")[1]
        }`
      );
      formData.append("refresh_token", localStorage.getItem("REFRESH_TOKEN"));
      formData.append("access_token", sessionStorage.getItem("ACCESS_TOKEN"));
      formData.append("transaction_id", transaction_id);
      const payBackRes = await fetch(
        `https://${REACT_APP_SERVER_URL}/transaction/docs/add`,
        {
          method: "post",
          body: formData,
        }
      );
      const JSON_response = await payBackRes.json();
      const { success } = JSON_response;
      if (success === true) return cb();
    } catch (err) {
    }
  };

export const updateTransaction = (updateTransaction) => async (dispatch) => {
  try {
    let {
      key,
      account,
      categorie,
      isIn,
      transaction_amount,
      memo,
      date,
      payee,
      is_it_closed,
      is_it_imported
    } = updateTransaction;
    if (!is_it_closed) {
      isIn = true;
      if (parseFloat(transaction_amount) > 0) isIn = true;
      if (parseFloat(transaction_amount) < 0) {
        isIn = false;
        transaction_amount = parseFloat(transaction_amount) * -1;
      }
      const updateTransactionReq = await fetch(
        `https://${REACT_APP_SERVER_URL}/transaction/update`,
        {
          method: "put",
          headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
          body: JSON.stringify({
            refresh_token: localStorage.getItem("REFRESH_TOKEN"),
            access_token: sessionStorage.getItem("ACCESS_TOKEN"),
            transaction_id: key,
            transaction_date: date,
            is_it_closed: is_it_closed,
            is_it_imported: is_it_imported,
            account_id: account,
            budget_id: categorie,
            space_id: sessionStorage.getItem("SESSION_SPACE"),
            transaction_amount: transaction_amount,
            transaction_note: memo,
            payee:
              payee === undefined || payee === null
                ? (payee = "")
                : (payee = payee),
            transaction_account_in: isIn,
          }),
          credentials: "include",
        }
      );

      const JSON_response = await updateTransactionReq.json();
      isStillLogedIn(JSON_response, dispatch);
      const { success } = JSON_response;

      if (success === true) {
        ReactGAEvent('Update', 'Update a Transaction');
        dispatch(getCategories());
        dispatch(getAllCategories());
        dispatch(getAccounts());
        dispatch(getPayees());
      }
    }


  } catch (err) { }
};

// Action: Delete one or many transactions:
export const deleteTransaction = (toDeleteTransactionKeys, cb) => async (dispatch) => {
    // We are passing an array keys of the targetten transactions (toDeleteTransactionKeys).
    try {
      const deletetransaction = await fetch(
        `https://${REACT_APP_SERVER_URL}/transaction/delete`,
        {
          method: "delete",
          headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
          body: JSON.stringify({
            refresh_token: localStorage.getItem("REFRESH_TOKEN"),
            access_token: sessionStorage.getItem("ACCESS_TOKEN"),
            transactions_ids: toDeleteTransactionKeys,
          }),
          credentials: "include",
        }
      );

      const JSON_response = await deletetransaction.json();
      isStillLogedIn(JSON_response, dispatch);
      const { success } = JSON_response;

      if (success === true) {
        ReactGAEvent('Delete', 'Delete a Transaction');
        dispatch(await getCategories());
        dispatch(await getPayees());
        cb();
      }
    } catch (err) { }

  };

// Action: Set repetition type to a spicifice transaction.
export const getPeriodiciteType = (transaction_id, type, isActive) => async () => {
    // We are passing as params:
    // (transaction_id): the target transaction id.
    // (type): the type of repetition (type id).
    // (is_active): is this repetition is activited or not.
    try {
      return await fetch(
        `https://${REACT_APP_SERVER_URL}/transaction/setperiodicity`,
        {
          method: "post",
          headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
          body: JSON.stringify({
            refresh_token: localStorage.getItem("REFRESH_TOKEN"),
            access_token: sessionStorage.getItem("ACCESS_TOKEN"),
            transaction_id: transaction_id,
            periodicity_id: type,
            is_active: isActive,
            space_id: sessionStorage.getItem("SESSION_SPACE")
          }),
          credentials: "include",
        }
      );
    } catch (err) { }
  };

// Action: Get all repetition types list.
export const getPeriodicite = () => async (dispatch) => {
  try {
    return await fetch(
      `https://${REACT_APP_SERVER_URL}/transaction/periodicities`,
      {
        method: "post",
        headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
        credentials: "include",
        body: JSON.stringify({
          refresh_token: localStorage.getItem("REFRESH_TOKEN"),
          access_token: sessionStorage.getItem("ACCESS_TOKEN"),
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        isStillLogedIn(response, dispatch);
        if (response.success === true)
          // Dispatch: GET_PERIODICITE Reducer.
          // Pass data to reducer in the payload.
          return dispatch({
            type: GET_PERIODICITE,
            payload: response.messages.payload,
          });
      });
  } catch (err) { }
};

// Action: Filter only the not futur transactions:
export const getTransactionNotFuturs = (hideFutureTransaction) => async (dispatch) => {
  // Dispatch: DELETE_TRANSACTIONS Reducer.
  // Here we are just dispatching the reducer without passing any data.
  return dispatch({
    type: GET_TRANSACTIONS_NOT_FUTURS,
    payload: { hideFutureTransaction: hideFutureTransaction },
  });
};

// refresh transactions
export const refreshBankTransactions = (cb) => async (dispatch) => {
  try {
    return await fetch(`https://${REACT_APP_SERVER_URL}/refreshtransactions`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      credentials: "include",
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
        access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
      }),
    })
      .then((response) => response.json())
      .then(async (response) => {
        isStillLogedIn(response, dispatch);
        if (response.success === true) {
          setTimeout(async () => {
            cb(response.messages);
            dispatch(getTransaction(true));
            dispatch(getPayees());
          }, 2500);
        }
      });
  } catch (err) { }
};

export const startOnBoardingTransactionView = (value) => async (dispatch) => {
  dispatch({
    type: RUN_TRANSACTION_VIEW_ONBOARDING,
    payload: value
  });
};

export const cleanTransaction = () => (dispatch) => {
  return dispatch({
    type: CLEAN_TRANSACTION,
    payload: {},
  });
};

const isStillLogedIn = async (response, dispatch) => {
  if (response.success === false && response.isSessionHasExpired) {
    await dispatch({ type: SET_LOGOUT, payload: {} });
  }
};
