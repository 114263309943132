import {
  GET_ACCOUNT,
  GET_ACCOUNTS,
  GET_ACCOUNT_TYPES,
  GET_ACCOUNT_HEADER,
  GET_NORDIGEN_BANKS,
  GET_NORDIGEN_ACCOUNTS,
  SET_TO_IMPORT_ACCOUNT_NORDIGEN,
  SET_NAME_TO_IMPORT_ACCOUNT,
  SET_NAME_TO_IMPORT_ACCOUNT_NORDIGEN,
  SET_STARTING_DATE_TO_IMPORT_ACCOUNT_NORDIGEN,
  SET_ADD_OR_IMPORT_ACCOUNT_MODAL,
  DELETE_ACCOUNT,
  CLEAN_ACCOUNT,
  GET_ACCOUNTS_FOR_SELECT,
  GET_TR_ACCOUNTS
} from "./types";

import {
  GET_LIST_OF_ACCOUNTS_API,
  GET_ONE_ACCOUNT_API,
  GET_ACCOUNT_HEADER_API,
  GET_ACCOUNT_TYPES_API,
  ADD_NEW_ACCOUNT_API,
  UPDATE_ACCOUNT_API,
  CLOSE_ACCOUNT_API,
  DELETE_ACCOUNT_API
} from "../api/account";

import {
  GET_LIST_OF_BANKS_NORDIGEN_API,
  GET_LIST_OF_ACCOUNTS_NORDIGEN_API,
  IMPORT_SELECTED_ACCOUNTS_NORDIGEN_API,
  SYNC_IMPORTED_ACCOUNTS_NORDIGEN_API
} from "../api/nordigen";

import apiHelpers from "../helpers/api";
import dateHelpers from "../helpers/date";
import moenyHelpers from "../helpers/money";

const { callApiHelper, isStillLogedIn } = apiHelpers;
const { adjustDateFormatForApi } = dateHelpers;
const { adjustMoneyFormatForApi } = moenyHelpers;

// Action: Get all accounts for the current user.
export const getAccounts = () => async (dispatch) => {
  try {
    const response = await callApiHelper(GET_LIST_OF_ACCOUNTS_API, "post", {}, true);

    await isStillLogedIn(response, dispatch);

    if (response.success === true) {
      dispatch(await getAccountHeader());
      dispatch({
        type: GET_ACCOUNTS,
        payload: {
          account: response.messages.account,
        },
      });
      dispatch({
        type: GET_ACCOUNTS_FOR_SELECT,
        payload: {
          selectAccounts: response.messages.account,
        },
      });
      dispatch({ type: GET_TR_ACCOUNTS, payload: response.messages.account });
    }
  } catch (err) { }
};

// Action: Get statistics for account page header.
export const getAccountHeader = (accountsType = 1) => async (dispatch) => {
  try {
    const response = await callApiHelper(GET_ACCOUNT_HEADER_API, "post", {}, true);

    await isStillLogedIn(response, dispatch);

    if (response.success === true) {
      return dispatch({
        type: GET_ACCOUNT_HEADER,
        payload: response.messages.account,
      });
    }
  } catch (err) { }
};

// Action: Get all account Types for the current user.
export const getAccountTypes = () => async (dispatch) => {
  try {
    const response = await callApiHelper(GET_ACCOUNT_TYPES_API, "post", {}, true);

    await isStillLogedIn(response, dispatch);

    if (response.success === true) {
      dispatch({
        type: GET_ACCOUNT_TYPES,
        payload: response.messages.accountType,
      });
    }
  } catch (error) { }
};

// Action: Get one account by account id passing on params.
export const getAccount = (account_id) => async (dispatch) => {
  try {
    const response = await callApiHelper(GET_ONE_ACCOUNT_API, "post", { account_id: account_id }, true);
    await isStillLogedIn(response, dispatch);

    if (response.success === true) dispatch({
      type: GET_ACCOUNT,
      payload: response.messages,
    });
  } catch (err) { }
};

// Action: Get accounts list by tow filters:
export const filterAccountByType = (accountType = -1) =>
  async (dispatch) => {
    try {
      const response = await callApiHelper(GET_LIST_OF_ACCOUNTS_API, "post", {}, true);

      await isStillLogedIn(response, dispatch);

      if (response.success === true) {
        const { account } = response.messages;

        if (accountType !== -1) {
          let newState = [];

          for (let i = 0; i < account.length; i++) {
            if (account[i].accountTypeId === accountType)
              newState.push(account[i]);
          }
          return dispatch({
            type: GET_ACCOUNTS,
            payload: { account: newState },
          });
        }

        dispatch({
          type: GET_ACCOUNTS,
          payload: { account: account },
        });
      }
    } catch (err) { }
  };

// Action: Set/Add new account:
export const addNewAccount = (new_account) => async (dispatch) => {
  try {
    const response = await callApiHelper(ADD_NEW_ACCOUNT_API, "post", {
      account_name: new_account.accountName,
      account_type_id: new_account.accountType,
      account_creation_date: await adjustDateFormatForApi(new_account.accountDate),
      account_note: new_account.account_note,
      initial_balance: await adjustMoneyFormatForApi(new_account.accountInitalSold),
      is_it_to_budget: new_account.accountABudgeter,
    }, true);

    await isStillLogedIn(response, dispatch);

    if (response.success === true) {
      dispatch(getAccounts());
    }
  } catch (err) { }
};

// Action: Edit/Update an existing account:
export const updateAccount = (targetAccount) => async (dispatch) => {
  try {
    targetAccount.accountInitalSold = targetAccount.accountInitalSold.toString().replace(",", ".");
    const response = await callApiHelper(UPDATE_ACCOUNT_API, "put", {
      account_id: targetAccount.accountId,
      account_name: targetAccount.accountName,
      account_type_id: targetAccount.accountType,
      account_note: targetAccount.accountNote,
      account_creation_date: await adjustDateFormatForApi(targetAccount.accountDate),
      initial_balance: await adjustMoneyFormatForApi(targetAccount.accountInitalSold),
      is_it_to_budget: targetAccount.accountABudgeter,
      is_it_closed: targetAccount.is_it_closed,
    }, true);

    await isStillLogedIn(response, dispatch);

    if (response.success === true) {
      dispatch(getAccount(targetAccount.accountId));
      dispatch(getAccounts());
    }
  } catch (err) { }
};

// Action: Close account:
export const closeAccount = (account_id, transfer_account_id) => async (dispatch) => {
  try {
    const response = await callApiHelper(CLOSE_ACCOUNT_API, "post", {
      account_id: account_id,
      transfer_account_id: transfer_account_id,
      is_it_closed: true,
    }, true);

    await isStillLogedIn(response, dispatch);

    if (response.success === true) {
      dispatch(getAccounts());
      return dispatch({
        type: DELETE_ACCOUNT,
        payload: account_id,
      });
    }
  } catch (err) { }
};

// Action: Delete account:
export const deleteAccount = (accountId, keepTransactions) => async (dispatch) => {
  try {
    const response = await callApiHelper(DELETE_ACCOUNT_API, "delete", {
      account_id: accountId,
      keep_transactions: keepTransactions,
    }, true);

    await isStillLogedIn(response, dispatch);

    if (response.success === true) {
      dispatch(await getAccounts())
      return dispatch({
        type: DELETE_ACCOUNT,
        payload: accountId,
      });
    }
  } catch (err) { }
};

export const setAddOrImportAccountMoadl = (value) => (dispatch) => {
  return dispatch({
    type: SET_ADD_OR_IMPORT_ACCOUNT_MODAL,
    payload: value,
  });
}

export const cleanAccount = () => (dispatch) => {
  return dispatch({
    type: CLEAN_ACCOUNT,
    payload: {},
  });
};

// #region Nordigen api endPoints:
export const getListOfBanks = (country) => async (dispatch) => {
  try {
    const response = await callApiHelper(GET_LIST_OF_BANKS_NORDIGEN_API, "post", {
      country: country
    }, true);

    await isStillLogedIn(response, dispatch);

    if (response.success === true) {
      dispatch({
        type: GET_NORDIGEN_BANKS,
        payload: {
          data: response.messages,
        },
      });
    }
  } catch (err) { }
};

export const getListOfNordigenAccounts = (aspsp, callback) => async (dispatch) => {
  try {
    const response = await callApiHelper(GET_LIST_OF_ACCOUNTS_NORDIGEN_API, "post", {
      aspsp: aspsp
    }, true);

    await isStillLogedIn(response, dispatch);

    if (response.success === true) {
      dispatch({
        type: GET_NORDIGEN_ACCOUNTS,
        payload: {
          data: response.messages,
        },
      });
      callback(response.isRestoringAccount);
    }
  } catch (err) { }
};

export const importTheSelectedAccountsFromNordigen = (accounts, institution_id, message, t, callback) => async (dispatch) => {
  try {
    if (accounts.filter((acc) => acc.selected === true).length === 0) {
      callback(false);
      return (
        t("Account_Veuillez_sélectionner_au_moins_un_compte_avant_d'enregistrer_les_modifications"),
        4
      );
    }

    const response = await callApiHelper(IMPORT_SELECTED_ACCOUNTS_NORDIGEN_API, "post",
      { selectedAccounts: accounts.filter((element) => element.selected === true), institution_id: institution_id }, true);
    if (response.success === true) {
      callback(true);
      message.success(t("Transaction_Les_comptes_sélectionnés_ont_été_importés_avec_succès"), 5);
      dispatch(await getAccounts());
    }
  } catch (err) {
  }
};

export const syncImportedAccountsFromNordigen = (body = {}, callback) => async (dispatch) => {
  try {
    const response = await callApiHelper(SYNC_IMPORTED_ACCOUNTS_NORDIGEN_API, "post", body, true);

    await isStillLogedIn(response, dispatch);
    callback(response);
  } catch (err) { }
};

export const setNewNameToImportAccountsNordigen =
  (account_id, value, editStartingDate = false) =>
    (dispatch) => {
      if (editStartingDate === true) {
        return dispatch({
          type: SET_STARTING_DATE_TO_IMPORT_ACCOUNT_NORDIGEN,
          payload: { account_id, value },
        });
      }
      return dispatch({
        type: SET_NAME_TO_IMPORT_ACCOUNT_NORDIGEN,
        payload: { account_id, value },
      });
    };

export const setSelectedToImportAccountIdsNordigen = (account_id, value) => (dispatch) => {
  return dispatch({
    type: SET_TO_IMPORT_ACCOUNT_NORDIGEN,
    payload: {
      account_id, value
    },
  });
};

export const setNewNameToImportAccounts = (account_id, value) => (dispatch) => {
  return dispatch({
    type: SET_NAME_TO_IMPORT_ACCOUNT,
    payload: { account_id, value },
  });
};
// #endregion
