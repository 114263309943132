import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import TransactionTable from "../components/Tables/TransactionTable.js";
import TransactionWidget from "../components/Widgets/TransactionWidget.js";
import FormatCurrencyColored from "../components/Tools/Currency/FormatCurrencyColored.js";
import { Row, Col, Popover, PopoverBody, PopoverHeader } from "reactstrap";
import Joyride, { STATUS } from 'react-joyride';
import { connect } from "react-redux";
import {
  getTransaction,
  getCategories,
  getPayees,
  setTransaction,
  setNewTransactionRow,
  filterTransaction,
  updateTransaction,
  getPeriodicite,
  refreshBankTransactions,
  deleteTransaction,
  getPeriodiciteType,
  getTransactionNotFuturs,
  getTransactionWithoutCats,
  setTransactionDoc,
  getAllCategories,
} from "../actions/Transaction";
import { getAccounts, setAddOrImportAccountMoadl, addNewAccount, getAccountTypes } from "../actions/Account";
import { openAddTransactionModal } from "../actions/Global";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { DatePicker, message } from "antd";
import Icons from "../components/Randoms/Icons"
import DefaultModal from "components/Modals/DefaultModal.jsx";
import { COLORS } from "assets/constants/theme.js";
import { StringManipulator } from "@fineo-io/main-javascript-library";
import AddAccountModal from "components/Modals/AddAccountModal.jsx";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min.js";
import DefaultSelect from "components/inputs/DefaultSelect.jsx";
import StandardButton from "components/Buttons/standardButton/StandardButton.jsx";
import MainHeader from "components/Headers/MainHeader.jsx";

const {
  BlackTransaction,
  BlackDateSelector,
  BlackBudgetCategoriesSelector
} = Icons;
const { RangePicker } = DatePicker;

function Transactions({
  transactionState,
  getTransaction,
  getPeriodiciteType,
  getAccounts,
  filterTransaction,
  getTransactionWithoutCats,
  setNewTransactionRow,
  setTransaction,
  updateTransaction,
  deleteTransaction,
  getPayees,
  getCategories,
  getAllCategories,
  getTransactionNotFuturs,
  accountState,
  addNewAccount,
  authState,
  budgetState,
  getPeriodicite,
  getAccountTypes,
  openAddTransactionModal,
  setTransactionDoc,
  setAddOrImportAccountMoadl
}) {
  const { t } = useTranslation();
  const history = useHistory();
  // A Boolean For Enable Or Disable The Delete Button (True On Row Check)
  const [isEnable, setisEnable] = useState(true);
  // A Boolean For Enbale or disable Enregistrer Button
  const [isEnableToSave, setisEnableToSave] = useState(true);
  const [hideFutureTransactionRows, setHide] = useState(!transactionState.hideFutureTransaction);
  // var to handle the transaction view when the view is in add new transaction mode
  const [onAddMode, setOnAddMode] = useState(false);
  // String Object For Setting The Befor Deleting Text
  const [handleRemoveText, sethandleRemoveText] = useState("");
  // Array: To Store In All Wanted To Be Deleted Transactions
  const [toDeleteTransactions, setToDeleteTransactions] = useState([]);

  // Boolean: show or hide the confirm delete transaction
  const [togelDeleteTransationModal, setTogelDeleteTransationModal] = useState(false);
  const [userCurrentTablePage, setUserCurrentTablePage] = useState(1)
  const [totalAccountsSolde, setTotalAccountsSolde] = useState(0);
  const [needToAddAccountModal, setNeedToAddAccountModal] = useState(false)
  // Fieds Infos:
  const [showPayeeInfo, setShowPayeeInfo] = useState(false);
  const [showCategoryeInfo, setShowCategoryInfo] = useState(false);
  const [showAccountInfo, setShowAccountInfo] = useState(false);
  const [selectedAccountsToBeFilterTransactionBy, setSelectedAccountsToBeFilterTransactionBy] = useState([])
  const getParams = () => new URLSearchParams(useLocation().search);
  const params = getParams();
  const steps = [
    { target: '.add-transaction-button-1', content: "This the add transaction button 1", "placement": "left" },
  ];

  // Filters
  const defaultFilterOptions = {
    account_ids: "Tous les comptes",
    budget_category_id: -2,
    inOutFilters: "allTr",
    dateFilter: '',
  };
  const [filterOptions, setFilterOptions] = useState({
    ...defaultFilterOptions,
    budget_category_id: budgetState.selectedBudgetCategoryId || -2,
    dateFilter: budgetState?.selectedBudgetCategoryDate || "",
  });
  const resetFilterOptions = () => {setFilterOptions({ ...defaultFilterOptions })};
  const resetFilters = async () => {
    await getTransaction(true, t);
    resetFilterOptions();
  };

  // Calculations
  const sumTransactions = (accumulator, currentValue) => {
    const income = parseFloat(currentValue.in) || 0;
    const expense = parseFloat(currentValue.out) || 0;
    return accumulator + income - expense;
  };

  useEffect(() => {
    if (isEnableToSave === true) {
      getAccountTypes();
      getPeriodicite();
      getAccounts();
      getCategories(t);
      getAllCategories(t);
      if (params.size !== 0) {
        if (params.get('uncategorized') === 'true') {
          getTransactionWithoutCats(t);
        } else {
          filterTransaction({
            account_ids: -1,
            budget_category_id: params.get('category'),
            dateFilter: [params.get('start_date'), params.get('end_date')],
            inOutFilters: "allTr"
          }, t);
        }
      } else {
        getTransaction(true, t);
      }
    }
  }, [
    isEnableToSave,
    getCategories,
    getAccountTypes,
    getPayees,
    getAccounts,
    getPeriodicite,
    sessionStorage.getItem("SESSION_SPACE")
  ]);

  useEffect(() => {
    let total = 0;
    accountState.accounts.forEach((account) => {
      total = total + account.solde;
    })
    setTotalAccountsSolde(total)
  }, [accountState.accounts]);

  // Handle Save transaction to db
  const handleAddNewTransaction = async (row, cb) => {
    try {
      if (row.key === "-1") {
        if (row.andAddNewOne === false) setisEnableToSave(true);
        if (row.andAddNewOne === true) setisEnableToSave(false);
        setOnAddMode(false);
        await setTransaction(row, message, t);
        if (transactionState.getTrnsactionWithout) {
          await getTransactionWithoutCats(t)
        } else {
          await filterTransaction(budgetState.selectedBudgetCategoryId ? { ...filterOptions, budget_category_id: budgetState.selectedBudgetCategoryId } : { ...filterOptions }, t);
        }
        await getAccounts();
        await getCategories(t);
        await getCategories(t, false);
        await getPayees();
        cb();
      }
    } catch (error) { }
  };

  // Handle add transaction to table, and open inputs
  const handleAdd = async () => {
    try {
      if (transactionState.transactions.filter((tr) => tr.key === "-1").length === 0) {
        await setNewTransactionRow(selectedAccountsToBeFilterTransactionBy[0]);
      }
    } catch (error) { }
  };

  // handle update Transaction
  const handleSave = async (row) => {
    try {
      if (transactionState.transactions.filter((tr) => tr.key === "-1").length === 1) return;
      row.date = row.date.split("/").reverse().join("-");
      await updateTransaction(row);
      if (transactionState.getTrnsactionWithout) {
        await getTransactionWithoutCats(t)
      } else {
        await filterTransaction({ ...filterOptions }, t);
      }
    } catch (error) {}
  };

  // Handle remove selected transactions
  const handleRemove = async () => {
    try {
      setisEnable(true);
      setisEnableToSave(true);
      setTogelDeleteTransationModal(!togelDeleteTransationModal);
      let newState = [];
      let newState2 = [];
      toDeleteTransactions.forEach((tr) => {
        const res = transactionState.transactions.filter((transaction) => transaction.key === tr);
        newState.push(res[0].key);
      });
      if (newState.length !== toDeleteTransactions.length) {
        setisEnable(false);
        sethandleRemoveText(`${t("Transaction_Êtes_vous_sûr(e)_de_vouloir_supprimer_la_sélection")} ( ${newState2.length} )`);
        setToDeleteTransactions(newState2);
      } else {
        setisEnable(true);
        setToDeleteTransactions([]);
        sethandleRemoveText("");
      }
      await deleteTransaction(newState, async () => {
        await filterTransaction({ ...filterOptions }, t);
        if (transactionState.getTrnsactionWithout) {
          await getTransactionWithoutCats(t)
        } else {
          await filterTransaction({ ...filterOptions }, t);
        }
        if (newState.length !== 0) {
          message.success(t("Transaction_Les_transactions_sélectionnées_sont_supprimées_avec_succès"));
        }
        await getAccounts();
      });
      await filterTransaction({ ...filterOptions }, t);
    } catch (error) { }
  };

  // Handle cancel remove
  const cancelRemove = (e) => {
    setTogelDeleteTransationModal(!togelDeleteTransationModal);
  };

  // Cancel the add event, hide the add inputs
  const CancelAdd = async (e) => {
    try {
      document.getElementById(`enableSelectCategorie`).style.display = "inline";
      document.getElementById(`disabledSelectCategorie`).style.display = "none";
      setOnAddMode(false);
      setisEnableToSave(true);
      await filterTransaction({ ...filterOptions }, t);
      return;
    } catch (error) { }
  };

  // Handle selecting transaction befor remobing them
  const rowSelection = (data) => {
    if (data === true) {
      setisEnable(false);
      sethandleRemoveText(`Êtes vous sûr(e) de vouloir supprimer la sélection ( ${transactionState.transactions.length} )`);
      const allTransactionKeys = [];
      transactionState.transactions.forEach((transaction) => allTransactionKeys.push(transaction.key));
      return setToDeleteTransactions(allTransactionKeys);
    } else if (data === false) {
      sethandleRemoveText("");
      setisEnable(true);
      return setToDeleteTransactions([]);
    }

    if (toDeleteTransactions.length === 0) {
      setisEnable(false);
      sethandleRemoveText();
      sethandleRemoveText(`${t("Transaction_Êtes_vous_sûr(e)_de_vouloir_supprimer_la_sélection")} ( ${1} )`);
      return setToDeleteTransactions([...toDeleteTransactions, data.key]);
    }

    const exist = toDeleteTransactions.filter((row) => data.key === row);
    if (exist.length === 0 && toDeleteTransactions.length !== 0) {
      setisEnable(false);
      if (toDeleteTransactions.length === 0) setisEnable(true);
      sethandleRemoveText(`${t("Transaction_Êtes_vous_sûr(e)_de_vouloir_supprimer_la_sélection")} ( ${toDeleteTransactions.length + 1} )`);
      return setToDeleteTransactions([...toDeleteTransactions, data.key]);
    }

    if (exist.length !== 0) {
      if (toDeleteTransactions.length - 1 > 0) {
        setisEnable(false);
        if (toDeleteTransactions.length === 0) setisEnable(true);
        sethandleRemoveText(`Transaction_Êtes_vous_sûr(e)_de_vouloir_supprimer_la_sélection`);
        sethandleRemoveText(`${t("Transaction_Êtes_vous_sûr(e)_de_vouloir_supprimer_la_sélection")} ( ${toDeleteTransactions.length - 1} )`);
        let newTempData = toDeleteTransactions.filter((row) => row !== data.key);
        return setToDeleteTransactions([...newTempData]);
      }
      if (toDeleteTransactions.length - 1 === 0) {
        setisEnable(true);
        if (toDeleteTransactions.length === 0) setisEnable(true);
        sethandleRemoveText("");
        return setToDeleteTransactions([]);
      }
    }
  };

  // Filter transaction by account
  const filterTransactionByAccount = async (option) => {
    try {
      if (option.value === "Tous les comptes") {
        await filterTransaction({ ...filterOptions, account_ids: -1 }, t);
        setFilterOptions({ ...filterOptions, account_ids: "Tous les comptes" });
        setSelectedAccountsToBeFilterTransactionBy([])
        return;
      }
      if (selectedAccountsToBeFilterTransactionBy.filter((item) => item === option.value).length !== 0) {
        setSelectedAccountsToBeFilterTransactionBy(selectedAccountsToBeFilterTransactionBy.filter((item) => item !== option.value))
        await filterTransaction({
          ...filterOptions, account_ids: selectedAccountsToBeFilterTransactionBy.filter((item) => item !== option.value).length !== 0
            ? selectedAccountsToBeFilterTransactionBy.filter((item) => item !== option.value) : -1,
        }, t);
        setFilterOptions({
          ...filterOptions,
          account_ids: selectedAccountsToBeFilterTransactionBy.filter((item) => item !== option.value).length !== 0
            ? selectedAccountsToBeFilterTransactionBy.filter((item) => item !== option.value) : "Tous les comptes",
        });
      } else {
        setSelectedAccountsToBeFilterTransactionBy([...selectedAccountsToBeFilterTransactionBy, option.value])
        await filterTransaction({ ...filterOptions, account_ids: [...selectedAccountsToBeFilterTransactionBy, option.value] }, t);
        setFilterOptions({
          ...filterOptions,
          account_ids: [...selectedAccountsToBeFilterTransactionBy, option.value],
        });
      }
    } catch (error) { }
  };

  // Filter transaction by categorie
  const filterTransactionByCategorie = async (option) => {
    try {
      await filterTransaction({ ...filterOptions, budget_category_id: option.value }, t);
      setFilterOptions({ ...filterOptions, budget_category_id: option.value });
    } catch (error) {}
  };

  // Filter transaction by date
  const filterTransactionByDate = async (date) => {
    const startDate = date[0].startOf('day');
    const endDate = date[1].startOf('day');
    try {
      await filterTransaction({ ...filterOptions, dateFilter: date }, t);
      setFilterOptions(
        { ...filterOptions, dateFilter: [startDate, endDate] },
        sessionStorage.getItem("SESSION_SPACE"),
        transactionState.hideFutureTransaction
      );
    } catch (error) { }
  };

  // Get only the non-future transactions:
  const handleFilter = async () => {
    try {
      await filterTransaction({ ...filterOptions }, t);
    } catch (error) {}
  };

  // Render the add button
  const renderAddButton = () => (
    <StandardButton
      text=""
      title={StringManipulator.capitalize(t("Transaction_Quick_Add"))}
      onClick={async () => {
        if (accountState.selectAccounts.length === 0) {
          setNeedToAddAccountModal(true);
          return;
        }
        if (transactionState.transactions.length === 0 && accountState.selectAccounts.length !== 0) return;
        if (userCurrentTablePage === 1) handleAdd();
        else openAddTransactionModal(true);
      }}
    >
      <i className="fas fa-plus-circle" />
    </StandardButton>
  );

  // Render the delete button
  const renderDeleteButton = () => (
    <StandardButton
      text=""
      title={StringManipulator.capitalize(t("global.delete._"))}
      color={COLORS.danger}
      disabled={toDeleteTransactions.length === 0}
      onClick={() => {
        if (transactionState.transactions.filter((tr) => tr.key === "-1").length === 1) return;
        setTogelDeleteTransationModal(!togelDeleteTransationModal)
      }}
    >
      <i className="fas fa-trash-alt" />
    </StandardButton>
  );

  const renderConfirmRemoveTransactions = () => (
    <DefaultModal
      title={t("global.warning._")}
      size="modal-md"
      color={COLORS.danger}
      isOpen={togelDeleteTransationModal}
      toggle={() => cancelRemove()}
      hasTwoButtons={true}
      btnText={StringManipulator.capitalize(t("global.delete._"))}
      onClick={() => handleRemove()}
      secondBtnText={StringManipulator.capitalize(t("global.cancel._"))}
      secondOnClick={() => cancelRemove()}
    >
      <div className="modal-body">
        <div className="py-3 text-center">
          <p>{handleRemoveText}</p>
        </div>
      </div>
    </DefaultModal>
  );

  // Render the button for show/hide future transactions
  const renderButtonForFutureTransaction = () => {
    const hideFutureTransactions = async () => {
      handleFilter();
      await getTransactionNotFuturs(true);
      setHide(!hideFutureTransactionRows);
    }
    const showFutureTransactions = async () => {
      setHide(!hideFutureTransactionRows);
      await getTransactionNotFuturs(false);
      await filterTransaction({ ...filterOptions }, t);
    }
    const handleRows = hideFutureTransactionRows ? hideFutureTransactions : showFutureTransactions;
    const chevron = hideFutureTransactionRows ? 'fa-chevron-up' : 'fa-chevron-down';
    const action = hideFutureTransactionRows ? 'hide' : 'show';
    return (
      !onAddMode && (
        <StandardButton
          text=""
          title={StringManipulator.capitalize(t(`global.${action}.future.transactions`))}
          onClick={handleRows}
        >
          <i className="fas fa-binoculars" />
          <span>{`(${transactionState?.futurTransactions?.length})`}</span>
          <i className={`fas ${chevron}`} />
        </StandardButton>
      )
    );
  };

  // Render the show/hide transactions filter button
  const [openPopOver, setOpenPopOver] = useState(false);
  const togglePopover = () => {setOpenPopOver(!openPopOver)};
  const renderButtonForTransactionFilters = () => {
    const chevron = openPopOver ? 'fa-chevron-up' : 'fa-chevron-down';
    return (
      <StandardButton text="" title={t('Transaction_Filters')} onClick={togglePopover}>
        <i id="Popover2" className="fas fa-filter"/>
        <span>{`(${transactionState?.transactions?.length})`}</span>
        <i className={`fas ${chevron}`} />
      </StandardButton>
    );
  };

  // Render the reset transactions filter button
  const renderButtonForResettingTransactionFilters = () => {
    return (
      <StandardButton
        text=""
        title={t('Transaction_Clear_Filter')}
        disabled={JSON.stringify(filterOptions) === JSON.stringify(defaultFilterOptions)}
        onClick={() => {
          setOpenPopOver(false);
          resetFilters();
        }}
      >
        <i className="fas fa-filter" />
        <i className="fas fa-times" />
      </StandardButton>
    );
  };

  // Render the transactions filter popover
  const renderTransactionsFilterPopover = () => {
    const renderTransactionsFilterByType = () => (
      <Col className="mt--2">
        <h4>
          <span>
            <img src={BlackTransaction} width={"15px"} height={"15px"} />{" "}
            {t("Transaction_Filtrer_par_Type")}
          </span>
        </h4>
        <DefaultSelect
          placeholder={t("Transaction_Sélectionner")}
          options={[
            { value: "allTr", label: StringManipulator.capitalize(t("global.all._")) },
            { value: "inTr", label: StringManipulator.capitalize(t("global.income._")) },
            { value: "outTr", label: StringManipulator.capitalize(t("global.expense._")) },
            { value: "transfer", label: StringManipulator.capitalize(t("transfer")) },
          ]}
          onChange={async (selected) => {
            await filterTransaction({ ...filterOptions, inOutFilters: selected.value}, t);
            setFilterOptions({ ...filterOptions, inOutFilters: selected.value });
          }}
        />
      </Col>
    );

    const renderTransactionsFilterByBudgetCategory = () => (
      <Col className="mt-3" md="12">
        <h4>
          <img src={BlackBudgetCategoriesSelector} width={"15px"} height={"15px"} />{" "}
          {t("Transaction_Filtrer_par_Categorie")}
        </h4>
        <DefaultSelect
          placeholder={t("Transaction_Sélectionner")}
          value={filterOptions.budget_category_id}
          options={transactionState?.filterCategories}
          onChange={filterTransactionByCategorie}
        />
        <div style={{ zIndex: 3, position: "absolute", right: 7 }}></div>
      </Col>
    );

    const renderTransactionsFilterByDate = () => (
      <Col className="mt-3 mb--1">
        <h4>
          <img src={BlackDateSelector} width={"15px"} height={"15px"} />{" "}
          {t("Transaction_Filtrer_par_Date")}
        </h4>
        <RangePicker
          id="date"
          name="date"
          picker="Day"
          placeholder={[t("Rapports_Date_de_début"), t("Rapports_Date_de_fin")]}
          className="w-100 ourCustomInputStyle"
          separator="-"
          allowClear={false}
          onChange={(value) => {filterTransactionByDate(value)}}
        />
      </Col>
    );

    return (
      <Popover
        target="Popover2"
        placement="bottom-start"
        isOpen={openPopOver}
        trigger="focus"
        toggle={togglePopover}
        style={{ width: "400", borderRadius: "10px" }}
        modifiers={{ offset: { enabled: true, offset: '0, 14px' } }}
      >
        <PopoverHeader className="d-flex justify-content-between" style={{backgroundColor: COLORS.primary }}>
          <span style={{ color: COLORS.neutral }}>
            {t('Transaction_Filters')}
          </span>
          <span>
            <i className="fas fa-times" onClick={() => setOpenPopOver(false)} style={{ cursor: "pointer", color: COLORS.neutral }} />
          </span>
        </PopoverHeader>
        <PopoverBody className="bg-white">
          <Row className="align-items-left p-3">
            {renderTransactionsFilterByType()}
            {renderTransactionsFilterByBudgetCategory()}
            {renderTransactionsFilterByDate()}
          </Row>
          {/* TODO: fix the select input reset, then enable the below buttons */}
          {/* <Row className="p-3">
            <Col>
              <StandardButton
                className="btn-block"
                color={COLORS.glyphSecondary}
                text={t('Reset')}
                onClick={resetFilters}
              />
            </Col>
            <Col>
              <StandardButton
                className="btn-block"
                text={t('Apply')}
                onClick={() => {setOpenPopOver(false)}}
              />
            </Col>
          </Row> */}
        </PopoverBody>
      </Popover>
    );
  };

  return (
    <div onClick={() => {
      if (showPayeeInfo === true) setShowPayeeInfo(false)
      if (showCategoryeInfo === true) setShowCategoryInfo(false)
      if (showAccountInfo === true) setShowAccountInfo(false)
    }}>
      <MainHeader title={StringManipulator.capitalize(t("global.transaction.s"))} />
      <div className="w-auto h-100">
        <TransactionWidget
          transactionWidgetData={[{
            key: "Tous les comptes",
            account: t("Account_Tous_les_comptes"),
            solde: totalAccountsSolde,
            accountTypeId: -1,
            is_it_imported: null,
            user_id: 0,
            is_it_closed: false
          }, ...accountState.accounts]}
          authState={authState}
          filterTransactionByAccount={filterTransactionByAccount}
          selectedAccountsToBeFilterTransactionBy={selectedAccountsToBeFilterTransactionBy}
          t={t}
        />
        <Row className="align-items-center">
          <Col md="12" xs="12" xl="12" xm="12" className="text-black">
            <hr />
          </Col>
          <Col md="12" xs="12" xl="12" xm="12">
            {renderTransactionsFilterPopover()}
          </Col>
        </Row>
        <Row className="mt-4 mx-3">
          <Col className="d-flex">
            {renderAddButton()}
            {renderButtonForFutureTransaction()}
            {renderButtonForTransactionFilters()}
            {renderButtonForResettingTransactionFilters()}
            {renderDeleteButton()}
          </Col>
          <Col className="d-flex">
            <span style={styles.right}>
              {`${t('Transaction_Net_Total')}: `}&nbsp;
              {FormatCurrencyColored(
                transactionState?.transactions.reduce(sumTransactions, 0),
                authState.user.currency_code,
                authState.user.currency_locales
              )}
            </span>
          </Col>
        </Row>
      </div>
      <Row className="mt-1 mx-3">
        <Col lg="12" xl="12" sm="12">
          <TransactionTable
            transactionData={transactionState.transactions}
            accountsData={accountState.selectAccounts}
            beneficiaryData={transactionState.payees}
            periodicitesData={transactionState.periodicite}
            BudgetsData={transactionState.categories}
            rowSelection={rowSelection}
            onAddMode={onAddMode}
            handleRemoveText={handleRemoveText}
            isEnable={isEnable}
            isEnableToSave={isEnableToSave}
            handleSaveTransactionToDb={handleAddNewTransaction}
            setUserCurrentTablePage={setUserCurrentTablePage}
            handleAdd={handleAdd}
            handleSave={handleSave}
            handleRemove={handleRemove}
            filterTransaction={filterTransaction}
            filterOptions={filterOptions}
            cancelRemove={cancelRemove}
            CancelAdd={CancelAdd}
            getTransaction={getTransaction}
            authState={authState}
            handleFilter={handleFilter}
            getPeriodiciteType={getPeriodiciteType}
            setTransactionDoc={setTransactionDoc}
            showPayeeInfo={showPayeeInfo}
            showCategoryeInfo={showCategoryeInfo}
            showAccountInfo={showAccountInfo}
            setShowPayeeInfo={setShowPayeeInfo}
            setShowCategoryInfo={setShowCategoryInfo}
            setShowAccountInfo={setShowAccountInfo}
          />
        </Col>
      </Row>
      <Row>
        <Col>{renderConfirmRemoveTransactions()}</Col>
      </Row>
      <AddAccountModal
        authState={authState}
        needToAddAccountModal={needToAddAccountModal}
        setNeedToAddAccountModal={setNeedToAddAccountModal}
      />
      <Joyride
        // run={transactionState.runTransactionViewOnBoarding}
        run={false}
        disableCloseOnEsc={true}
        showProgress={true}
        callback={(data) => {
          const { status, step: { target } } = data;
          if (target === ".add-transaction-button-1") {
          }
          if ([STATUS.FINISHED].includes(status)) {
            handleAdd();
          }
        }}
        showSkipButton={true}
        // continuous={true}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      // steps={steps}
      />
    </div >
  );
}

Transactions.prototype = {
  transactionState: PropTypes.object.isRequired,
  authState: PropTypes.object.isRequired,
  getTransactions: PropTypes.func.isRequired,
  getAccountTypes: PropTypes.func.isRequired,
  filterTransaction: PropTypes.func.isRequired,
  addNewAccount: PropTypes.func.isRequired,
  getPayees: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  getAllCategories: PropTypes.func.isRequired,
  getAccounts: PropTypes.func.isRequired,
  refreshBankTransactions: PropTypes.func.isRequired,
  setNewTransactionRow: PropTypes.func.isRequired,
  setTransaction: PropTypes.func.isRequired,
  setTransactionDoc: PropTypes.func.isRequired,
  updateTransaction: PropTypes.func.isRequired,
  deleteTransaction: PropTypes.func.isRequired,
  getTransactionNotFuturs: PropTypes.func.isRequired,
  accountState: PropTypes.object.isRequired,
  getTransactionWithoutCats: PropTypes.func.isRequired,
  getPeriodicite: PropTypes.func.isRequired,
  getPeriodiciteType: PropTypes.func.isRequired,
  setAddOrImportAccountMoadl: PropTypes.func.isRequired,
  openAddTransactionModal: PropTypes.func.isRequired,
};

const styles = {
  right: {
    alignItems: 'center',
    justifyContent: 'right',
    display: 'flex',
    flex: 1,
  },
};

const mapStateToProps = (state) => ({
  transactionState: state.transaction,
  accountState: state.account,
  authState: state.auth,
  budgetState: state.budget,
});

export default connect(mapStateToProps, {
  getTransaction,
  getPayees,
  filterTransaction,
  getCategories,
  getAllCategories,
  getAccounts,
  getTransactionWithoutCats,
  setTransaction,
  setNewTransactionRow,
  updateTransaction,
  getAccountTypes,
  getPeriodiciteType,
  addNewAccount,
  openAddTransactionModal,
  deleteTransaction,
  refreshBankTransactions,
  getTransactionNotFuturs,
  getPeriodicite,
  setTransactionDoc,
  setAddOrImportAccountMoadl,
})(Transactions);
