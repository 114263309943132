import {
  STRIPE_GET_HISTORY_PAIMENT,
  STRIPE_GET_LIST_PRICE,
} from "../actions/types";
import { SET_LOGOUT } from "../actions/types";
const { REACT_APP_SERVER_URL } = process.env;

export const createSubscription =
  async (
    {
      price,
      currency,
    },
    authState = {
      refresh_token: localStorage.getItem("REFRESH_TOKEN"),
      access_token: sessionStorage.getItem("ACCESS_TOKEN")
    }
  ) => {
    try {
      const { refresh_token, access_token } = authState;
      return await fetch(`https://${REACT_APP_SERVER_URL}/stripe/createSubscription`, {
        method: "post",
        headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
        body: JSON.stringify({
          refresh_token,
          access_token,
          currency: currency,
          price: price,
        }),
        credentials: "include",
      })
        .then((response) => response.json())
        .then(async (response) => {
          if (response.success) return response.payload;
          return false
        });
    } catch (err) {
      return false;
    }
  };

export const createCustomerPortalSession = () => async () => {
  try {
    fetch(`https://${REACT_APP_SERVER_URL}/stripe/createcustomerportalsession`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN"),
        access_token: sessionStorage.getItem("ACCESS_TOKEN"),
      }),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          window.location.href = response.payload.url;
        }
      });
  } catch (err) { }
};

export const getHistoryPaiment = () => async (dispatch) => {
  try {
    fetch(`https://${REACT_APP_SERVER_URL}/stripe/gethistorypaiment`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN"),
        access_token: sessionStorage.getItem("ACCESS_TOKEN"),
      }),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          dispatch({
            type: STRIPE_GET_HISTORY_PAIMENT,
            payload: response.messages.payload,
          });
        }
      });
  } catch (err) { }
};

export const getListPrice = (
  authState = {
    refresh_token: localStorage.getItem("REFRESH_TOKEN"),
    access_token: sessionStorage.getItem("ACCESS_TOKEN")
  }
) => async (dispatch) => {
  try {
    const { refresh_token, access_token } = authState;
    await fetch(`https://${REACT_APP_SERVER_URL}/stripe/getlistprice`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      body: JSON.stringify({
        refresh_token: refresh_token,
        access_token: access_token,
      }),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          dispatch({ type: STRIPE_GET_LIST_PRICE, payload: response.messages.payload });
        }
      });
  } catch (err) { }
};



const isStillLogedIn = async (response, dispatch) => {
  if (response.success === false && response.isSessionHasExpired) {
    await dispatch({ type: SET_LOGOUT, payload: {} });
  }
};
