import { useTranslation } from "react-i18next";
import { Modal } from "reactstrap";
import StandardButton from '../Buttons/standardButton/StandardButton';
import { COLORS } from "assets/constants/theme";
import { StringManipulator } from "@fineo-io/main-javascript-library";

export default function DefaultModal({
  isOpen = false,
  title = "",
  content = "",
  btnText = "Click",
  secondBtnText = "Click",
  toggle = () => {},
  onClick = () => {},
  secondOnClick = () => {},
  children = <></>,
  size = "18vh",
  height = "h-100",
  disabled = false,
  hasTwoButtons = false,
  hasNoButtons = false,
  style = {},
  bodyStyle = {},
  btnStyle = {},
  secondBtnStyle = {},
  ...props
}) {
  const { t } = useTranslation();
  return (
    <Modal
      className={"modal-dialog-centered mt--100 " + size}
      isOpen={isOpen}
      style={style}
      centered
      backdrop={true}
      toggle={() => toggle(false)}
    >
      <div className="modal-header" style={{ color: COLORS.neutral, backgroundColor: COLORS.primary, fontSize: "1.4rem", position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {StringManipulator.titleize(t(title))}
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", position: "absolute", right: "20px" }}
          onClick={() => toggle(!isOpen)}
        >
          <span style={{ color: COLORS.neutral, fontSize: "25px" }}aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body"
        style={{
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
          height: height,
          alignItems: "center",
          alignContent: "center",
          ...bodyStyle
        }}>
        {children} {t(content)}
      </div>
      {!hasNoButtons && <div className="modal-footer mt--4" style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
        <StandardButton
          onClick={onClick}
          text={btnText}
          disabled={disabled}
          style={{
            height: "40px",
            minWidth: "100px",
            textAlign: "center",
            alignItems: "center",
            fontSize: "16px",
            ...btnStyle
          }}
          {...props}
        />
        {hasTwoButtons &&
          <StandardButton
            color={COLORS.glyphSecondary}
            onClick={secondOnClick}
            text={secondBtnText}
            style={{
              height: "40px",
              minWidth: "100px",
              textAlign: "center",
              alignItems: "center",
              fontSize: "16px",
              ...secondBtnStyle
            }}
          />
        }
      </div>}
    </Modal >
  )
}
