import { StringManipulator } from "@fineo-io/main-javascript-library";
import {
  GET_BUDGETS,
  FETCH_BUDGET_REQUEST,
  GET_BUDGET_HEADER,
  SET_BUDGET_CATEGORIE,
  DELETE_BUDGET_CATEGORIE,
  DEFINE_BUDGET_TRANSACTIONS,
  SET_CATEGORY_ROW,
  DEFINE_BUDGET_GROUP_ORDER,
  DEFINE_BUDGET_CATEGORY_ORDER,
  HANDEL_SAVE_CATEGORY_ROW,
  CLEAN_BUDGET,
  SET_LOGOUT,
  HIDE_TRANSACTIONS_WITHOUT_CATS,
  RUN_BUDGET_ONBOARDING,
  SET_BDATE,
  SET_CATEGORY_FILTER,
} from "./types";
import moment from "moment";

// Import the Api Url:
const { REACT_APP_SERVER_URL } = process.env;
const getbudgetAmount = (dataset) => {
  let budgetAmounts = 0;
  let activitie = 0;
  let disponibles = 0;
  dataset.forEach(({ budgetAmount, activities, disponible }) => {
    budgetAmounts += budgetAmount * 100;
    activitie += activities * 100;
    disponibles += disponible * 100;
  });
  return [
    {
      budgetAmount: budgetAmounts / 100,
      activities: activitie / 100,
      disponible: disponibles / 100,
    },
  ];
};

// Action: Get all budget for the current user.
export const getBudget = (budgetDate, space_id = sessionStorage.getItem("SESSION_SPACE").toString(),) => async (dispatch) => {
  try {
    var table = [], data = [];
    dispatch({
      type: FETCH_BUDGET_REQUEST,
      payload: {},
    });
    await fetch(`https://${REACT_APP_SERVER_URL}/budget`, {
      method: "post",
      credentials: "include",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
        access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
        budget_date: budgetDate,
        space_id: space_id,
        budget_dateend: moment(budgetDate).endOf("month").format("YYYY-MM-DD"),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.messages === undefined) return;
        const budget = response.messages.budgetSelect;
        const disponible = response.messages.disponible;
        const mouvement = response.messages.mouvement;
        const groupeExpenses = response.messages.groupeExpenses;
        for (var i = 0; i < budget.length; i++) {
          var existe = -1;
          for (var j = 0; j < disponible.length; j++) {
            if (parseFloat(disponible[j].budget_category_id) === budget[i].key) {
              existe = j;
            }
          }
          table.push({
            key: budget[i].key,
            order: budget[i].order,
            budget_type_name: budget[i].budget_type_name,
            budget_type_id: budget[i].budget_type_id,
            budget_category_name: budget[i].budget_category_name,
            budget_group_name: budget[i].budget_group_name,
            groupId: budget[i].groupId,
            groupOrder: budget[i].budget_group_order,
            has_group_changed: budget[i].has_group_changed,
            budgetAmount: budget[i].budgetAmount / 100,
            disponible: existe !== -1 ? disponible[existe].disponible_tr / 100 : budget[i].budgetAmount / 100,
          });
        }
        for (var k = 0; k < table.length; k++) {
          var cpt = -1;
          for (var l = 0; l < mouvement.length; l++) {
            if (parseInt(mouvement[l].budget_category_id) === table[k].key) {
              cpt = l;
            }
          }
          var consumed = -1 * (cpt !== -1 ? parseFloat(mouvement[cpt].movement) : 0);
          data.push({
            key: table[k].key,
            order: table[k].order,
            budget_type_name: table[k].budget_type_name,
            budget_type_id: table[k].budget_type_id,
            budget_category_name: table[k].budget_category_name,
            budget_group_name: table[k].budget_group_name,
            groupId: table[k].groupId,
            groupOrder: table[k].groupOrder,
            has_group_changed: table[k].has_group_changed,
            budgetAmount: table[k].budgetAmount,
            disponible: table[k].disponible,
            activities: consumed,
            carried: consumed + table[k].disponible - table[k].budgetAmount,
          });
        }
        var temp = data.map((element) => {
          return {
            budget_type_id: element.budget_type_id,
            budget_type_name: element.budget_type_name,
          };
        });
        const arr = groupBy(temp, "budget_type_id");
        const groupedHead = groupBy(data, "budget_type_id");
        var head = Object.keys(groupedHead);
        const newBudgetData = [];
        head.map((item) => {
          newBudgetData[item] = groupBy(groupedHead[item], "budget_group_name");
          return newBudgetData;
        });
        let newArray = [];
        for (let i = 0; i < head.length; i++) {
          for (let j = 0; j < temp.length; j++) {
            if (parseInt(head[i]) === parseInt(temp[j].budget_type_id)) {
              for (let h = 1; h <= head.length; h++) {
                if (temp[j].budget_type_id === h) newArray[h - 1] = head[i];
              }
            }
          }
        }
        // ---------------------------chart of budget-----------------------
        var CategoryName = [],
          CategoryAmount = [],
          categoryActivite = [],
          CategoryDisponible = [],
          TotalAmountType = [];
        head = newArray;

        Object.keys(groupedHead).map((type) =>
          TotalAmountType.push({
            total: getbudgetAmount(groupedHead[type])[0].budgetAmount,
            activities: getbudgetAmount(groupedHead[type])[0].activities,
            disponible: getbudgetAmount(groupedHead[type])[0].disponible,
            type: type,
          })
        );

        Object.keys(newBudgetData).map((type) =>
          Object.keys(newBudgetData[type]).map(
            (category) => (
              CategoryName.push(category),
              CategoryAmount.push(
                getbudgetAmount(newBudgetData[type][category])[0].budgetAmount
              ),
              categoryActivite.push(
                getbudgetAmount(newBudgetData[type][category])[0].activities
              ),
              CategoryDisponible.push(
                getbudgetAmount(newBudgetData[type][category])[0].disponible
              )
            )
          )
        );
        const reducer = (accumulator, currentValue) => accumulator + currentValue;
        if (categoryActivite.reduce(reducer) === 0) {
          categoryActivite = [];
        }
        if (CategoryDisponible.reduce(reducer) === 0) {
          CategoryDisponible = [];
        }
        if (CategoryAmount.reduce(reducer) === 0) {
          CategoryAmount = [];
        }

        dispatch({
          type: GET_BUDGETS,
          payload: {
            budgetData: newBudgetData,
            heading: head,
            BudgetTypeName: arr,
            labels: CategoryName,
            data: CategoryAmount,
            categoryActivite: categoryActivite,
            CategoryDisponible: CategoryDisponible,
            TotalAmountType: TotalAmountType,
            groupeExpenses: groupeExpenses
          },
          transactionsWithoutCats: response.messages.transactionsSelect,
        });
      });
  } catch (err) { }
};

// Action: Get budget calculation widget for the current user.
export const getGlobalValues = (budgetDate) => async (dispatch) => {
  try {
    dispatch({
      type: SET_BDATE,
      payload: budgetDate
    })
    await fetch(`https://${REACT_APP_SERVER_URL}/globalvalues`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      credentials: "include",
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
        access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
        b_date: budgetDate,
        space_id: sessionStorage.getItem("SESSION_SPACE"),
        budget_dateend: moment(budgetDate).endOf("month").format("YYYY-MM-DD"),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.messages === undefined) return;
        dispatch({
          type: GET_BUDGET_HEADER,
          payload: {
            // The budget amount
            budgetedCurrentMonth: response.messages.payload.budgetedSelectedMonth,
            // The total amount the user can budget this month
            totalToBudgetCurrentMonth: response.messages.payload.totalToBudgetCurrentMonth,
            // The available amount the user can still budget
            availableCurrentMonth: response.messages.payload.availableToBudgetCurrentMonth,
            // The entries of the current month
            entriesCurrentMonth: response.messages.payload.totalEntriesSelectedMonth,
            // The available amount from the last month that the user can still budget
            availableFromMonthBefore: response.messages.payload.availableFromPreviousMonths,
          },
        });
      });
  } catch (err) {
  }
};

//Action: Set budget group to allow user to create a new budget group,send and save a new budget group to DB
export const setBudgetGroup = (budgetType, budgetDate, budgetData, t, space_id) => (
  dispatch
) => {
  try {
    fetch(`https://${REACT_APP_SERVER_URL}/budget/group/add`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      credentials: "include",
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
        access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
        type_id: budgetType,
        group_name: StringManipulator.capitalize(t("global.new.group")),
        category_name: StringManipulator.capitalize(t("global.new.category")),
        category_date: budgetDate,
        space_id: space_id,
        category_amount: "0",
      }),
    })
      .then((response) => response.json())
      .then(async (response) => {
        isStillLogedIn(response, dispatch);
        if (response.success === true) {
          dispatch(await getBudget(budgetDate, space_id));
        }
      });
  } catch (err) { }
};

export const setNewBudgetRow = (row) => async (dispatch) => {
  try {
    return dispatch({
      type: SET_CATEGORY_ROW,
      payload: row,
    });
  } catch (err) { }
};

export const handelSaveNewBudgetRow = (row) => async (dispatch) => {
  try {
    return dispatch({
      type: HANDEL_SAVE_CATEGORY_ROW,
      payload: row,
    });
  } catch (err) { }
};

//Action: Set budget category to allow user to create a new budget category,send and save a new budget category to DB
export const setCategorie = (newCategorie) => async (dispatch) => {
  try {
    await fetch(`https://${REACT_APP_SERVER_URL}/budget/category/add`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      credentials: "include",
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
        access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
        space_id: sessionStorage.getItem("SESSION_SPACE").toString(),
        group_id: newCategorie.groupId,
        category_name: newCategorie.budget_category_name,
        budget_date: newCategorie.monthly_budget_date,
        is_it_active: newCategorie.is_it_active,
        budget_amount: newCategorie.budgetAmount * 100,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        isStillLogedIn(response, dispatch);
        if (response.success === true) {
          dispatch({
            type: SET_BUDGET_CATEGORIE,
            payload: response.messages.budgetcat,
          });
          return { newId: response.messages.budgetcat };
        }
      });
  } catch (err) { }
};

//Action: Update budget category
export const updateCategorie = (updateCategorie, targetKey, date, space_id) => (
  dispatch
) => {
  try {
    updateCategorie.budgetAmount = String(updateCategorie.budgetAmount).replace(
      ",",
      "."
    );
    return fetch(`https://${REACT_APP_SERVER_URL}/budget/category/update`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      credentials: "include",
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
        access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
        category_id: targetKey,
        space_id,
        budget_date: date,
        category_name: updateCategorie.budget_category_name,
        budget_amount:
          parseFloat(updateCategorie.budgetAmount * 100).toFixed(2),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        isStillLogedIn(response, dispatch);
      });
  } catch (err) { }
};

//Action: Update name of budget group
export const updateGroupName = (groupId, newGroupName) => async (dispatch) => {
  try {
    fetch(`https://${REACT_APP_SERVER_URL}/budget/group/update`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      credentials: "include",
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
        access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
        space_id: sessionStorage.getItem("SESSION_SPACE").toString(),
        group_id: groupId,
        group_name: newGroupName,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        isStillLogedIn(response, dispatch);
        if (response.success === true) {
        }
      });
  } catch (err) { }
};

//Action: Delete budget category
export const deleteCategorie = (toDeleteCategorieKey, cb) => async (dispatch) => {
  try {
    return fetch(`https://${REACT_APP_SERVER_URL}/budget/category/delete`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      credentials: "include",
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
        access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
        space_id: sessionStorage.getItem("SESSION_SPACE").toString(),
        category_id: toDeleteCategorieKey,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        isStillLogedIn(response, dispatch);
        if (response.success === true)
          dispatch({
            type: DELETE_BUDGET_CATEGORIE,
            payload: { toDeleteCategorieKey },
          });
        cb()
      });
  } catch (err) { }
};

export const deleteGroup = (groupId) => async (dispatch) => {
  try {
    return fetch(`https://${REACT_APP_SERVER_URL}/budget/group/delete`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      credentials: "include",
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
        access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
        space_id: sessionStorage.getItem("SESSION_SPACE").toString(),
        groupId: groupId,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        isStillLogedIn(response, dispatch);
      });
  } catch (err) { }
};

//Action: Update order of budget category
export const updateCategorieOrder = (
  { id_bud1, order1 },
  { id_bud2, order2 },
  { startIndex, endIndex },
  { budget_type_id, budget_group_name },
  date,
  space_id
) => async (dispatch) => {
  try {
    dispatch({
      type: DEFINE_BUDGET_CATEGORY_ORDER,
      payload: {
        id_bud1,
        order1: startIndex,
        id_bud2,
        order2: endIndex,
        budget_group_name,
        budget_type_id,
      },
    });
    fetch(`https://${REACT_APP_SERVER_URL}/budget/category/updateorder`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      credentials: "include",
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
        access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
        space_id: sessionStorage.getItem("SESSION_SPACE").toString(),
        category1_id: id_bud1,
        category2_id: id_bud2,
        category_order1: order1,
        category_order2: order2,
      }),
    })
      .then((response) => response.json())
      .then(async (response) => {
        isStillLogedIn(response, dispatch);
        dispatch(await getBudget(date, space_id));
      });
  } catch (err) { }
};

//Action: Hide budget category
export const hideBudgetCategorie = (category_id, date, space_id) => async (dispatch) => {
  try {
    return fetch(`https://${REACT_APP_SERVER_URL}/budget/category/hide`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      credentials: "include",
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
        access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
        category_id: category_id,
        space_id: space_id
      }),
    })
      .then((response) => response.json())
      .then(async (response) => {
        isStillLogedIn(response, dispatch);
        if (response.success === true) return;
        dispatch(await getBudget(date, space_id));
      });
  } catch (err) { }
};

export const hideBudgetGroup = (groupId, date, space_id) => async (dispatch) => {
  try {
    fetch(`https://${REACT_APP_SERVER_URL}/budget/group/hide`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      credentials: "include",
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
        access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
        space_id: sessionStorage.getItem("SESSION_SPACE").toString(),
        groupId: groupId,
      }),
    })
      .then((response) => response.json())
      .then(async (response) => {
        isStillLogedIn(response, dispatch);
        if (response.success === true) {
          dispatch(await getBudget(date, space_id));
        }
      });
  } catch (err) { }
};

export const showBudgetCategorie = (bDate, space_id) => async (dispatch) => {
  try {
    fetch(`https://${REACT_APP_SERVER_URL}/budget/category/show`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
        access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
        space_id
      }),
      credentials: "include",
    })
      .then((response) => response.json())
      .then(async (response) => {
        isStillLogedIn(response, dispatch);
        if (response.success === true) {
          dispatch(await getBudget(bDate, space_id));
        }
      });
  } catch (err) { }
};

export const showBudgetGroup = (bDate, space_id) => async (dispatch) => {
  try {
    fetch(`https://${REACT_APP_SERVER_URL}/budget/group/show`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      credentials: "include",
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
        access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
        space_id
      }),
    })
      .then((response) => response.json())
      .then(async (response) => {
        isStillLogedIn(response, dispatch);
        if (response.success === true) {
          dispatch(await getBudget(bDate, space_id));
        }
      });
  } catch (err) { }
};

//Action: Reset all budgets category to 0
export const resetBudgetCategorie = (bDate, space_id) => async (dispatch) => {
  try {
    await fetch(`https://${REACT_APP_SERVER_URL}/budget/category/reset`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      credentials: "include",
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
        access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
        budget_date: bDate,
        space_id
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        isStillLogedIn(response, dispatch);
        if (response.success === true) {
          getBudget(bDate, space_id);
        }
      });
  } catch (err) { }
};

//Action: Define all the budgets category by the budget_category_amounts of the previous month
export const defineBudgetCategorie = (bDate, space_id) => async (dispatch) => {
  try {
    await fetch(`https://${REACT_APP_SERVER_URL}/budget/category/define`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      credentials: "include",
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
        access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
        budget_date: bDate,
        space_id
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        isStillLogedIn(response, dispatch);
      });
  } catch (err) { }
};

//Action: Get all transaction for the budget_category_id
export const defineBudgetTransactions = (
  budgetCategoryId,
  dateDebut,
  dateFin,
  space_id
) => async (dispatch) => {
  try {
    await fetch(
      `https://${REACT_APP_SERVER_URL}/budget/category/getMouvementTransaction`,
      {
        method: "post",
        headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
        credentials: "include",
        body: JSON.stringify({
          refresh_token: localStorage.getItem("REFRESH_TOKEN"),
          access_token: sessionStorage.getItem("ACCESS_TOKEN"),
          budgetCategoryId: budgetCategoryId,
          dateDebut: dateDebut,
          dateFin: dateFin,
          space_id
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        isStillLogedIn(response, dispatch);
        if (response.success) {
          return dispatch({
            type: DEFINE_BUDGET_TRANSACTIONS,
            payload: {
              budgetMouvement:response.messages.budget,
              budgetCategoryId
            },
          });
        }
      });
  } catch (err) { }
};

export const defineBudgetGroupOrder = (
  category,
  id,
  startIndex,
  endIndex,
  date,
  space_id
) => async (dispatch) => {
  try {
    dispatch({
      type: DEFINE_BUDGET_GROUP_ORDER,
      payload: {
        category,
        id,
        startIndex,
        endIndex,
      },
    });

    await fetch(`https://${REACT_APP_SERVER_URL}/budget/group/updateorder`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      credentials: "include",
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
        access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
        budget_type_id: category,
        space_id: space_id,
        order1: endIndex,
        order2: startIndex,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        isStillLogedIn(response, dispatch);
        if (response.success) {
          dispatch(getBudget(date, space_id));
          return;
        }
      });
  } catch (err) { }
};

export const UpdateCategoryGroup = (key, groupId, date, space_id) => async (dispatch) => {
  try {
    await fetch(
      `https://${REACT_APP_SERVER_URL}/budget/categorie/groupUpdate`,
      {
        method: "post",
        headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
        credentials: "include",
        body: JSON.stringify({
          refresh_token: localStorage.getItem("REFRESH_TOKEN"),
          access_token: sessionStorage.getItem("ACCESS_TOKEN"),
          space_id: sessionStorage.getItem("SESSION_SPACE").toString(),
          budget_category_id: key,
          budget_group_id: groupId,
          space_id: space_id
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => {
        isStillLogedIn(response, dispatch);
        if (response.success) {
          dispatch(getBudget(date, space_id));
          return;
        }
      });
  } catch (err) { }
};

export const hideTransactionsWithoutCategories = () => async (dispatch) => {
  try {
    dispatch({
      type: HIDE_TRANSACTIONS_WITHOUT_CATS,
    });
  } catch (err) { }
};

export const runOnBoardingBudget = (value) => async (dispatch) => {
  dispatch({
    type: RUN_BUDGET_ONBOARDING,
    payload: value
  });
};

// Helpers:
function groupBy(xs, prop) {
  var grouped = [];
  if (xs.length > 0) {
    for (var i = 0; i < xs.length; i++) {
      var p = xs[i][prop];
      if (!grouped[p]) {
        grouped[p] = [];
      }
      grouped[p].push(xs[i]);
    }
    return grouped;
  } else {
    return [];
  }
}

const isStillLogedIn = async (response, dispatch) => {
  if (response.success === false && response.isSessionHasExpired) {
    await dispatch({ type: SET_LOGOUT, payload: {} });
  }
};

export const cleanBudget = () => (dispatch) => {
  return dispatch({
    type: CLEAN_BUDGET,
    payload: {},
  });
};

export const setGlobalStateCategoryFitler = (categoryId, date) => (dispatch) => {
  return dispatch({
    type: SET_CATEGORY_FILTER,
    payload: {categoryId, date},
  });
};