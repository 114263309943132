import React from "react";
import { Input, message } from "antd";
import { Helmet } from 'react-helmet-async';
import { isBrowser } from 'react-device-detect';
import { useHistory } from "react-router-dom";
import { Row, Col, FormGroup, InputGroup } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactGAEvent from "../components/Tools/ReactGAEvent";
import sideImage from "../assets/img/Views/User Forgot Password.png"
import Logo from "../assets/img/brand/fineo-login-logo.png";
import StandardButton from "components/Buttons/standardButton/StandardButton";
import { COLORS } from "assets/constants/theme";
import { StringManipulator } from "@fineo-io/main-javascript-library";

const { REACT_APP_SERVER_URL } = process.env;

function Forgetpass(props) {
  const { t } = useTranslation();
  const history = useHistory();

  //update password and confirm
  const Confirm = () => {
    fetch(`https://${REACT_APP_SERVER_URL}/forgetPassword`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      body: JSON.stringify({
        email: document.getElementById("email").value,
        language: t("Transaction_lang")
      }),
      credentials: "include",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          sessionStorage.setItem(
            "email",
            document.getElementById("email").value
          );
          ReactGAEvent('Forget Password', 'forget password');
          history.push(`/reg/passwordrecovery`);
        } else if (!response.success) {
          message.error(t("Forget_Password_n'a_aucune_email"));
        }
      });
  };

  return (
    <Row className="d-flex align-items-center" style={{
      height: "100vh"
    }}>
      <Helmet>
        <title>Fineo | {StringManipulator.capitalize(t("forgot_password_title"))}</title>
      </Helmet>
      {isBrowser && (<Col xl="6" lg="6" md="6" xs="12" sm="12" className="d-flex align-items-center" style={{
        height: "100%",
        textAlign: "center",
        justifyContent: "center"
      }}>
        <div style={{
          backgroundColor: COLORS.glyphLight,
          height: "100%",
          borderRadius: "8px",
          width: "100%",
          textAlign: "center",
          alignContent: "center"
        }}>
          <div style={{
            height: "auto",
            marginTop: "10%",
            marginBottom: "10%",
          }}>
            <img
              alt="..."
              style={{
                minHeight: "150px",
                minWidth: "150px",
                maxWidth: "450px",
                maxHeight: "400px"
              }}
              src={sideImage}
            />
            <div style={{
              fontWeight: "bolder"
            }}>
              <h1 style={{
                fontWeight: "bolder"
              }}>
                {t("Join Us At Fineo !")}
              </h1>
              <h1 style={{
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
                fontWeight: "bolder"
              }}>
                {t("Manage all your money from one single app and take back control of your finances")}
              </h1>
            </div>
          </div>
        </div>
      </Col>)}
      <Col xl="6" lg="6" md="6" xs="12" sm="12" className="mt-2">
        <div style={{
          width: isBrowser ? "70%" : "80%",
          marginLeft: "auto",
          marginRight: "auto"
        }}>
          <form>
            <img src={Logo} style={{
              height: "65px",
              width: "190px",
              marginBottom: "15px",
              marginLeft: "-20px"
            }} />
            <h1>{t("Forget_Password_Mot_de_passe_oublié!")}</h1>
            <div>
              <p className="h3 bold text-gray-dark">
                {t("Forget_Password_Saisissez_votre_adresse_e-mail_et_nous_vous_enverrons_un_lien_pour_réinitialiser_votre_mot_de_passe.")}</p>
              <FormGroup>
                <InputGroup className="input-group-merge input-group-alternative">
                  <Input
                    type="email"
                    id="email"
                    placeholder="email"
                    required
                  />
                </InputGroup>
              </FormGroup>
              <StandardButton
                className="my-4"
                style={{
                  width: "100%"
                }}
                type="button"
                onClick={Confirm}
                text={t("Forget_Password_Envoyer")}
              />
              <Link className="text-default" to="/auth/login">
                <span>{t("Forget_Password_Retour_à_login")}</span>
              </Link>
            </div>
          </form>
        </div>
      </Col>
    </Row>
  );
}
export default Forgetpass;
