import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { logIn, resendValidationEmail, loginFromRedirection } from "../actions/Auth";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

function RedirectUser(props) {

  const { loginFromRedirection } = props;
  const { t } = useTranslation();
  const [message, setmessage] = useState(t("Loading"))
  const history = useHistory();

  const checkTheRedirectionToken = async (token) => {
    loginFromRedirection(token, (result) => {
      if (result) {
        history.push('/app/payment')
      } else {
        setmessage(t('Error'))
        setTimeout(() => {
          history.push('/')
        }, 3000);
      }
    });
  }


  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const token = query.get('token');
    checkTheRedirectionToken(token);
  }, [])


  return (
    <div style={{
      height: "100vh",
      padding: "30px",
      fontWeight: "bold"
    }}>{message}</div>
  );
}

RedirectUser.prototype = {
  logIn: PropTypes.func.isRequired,
  resendValidationEmail: PropTypes.func.isRequired,
  loginFromRedirection: PropTypes.func.isRequired,
  logInState: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  logInState: state.auth,
});

export default connect(mapStateToProps, {
  logIn,
  resendValidationEmail,
  loginFromRedirection,
})(RedirectUser);
