import React from "react";
import { Row, Col } from "reactstrap";
import TransactionWidgetSosComponent from "./TransactionWidgetSosComponent"
export default function transactionWidget(props) {
  return (
    <div className="row mb-2 mt-4 pt-1 mx-4 text-center px-4 mt--2 mb--2">
      {props.transactionWidgetData.map(
        (item) =>
          item.is_it_closed === false && (
            <div
              key={item.key + 1022}
              // md="3" lg="3" xl="3" xs="3" sm="3"
              className="col-sm w-100"
              style={{
                cursor: "pointer",
              }}
            >
              <TransactionWidgetSosComponent
                selected={props.selectedAccountsToBeFilterTransactionBy.length === 0 ? false : true}
                filterTransactionByAccount={props.filterTransactionByAccount}
                item={item}
                authState={props.authState} />
            </div>
          )
      )}
    </div>
  );
}
