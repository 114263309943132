import ReactDatetime from "react-datetime";
import { FormGroup } from "reactstrap";
import { useTranslation } from "react-i18next";
import { STYLES } from "assets/constants/theme";
import moment from "moment";

export default function MonthPicker({
  date: date,
  setDate: setDate
}) {
  const { t } = useTranslation();
  return (
    <FormGroup>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>
          <i
            className="fas fa-chevron-left mr-3 hoverdT"
            style={{ cursor: "pointer" }}
            onClick={() => setDate(moment(date).add(-1, "M").format("YYYY-MM-01"))}
          />
        </span>
        <ReactDatetime
          dateFormat={"MMMM YYYY"}
          locale={t("Budget_ReactDatetime")}
          inputProps={{ style: { ...STYLES.selectStyle, textAlign: 'center' }}}
          timeFormat={false}
          value={moment(date)}
          onChange={(date) => setDate(date)}
        />
        <span>
          <i
            className="fas fa-chevron-right ml-3 hoverdT"
            style={{ cursor: "pointer" }}
            onClick={() => setDate(moment(date).add(1, "M").format("YYYY-MM-01"))}
          />
        </span>
      </div>
    </FormGroup>
  );
}
