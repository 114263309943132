import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Chart from "react-apexcharts";
import { Doughnut } from "react-chartjs-2";
import { Card, CardBody, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import FormatCurrency from "../Tools/Currency/Currency";
import { getBudget } from "../../actions/Budget";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { COLORS } from "assets/constants/theme";
import { StringManipulator } from "@fineo-io/main-javascript-library";
import DefaultSelect from "components/inputs/DefaultSelect";

function SecBudgettWidget({ authState, budgetState }) {
  const { t } = useTranslation();
  const [selectWidget, setSelectWidget] = useState("activitiesChart");

  const getbudgetAmount = (dataset) => {
    let budgetAmounts = 0;
    let activitie = 0;
    let disponibles = 0;
    dataset.forEach(({ budgetAmount, activities, disponible }) => {
      budgetAmounts += budgetAmount;
      activitie += activities;
      disponibles += disponible;
    });
    return [
      {
        budgetAmount: budgetAmounts,
        activities: activitie,
        disponible: disponibles,
      },
    ];
  };

  // to handle change data displayed in the chart depending of the selection
  function handleChange(value) {
    setSelectWidget(value.value);
  }

  // function to display the donut charts depending on the selection
  function display_chart(chartType, state) {
    let item = [];
    if (state.budgets.budgetData !== undefined) {
      let expenData = [];
      let expenlabel = [];
      if (chartType === "budgetedChart") {
        item = state.budgets.data;
      } else if (chartType === "activitiesChart") {
        state.budgets.groupeExpenses.forEach((item) => {expenData.push(Math.abs(item.expenses))});
        state.budgets.groupeExpenses.forEach((item) => {expenlabel.push(item.groupeName)});
        item = state.budgets.groupeExpenses;
      } else {
        item = state.budgets.CategoryDisponible;
      }

      if (item.length === 0 && expenData.length === 0) {
        return (
          <Doughnut
            width={240}
            height={240}
            options={{
              tooltips: { enabled: false },
              colors: ["#26C1FD", "#FF4D4D", "#14C48B", "#FFBF53", "#ff7f50", "#a4b0be", "#354655", "#f9844a", "#f9c74f", "#277da1"]
            }}
            data={{
              datasets: [
                {
                  data: [1],
                },
              ],
            }}
          />
        );
      } else if (chartType === "activitiesChart") {
        return (
          <Chart
            width={240}
            height={240}
            options={{
              plotOptions: {
                pie: {
                  donut: {
                    size: "75%",
                    labels: {
                      show: true,
                      total: {
                        show: true,
                        showAlways: true,
                        label: t("Total expenses"),
                        fontFamily: "'Poppins', sans-serif",
                        fontSize: "12px",
                        formatter: (data) => {
                          let total = 0;
                          if (data.config.series !== undefined) {
                            data.config.series.forEach((val) => {
                              total = total + val;
                            })
                          }
                          return new Intl.NumberFormat(authState.user.currency_locales, {
                            style: "currency",
                            currency: authState.user.currency_code !== undefined ? authState.user.currency_code : "EUR",
                          }).format(total)
                        }
                      }
                    }
                  }
                }
              },
              colors: ["#26C1FD", "#FF4D4D", "#FFBF53", "#ff7f50", "#a4b0be", "#354655", "#14C48B", "#f9844a", "#f9c74f", "#277da1"],
              dataLabels: {
                enabled: false,
              },
              legend: {
                show: false,
              },
              labels: {
                show: true,
                formatter: function (val) {
                  return `-${val}`;
                },
              },
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 100,
                    },
                    tooltip: {
                      enabled: true,
                      fillSeriesColor: false,
                      theme: "dark",
                      style: {
                        fontSize: "12px",
                      },
                    },
                    legend: {
                      show: false,
                    },
                  },
                },
              ],
              labels: [...expenlabel],
            }}
            series={[...expenData]}
            type="donut"
          />
        );
      } else {
        return (
          <Chart
            width={240}
            height={240}
            options={{
              plotOptions: {
                pie: {
                  donut: {
                    size: "75%",
                    labels: {
                      show: true,
                      total: {
                        show: true,
                        showAlways: true,
                        label: chartType === "budgetedChart" ? t("Total budgeted") : t("Total available"),
                        fontFamily: "'Poppins', sans-serif",
                        fontSize: "12px",
                        formatter: (data) => {
                          let total = 0;
                          if (data.config.series !== undefined) {
                            data.config.series.forEach((val) => {
                              total = total + val;
                            })
                          }
                          return new Intl.NumberFormat(authState.user.currency_locales, {
                            style: "currency",
                            currency: authState.user.currency_code !== undefined ? authState.user.currency_code : "EUR",
                          }).format(total)
                        }
                      }
                    }
                  }
                }
              },
              colors: ["#26C1FD", "#FF4D4D", "#14C48B", "#FFBF53", "#ff7f50", "#a4b0be", "#354655", "#f9844a", "#f9c74f", "#277da1"],
              dataLabels: {
                enabled: false,
              },
              legend: {
                show: false,
              },
              labels: {
                show: true,
                formatter: function (val) {
                  return val;
                },
              },
              responsive: [
                {
                  breakpoint: 80,
                  options: {
                    chart: {
                      width: 100,
                    },
                    tooltip: {
                      enabled: true,
                      fillSeriesColor: false,
                      theme: "dark",
                      style: {
                        fontSize: "12px",
                      },
                    },
                    legend: {
                      show: false,
                    },
                  },
                },
              ],
              labels: state.budgets.labels,
            }}
            series={item}
            type="donut"
          />
        );
      }
    } else {
      return <div></div>;
    }
  }

  //function to display different categories and the amounts in the last part of the widget
  function display_categories(chartType) {
    if (budgetState.budgets.budgetData !== undefined) {
      let dataType = "";
      let totalAmount = "";
      if (chartType === "budgetedChart") {
        dataType = "budgetAmount";
        totalAmount = "total";
      } else if (chartType === "activitiesChart") {
        budgetState.budgets.TotalAmountType.forEach((item1) => {
          item1.activities = 0
          budgetState.budgets.groupeExpenses.forEach((item2) => {
            if (item1.type == item2.typeId) {
              item1.activities = item1.activities + item2.expenses
            }
          })
        })
        dataType = "activities";
        totalAmount = "activities";
      } else {
        dataType = "disponible";
        totalAmount = "disponible";
      }
      return budgetState.budgets.TotalAmountType.map((data) => (
        <div key={Math.random()}>
          <ListGroup className="list" flush>
            <ListGroupItem>
              <Row className="align-item-centermt-4">
                <Col className="justify-content-left">
                  {data.type === "1" ? (
                    <h4 style={{ color: COLORS.primary }}>{StringManipulator.capitalize(t("global.monthly._"))}</h4>
                  ) : data.type === "2" ? (
                    <h4 style={{color: COLORS.primary}}>{StringManipulator.capitalize(t("global.provision.s"))}</h4>
                  ) : (
                    data.type === "3" &&
                    <h4 style={{color: COLORS.primary}}>{StringManipulator.capitalize(t("global.saving.s"))}</h4>
                  )}
                </Col>

                <Col className="col-auto">
                  <h4 style={{ color: COLORS.primary }}>
                    {FormatCurrency(
                      totalAmount == "activities" ? data[totalAmount] !== 0 ? -1 * data[totalAmount] : data[totalAmount] : data[totalAmount],
                      authState.user.currency_code,
                      authState.user.currency_locales
                    )}
                  </h4>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
          {Object.keys(budgetState.budgets.budgetData[data.type]).map(
            (category) => (
              <div key={Math.random()}>
                <ListGroup className="list" flush>
                  <ListGroupItem>
                    <Row className="align-items-center">
                      <Col className="justify-content-left">
                        {'> ' + category}
                      </Col>
                      <Col className="col-auto text-muted">
                        <h5>
                          {dataType !== "activities" ? FormatCurrency(
                            getbudgetAmount(budgetState.budgets.budgetData[data.type][category])[0][dataType],
                            authState.user.currency_code,
                            authState.user.currency_locales
                          ) : budgetState.budgets.groupeExpenses.filter((gp) => gp.groupeName === category).length !== 0 ? budgetState.budgets.groupeExpenses.map(item => {
                            if (item.groupeName === category) {
                              return FormatCurrency(
                                -1 * item.expenses,
                                authState.user.currency_code,
                                authState.user.currency_locales
                              )
                            }
                          }) : FormatCurrency(
                            0,
                            authState.user.currency_code,
                            authState.user.currency_locales
                          )}
                        </h5>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </div>
            )
          )}
        </div>
      ));
    } else {
      return <div></div>;
    }
  }

  return (
    <Card style={{ borderRadius: "10px" }}>
      <CardBody className="py--3" style={{ borderRadius: "10px" }}>
        <Row className="d-flex justify-content-center" style={{
        }}>
          <Col xl="10" lg="10" md="10" sm="12" xs="12" className="text-left rounded mx-1">
            <DefaultSelect
              className="mb-4"
              defaultValue={StringManipulator.capitalize(t("global.expense.s"))}
              placeholder={StringManipulator.capitalize(t("global.expense.s"))}
              onChange={handleChange}
              options={[
                { value: "budgetedChart", label: StringManipulator.capitalize(t("global.budgeted._")) },
                { value: "availbleChart", label: StringManipulator.capitalize(t("global.available._")) },
                { value: "activitiesChart", label: StringManipulator.capitalize(t("global.expense.s")) },
              ]}
            />
          </Col>
        </Row>
        <div>
          <Row className="justify-content-center">
            {display_chart(selectWidget, budgetState)}
          </Row>
          {display_categories(selectWidget)}
        </div>
      </CardBody>
    </Card>
  );
}

SecBudgettWidget.prototype = {
  authState: PropTypes.object.isRequired,
  budgetState: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authState: state.auth,
  budgetState: state.budget,
});

export default connect(mapStateToProps, {
  getBudget,
})(SecBudgettWidget);
