import {
  GET_DASHBOARD_BUDGET_SITUATION,
  GET_DASHBOARD_ENTRIES,
  GET_DASHBOARD_DEPENSE
} from "../actions/types";

const initialState = {
  globalValues: {
    // The total amount the user can budget this month
    totalToBudgetCurrentMonth: 0,
    // The available amount the user can still budget
    availableCurrentMonth: 0,
    //
    availableAmountToSpent: 0,
    budgetedCurrentMonth: 0,
    entriesCurrentMonth: 0,
    totalExpensesUpToSeloctedMonth: 0,
    expensesCurrentMonth: 0,
    availableFromMonthBefore: 0,
    EpargneCurrentMonth: 0,
    savingUpToSelectedMonth: 0
  },
  dashboardSemestreDepense: {
    data: {
      labels: [],
      datasets: [
        {
          label: "",
          backgroundColor: "white",
          data: [],
          maxBarThickness: 10,
        },
      ],
    },
    options: {

    }

  },
  dashboardSemestreEntries: {
    data: {
      labels: [],
      datasets: [
        {
          label: "",
          backgroundColor: "white",
          data: [],
          maxBarThickness: 10,
        },
      ],
    },
    options: {

    }

  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {

    case GET_DASHBOARD_BUDGET_SITUATION:
      return {
        ...state,
        globalValues: payload,
      };
    case GET_DASHBOARD_DEPENSE:
      return {
        ...state,
        dashboardSemestreDepense: payload,
      };
    case GET_DASHBOARD_ENTRIES:
      return {
        ...state,
        dashboardSemestreEntries: payload,
      };
    default:
      return state;
  }
}
