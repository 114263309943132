import { combineReducers } from "redux";
import account from "./Accounts";
import transaction from "./Transaction";
import budget from "./Budget";
import auth from "./Auth";
import Reports from "./Reports";
import profile from "./profile";
import dashboard from "./Dashboard";
import GlobalState from "./GlobalState"
import Stripe from "./Stripe";
export default combineReducers({
  account,
  transaction,
  budget,
  auth,
  Reports,
  profile,
  dashboard,
  GlobalState,
  Stripe,
});
