import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from "react-redux";
import store from "./store";
import AppLayout from "./layouts/App.js";
import AuthLayout from "./layouts/Auth.js";
import RegLayout from "./layouts/Reg.js";
import ReactGA from "react-ga4";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-pro-react.scss?v1.1.0";
import "./i18n.conf";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, { gaOptions: { allowLinker: true } });
ReactGA.ga('require', 'linker');
ReactGA.ga('linker:autoLink', ['app.fineo.io']);
ReactGA.send({
  hitType: "pageview",
  page: window?.location?.pathname + window?.location?.search,
  title: window?.location?.pathname?.toString()?.split('/')?.pop()
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <HelmetProvider>
        <Switch>
          <Route path="/app" render={(props) => <AppLayout {...props} />} />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Route path="/reg" render={(props) => <RegLayout {...props} />} />
          <Route path="/" render={(props) => <AppLayout {...props} />} />
          <Redirect from="*" to="/" />
        </Switch>
      </HelmetProvider>
    </BrowserRouter>
  </Provider>
  ,
  document.getElementById("root")
);
