import { GET_CURRENCY, CLEAN_PROFILE } from "../actions/types";
const initialState = {
  currency: [],
  loading: true,
  updatecurrency: {},
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    //Get currency for the current user
    case GET_CURRENCY:
      payload?.map(
        (currency) =>
          (currency.currency_label = `${currency.currency_label}`)
      );
      return {
        ...state,
        currency: payload || [],
        loading: false,
      };
    case CLEAN_PROFILE:
      return {
        currency: {},
        loading: true,
        updatecurrency: {},
      };
    default:
      return state;
  }
}
