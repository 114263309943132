import {
  CLEAN_PROFILE,
  GET_CURRENCY,
  UPDATE_CURRENCY,
  SET_LOGOUT,
} from "./types";
import ReactGAEvent from "../components/Tools/ReactGAEvent";

const { REACT_APP_SERVER_URL } = process.env;

//Action: Get currency for the current user
export const getCurrency = () => async (dispatch) => {
  try {
    await fetch(`https://${REACT_APP_SERVER_URL}/SelectCurrency`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      credentials: "include",
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
        access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        isStillLogedIn(response, dispatch);
        if (response.success === true) {
          return dispatch({
            type: GET_CURRENCY,
            payload: response.messages.sccuessCurrency,
          });
        }
      });
  } catch (err) { }
};

//Action:Update currency for the current user
export const updateCurrency = (value, message, onHide) => async (dispatch) => {
  try {
    await fetch(`https://${REACT_APP_SERVER_URL}/UpdateCurrency`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      credentials: "include",
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
        access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
        currency_id: value,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        isStillLogedIn(response, dispatch);
        if (response.success === true) {
          ReactGAEvent('Update', 'Update a Currency');
          dispatch({
            type: UPDATE_CURRENCY,
            payload: response.messages.UpdateCurrency,
          });
          onHide();
        }
      });
  } catch (err) { }
};

export const insertTheUserSelectedCategories = (data) => async (dispatch) => {
  try {
    await fetch(`https://${REACT_APP_SERVER_URL}/insertTheUserSelectedCategories`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      credentials: "include",
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
        access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
        space_id: sessionStorage.getItem("SESSION_SPACE"),
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        isStillLogedIn(response, dispatch);
        return;
      });
  } catch (err) { }
};

export const cleanProfile = () => (dispatch) => {
  return dispatch({
    type: CLEAN_PROFILE,
    payload: {},
  });
};

const isStillLogedIn = async (response, dispatch) => {
  if (response.success === false && response.isSessionHasExpired) {
    await dispatch({ type: SET_LOGOUT, payload: {} });
  }
};
