import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import './standardButton.css';
import { useEffect, useState } from "react";
import { COLORS } from "assets/constants/theme";

export default function StandardButton({
  className = "",
  color = COLORS.primary,
  secondColor = COLORS.glyphPrimary,
  outline = true,
  disabled = false,
  onClick = () => { },
  style = {},
  size = "",
  text = "button",
  children = <></>,
  textStyle = {},
  ...props
}) {
  const { t } = useTranslation();
  const [coords, setCoords] = useState({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = () => {setIsHovered(!isHovered);};

  useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);
      setTimeout(() => setIsRippling(false), 300);
    } else setIsRippling(false);
  }, [coords]);

  useEffect(() => {
    if (!isRippling) setCoords({ x: -1, y: -1 });
  }, [isRippling]);

  const buttonStyles = [
  {
    cursor: "pointer",
    backgroundColor: isHovered ? color : 'transparent',
    ...style,
    boxShadow: "none",
  },
  outline ? {
    borderColor: `${color}`,
  } :
    {
      borderColor: 'transparent',
      backgroundColor: isHovered ? '#A4B0BE' : 'transparent',
    }
  ,
  disabled && {
    backgroundColor: COLORS.glyphSecondary,
    cursor: "default",
    borderColor: 'transparent'
  }];

  return (
    <Button
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
      className={`btn btn-success-outline ${className}  ${!disabled && "ripple-button"}`}
      disabled={disabled}
      color={color}
      outline={outline}
      size={size}
      onClick={e => {
        const rect = e.target.getBoundingClientRect();
        setCoords({ x: e.clientX - rect.left, y: e.clientY - rect.top });
        onClick && onClick(e);
      }}
      style=
      {Object.assign({}, ...buttonStyles)}
      {...props}
    >
      {isRippling ? (
        <span className="ripple" style={{ left: coords.x, top: coords.y }} />
      ) : (
        ''
      )}
      <span className="content" style={{ color: disabled ? 'white' : isHovered ? 'white' : outline ? color : secondColor, ...textStyle }}>
        {children}
        {t(text)}
      </span>
    </Button>
  )
}
