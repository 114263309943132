import React, { useEffect } from "react";
import { isBrowser } from 'react-device-detect';
import { Helmet } from 'react-helmet-async';
import { Input, message, Form } from "antd";
import { useHistory } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import ReactGAEvent from "../components/Tools/ReactGAEvent";
import sideImage from "../assets/img/Views/Set New Password.png"
import Logo from "../assets/img/brand/fineo-login-logo.png";
import StandardButton from "components/Buttons/standardButton/StandardButton";
import { COLORS } from "assets/constants/theme";
import { StringManipulator } from "@fineo-io/main-javascript-library";

const { REACT_APP_SERVER_URL } = process.env;

function Passrecovery(props) {
  const { t } = useTranslation();
  useEffect(() => { }, []);
  const history = useHistory();

  const Confirm = () => {
    if (
      document.getElementById("newpass").value.length >= 6 &&
      document.getElementById("confirmnewpass").value.length >= 6
    ) {
      if (
        document.getElementById("newpass").value !==
        document.getElementById("confirmnewpass").value
      ) {
        message.error(t("Password_Recovery_les_deux_mot_de_passe_sont_différent"));
      } else if (
        document.getElementById("newpass").value ===
        document.getElementById("confirmnewpass").value
      ) {
        fetch(`https://${REACT_APP_SERVER_URL}/passwordrecovory`, {
          method: "post",
          headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
          body: JSON.stringify({
            token: props.match.params.token,
            password: document.getElementById("newpass").value,
          }),
          credentials: "include",
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.success === true) {
              fetch(`https://${REACT_APP_SERVER_URL}/receivedpass`, {
                method: "post",
                headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
                body: JSON.stringify({
                  token: props.match.params.token,
                  language: t("Transaction_lang")
                }),
                credentials: "include",
              })
                .then((response) => response.json())
                .then(() => {
                  message.success(
                    t("Password_Recovery_votre_mot_de_passe_a_été_modifié_avec_succès!")
                  );
                  ReactGAEvent('Change', 'Change password');
                  history.push(`/auth/login`);
                });
            }
          });
      }
    } else {
      message.error(t("Password_Recovery_Le_mot_de_passe_doit_contenir_au_moins_6_caractères"));
    }
  };

  return (
    <Row className="d-flex align-items-center" style={{
      height: "100vh"
    }}>
      <Helmet>
        <title>Fineo | {StringManipulator.capitalize(t("password_recovery_title"))}</title>
      </Helmet>
      {isBrowser && (<Col xl="6" lg="6" md="6" xs="12" sm="12" className="d-flex align-items-center" style={{
        height: "100%",
        textAlign: "center",
        justifyContent: "center"
      }}>
        <div style={{
          backgroundColor: COLORS.glyphLight,
          height: "100%",
          borderRadius: "8px",
          width: "100%",
          textAlign: "center",
        }}>
          <div style={{
            height: "auto",
            marginTop: "10%",
            marginBottom: "10%",
          }}>
            <img
              alt="..."
              style={{
                minHeight: "150px",
                minWidth: "150px",
                maxWidth: "450px",
                maxHeight: "400px"
              }}
              src={sideImage}
            />
            <div style={{
              fontWeight: "bolder"
            }}>
              <h1 style={{
                fontWeight: "bolder"
              }}>
                {t("Join Us At Fineo !")}
              </h1>
              <h1 style={{
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
                fontWeight: "bolder"
              }}>
                {t("Manage all your money from one single app and take back control of your finances")}
              </h1>
            </div>
          </div>
        </div>
      </Col>)}

      <Col xl="6" lg="6" md="6" xs="12" sm="12" className="mt-4 mb-4">
        <div style={{
          width: isBrowser ? "70%" : "80%",
          marginLeft: "auto",
          marginRight: "auto"
        }}>
          <img src={Logo} style={{
            height: "65px",
            width: "190px",
            marginBottom: "15px",
            marginTop: "10px",
            marginLeft: "-20px"
          }} />
          <h1>{t("Create a new password !")}</h1>
          <div className="mb-3">
            <p className="h3 bold text-gray-dark">
              {t("Please make sure to create a strong password that also you can remember as well")}
            </p>
          </div>
          <Form>
            <Form.Item
              name="newpassword"
              rules={[
                {
                  required: true,
                  message: t("Password_Recovery_Merci_d'entrer_un_mot_de_passe!"),
                },
              ]}
            >
              <Input.Password
                placeholder={t("Password_Recovery_Nouveau_mot_de_passe")}
                id="newpass"
              />
            </Form.Item>
            <Form.Item
              name="newpasswordconfirm"
              rules={[
                {
                  required: true,
                  message: t("Password_Recovery_Merci_d'entrer_un_mot_de_passe!"),
                },
              ]}
            >
              <Input.Password
                placeholder={t("Password_Recovery_Confirmer_votre_nouveau_mot_de_passe")}
                id="confirmnewpass"
              />
            </Form.Item>
            <Form.Item >
              <StandardButton
                className="my-2"
                type="button"
                style={{
                  width: "100%",
                  fontSize: "20px",
                  height: "50px"
                }}
                onClick={Confirm}
                text={t("Password_Recovery_Enregistrer")}
              />
            </Form.Item>
          </Form>
        </div>
      </Col>
    </Row>
  );
}
export default Passrecovery;
