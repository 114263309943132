import React from "react";
import PropTypes from "prop-types";
import ReactDatetime from "react-datetime";
import { STYLES } from "assets/constants/theme";
import "react-datetime/css/react-datetime.css";

class SelectDate extends React.Component {
  constructor(props) {
    super(props);
    this.value = props.value;
  }

  static propTypes = {
    value: PropTypes.string,
    onUpdate: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: "",
  };

  getValue(value, defaultValue) {
    if (value === "") return defaultValue;
    return value;
  }

  render() {
    const { value, onUpdate, defaultValue, ...rest } = this.props;
    return [
      <ReactDatetime
        {...rest}
        initialValues={defaultValue}
        defaultValue={defaultValue}
        inputProps={{
          autoFocus: true,
          style: {
            ...STYLES.selectStyle,
          },
        }}
        dateFormat="YYYY/MM/DD"
        timeFormat={false}
        onChange={(value) => onUpdate(this.getValue(value, defaultValue))}
      />,
    ];
  }
}

export default SelectDate;
