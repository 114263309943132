import { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import StandardButton from "../Buttons/standardButton/StandardButton";
import PriceCard from "../cards/PriceCard";
import { COLORS } from "assets/constants/theme";
import MoneyHelpers from "../../helpers/money.js";

export default function PaymentC({
  t,
  setActivePlan,
  activePlan,
  authState,
  getListPrice,
  stripeState,
  subscribe,
  listOfPrices,
  setListOfPrices,
}) {
  const [disablePlan, setDisablePlan] = useState(false);

  useEffect(() => {
    getListPrice(authState);
  }, []);

  useEffect(() => {
    if (stripeState.listPrice.length !== 0) {
      setListOfPrices([
        ...stripeState.listPrice
          ?.map(price => ({
            ...price,
            currency: MoneyHelpers.getDefaultCurrency(Object.keys(price.currency_options)),
            currencySymbol: String(MoneyHelpers.getDefaultCurrency(Object.keys(price.currency_options))).toUpperCase(),
            ...price.currency_options[MoneyHelpers.getDefaultCurrency(Object.keys(price.currency_options))]
          }))
      ])
      setActivePlan({ ...listOfPrices[0], index: 0 });
    }
  }, [stripeState.listPrice.length]);

  useEffect(() => {
    if (listOfPrices?.length !== 0) setActivePlan({ ...listOfPrices[activePlan?.index], index: activePlan?.index });
  }, [listOfPrices[0]?.currencySymbol]);

  useEffect(() => {
    if (stripeState?.couponCodeData?.percent_off === 100) {
      setDisabledButton(true);
      setDisablePlan(false);
    }
  }, [stripeState?.couponCodeData]);

  return (
    <div className="card-frame">
      <Row className="text-center justify-content-center px-6">
        {listOfPrices?.map((price, index) =>
          <Col key={index} style={{
            minWidth: "200px",
          }}>
            <PriceCard
              t={t}
              style={
                {
                  cursor: "pointer",
                  zIndex: "10px",
                  ...!disablePlan && activePlan.id === price?.id
                  && {
                    borderTop: `5px solid ${COLORS.primary}`,
                    cursor: "pointer",
                    zIndex: "10px",
                  }
                }
              }
              planId={price?.id}
              title={price.nickname}
              unitAmountAecimal={price?.unit_amount_decimal}
              currency={price?.currency}
              currencyOptions={price?.currency_options}
              currencySymbol={price?.currencySymbol}
              onClick={() => setActivePlan({ ...price, index })}
              onChange={async (e) => {
                setListOfPrices([...listOfPrices?.map(p => {
                  if (price.id === p?.id) {
                    setActivePlan({
                      ...activePlan,
                      currency: e.target.value,
                      currencySymbol: String(e.target.value).toUpperCase(),
                      ...activePlan.currency_options[e.target.value],
                    })

                    return {
                      ...p,
                      currency: e.target.value,
                      currencySymbol: String(e.target.value).toUpperCase(),
                      ...p.currency_options[e.target.value],
                    }
                  }
                  return p
                })])
              }}
            />
          </Col>
        )}
        <Col md="12" xs="12" sm="12" lg="12" xl="12">
          <Row className="my-6">
            <Col style={{
              display: "flex",
              justifyContent: "center"
            }}>
              <StandardButton
                className="w-25 mx-auto mt-1"
                text="Confirm Subscription"
                onClick={subscribe}
                style={{
                  fontWeight: "bold",
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
