import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import BudgetWidget from "../components/Widgets/BudgetWidget.js";
import BudgetTotalWidget from "../components/Widgets/BudgetTotalWidget";
import "antd/dist/antd.css";
import "react-datetime/css/react-datetime.css";
import ReactGAEvent from "../components/Tools/ReactGAEvent";
import Joyride, { STATUS } from 'react-joyride';
import {
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Alert,
} from "reactstrap";
import BudgetType from "../components/Randoms/BudgetType";
import { connect } from "react-redux";
import {
  getBudget,
  getGlobalValues,
  setBudgetGroup,
  showBudgetCategorie,
  resetBudgetCategorie,
  defineBudgetCategorie,
  defineBudgetGroupOrder,
  hideTransactionsWithoutCategories,
  runOnBoardingBudget,
  showBudgetGroup,
} from "../actions/Budget";
import { getTransactionWithoutCats, startOnBoardingTransactionView } from "../actions/Transaction";
import { setFirstLoginState } from "../actions/Auth";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DefaultModal from "components/Modals/DefaultModal.jsx";
import { COLORS } from "assets/constants/theme.js";
import { StringManipulator } from "@fineo-io/main-javascript-library";
import MonthPicker from "components/Randoms/MonthPicker.jsx";
import moment from "moment";
import MainHeader from "components/Headers/MainHeader.jsx";
import StandardButton from "components/Buttons/standardButton/StandardButton.jsx";

const { REACT_APP_SERVER_URL } = process.env;

function Budget({
  getBudget,
  getGlobalValues,
  budgetState,
  setFirstLoginState,
  setBudgetGroup,
  runOnBoardingBudget,
  showBudgetCategorie,
  resetBudgetCategorie,
  defineBudgetCategorie,
  defineBudgetGroupOrder,
  showBudgetGroup,
  getTransactionWithoutCats,
  authState,
  startOnBoardingTransactionView,
  hideTransactionsWithoutCategories,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  // ---------Date object to define the date of this month----------
  const [bDate, setDate] = useState(budgetState.bDate || moment(new Date()).format("YYYY-MM-01"));
  const [show, setShow] = useState(false);
  const [defineCategorie, setDefineCategorie] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showFinishBudgetModal, setShowFinishBudgetModal] = useState(false);
  const steps = [
    { target: '.budget-type-1', content: <h2>{t('This is the budget type of Monthly!')}</h2>, "placement": "right" },
    { target: '.budget-type-2', content: 'This is the budget type of Provisions!', "placement": "right" },
    { target: '.budget-type-3', content: 'This is the budget type of Saving!', "placement": "right" },
    { target: '.budget-group', content: 'This is a budget group!', "placement": "right" },
    { target: '.budget-cetgories', content: 'This is a budget category!', "placement": "right" },
    { target: '.budget-amount-to-budget', content: 'This is the amount to budget!', "placement": "left" },
    { target: '.budget-how-to-budget-1', content: 'This how to budget!', "placement": "bottom" },
    { target: '.budget-how-to-use-last-month-budget', content: 'This the last month budget!', "placement": "bottom" },
    // { target: '.budget-how-to-budget-2', content: 'This what happnes when you budget an amount!', "placement": "bottom" },
    // { target: '.budget-use-of-spaces', content: 'This how to use spaces!', "placement": "bottom" },
  ];
  const [showDoneModal, setShowDoneModal] = useState(false)

  useEffect(() => {
    getGlobalValues(bDate);
    getBudget(bDate, sessionStorage.getItem("SESSION_SPACE"));
  }, [bDate, sessionStorage.getItem("SESSION_SPACE")]);

  const handleAddgroup = async (key) => {
    ReactGAEvent('Add', 'Created new Bugdet group');
    await setBudgetGroup(key, bDate, budgetState.budgets.budgetData, t, sessionStorage.getItem("SESSION_SPACE"));
    // await getBudget(bDate, sessionStorage.getItem("SESSION_SPACE"));
  };

  const reorder = async (category, id, startIndex, endIndex) => {
    await defineBudgetGroupOrder(category, id, startIndex, endIndex, bDate, sessionStorage.getItem("SESSION_SPACE"));
    ReactGAEvent('Move', 'Move an budget group');
    await getBudget(bDate, sessionStorage.getItem("SESSION_SPACE"));
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    reorder(
      JSON.parse(result.draggableId).category,
      JSON.parse(result.draggableId).id,
      result.destination.index,
      result.source.index
    );
  };

  return (
    <>
      <MainHeader title={StringManipulator.capitalize(t("global.budget._"))} />
      <div className="mx-4">
        <Row>
          <Col lg="12" xl="8" md="12" sm="12" xs="12">
            <Row>
              <Col>
                <UncontrolledDropdown direction="right">
                  <DropdownToggle className="shadow-none border-0 mt--2" color="transparent">
                    <StandardButton text="" size="lg">
                      <i className="fas fa-cog mr-2" />
                      {t("Budget_Gérer_le_budget") + ' >'}
                    </StandardButton>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      key="1"
                      onClick={() => {setShow(true)}}
                    >
                      <i className="fas fa-sync mr-1" />
                      {t("Budget_Réinitialiser_les_catégories")}
                    </DropdownItem>
                    <DropdownItem
                      key="2"
                      onClick={() => {setDefineCategorie(true)}}
                    >
                      <i className="fas fa-undo mr-1"></i>
                      {t("Budget_Utiliser_le_budget_du_mois_précédant")}
                    </DropdownItem>
                    <DropdownItem
                      key="3"
                      onClick={() => {
                        showBudgetCategorie(bDate, sessionStorage.getItem("SESSION_SPACE"));
                        ReactGAEvent('Show', 'Show budget categories');
                      }}
                    >
                      <i className="fas fa-eye mr-1"></i>
                      {t("Budget_Afficher_les_catégories_masquées")}
                    </DropdownItem>
                    <DropdownItem
                      key="4"
                      onClick={() => {
                        showBudgetGroup(bDate, sessionStorage.getItem("SESSION_SPACE"));
                        ReactGAEvent('Show', 'Show hidden groups');
                      }}
                    >
                      <i className="fas fa-eye mr-1"></i>
                      {t("Budget_Afficher_les_groupes_masqués")}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
              <Col className="pl-9 pr-5">
                <MonthPicker date={bDate} setDate={setDate} />
              </Col>
            </Row>
            {budgetState.budgets.heading !== undefined && budgetState.budgets.heading.map((type) => (
              <BudgetType
                key={type}
                type={type}
                onDragEnd={onDragEnd}
                handleAddgroup={handleAddgroup}
                data={budgetState.budgets.budgetData}
                budgetState={budgetState}
                bDate={bDate}
                loading={budgetState.loading}
                getBudget={getBudget}
                getGlobalValues={getGlobalValues}
                editMode={editMode}
                setEditMode={setEditMode}
              />
            ))}
          </Col>
          <Col lg="12" xl="4" md="12" sm="12" xs="12">
            <div style={{ position: "sticky", top: "2rem" }}>
              {budgetState.transactionsWithoutCats !== 0 &&
                budgetState.showTransactionsWithoutCats === true ? (
                <Alert color="warning">
                  <h4 style={{ cursor: "pointer" }}>
                    <Row>
                      <Col
                        style={{ color: COLORS.neutral }}
                        className="d-flex justify-content-center"
                        onClick={async () => {
                          history.push("/app/transactions?uncategorized=true");
                        }}
                      >
                        <span>
                          <i className="fas fa-exchange-alt arrow1 mr-3"></i>
                          {StringManipulator.capitalize(t(`global.uncategorized.expenses`))}
                          <span className="ml-4">
                            {new Intl.NumberFormat(authState.user.currency_locales, {
                              style: "currency",
                              currency: authState.user.currency_code !== undefined ? authState.user.currency_code : "EUR",
                            }).format(budgetState.transactionsWithoutCats)}
                          </span>
                        </span>
                      </Col>
                    </Row>
                  </h4>
                </Alert>
              ) : (<></>)}
              <BudgetWidget BUDGETWIDGETDATA={budgetState.globalValues} />
              <BudgetTotalWidget />
            </div>
          </Col>
        </Row>
        <DefaultModal
          title={t("global.warning._")}
          size="modal-md"
          isOpen={show}
          color={COLORS.danger}
          toggle={() => setShow(false)}
          hasTwoButtons={true}
          btnText={t("OK")}
          onClick={async () => {
            await resetBudgetCategorie(bDate, sessionStorage.getItem("SESSION_SPACE"));
            ReactGAEvent('Reset', 'Reset all categories');
            getGlobalValues(bDate);
            getBudget(bDate, sessionStorage.getItem("SESSION_SPACE"));
            setShow(false);
          }}
          secondBtnText={StringManipulator.capitalize(t("global.cancel._"))}
          secondOnClick={() => setShow(false)}
        >
          {t("Resetting your budget for this month will permanently delete all budgeted values. Are you sure you want to proceed?")}
        </DefaultModal>
        <DefaultModal
          title={t("global.warning._")}
          size="modal-md"
          isOpen={defineCategorie}
          color={COLORS.danger}
          toggle={() => setDefineCategorie(false)}
          hasTwoButtons={true}
          onClick={async () => {
            await defineBudgetCategorie(bDate, sessionStorage.getItem("SESSION_SPACE"));
            ReactGAEvent('Define', 'Define all categories');
            getGlobalValues(bDate);
            getBudget(bDate, sessionStorage.getItem("SESSION_SPACE"));
            setDefineCategorie(false);
          }}
          btnText={t("OK")}
          secondBtnText={StringManipulator.capitalize(t("global.cancel._"))}
          secondOnClick={() => setDefineCategorie(false)}
        >
          {t("Do you want to use the previous month's budget? This will replace the budgeted values for the current month.")}.&nbsp;
          {StringManipulator.capitalize(t("prompts.are.you.sure.you.want.to.proceed.?"))}
        </DefaultModal>
        <Joyride
          run={false}
          // run={budgetState.onBoardingModeBudget !== undefined ? budgetState.onBoardingModeBudget : false}
          disableCloseOnEsc={true}
          showProgress={true}
          callback={(data) => {
            const { status } = data;
            if ([STATUS.FINISHED].includes(status)) {
              runOnBoardingBudget(false)
              // runOnBoardingBudget(false);
              // setShowFinishBudgetModal(true);
              setShowDoneModal(true)
            }
          }}
          showSkipButton={true}
          // continuous={true}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        // steps={steps}
        />
        {/* ================================================================================================= */}
        <DefaultModal
          size={"modal-md"}
          isOpen={showFinishBudgetModal}
          toggle={async () => {
            setShowFinishBudgetModal(false);
          }}
          title={t("Quick guideline")}
          btnText={t("Let's Go to the transaction view")}
          onClick={() => {
            history.push("/app/transactions");
            startOnBoardingTransactionView(true);
          }}
        >
          <Row>
            <Col sm="12" xs="12" md="12" lg="12" xl="12" className="d-flex justify-content-center text-default">
              <h4 style={{
                fontWeight: "bold"
              }}>
                {t("Now after we have finished the budget view quick tour, let's now move to another quick guideline to discover budget view tools")}{" "}
              </h4>
            </Col>
          </Row>
        </DefaultModal>
        {
          <DefaultModal
            btnText={t("First_Login_Done")}
            title={t("Setup completed")}
            isOpen={showDoneModal}
            onClick={async () => {
              setShowDoneModal(false);
              await fetch(
                `https://${REACT_APP_SERVER_URL}/Completusersppsetup`,
                {
                  method: "post",
                  headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
                  body: JSON.stringify({
                    refresh_token: localStorage.getItem("REFRESH_TOKEN"),
                    access_token: sessionStorage.getItem("ACCESS_TOKEN"),
                  }),
                  credentials: "include",
                }
              )
                .then((response) => response.json())
                .then(async (response) => {
                  setShowDoneModal(false);
                  await setFirstLoginState(true);
                });
            }}
            toggle={() => {
              setShowDoneModal(false);
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <i className="fa fa-check fa-4x"></i>
              <br />
              <div>
                {t("First_Login_You_have_completed_your_setup")}
              </div>
            </div>
          </DefaultModal>
        }
      </div>
    </>
  );
}

Budget.prototype = {
  getBudget: PropTypes.func.isRequired,
  transactionState: PropTypes.object.isRequired,
  getGlobalValues: PropTypes.func.isRequired,
  setBudgetGroup: PropTypes.func.isRequired,
  budgetState: PropTypes.object.isRequired,
  authState: PropTypes.object.isRequired,
  showBudgetCategorie: PropTypes.func.isRequired,
  resetBudgetCategorie: PropTypes.func.isRequired,
  getTransactionWithoutCats: PropTypes.func.isRequired,
  defineBudgetCategorie: PropTypes.func.isRequired,
  defineBudgetGroupOrder: PropTypes.func.isRequired,
  showBudgetGroup: PropTypes.func.isRequired,
  hideTransactionsWithoutCategories: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  runOnBoardingBudget: PropTypes.func.isRequired,
  setFirstLoginState: PropTypes.func.isRequired,
  startOnBoardingTransactionView: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  budgetState: state.budget,
  authState: state.auth,
  transactionState: state.transaction,
});

export default connect(mapStateToProps, {
  getBudget,
  getGlobalValues,
  setBudgetGroup,
  showBudgetCategorie,
  resetBudgetCategorie,
  defineBudgetCategorie,
  getTransactionWithoutCats,
  hideTransactionsWithoutCategories,
  setFirstLoginState,
  defineBudgetGroupOrder,
  runOnBoardingBudget,
  showBudgetGroup,
  startOnBoardingTransactionView,
})(Budget);
