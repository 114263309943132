import React, { useEffect, useState } from "react";
import Chart from "chart.js";
import { Helmet } from 'react-helmet-async';
import { Bar } from "react-chartjs-2";
import ReactDatetime from "react-datetime";
import { getBudget, getGlobalValues } from "../actions/Budget"
import {
  Card,
  CardBody,
  Row,
  Col,
  Popover, PopoverBody, PopoverHeader,
  UncontrolledCollapse,
} from "reactstrap";
import { chartOptions, parseOptions } from "../components/Tools/charts.js";
import RepportCategoriesWidget from "../components/Widgets/RepportCategoriesWidget.js";
import moment from "moment";
import { connect } from "react-redux";
import { getAccountReport, getEntriesReport, getDepenseReport, getBudgetReport } from "../actions/Reports";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { COLORS } from "assets/constants/theme";
import { StringManipulator } from "@fineo-io/main-javascript-library";
import DefaultSelect from "components/inputs/DefaultSelect";
import MonthPicker from "components/Randoms/MonthPicker";
import MainHeader from "components/Headers/MainHeader.jsx";

function Rapports({
  getAccountReport,
  getEntriesReport,
  getBudgetReport,
  getDepenseReport,
  getBudget,
  getGlobalValues,
  reportState,
  authState,
  budgetState,
  transactionState,
}) {
  //default start Date
  var dateFrom = new Date();
  dateFrom.setMonth(dateFrom.getMonth() - 4);

  const { t } = useTranslation();
  const [startEntriesDate, setEntriesStartDate] = useState(moment(dateFrom).format("YYYY-MM-DD"));
  const [endEntriesDate, setEntriesEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [startDepenseDate, setDepenseStartDate] = useState(moment(dateFrom).format("YYYY-MM-DD"));
  const [endDepenseDate, setDepenseEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [showBudgetCategoryDetails, setShowBudgetCategoryDetails] = useState(false);
  const [budgetCategoryDetailsObject, setBudgetCategoryDetailsObject] = useState({ Name: "" });
  const [selectDoughnot, setSelectDoughnot] = useState("activitiesChart");
  const [seleectedCategories, setSeleectedCategories] = useState([-2]);
  const [seleectedCategoriesDepenses, setseleectedCategoriesDepenses] = useState([-2]);
  const [showFilterCatEntries, setShowFilterCatEntries] = useState(false);
  const [showFilterCaDepenses, setShowFilterCaDepenses] = useState(false);
  const [showFilterCatEntriesAccount, setShowFilterCatEntriesAccount] = useState(false);
  const [seleectedAccount, setSeleectedAccount] = useState([-1]);
  const [seleectedAccountDepenses, setseleectedAccountDepenses] = useState([-1]);
  const [bDate, setDate] = useState(budgetState.bDate || moment(new Date()).format("YYYY-MM-01"));
  const [actvieView, setActiveView] = useState("budget");

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  useEffect(() => {
    getGlobalValues(bDate);
    getBudget(bDate, sessionStorage.getItem("SESSION_SPACE"));
  }, [bDate, sessionStorage.getItem("SESSION_SPACE")]);

  //entries--------------------------------------------------------------------
  useEffect(() => {
    //array with months of year
    var months = [
      "Janvier",
      "Fevrier",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Aout",
      "Septembre",
      "Octobre",
      "Novembre",
      "Decembre",
    ];
    months = months.map((month) => (month = t(`Dashboard_${month}`)));

    //fill entriesDays Array with days from selected
    var entriesDays = [];
    var entriesData = [];
    var monthCount = monthDiff(new Date(startEntriesDate), new Date(endEntriesDate)) + 1;
    var start = parseInt(new Date(startEntriesDate).getMonth()) + 1;
    for (var i = 0; i < monthCount; i++) {
      entriesData.push(0);
      entriesDays.push(months[start - 1]);
      if (start != 12) {
        start = start + 1;
      } else {
        start = 1;
      }
    }

    //reload the chart
    getAccountReport(sessionStorage.getItem("SESSION_SPACE"));
    getEntriesReport(
      sessionStorage.getItem("SESSION_SPACE"),
      startEntriesDate,
      endEntriesDate,
      seleectedAccount,
      seleectedCategories,
      entriesDays,
      entriesData,
      authState.user.currency_code,
      authState.user.currency_locales
    );
  }, [
    startEntriesDate,
    endEntriesDate,
    seleectedAccount,
    seleectedCategories,
    authState,
    getEntriesReport,
    getAccountReport,
  ]);

  //depenses-----------------------------------------------------------------------
  useEffect(() => {
    //array with months of year
    var months = [
      "Janvier",
      "Fevrier",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Aout",
      "Septembre",
      "Octobre",
      "Novembre",
      "Decembre",
    ];
    months = months.map((month) => (month = t(`Dashboard_${month}`)));
    var monthCount =
      monthDiff(new Date(startDepenseDate), new Date(endDepenseDate)) + 1;
    var start = parseInt(new Date(startDepenseDate).getMonth()) + 1;

    //fill depensesDays Array with days from selected
    var depenseData = [];
    var depenseDays = [];
    for (var i = 0; i < monthCount; i++) {
      depenseData.push(0);
      depenseDays.push(months[start - 1]);
      if (start != 12) {
        start = start + 1;
      } else {
        start = 1;
      }
    }
    getDepenseReport(
      sessionStorage.getItem("SESSION_SPACE"),
      startDepenseDate,
      endDepenseDate,
      seleectedAccountDepenses,
      seleectedCategoriesDepenses,
      depenseDays,
      depenseData,
      authState.user.currency_code,
      authState.user.currency_locales
    );
  }, [
    startDepenseDate,
    endDepenseDate,
    seleectedAccountDepenses,
    seleectedCategoriesDepenses,
    authState,
    getDepenseReport,
  ]);

  //month difference between two dates
  function monthDiff(dateFrom, dateTo) {
    return dateTo.getMonth() - dateFrom.getMonth() + 12 * (dateTo.getFullYear() - dateFrom.getFullYear());
  }

  return (
    <>
      <MainHeader title={StringManipulator.capitalize(t("global.analytic.s"))} />
      <Row className="m-2 p-3 mt-6" style={{ fontWeight: "200" }}>
        <Col xl="12" lg="12" md="12" sm="12" xs="12">
          <Row>
            <Col xl="12" lg="12" md="12" sm="12" xs="12" >
              <Row>
                <Col
                  xl="4" lg="4" md="4" sm="12" xs="12"
                  style={{
                    cursor: "pointer",
                    fontWeight: `${actvieView === "budget" ? "200" : "normal"}`,
                  }}
                  className="text-center"
                  onClick={() => {
                    setShowFilterCatEntriesAccount(false)
                    setShowFilterCatEntries(false);
                    setShowFilterCaDepenses(false);
                    setActiveView("budget");
                  }}
                >
                  <span style={{
                    borderBottom: `${actvieView === "budget" ? "3px solid blue" : "0px"}`,
                    paddingBottom: "4px"
                  }}>
                    {t("Assignment of budget groups")}
                  </span>
                </Col>
                <Col
                  xl="4" lg="4" md="4" sm="12" xs="12"
                  style={{
                    cursor: "pointer",
                    fontWeight: `${actvieView === "activities 1" ? "200" : "normal"}`,
                  }}
                  onClick={() => {
                    setShowFilterCatEntriesAccount(false)
                    setShowFilterCatEntries(false);
                    setShowFilterCaDepenses(false);
                    setActiveView("activities 1");
                  }}
                  className="text-center"
                >
                  <span style={{
                    borderBottom: `${actvieView === "activities 1" ? "3px solid blue" : "0px"}`,
                    paddingBottom: "4px"
                  }}>
                    {t("Entries evolution")}
                  </span>
                </Col>
                <Col
                  xl="4" lg="4" md="4" sm="12" xs="12"
                  style={{
                    cursor: "pointer",
                    fontWeight: `${actvieView === "activities 2" ? "bold" : "normal"}`,
                  }}
                  onClick={() => {
                    setShowFilterCatEntriesAccount(false)
                    setShowFilterCatEntries(false);
                    setShowFilterCaDepenses(false);
                    setActiveView("activities 2");
                  }}
                  className="text-center"
                >
                  <span style={{
                    borderBottom: `${actvieView === "activities 2" ? "3px solid blue" : "0px"}`,
                    paddingBottom: "4px"
                  }}>
                    {t("Expenses evolution")}
                  </span>
                  {t("")}
                </Col>
              </Row>
            </Col>
          </Row>
          {
            actvieView === "budget" && (
              <>
                <Row className="mt-3 mx-2">
                  <Col xl="12" lg="12" md="12" sm="12" xs="12" className="mx-1 d-flex justify-content-center">
                    <MonthPicker date={bDate} setDate={setDate} />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <Card style={{ borderRadius: "8px" }}>
                      <CardBody style={{ borderRadius: "8px", width: "80%" }}>
                        <Row>
                          <Col xl="8" lg="8" md="8" sm="12" xs="12">
                            {
                              showBudgetCategoryDetails === false ? (<h3 className="h2 mb-1">{t("Assignment of budget groups")}</h3>) :
                                (<h3 className="h2 mb-1" style={{ cursor: "pointer" }} onClick={async () => await setShowBudgetCategoryDetails(false)}>
                                  <i className="fas fa-arrow-left mr-1"></i>
                                  {t(`Assignment of `)}{" "}{budgetCategoryDetailsObject.Name}
                                </h3>)
                            }
                          </Col>
                          <Col xl="4" lg="4" md="4" sm="12" xs="12">
                            <DefaultSelect
                              defaultValue={StringManipulator.capitalize(t("global.expense.s"))}
                              placeholder={StringManipulator.capitalize(t("global.expense.s"))}
                              value={selectDoughnot}
                              onChange={(element) => {
                                setSelectDoughnot(element.value);
                              }}
                              options={[
                                { value: "budgetedChart", label: StringManipulator.capitalize(t("global.budgeted._")) },
                                { value: "availbleChart", label: StringManipulator.capitalize(t("global.available._")) },
                                { value: "activitiesChart", label: StringManipulator.capitalize(t("global.expense.s")) },
                              ]}
                            />
                          </Col>
                        </Row>
                        {
                          showBudgetCategoryDetails === false ? (
                            <RepportCategoriesWidget
                              authState={authState}
                              labels={budgetState.budgets.labels}
                              data={selectDoughnot === "budgetedChart" ? budgetState.budgets.data : budgetState.budgets.CategoryDisponible}
                              reportState={reportState}
                              budgetState={budgetState}
                              setShowBudgetCategoryDetails={setShowBudgetCategoryDetails}
                              setBudgetCategoryDetailsObject={setBudgetCategoryDetailsObject}
                              selectDoughnot={selectDoughnot}
                            />) : (
                            <RepportCategoriesWidget
                              authState={authState}
                              group={false}
                              reportState={reportState}
                              budgetState={budgetState}
                              setShowBudgetCategoryDetails={setShowBudgetCategoryDetails}
                              setBudgetCategoryDetailsObject={setBudgetCategoryDetailsObject}
                              budgetGroup={budgetCategoryDetailsObject.Name}
                              selectDoughnot={selectDoughnot}
                            />)
                        }
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            )
          }
          {
            actvieView === "activities 1" && (
              <Row>
                <Col xl="12" lg="12" md="12" sm="12" xs="12">
                  <Row className="mt-3 mx-2">
                    <Col xl="4" lg="4" md="4" sm="12" xs="12" className="d-flex justify-content-center">
                      <span style={{ fontSize: "15px", marginTop: "12px" }} className="mr-1">{t("From")} </span>
                      <i className="fas fa-calendar-alt mt-3" />
                      <ReactDatetime
                        dateFormat="MMMM YYYY"
                        locale={t("Budget_ReactDatetime")}
                        timeFormat={false}
                        defaultValue={new Date(startEntriesDate).setMonth(new Date(endEntriesDate).getMonth() - 4)}
                        onChange={(date) => {
                          setEntriesStartDate(moment(date).format("YYYY-MM-01"));
                        }}
                        inputProps={{
                          style: {
                            width: "100%",
                            color: COLORS.glyphPrimary,
                            backgroundColor: "transparent",
                            fontSize: "15px",
                            border: "none",
                            cursor: "pointer",
                            textAlign: "left",
                          },
                        }}
                      />
                      <span style={{ fontSize: "15px", marginTop: "12px" }} className="mr-1">{t("to")}</span>
                      <i className="fas fa-calendar-alt mt-3" />
                      <ReactDatetime
                        dateFormat="MMMM YYYY"
                        locale={t("Budget_ReactDatetime")}
                        timeFormat={false}
                        isValidDate={(current) => {
                          if (new Date(moment(current).endOf("M").format("YYYY-MM-DD")) < new Date(endEntriesDate)) {
                            return true;
                          } else {
                            return false;
                          }
                        }}
                        defaultValue={new Date(endEntriesDate)}
                        onChange={(date) => {
                          setEntriesEndDate(moment(date).endOf("M").format("YYYY-MM-DD"));
                        }}
                        inputProps={{
                          style: {
                            width: "100%",
                            color: COLORS.glyphPrimary,
                            backgroundColor: "transparent",
                            fontSize: "15px",
                            border: "none",
                            cursor: "pointer",
                            textAlign: "left",
                          },
                        }}
                      />
                    </Col>
                    <Col xl="4" lg="4" md="4" sm="6" xs="6" style={{ marginTop: "18px" }}>
                      <h4 id="showFilterCatEntriesAccount" className="d-flex justify-content-center" style={{ cursor: "pointer" }} onClick={() => setShowFilterCatEntriesAccount(!showFilterCatEntriesAccount)}>
                        <i className="fas fa-filter mt-1" style={{
                          marginTop: "8px"
                        }}></i>
                        <span style={{
                          fontSize: "15px"
                        }}>
                          {t("Filter By Account")}
                        </span>
                      </h4>
                      <Popover
                        placement="bottom"
                        isOpen={showFilterCatEntriesAccount}
                        target="showFilterCatEntriesAccount"
                        toggle={() => setShowFilterCatEntriesAccount(false)}
                      >
                        <PopoverHeader className="bg-white d-flex justify-content-between">
                          <span>
                            {t("Accounts")}
                          </span>
                          <span>
                            <i className="fas fa-times" style={{ cursor: "pointer" }}
                              onClick={() => setShowFilterCatEntriesAccount(false)}
                            ></i>
                          </span>
                        </PopoverHeader>
                        <PopoverBody className="bg-white">
                          <ul style={{
                            paddingRight: "60px",
                            paddingLeft: "30px"
                          }}>
                            {
                              reportState.depenseCompte.map((account, index) => (
                                <li
                                  onClick={() => {
                                    if (index === 0 && seleectedAccount.filter((acc) => acc === -1).length === 0) {
                                      setSeleectedAccount([-1]);
                                      return;
                                    } else if (index === 0 && seleectedAccount.filter((acc) => acc === -1).length !== 0) {
                                      setSeleectedAccount([]);
                                      return;
                                    }
                                    if (seleectedAccount.filter((acc) => acc === account.account_id).length === 0) {
                                      setSeleectedAccount([...seleectedAccount, account.account_id].filter((acc1) => acc1 !== -1));
                                      return;
                                    } else if (seleectedAccount.filter((acc) => acc === account.account_id).length !== 0) {
                                      setSeleectedAccount([...seleectedAccount.filter((ac) => ac !== account.account_id).filter((acc1) => acc1 !== -1)]);
                                      return;
                                    }
                                  }}
                                >
                                  <span>
                                    <input
                                      type="checkbox"
                                      className="mt--1 mr-1"
                                      defaultChecked={false}
                                      checked={seleectedAccount.filter((acc) => acc === account.account_id).length !== 0}
                                    />
                                  </span>
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      paddingBottom: "8px",
                                      fontWeight: `${seleectedAccount.filter((acc) => acc === account.account_id).length !== 0 ? "bold" : "normal"}`,
                                    }}
                                  >

                                    {index !== 0 ? account.account_name : t("Account_Tous_les_comptes")}
                                  </span>
                                </li>
                              ))
                            }
                          </ul>
                        </PopoverBody>
                      </Popover>
                    </Col>
                    <Col xl="4" lg="4" md="4" sm="6" xs="6" style={{ marginTop: "18px" }}>
                      <h4 id="showFilterCatEntries" className="d-flex justify-content-center" style={{ cursor: "pointer" }} onClick={() => setShowFilterCatEntries(!showFilterCatEntries)}>
                        <i className="fas fa-filter mt-1"></i>
                        <span style={{ fontSize: "15px" }}>
                          {t("Filter By Budget Categories")}
                        </span>
                      </h4>
                      <Popover
                        placement="bottom"
                        isOpen={showFilterCatEntries}
                        target="showFilterCatEntries"
                        toggle={() => setShowFilterCatEntries(false)}
                      >
                        <PopoverHeader className="bg-white d-flex justify-content-between">
                          <span>
                            {t("Budget Groups")}
                          </span>
                          <span>
                            <i className="fas fa-times" style={{ cursor: "pointer" }}
                              onClick={() => setShowFilterCatEntries(false)}
                            ></i>
                          </span>
                        </PopoverHeader>
                        <PopoverBody className="bg-white">
                          <ul style={{
                            paddingRight: "60px",
                            paddingLeft: "30px"
                          }}>
                            {
                              transactionState.categories.map((group, index) => (
                                <span
                                  onClick={() => {
                                    if (index === 0 && seleectedCategories.filter((cate) => cate === -2).length === 0) {
                                      setSeleectedCategories([-2]);
                                      return;
                                    } else if (index === 0 && seleectedCategories.filter((cate) => cate === -2).length !== 0) {
                                      setSeleectedCategories([]);
                                      return;
                                    }

                                    if (index === 1 && seleectedCategories.filter((cate) => cate === -1).length === 0) {
                                      setSeleectedCategories([-1]);
                                      return;
                                    } else if (index === 1 && seleectedCategories.filter((cate) => cate === -1).length !== 0) {
                                      setSeleectedCategories([]);
                                      return;
                                    }
                                  }}
                                >
                                  <span>
                                    <input
                                      type="checkbox"
                                      className="mt--1 mr-1"
                                      defaultChecked={false}
                                      checked={seleectedCategories.filter((cate) => group.options.filter((category) => category.value === cate).length !== 0).length !== 0}
                                      onChange={(e) => {
                                        if (index === 0 && seleectedCategories.filter((cate) => cate === -2).length === 0) {
                                          setSeleectedCategories([-2]);
                                          return;
                                        } else if (index === 0 && seleectedCategories.filter((cate) => cate === -2).length !== 0) {
                                          setSeleectedCategories([]);
                                          return;
                                        }

                                        if (e.target.checked === true) {
                                          if (index === 1 && seleectedCategories.filter((cate) => cate === -1).length === 0) {
                                            setSeleectedCategories([-1]);
                                            return;
                                          } else if (index === 1 && seleectedCategories.filter((cate) => cate === -1).length !== 0) {
                                            setSeleectedCategories([]);
                                            return;
                                          }
                                          let selecteds = []
                                          group.options.forEach((element) => {
                                            selecteds.push(element.value)
                                          })
                                          setSeleectedCategories([...seleectedCategories, ...selecteds].filter((catt) => catt !== -2 && catt !== -1))
                                        } else {
                                          let selecteds = []
                                          group.options.forEach((element) => {
                                            selecteds.push(element.value)
                                          })
                                          setSeleectedCategories(seleectedCategories.filter((catt) => selecteds.indexOf(catt) === -1).filter((catt) => catt !== -2 && catt !== -1))
                                        }
                                      }}
                                    />
                                  </span>
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      paddingBottom: "8px",
                                      fontWeight: `${seleectedCategories.filter((cate) => group.options.filter((category) => category.value === cate).length !== 0).length !== 0 ? "bold" : "normal"}`,
                                    }}
                                    id={`target_${index}`}
                                    href="#collapseExample"
                                  >
                                    {group.options[0].key === -1 ? t("Transaction_À_budgéter") : group.options[0].key === -2 ? t("budget_get_all") : group.label}
                                  </span>
                                  <ul>
                                    {
                                      group.options.map((cat, index1) => index > 1 && (
                                        <UncontrolledCollapse toggler={`#collapseExample,#target_${index}`} >
                                          <span className="d-flex justify-content-around"
                                            onClick={() => {
                                              if (seleectedCategories.filter((cate) => cate === cat.value).length !== 0 !== true) {
                                                setSeleectedCategories([...seleectedCategories, cat.value].filter((catt) => catt !== -2))
                                              } else {
                                                setSeleectedCategories(seleectedCategories.filter((catt) => catt !== cat.value).filter((catt) => catt !== -2 && catt !== -1))
                                              }
                                            }}
                                            style={{
                                              cursor: "pointer"
                                            }}>
                                            <span>
                                              <input
                                                type="checkbox"
                                                className="mt-1 mr-1"
                                                key={cat.key}
                                                id={cat.key}
                                                defaultChecked={false}
                                                checked={seleectedCategories.filter((cate) => cate === cat.value).length !== 0}
                                                onChange={(e) => {
                                                  if (e.target.checked === true) {
                                                    setSeleectedCategories([...seleectedCategories, cat.value].filter((catt) => catt !== -2 && catt !== -1))
                                                  } else {
                                                    setSeleectedCategories(seleectedCategories.filter((catt) => catt !== cat.value).filter((catt) => catt !== -2 && catt !== -1))
                                                  }
                                                }}
                                              />
                                            </span>
                                            <span>
                                              {cat.label}
                                            </span>
                                          </span>
                                        </UncontrolledCollapse>
                                      ))
                                    }
                                  </ul>
                                </span>
                              ))
                            }
                          </ul>
                        </PopoverBody>
                      </Popover>
                    </Col>
                  </Row>
                  <Card style={{ borderRadius: "8px" }}>
                    <CardBody style={{ borderRadius: "8px" }}>
                      <Row>
                        <Col xl="6" lg="6" md="6" sm="12" xs="12">
                          <h3 className="h3">
                            {t("Entries evolution")}
                          </h3>
                        </Col>
                      </Row>
                      <div className="chart p-3">
                        <Bar
                          data={reportState.chartEntries.data}
                          options={reportState.chartEntries.options}
                          className="chart-canvas"
                          id="chart-bars"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )
          }
          {
            actvieView === "activities 2" && (
              <Row>
                <Col xl="12" lg="12" md="12" sm="12" xs="12">
                  <Row className="mt-3 mx-2">
                    <Col xl="4" lg="4" md="4" sm="12" xs="12" className="d-flex justify-content-center">
                      <span style={{ fontSize: "15px", marginTop: "12px" }} className="mr-1">{t("From")} </span>
                      <i className="fas fa-calendar-alt mt-3" />
                      <ReactDatetime
                        dateFormat="MMMM YYYY"
                        locale={t("Budget_ReactDatetime")}
                        timeFormat={false}
                        defaultValue={new Date(startDepenseDate).setMonth(new Date(endDepenseDate).getMonth() - 4)}
                        onChange={(date) => {
                          setDepenseStartDate(moment(date).format("YYYY-MM-01"));
                        }}
                        inputProps={{
                          style: {
                            width: "100%",
                            color: COLORS.glyphPrimary,
                            backgroundColor: "transparent",
                            fontSize: "15px",
                            border: "none",
                            cursor: "pointer",
                            textAlign: "left",
                          },
                        }}
                      />
                      <span style={{ fontSize: "15px", marginTop: "12px" }} className="mr-1">{t("to")}</span>
                      <i className="fas fa-calendar-alt mt-3" />
                      <ReactDatetime
                        dateFormat="MMMM YYYY"
                        locale={t("Budget_ReactDatetime")}
                        timeFormat={false}
                        isValidDate={(current) => {
                          if (new Date(moment(current).endOf("M").format("YYYY-MM-DD")) < new Date(endDepenseDate)) {
                            return true;
                          } else {
                            return false;
                          }
                        }}
                        defaultValue={new Date(endDepenseDate)}
                        onChange={(date) => {
                          setDepenseStartDate(moment(date).endOf("M").format("YYYY-MM-DD"));
                        }}
                        inputProps={{
                          style: {
                            width: "100%",
                            color: COLORS.glyphPrimary,
                            backgroundColor: "transparent",
                            fontSize: "15px",
                            border: "none",
                            cursor: "pointer",
                            textAlign: "left",
                          },
                        }}
                      />

                    </Col>
                    <Col xl="4" lg="4" md="4" sm="6" xs="6" style={{ marginTop: "18px" }}>
                      <h4 id="showFilterCatEntriesAccountDepenses" className="d-flex justify-content-center" style={{ cursor: "pointer" }} onClick={() => setShowFilterCatEntriesAccount(!showFilterCatEntriesAccount)}>
                        <i className="fas fa-filter mt-1"></i>
                        <span style={{
                          fontSize: "15px"
                        }}>
                          {t("Filter By Account")}
                        </span>
                      </h4>
                      <Popover
                        placement="bottom"
                        isOpen={showFilterCatEntriesAccount}
                        target="showFilterCatEntriesAccountDepenses"
                        toggle={() => setShowFilterCatEntriesAccount(false)}
                      >
                        <PopoverHeader className="bg-white d-flex justify-content-between">
                          <span>
                            {t("Accounts")}
                          </span>
                          <span>
                            <i className="fas fa-times" style={{ cursor: "pointer" }}
                              onClick={() => setShowFilterCatEntriesAccount(false)}
                            ></i>
                          </span>
                        </PopoverHeader>
                        <PopoverBody className="bg-white">
                          <ul style={{
                            paddingRight: "60px",
                            paddingLeft: "30px"
                          }}>
                            {
                              reportState.depenseCompte.map((account, index) => (
                                <li
                                  onClick={() => {
                                    if (index === 0 && seleectedAccountDepenses.filter((acc) => acc === -1).length === 0) {
                                      setseleectedAccountDepenses([-1]);
                                      return;
                                    } else if (index === 0 && seleectedAccountDepenses.filter((acc) => acc === -1).length !== 0) {
                                      setseleectedAccountDepenses([]);
                                      return;
                                    }
                                    if (seleectedAccountDepenses.filter((acc) => acc === account.account_id).length === 0) {
                                      setseleectedAccountDepenses([...seleectedAccountDepenses, account.account_id].filter((acc1) => acc1 !== -1));
                                      return;
                                    } else if (seleectedAccountDepenses.filter((acc) => acc === account.account_id).length !== 0) {
                                      setseleectedAccountDepenses([...seleectedAccountDepenses.filter((ac) => ac !== account.account_id).filter((acc1) => acc1 !== -1)]);
                                      return;
                                    }
                                  }}
                                >
                                  <span>
                                    <input
                                      type="checkbox"
                                      className="mt--1 mr-1"
                                      defaultChecked={false}
                                      checked={seleectedAccountDepenses.filter((acc) => acc === account.account_id).length !== 0}
                                    />
                                  </span>
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      paddingBottom: "8px",
                                      fontWeight: `${seleectedAccountDepenses.filter((acc) => acc === account.account_id).length !== 0 ? "bold" : "normal"}`,
                                    }}
                                  >

                                    {index !== 0 ? account.account_name : t("Account_Tous_les_comptes")}
                                  </span>
                                </li>
                              ))
                            }
                          </ul>
                        </PopoverBody>
                      </Popover>
                    </Col>
                    <Col xl="4" lg="4" md="4" sm="6" xs="6" style={{ marginTop: "18px" }}>
                      <h4 id="showFilterCaDepenses" className="d-flex justify-content-center" style={{ cursor: "pointer" }} onClick={() => setShowFilterCaDepenses(!showFilterCaDepenses)}>
                        <i className="fas fa-filter mt-1"></i>
                        <span style={{
                          fontSize: "15px"
                        }}>
                          {t("Filter By Budget Categories")}
                        </span>
                      </h4>
                      <Popover
                        placement="bottom"
                        isOpen={showFilterCaDepenses}
                        target="showFilterCaDepenses"
                        toggle={() => setShowFilterCaDepenses(false)}
                      >
                        <PopoverHeader className="bg-white d-flex justify-content-between">
                          <span>
                            {t("Budget Groups")}
                          </span>
                          <span>
                            <i className="fas fa-times" style={{ cursor: "pointer" }}
                              onClick={() => setShowFilterCaDepenses(false)}
                            ></i>
                          </span>
                        </PopoverHeader>
                        <PopoverBody className="bg-white">
                          <ul style={{
                            paddingRight: "60px",
                            paddingLeft: "30px"
                          }}>
                            {
                              transactionState.categories.map((group, index) => (
                                <span
                                  onClick={() => {
                                    if (index === 0 && seleectedCategoriesDepenses.filter((cate) => cate === -2).length === 0) {
                                      setseleectedCategoriesDepenses([-2]);
                                      return;
                                    } else if (index === 0 && seleectedCategoriesDepenses.filter((cate) => cate === -2).length !== 0) {
                                      setseleectedCategoriesDepenses([]);
                                      return;
                                    }

                                    if (index === 1 && seleectedCategoriesDepenses.filter((cate) => cate === -1).length === 0) {
                                      setseleectedCategoriesDepenses([-1]);
                                      return;
                                    } else if (index === 1 && seleectedCategoriesDepenses.filter((cate) => cate === -1).length !== 0) {
                                      setseleectedCategoriesDepenses([]);
                                      return;
                                    }
                                  }}
                                >
                                  <span>
                                    <input
                                      type="checkbox"
                                      className="mt--1 mr-1"
                                      defaultChecked={false}
                                      checked={seleectedCategoriesDepenses.filter((cate) => group.options.filter((category) => category.value === cate).length !== 0).length !== 0}
                                      onChange={(e) => {
                                        if (index === 0 && seleectedCategoriesDepenses.filter((cate) => cate === -2).length === 0) {
                                          setseleectedCategoriesDepenses([-2]);
                                          return;
                                        } else if (index === 0 && seleectedCategoriesDepenses.filter((cate) => cate === -2).length !== 0) {
                                          setseleectedCategoriesDepenses([]);
                                          return;
                                        }

                                        if (e.target.checked === true) {
                                          if (index === 1 && seleectedCategoriesDepenses.filter((cate) => cate === -1).length === 0) {
                                            setseleectedCategoriesDepenses([-1]);
                                            return;
                                          } else if (index === 1 && seleectedCategoriesDepenses.filter((cate) => cate === -1).length !== 0) {
                                            setseleectedCategoriesDepenses([]);
                                            return;
                                          }
                                          let selecteds = []
                                          group.options.forEach((element) => {
                                            selecteds.push(element.value)
                                          })
                                          setseleectedCategoriesDepenses([...seleectedCategoriesDepenses, ...selecteds].filter((catt) => catt !== -2 && catt !== -1))
                                        } else {
                                          let selecteds = []
                                          group.options.forEach((element) => {
                                            selecteds.push(element.value)
                                          })
                                          setseleectedCategoriesDepenses(seleectedCategoriesDepenses.filter((catt) => selecteds.indexOf(catt) === -1).filter((catt) => catt !== -2 && catt !== -1))
                                        }
                                      }}
                                    />
                                  </span>
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      paddingBottom: "8px",
                                      fontWeight: `${seleectedCategoriesDepenses.filter((cate) => group.options.filter((category) => category.value === cate).length !== 0).length !== 0 ? "bold" : "normal"}`,
                                    }}
                                    id={`target_${index}`}
                                    href="#collapseExample"
                                  >
                                    {group.options[0].key === -1 ? t("Transaction_Categorie_à_définir") : group.options[0].key === -2 ? t("budget_get_all") : group.label}
                                  </span>
                                  <ul>
                                    {
                                      group.options.map((cat, index1) => index > 1 && (
                                        <UncontrolledCollapse toggler={`#collapseExample,#target_${index}`} >
                                          <span className="d-flex justify-content-around"
                                            onClick={() => {
                                              if (seleectedCategoriesDepenses.filter((cate) => cate === cat.value).length !== 0 !== true) {
                                                setseleectedCategoriesDepenses([...seleectedCategoriesDepenses, cat.value].filter((catt) => catt !== -2))
                                              } else {
                                                setseleectedCategoriesDepenses(seleectedCategoriesDepenses.filter((catt) => catt !== cat.value).filter((catt) => catt !== -2 && catt !== -1))
                                              }
                                            }}
                                            style={{
                                              cursor: "pointer"
                                            }}>
                                            <span>
                                              <input
                                                type="checkbox"
                                                className="mt-1 mr-1"
                                                key={cat.key}
                                                id={cat.key}
                                                defaultChecked={false}
                                                checked={seleectedCategoriesDepenses.filter((cate) => cate === cat.value).length !== 0}
                                                onChange={(e) => {
                                                  if (e.target.checked === true) {
                                                    setseleectedCategoriesDepenses([...seleectedCategoriesDepenses, cat.value].filter((catt) => catt !== -2 && catt !== -1))
                                                  } else {
                                                    setseleectedCategoriesDepenses(seleectedCategoriesDepenses.filter((catt) => catt !== cat.value).filter((catt) => catt !== -2 && catt !== -1))
                                                  }
                                                }}
                                              />
                                            </span>
                                            <span>
                                              {cat.label}
                                            </span>
                                          </span>
                                        </UncontrolledCollapse>
                                      ))
                                    }
                                  </ul>
                                </span>
                              ))
                            }
                          </ul>
                        </PopoverBody>
                      </Popover>
                    </Col>
                  </Row>
                  <Card style={{ borderRadius: "8px" }}>
                    <CardBody style={{ borderRadius: "8px" }}>
                      <Row>
                        <Col xl="3" lg="3" md="3" sm="12" xs="12">
                          <h3 className="h3">
                            {t("Expenses evolution")}
                          </h3>
                        </Col>
                      </Row>
                      <div className="chart p-3 mt--3">
                        <Bar
                          data={reportState.chartDepense.data}
                          options={reportState.chartDepense.options}
                          className="chart-canvas"
                          id="chart-bars"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )
          }
        </Col>
      </Row>
    </>
  );
}

Rapports.prototype = {
  authState: PropTypes.object.isRequired,
  reportState: PropTypes.object.isRequired,
  budgetState: PropTypes.object.isRequired,
  getAccountReport: PropTypes.func.isRequired,
  getEntriesReport: PropTypes.func.isRequired,
  getDepenseReport: PropTypes.func.isRequired,
  getBudgetReport: PropTypes.func.isRequired,
  transactionState: PropTypes.object.isRequired,
  getBudget: PropTypes.func.isRequired,
  getGlobalValues: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  reportState: state.Reports,
  authState: state.auth,
  budgetState: state.budget,
  transactionState: state.transaction
});

export default connect(mapStateToProps, {
  getAccountReport,
  getEntriesReport,
  getDepenseReport,
  getBudgetReport,
  getBudget,
  getGlobalValues
})(Rapports);