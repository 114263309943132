import React, { useState } from "react";
import { message } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { NavLink as NavLinkRRD } from "react-router-dom";
import AdminInfo from "../../components/Randoms/AdminInfo";
import {
  NavItem,
  NavLink,
  Nav,
} from "reactstrap";
import TextArea from "antd/lib/input/TextArea";
import Spaces from "components/Randoms/Spaces";
import { openAddTransactionModal, startSyncingAccounts } from '../../actions/Global.js'
import hexToRgba from "helpers/hexToRgba";
import { COLORS } from "assets/constants/theme";
import DefaultModal from "components/Modals/DefaultModal";
import { StringManipulator } from "@fineo-io/main-javascript-library";
import AddAccountModal from "components/Modals/AddAccountModal";
import Icons from "components/Randoms/Icons";
const { REACT_APP_SERVER_URL } = process.env;
const { AddIcon } = Icons;

function Sidebar({ userInfo, routes = [{}], sidenavOpen = false, location, history, GlobalState, accountState,
  logo = {
    innerLink: "",
    outterLink: "",
    imgSrc: "",
    imgAlt: "",
  },
  openAddTransactionModal
}) {
  const { t } = useTranslation();

  // This creates the intial state of this component based on the collapse routes
  // That it gets through routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  // Component global State:
  const [state, setState] = useState({
    collapseOpen: false,
    addNewSpaceName: "",
    ...getCollapseStates(routes),
    popoverOpen: false,
    isInAddMode: false,
    isInEditMode: false,
    isDeleteMode: false,
    selectValue: "Budget",
    inputValue: "",
    spaces: {},
    selectedSpace: "",
    editSpaceName: "",
    editSpaceId: "",
    alertHide: { display: "none" },
    refreshNordigen: true,
    space_name: "",
    openAddModal: false,
    startSync: false
  });
  const [reportBugModal, setReportBugModal] = useState(false);
  const [needToAddAccountModal, setNeedToAddAccountModal] = useState(false)

  const toggleBugModal = () => {
    setReportBugModal(!reportBugModal);
  };

  //Get popover bug select value
  const handleSelectValueChange = (event) => {
    setState({
      ...state,
      selectValue: event.target.value,
    });
  }

  // Get popover bug input value
  const handleInputValueChange = (event) => {
    setState({
      ...state,
      inputValue: event.target.value,
    });
  }

  // This verifies if any of the collapses should be default opened on a rerender of this component
  // For example, on the refresh of the page,
  // While on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  const activeRouteStyle = (routeName) => {
    return location.pathname.indexOf(routeName) > -1
      ? {
        background: "transparent",
        border: sidenavOpen ? `${COLORS.neutral} 1px solid` : "none",
        borderRadius: "5px",
        marginBottom: "12px",
        color: COLORS.neutral,
        marginRight: "10px",
        fontSize: "15px",
        padding: "8px 15px",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap"
      }
      : {
        marginBottom: "12px",
        padding: "8px 15px",
        color: COLORS.neutral,
        marginRight: "10px",
        fontSize: "15px",
      };
  };

  // This function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          {
            prop.path !== null ? (
              <NavLink
                to={prop.layout + prop.path}
                style={activeRouteStyle(prop.layout + prop.path)}
                tag={NavLinkRRD}
              >
                {prop.icon !== undefined ? (
                  <>
                    <span>
                      <img src={prop.icon} height="18px" width="18px" style={{
                        marginRight: "12px"
                      }} />
                    </span>
                    {
                      <span className="nav-link-text" style={{
                        fontWeight: "200",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}>{StringManipulator.capitalize(t(prop.name))}</span>
                    }
                  </>
                ) : (
                  prop.name
                )}
              </NavLink>
            ) :
              (
                <NavLink
                  to={"#"}
                  style={{
                    marginBottom: "5px",
                    color: "white",
                    marginRight: "10px",
                    fontSize: "15px",
                    backgroundColor: "transparent",
                    borderBottomWidth: "80%",
                    marginBottom: "12px",
                    padding: "8px 15px",
                  }}
                  tag={NavLinkRRD}
                >
                  {prop.icon !== undefined ? (
                    <>
                      <span>
                        <img src={prop.icon} height="18px" width="18px" style={{
                          marginRight: "12px"
                        }} />
                      </span>
                      {
                        <span className="nav-link-text" style={{
                          fontWeight: "200",
                          paddingTop: "5px",
                        }}>
                          {t(prop.name) === t("AddTrModal_Ajouter_Transaction") ? t(prop.name) :
                            (<>
                              <span hidden={GlobalState.isSyncingMyAccounts}>
                                {t(prop.name) !== t("AddTrModal_Ajouter_Transaction") && t(prop.name)}
                              </span>
                              <span hidden={!GlobalState.isSyncingMyAccounts} style={{ marginRight: 3 }}>{t("NavBar_Synchronisation")}</span>
                              <span className="spinner" hidden={!GlobalState.isSyncingMyAccounts}>
                                <div className="bounce1"></div>
                                <div className="bounce2"></div>
                                <div className="bounce3"></div>
                              </span>
                            </>)}
                        </span>
                      }
                    </>
                  ) : (
                    prop.name
                  )}
                </NavLink>
              )
          }
        </NavItem>
      );
    });
  };

  const scrollBarInner = () => (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh", width: "100%" }}>

      {/* logo and name */}
      <div
        className="sidenav-header d-flex align-items-center"
        style={{
          paddingTop: "5%",
        }}
      >
        <span
          style={{
            padding: "15px",
            border: "0px",
            borderRadius: "50%",
            backgroundColor: hexToRgba(COLORS.neutral),
          }}
        >
          <img
            alt={logo.imgAlt}
            className="navbar-brand-img"
            src={logo.imgSrc}
            width={"30px"}
          />
        </span>
        <span className="ml-2">
          <span
            style={{
              fontWeight: "600",
              color: "white",
              fontSize: "16px"
            }}
          >
            Fineo
          </span>
        </span>
      </div>

      {/* spaces */}
      <div style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: "10%"
      }}>
        <Spaces t={t} history={history} location={location} sidenavOpen={sidenavOpen} />
      </div>

      {/* menu items */}
      <div style={{ marginTop: "5%", alignSelf: "center", width: "100%", flex: 1 }}>
        <div
          style={{
            marginBottom: "5px",
            color: "white",
            marginRight: "10px",
            fontSize: "15px",
            backgroundColor: "transparent",
            borderBottomWidth: "80%",
            marginBottom: "12px",
            padding: "8px 15px",
            cursor: "pointer"
          }}
          onClick={async () => {
            if (accountState?.accounts?.length !== 0) {
              openAddTransactionModal(true);
              setState({
                ...state,
                openAddModal: !state.openAddModal,
              });
            } else {
              setNeedToAddAccountModal(true)
            }
          }}
          tag={NavLinkRRD}
        >
          <span>
            <img src={AddIcon} height="18px" width="18px" style={{marginRight: "12px"}} />
          </span>
          {
            <span className="nav-link-text" style={{
              fontWeight: "200",
              paddingTop: "5px",
            }}>
              {t("AddTrModal_Ajouter_Transaction")}
            </span>
          }
        </div>
        <Nav navbar>
          {createLinks(routes, setNeedToAddAccountModal)}
        </Nav>
      </div>

      {/* bottom avatar menu */}
      <div style={{ marginBottom: "20px", width: "100%" }}>
        <AdminInfo
          history={history}
          toggleBugModal={toggleBugModal}
        />
      </div>

      {/* modal */}
      <DefaultModal
        size={"modal-sm"}
        isOpen={reportBugModal}
        hasTwoButtons={true}
        style={{ paddingTop: "100px" }}
        toggle={toggleBugModal}
        btnText={t("SideBar_Soumettre_le_rapport")}
        title={t("SideBar_declarer_un_bug")}
        secondBtnText={StringManipulator.capitalize(t("global.cancel._"))}
        secondOnClick={toggleBugModal}
        onClick={() =>
          state.inputValue === ""
            ? setState({
              ...state,
              alertHide: { display: "block" },
            })
            : fetch(
              `https://${REACT_APP_SERVER_URL}/ReportBug`,
              {
                method: "post",
                credentials: "include",
                headers: {
                  "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}`,
                },
                body: JSON.stringify({
                  refresh_token:
                    localStorage.getItem("REFRESH_TOKEN"),
                  access_token:
                    sessionStorage.getItem("ACCESS_TOKEN"),
                  name: userInfo.name,
                  email: userInfo.email,
                  message: state.inputValue,
                  position: state.selectValue,
                }),
              }
            )
              .then((response) => response.json())
              .then((response) => {
                if (response.success === true) {
                  setState({
                    ...state,
                    inputValue: "",
                    alertHide: { display: "none" },
                  });
                  toggleBugModal();
                  message.success(
                    t(
                      "SideBar_Nous_corrigerons_ce_bug_aussitot_que_possible_Merci"
                    )
                  );
                }
              })
        }
      >
        <div>
          <br />
          <table className="pb-3 pt-2">
            <tbody>
              <tr>
                <td>{t("SideBar_Ou_avez_vous_eu_ce_bug")} ?</td>
              </tr>
              <tr>
                <td style={{ width: "100%" }}>
                  <select
                    placeholder="Choisir Un rapport"
                    style={{
                      width: "100%",
                      border: `solid 1px ${COLORS.glyphSecondary}`,
                      borderRadius: "5px",
                      padding: "10px 25px",
                    }}
                    onChange={(e) => handleSelectValueChange(e)}
                  >
                    <option value="Budget">{t("SideBar_Budget")}</option>
                    <option value="Compte">{t("SideBar_Comptes")}</option>
                    <option value="Transaction">
                      {t("SideBar_Transactions")}
                    </option>
                    <option value="Rapports">
                      {t("SideBar_Rapports")}
                    </option>
                    <option value="Profile">
                      {t("SideBar_Profile")}
                    </option>
                    <option value="Connexion">
                      {t("SideBar_Connexion")}
                    </option>
                    <option value="Inscription">
                      {t("SideBar_Inscription")}
                    </option>
                    <option value="Autres">{t("SideBar_Autres")}</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <hr />
                </td>
              </tr>
              <tr>
                <td>
                  {t("SideBar_Veuillez_decrire_ce_probleme_en_detail")}!
                </td>
              </tr>
              <tr>
                <td>
                  <TextArea
                    value={state.inputValue}
                    onChange={(e) => handleInputValueChange(e)}
                    rows={7}
                  ></TextArea>
                </td>
              </tr>
              <tr>
                <td>
                  <span
                    className="alert-danger"
                    style={state.alertHide}
                  >
                    {t("SideBar_Veuillez_decrire_le_probleme")}!
                  </span>
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <hr />
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </DefaultModal>

      <AddAccountModal
        needToAddAccountModal={needToAddAccountModal}
        setNeedToAddAccountModal={setNeedToAddAccountModal}
      />
    </div>
  );

  return (
    <>
      <div
        style={{
          background: `linear-gradient(0deg, ${COLORS.primary} 0%, ${COLORS.primary} 100%)`,
          minHeight: "100%",
          flex: 1,
          minWidth: "250px",
          maxWidth: "250px",
          width: "250px",
          paddingLeft: "10px",
          paddingRight: "10px"
        }}
      >
        {scrollBarInner()}
      </div>
    </>
  );
}

Sidebar.prototype = {
  authState: PropTypes.object.isRequired,
  GlobalState: PropTypes.object.isRequired,
  routes: PropTypes.array.isRequired,
  toggleSidenav: PropTypes.func.isRequired,
  logo: PropTypes.object.isRequired,
  sidenavOpen: PropTypes.bool,
  rtlActive: PropTypes.bool,
  openAddTransactionModal: PropTypes.func.isRequired,
  startSyncingAccounts: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authState: state.auth,
  GlobalState: state.GlobalState,
  accountState: state.account
});

export default connect(mapStateToProps, {
  openAddTransactionModal,
  startSyncingAccounts
})(Sidebar);
