const frTypes = [
  {
    typeId: 1,
    isOpen: false,
    typeName: 'Dépenses mensuelles',
    Groups: [
      {
        groupName: 'Maison',
        budget_group_order: 1,
        categories: [
          { name: 'Loyer/hypothèque', isSelected: true },
          { name: 'Fournitures de maison', isSelected: true },
          { name: 'Electricité', isSelected: true },
          { name: 'Eau', isSelected: true },
          { name: 'Téléphone', isSelected: true },
          { name: 'Internet', isSelected: true },
          { name: 'Maintenance', isSelected: true },
          { name: 'Entretien ménager', isSelected: true },
          { name: 'Lingerie & nettoyage à sec', isSelected: true },
          { name: 'Jardinage', isSelected: true },
          { name: 'Sécurité à domicile', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
      {
        groupName: 'Nouriture',
        budget_group_order: 2,
        categories: [
          { name: 'Courses', isSelected: true },
          { name: 'Restaurants', isSelected: true },
          { name: 'Fast food', isSelected: true },
          { name: 'Cafés', isSelected: true },
          { name: 'Glaces & yaourts glacés', isSelected: true },
          { name: 'Boulangerie', isSelected: true },
          { name: 'Service de restauration', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
      {
        groupName: 'Transport',
        budget_group_order: 3,
        categories: [
          { name: 'Transport en commun', isSelected: true },
          { name: 'Taxis et covoiturage', isSelected: true },
          { name: 'Déménagement et livraison', isSelected: true },
          { name: 'Parking', isSelected: true },
          { name: 'Carburant', isSelected: true },
          { name: 'Paiements du véhicule', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
      {
        groupName: 'Éducation',
        budget_group_order: 4,
        categories: [
          { name: 'École', isSelected: true },
          { name: 'Tutorat', isSelected: true },
          { name: 'Activités parascolaires', isSelected: true },
          { name: 'Livres & fournitures scolaires', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
      {
        groupName: 'Santé',
        budget_group_order: 5,
        categories: [
          { name: 'Medecin', isSelected: true },
          { name: 'Dentiste', isSelected: true },
          { name: 'Acupuncture & ventouses', isSelected: true },
          { name: 'Spa & massage', isSelected: true },
          { name: 'Gymnase et kinésithérapie', isSelected: true },
          { name: 'Vitamines & compléments alimentaires', isSelected: true },
          { name: 'Pharmacie', isSelected: true },
          { name: 'Analyses medicales', isSelected: true },
          { name: 'Santé mentale', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
      {
        groupName: 'Services',
        budget_group_order: 6,
        categories: [
          { name: 'Services bancaires', isSelected: true },
          { name: 'Services juridiques', isSelected: true },
          { name: 'Services d\'expédition', isSelected: true },
          { name: 'Services d\'impression', isSelected: true },
          { name: 'Services publicitaires', isSelected: true },
          { name: 'Operations', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
      {
        groupName: 'Divertissement',
        budget_group_order: 7,
        categories: [
          { name: 'Films & streaming', isSelected: true },
          { name: 'Musique', isSelected: true },
          { name: 'Arts', isSelected: true },
          { name: 'Loisirs', isSelected: true },
          { name: 'Activités récréatives', isSelected: true },
          { name: 'Journaux & magazines', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
    ],
  },
  {
    typeId: 2,
    isOpen: false,
    typeName: 'Provisions (pour dépenses périodique)',
    Groups: [
      {
        groupName: 'Conformité & sécurité',
        budget_group_order: 1,
        categories: [
          { name: 'Assurance habitation', isSelected: true },
          { name: 'Assurance automobile', isSelected: true },
          { name: 'Inspection et entretien du véhicule', isSelected: true },
          { name: 'Immatriculation du véhicule', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
      {
        groupName: 'Voyage',
        budget_group_order: 2,
        categories: [
          { name: 'Transit', isSelected: true },
          { name: 'Hébergement', isSelected: true },
          { name: 'Exploration', isSelected: true },
          { name: 'Amusement', isSelected: true },
          { name: 'Souvenirs et cadeaux', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
      {
        groupName: 'Shopping',
        budget_group_order: 3,
        categories: [
          { name: 'Articles de maison', isSelected: true },
          { name: 'Vêtements', isSelected: true },
          { name: 'Appareils électroniques', isSelected: true },
          { name: 'Jeux & jouets', isSelected: true },
          { name: 'Équipement sportif', isSelected: true },
          { name: 'Beauté & hygiène', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
      {
        groupName: 'Dons',
        budget_group_order: 4,
        categories: [
          { name: 'Cadeaux', isSelected: true },
          { name: 'Charité', isSelected: true },
          { name: 'Dons religieux', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
    ],
  },
  {
    typeId: 3,
    isOpen: false,
    typeName: 'Épargne (pour les dépenses à long terme)"',
    Groups: [
      {
        groupName: 'Personnelle',
        budget_group_order: 1,
        categories: [
          { name: 'Fonds de retraite', isSelected: true },
          { name: 'Fonds d\'investissement', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
      {
        groupName: 'Famille',
        budget_group_order: 2,
        categories: [
          { name: 'Fonds d\'urgence', isSelected: true },
          { name: 'Fonds médical', isSelected: true },
          { name: 'Éducation universitaire', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
      {
        groupName: 'Gros achats',
        budget_group_order: 3,
        categories: [
          { name: 'Maison', isSelected: true },
          { name: 'Maison de vacances', isSelected: true },
          { name: 'Picine', isSelected: true },
          { name: 'Bijoux', isSelected: true },
          { name: 'Voiture', isSelected: true },
          { name: 'Moto', isSelected: true },
          { name: 'Bateau', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
    ],
  },
];

const enTypes = [
  {
    typeId: 1,
    isOpen: false,
    typeName: 'Monthly expenses',
    Groups: [
      {
        groupName: 'House',
        budget_group_order: 1,
        categories: [
          { name: 'Rent/mortgage', isSelected: true },
          { name: 'Home supplies', isSelected: true },
          { name: 'Electricity', isSelected: true },
          { name: 'Water', isSelected: true },
          { name: 'Phone', isSelected: true },
          { name: 'Internet', isSelected: true },
          { name: 'Home maintenance', isSelected: true },
          { name: 'Housekeeping', isSelected: true },
          { name: 'Laundry & dry cleaning', isSelected: true },
          { name: 'Lawn & gardening', isSelected: true },
          { name: 'Home security', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
      {
        groupName: 'Food',
        budget_group_order: 2,
        categories: [
          { name: 'Groceries', isSelected: true },
          { name: 'Restaurants', isSelected: true },
          { name: 'Fast food', isSelected: true },
          { name: 'Coffeeshops', isSelected: true },
          { name: 'Icecream & froyos', isSelected: true },
          { name: 'Bakery', isSelected: true },
          { name: 'Catering', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
      {
        groupName: 'Transportation',
        budget_group_order: 3,
        categories: [
          { name: 'Public transport', isSelected: true },
          { name: 'Taxi & rideshare', isSelected: true },
          { name: 'Moving & delivery', isSelected: true },
          { name: 'Parking', isSelected: true },
          { name: 'Fuel', isSelected: true },
          { name: 'Vehicle payments', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
      {
        groupName: 'Education',
        budget_group_order: 4,
        categories: [
          { name: 'School', isSelected: true },
          { name: 'Tutoring', isSelected: true },
          { name: 'Extracurricular activities', isSelected: true },
          { name: 'Books & school supplies', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
      {
        groupName: 'Health',
        budget_group_order: 5,
        categories: [
          { name: 'Doctor', isSelected: true },
          { name: 'Dentist', isSelected: true },
          { name: 'Acupuncture & cupping', isSelected: true },
          { name: 'Spa & massage', isSelected: true },
          { name: 'Gym & physical therapy', isSelected: true },
          { name: 'Vitamines & supplements', isSelected: true },
          { name: 'Pharmacy', isSelected: true },
          { name: 'Lab & clinical tests', isSelected: true },
          { name: 'Mental health', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
      {
        groupName: 'Business services',
        budget_group_order: 6,
        categories: [
          { name: 'Banking', isSelected: true },
          { name: 'Legal', isSelected: true },
          { name: 'Shipping', isSelected: true },
          { name: 'Printing', isSelected: true },
          { name: 'Advertising', isSelected: true },
          { name: 'Opérations', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
      {
        groupName: 'Entertainment',
        budget_group_order: 7,
        categories: [
          { name: 'Movies & streaming', isSelected: true },
          { name: 'Music', isSelected: true },
          { name: 'Arts', isSelected: true },
          { name: 'Hobbies', isSelected: true },
          { name: 'Fun activities', isSelected: true },
          { name: 'Newspaper & magazines', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
    ],
  },
  {
    typeId: 2,
    isOpen: false,
    typeName: 'Provisions (for periodic expenses)',
    Groups: [
      {
        groupName: 'Compliance & safety',
        budget_group_order: 1,
        categories: [
          { name: 'Home insurance', isSelected: true },
          { name: 'Vehicle insurance', isSelected: true },
          { name: 'Vehicle inspection & maintenance', isSelected: true },
          { name: 'Vehicle registration', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
      {
        groupName: 'Travel',
        budget_group_order: 2,
        categories: [
          { name: 'Transit', isSelected: true },
          { name: 'Lodging', isSelected: true },
          { name: 'Exploration', isSelected: true },
          { name: 'Amusement', isSelected: true },
          { name: 'Souvenirs & gifts', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
      {
        groupName: 'Shopping',
        budget_group_order: 3,
        categories: [
          { name: 'House items', isSelected: true },
          { name: 'Clothing', isSelected: true },
          { name: 'Electronics', isSelected: true },
          { name: 'Games & toys', isSelected: true },
          { name: 'Sport supplies', isSelected: true },
          { name: 'Beauty & hygiene', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
      {
        groupName: 'Donations',
        budget_group_order: 4,
        categories: [
          { name: 'Gifts', isSelected: true },
          { name: 'Charity', isSelected: true },
          { name: 'Religious giving', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
    ],
  },
  {
    typeId: 3,
    isOpen: false,
    typeName: 'Savings (for long-term expenses)',
    Groups: [
      {
        groupName: 'Personal',
        budget_group_order: 1,
        categories: [
          { name: 'Retirement fund', isSelected: true },
          { name: 'Investment fund', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
      {
        groupName: 'Family',
        budget_group_order: 2,
        categories: [
          { name: 'Emergency fund', isSelected: true },
          { name: 'Medical fund', isSelected: true },
          { name: 'College education', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
      {
        groupName: 'Large purchases',
        budget_group_order: 3,
        categories: [
          { name: 'House', isSelected: true },
          { name: 'Vacation home', isSelected: true },
          { name: 'Swimming pool', isSelected: true },
          { name: 'Jewelry', isSelected: true },
          { name: 'Car', isSelected: true },
          { name: 'Motorcycle', isSelected: true },
          { name: 'Boat', isSelected: true },
          { name: 'Add New Category', isSelected: false },
        ],
      },
    ]
  },
];

module.exports = {
  frTypes,
  enTypes,
}
