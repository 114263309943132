const adjustMoneyFormatForApi = (amount) => {
  if (amount === "") amount = 0;
  return amount.toString().replace(",", ".");
};

const adjustMoneyFormatForView = (amount) => {
  if (amount === "") amount = 0;
  return amount.toString().replace(".", ",");
};

const adjustCurrencyFormat = (amount = 0, code = "EUR", locales = "fr-FR") => {
  return new Intl.NumberFormat(locales, {
    style: "currency",
    currency: code,
  }).format(amount)
};

const getDefaultCurrency = (currencies) => {
  if (currencies.includes("eur")) return "eur";
  if (currencies.includes("gbp")) return "gbp";
  if (currencies.includes("usd")) return "usd";
  return currencies[0];
}
const moenyHelpers = {
  adjustMoneyFormatForApi,
  adjustMoneyFormatForView,
  adjustCurrencyFormat,
  getDefaultCurrency
}
export default moenyHelpers;