import Profile from "./views/ProfileSettings.js";
import Login from "./views/Login.js";
import Register from "./views/Register.js";
import Accounts from "./views/Accounts.jsx";
import Budget from "./views/Budget.js";
import Transactions from "./views/Transactions.js";
import Rapports from "./views/Rapports.js";
import EmailVerification from "./views/EmailVerification.js";
import DeleteVerification from "./views/DeleteUserAccount";
import Confirm from "./views/ConfirmEmail";
import Forgetpassword from "./views/ForgotPassword";
import passwordrecovery from "./views/PasswordRecovery.js";
import Dashboard from "./views/Dashboard.js";
import Billing from "./views/Billing.jsx";
import Payment from "./views/Payment.jsx";
import AuthPayment from "./views/Auth-Payment.jsx";
import PasswordVerification from "./views/PasswordVerification";
import RedirectUser from "./views/RedirectUser.js";
// Icons:
import Icons from "./components/Randoms/Icons";
const { SavingBank, MoneyWallet, DashboardIcon, TransactionIcon, ReportIcon, AddIcon, CurrencyIcon, SettingsIcon, AccountingBills } = Icons;

let routes = [
  {
    collapse: false,
    path: "/dashboard",
    icon: DashboardIcon,
    name: "SideBar_Tableau_de_bord",
    component: Dashboard,
    layout: "/app",
  },
  {
    collapse: false,
    path: "/budget",
    name: "SideBar_Budget",
    icon: MoneyWallet,
    component: Budget,
    layout: "/app",
  },
  {
    collapse: false,
    path: "/transactions",
    name: "SideBar_Transactions",
    icon: TransactionIcon,
    component: Transactions,
    layout: "/app",
  },
  {
    collapse: false,
    path: "/comptes",
    name: "SideBar_Comptes",
    icon: SavingBank,
    component: Accounts,
    layout: "/app",
  },
  {
    collapse: false,
    path: "/rapports",
    name: "SideBar_Rapports",
    icon: ReportIcon,
    component: Rapports,
    layout: "/app",
  },
  {
    collapse: false,
    path: "/confirmationdelete",
    name: "ConfirmDel",
    component: DeleteVerification,
    layout: "/auth",
  },
  {
    collapse: false,
    path: "/confirmationemail/:id",
    name: "Confirm",
    component: Confirm,
    layout: "/auth",
  },
  {
    collapse: false,
    path: "/redirect",
    name: "RedirectUser",
    component: RedirectUser,
    layout: "/auth",
  },
  {
    collapse: false,
    path: "/payment",
    name: "AuthPayment",
    component: AuthPayment,
    layout: "/auth",
  },
  {
    collapse: false,
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/auth",
  },
  {
    collapse: false,
    path: "/signup",
    name: "Register",
    miniName: "R",
    component: Register,
    layout: "/reg",
  },
  {
    collapse: false,
    path: "/EmailVerification",
    name: "EmailVerification ",
    component: EmailVerification,
    layout: "/reg",
  },
  {
    collapse: false,
    path: "/forgotpassword",
    name: "forgotpassword",
    component: Forgetpassword,
    layout: "/reg",
  },
  {
    collapse: false,
    path: "/passwordrecovery/:token",
    name: "Passrecovery",
    component: passwordrecovery,
    layout: "/reg",
  },
  {
    collapse: false,
    path: "/passwordrecovery",
    name: "PasswordVerification",
    component: PasswordVerification,
    layout: "/reg",
  },
];

// To hide the features: localStorage.setItem("showHiddenFeatures", "false");
// To show the features: localStorage.setItem("showHiddenFeatures", "true");

// if (localStorage.getItem("showHiddenFeatures") == "true")
routes = [
  ...routes,
  {
    collapse: false,
    path: "/billing",
    name: "Billing",
    icon: AccountingBills,
    component: Billing,
    layout: "/app",
  },
  {
    collapse: true,
    name: "Multi Level 2",
    miniName: "M - 2",
    state: "multiCollapse",
    views: [
      {
        collapse: false,
        path: "/payment",
        name: "Payment",
        component: Payment,
        layout: "/app",
      },
    ],
  },
  {
    collapse: false,
    path: "/profile",
    name: "global.setting.s",
    icon: SettingsIcon,
    component: Profile,
    layout: "/app",
  },
]

export default routes;
