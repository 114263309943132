import { useEffect, useState } from "react";
import { Input } from "reactstrap";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import DefaultModal from "./DefaultModal";
import ReactDatetime from "react-datetime";
import FormatCurrency from "../Tools/Currency/Currency";
import moment from "moment";
import formatDate from "helpers/formatDate";
import { COLORS } from "assets/constants/theme";

export default function ImportLinkedAccountsModal({
  listOfAccounts,
  importTheSelectedAccountsFromNordigen,
  showNordigenAccounts,
  setShowNordigenAccounts,
  setNewNameToImportAccountsNordigen,
  setSelectedToImportAccountIdsNordigen,
  user
}) {
  const { t } = useTranslation();
  const [showTakeAttenionForNordigenModal, setShowTakeAttenionForNordigenModal] = useState(false);
  const [data, setData] = useState(listOfAccounts);
  const [importLoading, setImportLoading] = useState(false);

  useEffect(() => setData(listOfAccounts), [])
  return (
    <>
      <DefaultModal
        isOpen={showNordigenAccounts}
        toggle={() => {
          localStorage.removeItem("ref");
          setShowNordigenAccounts(!showNordigenAccounts);
        }}
        size={"modal-lg"}
        bodyStyle={{
          alignItems: "start",
          alignContent: "start",
        }}
        height="40vh"
        title="Account_Importer_mes_comptes"
        btnText="Link Selected Accounts"
        btnStyle={importLoading ? {
          border: "none",
          cursor: "not-allowed",
          width: "40%"
        } : {
          width: "40%"
        }}
        disabled={importLoading}
        onClick={async () => {
          localStorage.removeItem("ref");
          setImportLoading(true);
          await importTheSelectedAccountsFromNordigen(listOfAccounts, localStorage.getItem("aspsp_id"), message, t, (status) => {
            setImportLoading(false);
            if (status === false) message.error(t("Error"));
            setShowNordigenAccounts(!showNordigenAccounts);
          });
          setTimeout(() => { setShowNordigenAccounts(!showNordigenAccounts) }, 20000);
        }}
      >
        <div className="modal-body">
          <table className="table">
            <thead>
              <tr style={{ backgroundColor: COLORS.emptiness, borderBottom: `1px solid ${COLORS.glyphSecondary}` }}>
                {["Account_comptes", "Balances", "Account_Starting_Date", "Edit"].map((label, i) =>
                (<th key={i} style={{ width: "25%", padding: "17px" }}>
                  <span style={{ fontSize: "12px", textAlign: "start", display: "flex", alignItems: "center", width: "1px" }}>
                    {i === 0 && <input disabled={true} type="checkbox" className="mr-2" />}
                    {String(t(label)).toUpperCase()} {i === 2 && (<span className="ml-1"><i className="fas fa-info-circle fa-1x ml-2 text-default"
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowTakeAttenionForNordigenModal(true)} ></i></span>)}
                  </span>
                </th>)
                )}
              </tr>
            </thead>
            <tbody>
              {listOfAccounts.map((element, index) => (
                <tr key={Math.random()} style={{
                  textAlign: "start",
                  alignContent: "center"
                }}>
                  <td style={{ fontSize: "12px", textAlign: "start", padding: "16px", width: "30%" }}>
                    <span style={{
                      display: "flex", alignItems: "center",
                    }}>
                      <input
                        disabled={element.alredyImported}
                        checked={element.alredyImported || element.selected}
                        type="checkbox"
                        id={`select_account_name${element.account.resourceId}`}
                        defaultChecked={element.alredyImported}
                        onChange={(e) => setSelectedToImportAccountIdsNordigen(element.account.resourceId, e.target.checked)}
                      />
                      <span className="ml-2" id={`account_name${element.account.resourceId}`}>{element.account.name}</span>
                      <Input
                        type="text"
                        className="ml-2"
                        style={{ display: "none", width: "100%", height: "25px" }}
                        defaultValue={element.account.name}
                        id={`account_name_input${element.account.resourceId}`}
                        onChange={(event) => {
                          if (event.target.value !== "") {
                            listOfAccounts.forEach((item, index) => {
                              if (item.account.resourceId === element.account.resourceId) {
                                listOfAccounts[index].newName = event.target.value;
                              } else {
                                listOfAccounts[index].newName = listOfAccounts[index].account.name;
                              }
                            })
                          }
                        }
                        }
                      />
                    </span>
                  </td>
                  <td style={{ fontSize: "13px", width: "30%", padding: "16px" }}>
                    {FormatCurrency(element.balances[0].balanceAmount.amount, user.currency_code, user.currency_locales)}
                  </td>
                  <td style={{ fontSize: "12px", width: "30%", padding: "16px" }}>
                    <span id={`account_satring_date${element.account.resourceId}`}>{formatDate(element.account.starting_date)}</span>
                    <ReactDatetime
                      initialValue={moment(moment(element.account.starting_date).format("DD/MM/YYYY"))}
                      onChange={async (date) => {
                        listOfAccounts.forEach((item, index) => {
                          if (item.account.resourceId === element.account.resourceId) {
                            listOfAccounts[index].newDate = moment(date).format("DD/MM/YYYY");
                          } else {
                            listOfAccounts[index].newDate = listOfAccounts[index].account.starting_date;
                          }
                        })
                      }}
                      inputProps={{ placeholder: t("Select a date"), style: { display: "none", height: "25px", width: "100%" }, id: `input_account_satring_date${element.account.resourceId}` }}
                      timeFormat={false}
                      dateFormat="YYYY/MM/DD"
                    />
                  </td>
                  <td style={{ fontSize: "12px", cursor: "pointer", width: "10%", padding: "16px" }}>
                    {[
                      { key: 0, name: "ml-2 fas fa-edit", id: `edit_account${element.account.resourceId}` },
                      { key: 1, name: "ml-2 fas fa-check text-default", id: `edit_account_save${element.account.resourceId}` },
                      { key: 2, name: "ml-3 fas fa-times", id: `edit_account_cancel${element.account.resourceId}` }
                    ].map(({ key, name, id }) => (
                      <i className={name} id={id} style={{ display: key === 0 ? "inline" : "none", fontSize: "15px" }} key={key}
                        onClick={() => {
                          if (element.alredyImported) return false;
                          document.getElementById(`account_name${element.account.resourceId}`).style.display = key == 0 ? "none" : "inline";
                          document.getElementById(`account_name_input${element.account.resourceId}`).style.display = key == 0 ? "inline" : "none";
                          document.getElementById(`account_satring_date${element.account.resourceId}`).style.display = key == 0 ? "none" : "inline";
                          document.getElementById(`input_account_satring_date${element.account.resourceId}`).style.display = key == 0 ? "inline" : "none";
                          document.getElementById(`edit_account${element.account.resourceId}`).style.display = key == 0 ? "none" : "inline";
                          document.getElementById(`edit_account_save${element.account.resourceId}`).style.display = key == 0 ? "inline" : "none";
                          document.getElementById(`edit_account_cancel${element.account.resourceId}`).style.display = key == 0 ? "inline" : "none";
                          if (key === 1) {
                            listOfAccounts.forEach(async (item) => {
                              await setNewNameToImportAccountsNordigen(item.account.resourceId, item?.newName);
                              await setNewNameToImportAccountsNordigen(item.account.resourceId, item?.newDate, true);
                            })
                          }
                        }}></i>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </DefaultModal>
      <DefaultModal
        title={t("Transaction_Fais_attention")}
        btnText={t("Account_Continuer")}
        isOpen={showTakeAttenionForNordigenModal}
        onClick={() => setShowTakeAttenionForNordigenModal(false)}
        toggle={() => setShowTakeAttenionForNordigenModal(false)}
      >
        {t("Account_Imported_Nordigen")}
      </DefaultModal>
    </>
  )
}
