import React, { useState, useEffect } from "react";
import {Row, Col} from "reactstrap";
import DefaultModal from "components/Modals/DefaultModal";
import { COLORS } from "assets/constants/theme";
import { StringManipulator } from "@fineo-io/main-javascript-library";
import { useTranslation } from "react-i18next";

export default function DeleteAccount({
  handleDelete,
  totalNumberOfTransactions,
  state,
  targetAccount,
  showConfirmDeletenAccountModal,
  setShowConfirmDeletenAccountModal,
  changeDetailViewMode
}) {
  const { t } = useTranslation();
  const [showConfrimPopUp, setShowConfrimPopUp] = useState(false);
  const [keepTransactions, setKeepTransactions] = useState(true);

  useEffect(() => {
    if (showConfirmDeletenAccountModal === true && totalNumberOfTransactions === 1) {
      setShowConfrimPopUp(true);
    }
  }, [showConfirmDeletenAccountModal])
  return (
    <>
      <DefaultModal
        size={"modal-lg"}
        hasTwoButtons={true}
        color={COLORS.danger}
        isOpen={showConfirmDeletenAccountModal && totalNumberOfTransactions !== 1}
        toggle={async () => {
          setShowConfirmDeletenAccountModal(!showConfirmDeletenAccountModal)
        }}
        title={StringManipulator.titleize(t("global.account.deletion.option.s"))}
        btnText={t("Delete")}
        onClick={() => {
          setShowConfirmDeletenAccountModal(false);
          setShowConfrimPopUp(true);
        }}
        secondBtnText={StringManipulator.capitalize(t("global.cancel._"))}
        secondOnClick={() => setShowConfirmDeletenAccountModal(!showConfirmDeletenAccountModal)}
      >
        <Row>
          <Col>
            <p style={{
              fontSize: "15px"
            }}>
              {StringManipulator.capitalize(t('global.n.transactions.associated.with.account', { count: totalNumberOfTransactions }))}.&nbsp;
              {StringManipulator.capitalize(t("prompts.please.choose.option"))}:
            </p>
            <h3 className="d-flex text-center flex-row"
              style={{
                fontWeight: "300",
                marginTop: "2px",
                textAlign: "left",
                justifyContent: "left"
              }}>
              <div onChange={(e) => {
                if (e.target.value === 1) {
                  setKeepTransactions(true);
                }
                setKeepTransactions(false);
              }}>
                <div style={{ textAlign: "left", justifyContent: "left" }}>
                  <div style={{ marginBottom: "3px" }}>
                    <input type="radio" defaultChecked={true} value={1} name="action" /> {t("Delete the account and keep the associated transactions")}
                  </div>
                  <div>
                    <input type="radio" value={0} name="action" /> {t("Delete the account along with all associated transactions")}
                  </div>
                </div>
              </div>
            </h3>
          </Col>
        </Row>
      </DefaultModal>
      <DefaultModal
        title={t("global.warning._")}
        hasTwoButtons={true}
        isOpen={showConfrimPopUp}
        color={COLORS.danger}
        btnText={t("Account_Oui,_supprimer_ce_compte")}
        toggle={() => {
          setShowConfrimPopUp(!showConfrimPopUp)
        }}
        onClick={() => {
          handleDelete({ ...state, id: targetAccount.accountId, keepTransactions: keepTransactions });
          changeDetailViewMode();
          setShowConfrimPopUp(false);
        }}
        secondBtnText={StringManipulator.capitalize(t("global.cancel._"))}
        secondOnClick={() => {
          setShowConfrimPopUp(false);
          setShowConfirmDeletenAccountModal(false);
        }}
      >
        {t("Account_Est_ce_que_vous_êtes_sûr_de_vouloir_supprimer_ce_compte")}
        <br />
        <br />
        {keepTransactions && t("After deleting this account, all of its remaining transactions will be labeled as 'account to be defined'.")}
      </DefaultModal>
    </>
  );
}
