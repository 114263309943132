import React from "react";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import { useTranslation } from "react-i18next";

export default function CategoryInfo({ showCategoryeInfo, setShowCategoryInfo }) {
  const { t } = useTranslation();

  return (
    <Popover
      placement="right-start"
      isOpen={showCategoryeInfo}
      target="showCategoryInfo"
      toggle={() => setShowCategoryInfo(false)}
    >
      <PopoverHeader className="bg-white d-flex justify-content-between">
        <span>
          {t("The Category field")}
        </span>
        <span>
          <i className="fas fa-times" style={{ cursor: "pointer" }} onClick={() => setShowCategoryInfo(false)}></i>
        </span>
      </PopoverHeader>
      <PopoverBody className="bg-white">
        {t("Category Info 1")}.
        <br/><br/>
        {t("Category Info 2")}.
      </PopoverBody>
    </Popover>
  );
}
