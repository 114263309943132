import React from "react";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import { useTranslation } from "react-i18next";

export default function AccountInfo({ showAccountInfo, setShowAccountInfo }) {
  const { t } = useTranslation();

  return (
    <Popover
      placement="right-start"
      isOpen={showAccountInfo}
      target="showAccountInfo"
      toggle={() => setShowAccountInfo(false)}
    >
      <PopoverHeader className="bg-white d-flex justify-content-between">
        <span>
          {t("The Account field")}
        </span>
        <span>
          <i className="fas fa-times" style={{ cursor: "pointer" }} onClick={() => setShowAccountInfo(false)}></i>
        </span>
      </PopoverHeader>
      <PopoverBody className="bg-white">
        {t("Account Info 1")}.
        <br/><br/>
        {t("Account Info 2")}.
      </PopoverBody>
    </Popover>
  );
}
