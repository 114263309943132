import moneyBankWhite from '../../assets/2x/AccountTypes/money-bank@2x-white.png';
import moneyBank from '../../assets/2x/AccountTypes/money-bank@2x.png';
import moneyCashCoinsStackWhite from '../../assets/2x/AccountTypes/money-cash-coins-stack@2x-white.png';
import moneyCashCoinsStack from '../../assets/2x/AccountTypes/money-cash-coins-stack@2x.png';
import moneySafeVaultWhite from '../../assets/2x/AccountTypes/money-safe-vault@2x-white.png';
import moneySafeVault from '../../assets/2x/AccountTypes/money-safe-vault@2x.png';
import moneyWalletWhite from '../../assets/2x/AccountTypes/money-wallet-2@2x-white.png';
import moneyWallet from '../../assets/2x/AccountTypes/money-wallet-2@2x.png';

import moneyBankBig from '../../assets/2x/AccountTypes/money-bank@4x.png';
import moneyCashCoinsStackBig from '../../assets/2x/AccountTypes/money-cash-coins-stack@4x.png';
import moneySafeVaultBig from '../../assets/2x/AccountTypes/money-safe-vault@4x.png';
import moneyWalletBig from '../../assets/2x/AccountTypes/money-wallet-2@4x.png';


const AccountIcons = {
  moneyBank,
  moneyBankWhite,
  moneyCashCoinsStack,
  moneyCashCoinsStackWhite,
  moneySafeVault,
  moneySafeVaultWhite,
  moneyWallet,
  moneyWalletWhite,
  moneyBankBig,
  moneyCashCoinsStackBig, 
  moneySafeVaultBig,
  moneyWalletBig
}

export default AccountIcons;