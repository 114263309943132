import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Row, Col, Modal, Button, Card, CardBody, Collapse, Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import WidgetNewAccount from "./WidgetNewAccount";
import { getCurrency, updateCurrency, insertTheUserSelectedCategories } from "../../actions/Profile";
import { getUserAccountInfo, setFirstLoginState } from "../../actions/Auth";
import { addNewAccount, getAccountTypes, getAccounts, setAddOrImportAccountMoadl } from "../../actions/Account";
import { runOnBoardingBudget, getBudget } from "../../actions/Budget";
import { getAccessToken } from "../../actions/Auth";
import Logo from "../../assets/img/brand/fineo-logo-old-2.png";
import Image1 from "../../assets/img/FirstLogin/translate-2@4x.png";
import Image2 from "../../assets/img/FirstLogin/safe-3@4x.png";
import Image3 from "../../assets/img/FirstLogin/milestone-4@4x.png";
import Image4 from "../../assets/img/FirstLogin/in-app-purchase-2@4x.png";
import Image5 from "../../assets/img/FirstLogin/home-finances-3@4x.png";
import Image6 from "../../assets/img/FirstLogin/financial-literacy-3@4x.png";
import Image7 from "../../assets/img/FirstLogin/financial-literacy-1@4x.png";
import Currecny from "../../assets/img/FirstLogin/currencies-1@4x.png";
import Cryptocurrency from "../../assets/img/FirstLogin/cryptocurrency-wallet-4@4x.png";
import Searching from "../../assets/img/FirstLogin/searching-5@4x.png";
import { frTypes, enTypes } from "../Randoms/BudgetAssets";
import StandardButton from "components/Buttons/standardButton/StandardButton";
import DefaultModal from "components/Modals/DefaultModal";
import { COLORS } from "assets/constants/theme";
import { StringManipulator } from "@fineo-io/main-javascript-library";
import DefaultSelect from "components/inputs/DefaultSelect";
import moment from "moment";

const { REACT_APP_SERVER_URL } = process.env;
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
function FirstUserLogin({
  authState,
  profilState,
  accountState,
  updateCurrency,
  getUserAccountInfo,
  addNewAccount,
  runOnBoardingBudget,
  getAccounts,
  setAddOrImportAccountMoadl,
  getAccountTypes,
  getBudget,
  getCurrency,
  insertTheUserSelectedCategories,
  setFirstLoginState
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const param = useQuery();
  // const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(authState.user.first_login);
  const [chooseCurrencyModal, setChooseCurrencyModal] = useState(false);
  const [openAddAccountModal, setOpenAddAccountModal] = useState(false);
  const [showAddAcountModal, setShowAddAcountModal] = useState(false);
  const [showAddBudgetModal, setShowAddBudgetModal] = useState(false)
  const [showStartBudgetModal, setShowStartBudgetModal] = useState(false)
  const [selectedStep, setSelectedStep] = useState(0);
  const [steps, setSteps] = useState([
    {
      Name: "First_Login_Welcome",
      text1: "Bienvenue dans Fineo",
      text2: "Votre assistant de finances personnelles",
      text3: "Prenant ensemble un peu de temps",
      text4: "Start",
      text5: "",
      Image: Image1
    },
    {
      Name: "Recenser Vos ressources",
      text1: "Recenser Vos ressources",
      text2: "Au dernier centimes prêts",
      text3: "firstLogin_vous_pouvez_lier",
      text4: "Next",
      text5: "Previous",
      Image: Image2
    },
    {
      Name: "Etablir un budget",
      text1: "Etablir un budget",
      text2: "pour categoriser vos depenses",
      text3: "firstLogin_vous_pouvez_ajouter",
      text4: "Next",
      text5: "Previous",
      Image: Image5
    },
    {
      Name: "Affecter vos ressources",
      text1: "Affecter vos ressources",
      text2: "et construisez votre budget mensuel",
      text3: "firstLogin_vous_pouvez_affecter",
      text4: "Next",
      text5: "Previous",
      Image: Image7
    },
    {
      Name: "Suivre ses dépenses",
      text1: "Suivre ses dépenses",
      text2: "en catégorisant vos transactions",
      text3: "firstLogin_vous_pouvez4",
      text4: "Next",
      text5: "Previous",
      Image: Image4
    },
    {
      Name: "Réviser votre budget et réaffecter vos revenus",
      text1: "Réviser votre budget et réaffecter vos revenus",
      text2: "",
      text3: "firstLogin_vous_pouvez5",
      text4: "Next",
      text5: "Previous",
      Image: Image3
    },
    {
      Name: "Prêt pour reprendre le contrôle de vos finances?",
      text1: "Prêt pour reprendre le contrôle de vos finances?",
      text2: "",
      text3: "",
      text4: "Start Now",
      text5: "Previous",
      Image: Image6
    }
  ]);

  const [selectedCategories, setSelectedCategories] = useState([-1]);
  const [selecteDataCategories, setSelecteDataCategories] = useState([]);
  const [isOnEditeCat, setIsOnEditeCat] = useState("");
  const [collapsse, setCollpasse] = useState([]);
  const [showDoneModal, setShowDoneModal] = useState(false);

  useEffect(() => {
    if (t("Transaction_lang") === "en") {
      setSelecteDataCategories(enTypes)
    } else {
      setSelecteDataCategories(frTypes)
    }
  }, []);

  useEffect(() => {
    const data = [];
    const data1 = [];
    if (t("Transaction_lang") === "en") {
      enTypes.forEach((type) => {
        data.push({
          typeId: type.typeId,
          isOpen: type.isOpen,
        });
        type.Groups.forEach((group) => {
          group.categories.forEach((cate) => {
            data1.push(cate)
          })
        })
      });
    } else {
      frTypes.forEach((type) => {
        data.push({
          typeId: type.typeId,
          isOpen: type.isOpen,
        });
        type.Groups.forEach((group) => {
          group.categories.forEach((cate) => {
            data1.push(cate)
          })
        })
      });
    }

    setCollpasse(data)
    setSelectedCategories(data1)
  }, []);

  useEffect(() => {
    if (authState.user.first_login) {
      if (param.get("ref") != null && localStorage.getItem("ref") != null) {
        setShowWelcomeModal(false)
      } else {
        if (accountState.accounts.filter((acc) => acc.is_it_imported).length !== 0) {
          setShowWelcomeModal(false)
          setOpenAddAccountModal(true);
          localStorage.removeItem('initiate')
        }
      }
    }
  }, [accountState.accounts.length, authState.user.first_login]);

  useEffect(() => {
    if (localStorage.getItem("initiate")) {
      setShowWelcomeModal(false)
    } else if (authState.user.first_login) {
      setShowWelcomeModal(true)
    }
  }, [authState.user.first_login, accountState.add_or_import_account_modal]);

  const totalSoldeAccounts = () => {
    let total = 0;
    accountState.accounts.forEach((account) => {
      total = total + account.solde;
    })
    return total;
  }

  const handleSuccessfulConfiguration = async () => {
    setShowDoneModal(false);
    await fetch(
      `https://${REACT_APP_SERVER_URL}/Completusersppsetup`,
      {
        method: "post",
        headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
        body: JSON.stringify({
          refresh_token: localStorage.getItem("REFRESH_TOKEN"),
          access_token: sessionStorage.getItem("ACCESS_TOKEN"),
        }),
        credentials: "include",
      }
    )
      .then((response) => response.json())
      .then(async (response) => {
        setShowDoneModal(false);
      });
  }

  return <>
    <Modal
      className="modal-dialog-start mt--50 w-auto"
      isOpen={showWelcomeModal}
      centered
      backdrop={"static"}
      toggle={async () => {
        setShowWelcomeModal(false);
      }}
    >
      <div className="modal-body">
        <Row>
          <Col sm="12" xs="12" md="12" lg="12" xl="12" className="d-flex justify-content-center">
            <img alt="..."
              width="120px"
              height="50px"
              src={Logo}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="12" xs="12" md="12" lg="12" xl="12" className="d-flex justify-content-center">
            <img alt="..."
              width="300px"
              height="310px"
              src={steps[selectedStep].Image}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="12" xs="12" md="12" lg="12" xl="12" className="d-flex justify-content-center mt-1">
            <h2 className="text-center"
              style={{
                fontWeight: "bold",
                boxSizing: "border-box"
              }}>
              <span>{t(steps[selectedStep].text1)}</span>
            </h2>
          </Col>
          <Col sm="12" xs="12" md="12" lg="12" xl="12" className="d-flex justify-content-center mt-1">
            <h2 className="text-center">
              {t(steps[selectedStep].text2)}
            </h2>
          </Col>
          <Col sm="12" xs="12" md="12" lg="12" xl="12" className="d-flex justify-content-center mt-1">
            <h3 className="text-center">
              {t(steps[selectedStep].text3)}
            </h3>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col sm="6" xs="6" md="6" lg="6" xl="6" className="d-flex justify-content-start">
            {
              steps[selectedStep].text4 !== "Start" && (
                <StandardButton
                  onClick={() => {
                    setSelectedStep((step) => step - 1 !== -1 ? step - 1 : 0)
                  }} style={{
                    width: "140px"
                  }}
                  text={t(steps[selectedStep].text5)}
                />
              )
            }
          </Col>
          <Col sm="6" xs="6" md="6" lg="6" xl="6" className="d-flex justify-content-end">
            <StandardButton
              onClick={async () => {
                if (steps[selectedStep].Name === "Prêt pour reprendre le contrôle de vos finances?") {
                  history.push("/app/comptes")
                  setShowWelcomeModal(false);
                  getCurrency();
                  setChooseCurrencyModal(true);
                } else {
                  setSelectedStep((step) => step + 1);
                }
              }} style={{
                width: "150px"
              }}
              text={t(steps[selectedStep].text4)}
            />
          </Col>
        </Row>
      </div>
    </Modal>
    {/* ================================================================================================= */}
    <DefaultModal
      style={{ paddingTop: "100px" }}
      hasNoButtons={true}
      title={openAddAccountModal ? t("Import Your Accounts") : StringManipulator.titleize(t("global.currency.selection"))}
      isOpen={openAddAccountModal === true || chooseCurrencyModal === true}
      btnText={t(steps[selectedStep].text4)}
      onClick={async () => {
        if (steps[selectedStep].Name === "Prêt pour reprendre le contrôle de vos finances?") {
          history.push("/app/comptes")
          setShowWelcomeModal(false);
          getCurrency();
          setChooseCurrencyModal(true);
        } else {
          setSelectedStep((step) => step + 1);
        }
      }}
      toggle={async () => {
        setOpenAddAccountModal(false);
        setChooseCurrencyModal(false);
      }}
    >
      {
        chooseCurrencyModal === true && (
          <div className="modal-body">
            <Row>
              <Col sm="12" xs="12" md="12" lg="12" xl="12" className="d-flex justify-content-center">
                <img alt="..."
                  width="280px"
                  height="270px"
                  src={Currecny}
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" xs="12" md="12" lg="12" xl="12">
                {authState.user.currency_code && (<h2>
                  {t("First_Login_The_default_currency_of_the_app_is")}
                  <span className="h1 ml-3">
                    {authState.user.currency_code}
                  </span>
                </h2>)}
              </Col>
              <Col sm="12" xs="12" md="12" lg="12" xl="12">
                <h4>{t("First_Login_But_you_still_can_change_it_devis")}</h4>
                <DefaultSelect
                  className="form-select-lg mb-2 mt-4"
                  onChange={async (value) => {
                    await updateCurrency(value.value, "test", () => { });
                    await getUserAccountInfo();
                  }}
                  options={profilState.currency
                    ? profilState.currency.map((element) => ({
                      value: element.currency_id,
                      label: t(element.currency_label),
                      key: element.currency_code,
                    }))
                    : []}
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" xs="12" md="12" lg="12" xl="12" className="d-flex justify-content-end mt-5">
                <StandardButton
                  onClick={() => {
                    setChooseCurrencyModal(false);
                    //setOpenAddAccountModal(true);
                    setShowAddBudgetModal(true);
                  }}
                  text={StringManipulator.capitalize(t("global.next._"))}
                />
              </Col>
            </Row>
          </div>
        )
      }
      {
        openAddAccountModal === true &&
        (
          <div className="modal-body">
            {accountState.accounts !== undefined && accountState.accounts.length === 0 &&
              (<Row>
                <Col sm="12" xs="12" md="12" lg="12" xl="12" className="d-flex justify-content-center mt--4">
                  <img alt="..."
                    width="300px"
                    height="310x"
                    src={Cryptocurrency}
                  />
                </Col>
                <Col sm="12" xs="12" md="12" lg="12" xl="12" className="d-flex justify-content-center">
                  <h4>
                    {t(
                      "First_Login_Create_an_account_or_import_one_directly_from_any_bank_institution_so_you_can_budgetize_you_money"
                    )}
                    :
                  </h4>
                </Col>
              </Row>)
            }
            {accountState.accounts !== undefined && accountState.accounts.length !== 0 && (
              <Row>
                <Col sm="12" xs="12" md="12" lg="12" xl="12" className="mr-4 px-6">
                  <Row className="d-flex justify-content-center align-items-center">
                    {
                      accountState.accounts.map((account) => {
                        return (
                          <Col sm="12" xs="12" md="12" lg="12" xl="12" style={{
                            height: "50px"
                          }}>
                            <Card>
                              <CardBody className="d-flex justify-content-between align-items-center" style={{
                                height: "50px"
                              }}>
                                <span className="h3">
                                  {account.account}
                                </span>
                                <span className="h2 text-default">
                                  {/* {new Intl.NumberFormat(authState.user.currency_locales, {
                                    style: "currency",
                                    currency: authState.user && authState.user.currency_code,
                                  }).format(account.solde)} */}
                                </span>
                              </CardBody>
                            </Card>
                          </Col>
                        );
                      })
                    }
                  </Row>
                </Col>
              </Row>
            )}

            {accountState.accounts !== undefined && accountState.accounts.length !== 0 && (
              <Row>
                <Col sm="12" xs="12" md="12" lg="12" xl="12" className="mb-2 mt-2 mr-5 px-7">
                  {authState.user.currency_code && (
                    <h3 className="d-flex">
                      <span>
                        {t("Total Amount Of Your Accounts is")}:{" "}
                      </span>
                      <h3 className="text-default ml-2">
                        {new Intl.NumberFormat(authState.user.currency_locales, {
                          style: "currency",
                          currency: authState.user && authState.user.currency_code,
                        }).format(totalSoldeAccounts())}
                      </h3>
                    </h3>
                  )}
                </Col>
              </Row>
            )}

            <Row className="mx-4 px-4 my-4">
              <Col sm="6" xs="6" md="6" lg="6" xl="6" className="d-flex justify-content-center">
                <Button className="bg-transparent text-default"
                  onClick={() => {
                    // setOpenAddAccountModal(false);
                    // setAddOrImportAccountMoadl(true)
                  }} style={{
                    width: "auto",
                    padding: "20px",
                    marginLeft: "10%",
                    marginRight: "10%",
                    fontWeight: "bold",
                  }}>
                  <i className="fas fa-link mr-1"></i>
                  {accountState.accounts !== undefined && accountState.accounts.length === 0 ?
                    t("First_Login_Import_account_from_your_bank") :
                    t("Import another account from your bank")
                  }
                </Button>
              </Col>
              <Col sm="6" xs="6" md="6" lg="6" xl="6" className="d-flex justify-content-center">
                <Button className="bg-transparent text-default"
                  onClick={() => {
                    setOpenAddAccountModal(false);
                    setShowAddAcountModal(true);
                  }} style={{
                    width: "auto",
                    padding: "20px",
                    marginLeft: "10%",
                    marginRight: "10%",
                    fontWeight: "bold",
                  }}>
                  <i className="fas fa-wallet mr-1"></i>
                  {accountState.accounts !== undefined && accountState.accounts.length === 0 ?
                    t("Create a wallet instead?") :
                    t("Create a another wallet")
                  }
                </Button>
              </Col>
            </Row>

            <Row className="my-4">
              <Col sm="6" xs="6" md="6" lg="6" xl="6" className="d-flex justify-content-start">
                <StandardButton
                  onClick={() => {
                    setOpenAddAccountModal(false);
                    setChooseCurrencyModal(true);
                  }}
                  text={t("Change The Currency")}
                />
              </Col>
              <Col sm="6" xs="6" md="6" lg="6" xl="6" className="d-flex justify-content-end">
                <StandardButton
                  onClick={() => {
                    history.push("/app/budget");
                    setOpenAddAccountModal(false);
                    setShowAddBudgetModal(true);
                  }}
                  style={{
                    cursor: "pointer"
                  }}
                  text={t("Save")}
                />
              </Col>
            </Row>
          </div>
        )
      }
    </DefaultModal>

    {/* ================================================================================================= */}
    <DefaultModal
      title={t("global.new.account")}
      size={"modal-md"}
      style={{ paddingTop: "100px" }}
      hasNoButtons={true}
      bodyStyle={{ textAlign: "start" }}
      isOpen={showAddAcountModal}
      toggle={async () => {
        setShowAddAcountModal(false);
      }}
    >
      <div className="modal-body">
        <WidgetNewAccount
          allAccountType={accountState.accountTypes}
          handleAddNewAccountToDb={async (newAccount) => {
            if (newAccount.accountName !== "" || newAccount.accountInitalSold) {
              setShowAddAcountModal(false);
              setOpenAddAccountModal(true);
              await addNewAccount(newAccount);
            }
          }}
          onCancel={() => {}}
          translate={t}
          onBack={() => {
            setShowAddAcountModal(false);
            setOpenAddAccountModal(true);
          }}
        />
      </div>
    </DefaultModal>

    {/* ================================================================================================= */}
    <DefaultModal
      size={"modal-lg"}
      style={{ paddingTop: "100px" }}
      hasNoButtons={true}
      title={StringManipulator.capitalize(t("global.budget.configuration"))}
      isOpen={showAddBudgetModal}
      toggle={async () => {
        setShowAddBudgetModal(false);
      }}
    >
      <div className="modal-body">
        <Row>
          <Col sm="12" xs="12" md="12" lg="12" xl="12" className="d-flex justify-content-start text-default">
            <ul style={{
              paddingRight: "60px",
              paddingLeft: "10px",
            }}>
              {
                selecteDataCategories.map((type, index2) => (

                  <li key={index2} style={{
                    listStyleType: "initial"
                  }}>
                    <h2 className="d-flex align-items-center">
                      <span
                        onClick={() => {
                          const data = [];
                          collapsse.forEach((type_) => {
                            if (type_.typeId === type.typeId) {
                              data.push({
                                typeId: type_.typeId,
                                isOpen: !type_.isOpen,
                              });
                            } else {
                              data.push({
                                typeId: type_.typeId,
                                isOpen: false,
                              });
                            }
                          });
                          setCollpasse(data)
                        }}
                        style={{
                          cursor: "pointer"
                        }}>
                        {t(type.typeName)}
                      </span>
                      <span
                        onClick={() => {
                          const data = [];
                          collapsse.forEach((type_) => {
                            if (type_.typeId === type.typeId) {
                              data.push({
                                typeId: type_.typeId,
                                isOpen: !type_.isOpen,
                              });
                            } else {
                              data.push({
                                typeId: type_.typeId,
                                isOpen: false,
                              });
                            }
                          });
                          setCollpasse(data)
                        }}
                        style={{
                          cursor: "pointer"
                        }}>
                        {
                          collapsse.filter((type_) => type_.typeId === type.typeId && type_.isOpen).length !== 0 ?
                            (<i className="ml-2 fas fa-sort-down text-default" style={{
                              fontSize: "15px",
                            }}></i>) : (<i className="ml-2 fas fa-caret-right text-default" style={{
                              fontSize: "15px",
                            }}></i>)
                        }
                      </span>
                    </h2>
                    <Collapse style={{
                      marginBottom: "10px"
                    }} isOpen={collapsse.filter((type_) => type_.typeId === type.typeId && type_.isOpen).length !== 0}>
                      {
                        type.Groups.map((group, index1) => (
                          <li className="ml-5 mb-2" key={index1} style={{
                            listStyleType: "initial"
                          }}>
                            {group.budget_group_order !== -1 && (
                              <h2 style={{ display: "flex", justifyContent: "flex-start" }}>
                                <span>
                                  <input
                                    type="checkbox"
                                    className="mt--1 mr-1"
                                    defaultChecked={false}
                                    checked={group.categories.filter((category) => category.isSelected === true).length !== 0}
                                    onChange={(e) => {
                                      const data1 = [];
                                      selecteDataCategories.forEach((type, index22) => {
                                        if (index22 === index2) {
                                          const groupes = []
                                          type.Groups.forEach((group, index11) => {
                                            if (index11 === index1) {
                                              const categories = [];
                                              group.categories.forEach((categorie_) => {
                                                if (group.categories.filter((category) => category.isSelected === true).length !== 0) categories.push({ ...categorie_, isSelected: false });
                                                if (group.categories.filter((category) => category.isSelected === true).length === 0) categories.push({ ...categorie_, isSelected: true });
                                              })
                                              groupes.push({ ...group, categories: categories })
                                            } else {
                                              groupes.push(group);
                                            }
                                          })
                                          data1.push({ ...type, Groups: groupes })
                                        } else {
                                          data1.push(type)
                                        }
                                        setSelecteDataCategories(data1)
                                      })
                                    }}
                                  />
                                </span>
                                <span
                                  style={{
                                    cursor: "pointer",
                                    paddingBottom: "8px",
                                    textDecoration:
                                      group.categories.filter((category) => category.isSelected === true).length === 0 ? "line-through" : ""
                                  }}
                                >
                                  <span className="h3" onClick={() => {
                                    const data1 = [];
                                    selecteDataCategories.forEach((type, index22) => {
                                      if (index22 === index2) {
                                        const groupes = []
                                        type.Groups.forEach((group, index11) => {
                                          if (index11 === index1) {
                                            const categories = [];
                                            group.categories.forEach((categorie_) => {
                                              if (group.categories.filter((category) => category.isSelected === true).length !== 0) categories.push({ ...categorie_, isSelected: false });
                                              if (group.categories.filter((category) => category.isSelected === true).length === 0) categories.push({ ...categorie_, isSelected: true });
                                            })
                                            groupes.push({ ...group, categories: categories })
                                          } else {
                                            groupes.push(group);
                                          }
                                        })
                                        data1.push({ ...type, Groups: groupes })
                                      } else {
                                        data1.push(type)
                                      }
                                    })
                                    setSelecteDataCategories(data1)
                                  }}>
                                    {t(group.groupName)}
                                  </span>
                                  {/* {selectedCategories.filter((cate) => group.categories.filter((category) => category === cate).length !== 0).length !== 0 && <span>
                                        <i className="ml-1 fas fa-edit" style={{
                                          fontSize: "10px",
                                          color: "gray"
                                        }}></i>
                                      </span>} */}
                                </span>
                              </h2>
                            )}

                            {group.budget_group_order === -1 &&
                              (<h2
                                style={{ display: "flex", justifyContent: "flex-start", cursor: "pointer", }}
                                onClick={() => {
                                  const data1 = [];
                                  selecteDataCategories.forEach((type, index22) => {
                                    if (index22 === index2) {
                                      data1.push({
                                        ...type, Groups: [...type.Groups.filter((groupe) => groupe.budget_group_order !== -1), {
                                          groupName: 'New Group',
                                          categories: ['New Category'],
                                          budget_group_order: selecteDataCategories[index22].Groups[selecteDataCategories[index22].Groups.length - 2].budget_group_order,
                                        }, {
                                          groupName: 'Add New Group',
                                          categories: [],
                                          budget_group_order: -1,
                                        },]
                                      })
                                    } else {
                                      data1.push(type)
                                    }
                                  })
                                  setSelecteDataCategories(data1)
                                }}
                              >
                                <span>
                                  <i className="far fa-plus-square mr-1"></i>
                                </span>
                                <span>
                                  {group.groupName}
                                </span>
                              </h2>
                              )}

                            <ul>
                              {group.categories.map((category, index) => (
                                <li
                                  key={index}
                                >
                                  {`${category.name + index}` !== isOnEditeCat && category.name !== t("Add New Category") &&
                                    (
                                      <span style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }} >
                                        <span >
                                          <input
                                            type="checkbox"
                                            className="mt--1 mr-1"
                                            defaultChecked={false}
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            checked={category.isSelected}
                                            onChange={(e) => {
                                              const data1 = [];
                                              selecteDataCategories.forEach((type, index22) => {
                                                if (index22 === index2) {
                                                  const groupes = []
                                                  type.Groups.forEach((group, index11) => {
                                                    if (index11 === index1) {
                                                      const categories = [];
                                                      group.categories.forEach((categorie_, index_) => {
                                                        if (index === index_) {
                                                          categories.push({ ...categorie_, isSelected: !categorie_.isSelected });
                                                        } else {
                                                          categories.push(categorie_);
                                                        }
                                                      })
                                                      groupes.push({ ...group, categories: categories })
                                                    } else {
                                                      groupes.push(group);
                                                    }
                                                  })
                                                  data1.push({ ...type, Groups: groupes })
                                                } else {
                                                  data1.push(type)
                                                }
                                              })
                                              setSelecteDataCategories(data1)
                                            }}
                                          />
                                        </span>
                                        <span
                                          style={{
                                            cursor: "pointer",
                                            paddingBottom: "8px",
                                            textDecorationColor: COLORS.glyphPrimary,
                                            textDecoration:
                                              !category.isSelected ? "line-through" : ""
                                          }}
                                        >
                                          <span className="h4" onClick={() => {
                                            const data1 = [];
                                            selecteDataCategories.forEach((type, index22) => {
                                              if (index22 === index2) {
                                                const groupes = []
                                                type.Groups.forEach((group, index11) => {
                                                  if (index11 === index1) {
                                                    const categories = [];
                                                    group.categories.forEach((categorie_, index_) => {
                                                      if (index === index_) {
                                                        categories.push({ ...categorie_, isSelected: !categorie_.isSelected });
                                                      } else {
                                                        categories.push(categorie_);
                                                      }
                                                    })
                                                    groupes.push({ ...group, categories: categories })
                                                  } else {
                                                    groupes.push(group);
                                                  }
                                                })
                                                data1.push({ ...type, Groups: groupes })
                                              } else {
                                                data1.push(type)
                                              }
                                            })
                                            setSelecteDataCategories(data1)
                                          }}>
                                            {t(category.name)}
                                          </span>
                                          {category.isSelected && <span>
                                            <i className="ml-1 fas fa-edit" onClick={() => {
                                              setIsOnEditeCat(category.name + index)
                                            }} style={{
                                              fontSize: "10px",
                                              color: "gray"
                                            }}></i>
                                          </span>}
                                          {!category.isSelected && <span>
                                            <i className="ml-1 fas fa-trash" onClick={() => {
                                              const data1 = [];
                                              selecteDataCategories.forEach((type, index22) => {
                                                if (index22 === index2) {
                                                  const groupes = []
                                                  type.Groups.forEach((group, index11) => {
                                                    if (index11 === index1) {
                                                      groupes.push({ ...group, categories: group.categories.filter((catess) => catess.name !== category.name) })
                                                    } else {
                                                      groupes.push(group);
                                                    }
                                                  })
                                                  data1.push({ ...type, Groups: groupes })
                                                } else {
                                                  data1.push(type)
                                                }
                                              })
                                              setSelecteDataCategories(data1)
                                            }} style={{
                                              fontSize: "10px",
                                              color: "gray"
                                            }}></i>
                                          </span>}
                                        </span>
                                      </span>
                                    )}
                                  {`${category.name + index}` === isOnEditeCat && category.name !== t("Add New Category") &&
                                    (
                                      <Input
                                        className="my-2"
                                        autoFocus={true}
                                        type="text"
                                        defaultValue={category.name}
                                        name={category.name + index}
                                        id={category.name + index}
                                        style={{
                                          height: "30px",
                                          color: "black",
                                          fontWeight: "bolder",
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "flex-start"
                                        }}
                                        onKeyPress={(target) => {
                                          if (target.charCode == 13) {
                                            const data1 = [];
                                            selecteDataCategories.forEach((type, index22) => {
                                              if (index22 === index2) {
                                                const groupes = []
                                                type.Groups.forEach((group, index11) => {
                                                  if (index11 === index1) {
                                                    const categories = [];
                                                    group.categories.forEach((categorie_, index_) => {
                                                      if (index === index_) {
                                                        categories.push({
                                                          ...categorie_, name: document.getElementById(categorie_.name + index).value
                                                        });
                                                      } else {
                                                        categories.push(categorie_);
                                                      }
                                                    })
                                                    groupes.push({ ...group, categories: categories })
                                                  } else {
                                                    groupes.push(group);
                                                  }
                                                })
                                                data1.push({ ...type, Groups: groupes })
                                              } else {
                                                data1.push(type)
                                              }
                                            })
                                            setIsOnEditeCat("");
                                            setSelecteDataCategories(data1)
                                          }
                                        }}
                                        onBlur={() => {
                                          const data1 = [];
                                          selecteDataCategories.forEach((type, index22) => {
                                            if (index22 === index2) {
                                              const groupes = []
                                              type.Groups.forEach((group, index11) => {
                                                if (index11 === index1) {
                                                  const categories = [];
                                                  group.categories.forEach((categorie_, index_) => {
                                                    if (index === index_) {
                                                      categories.push({
                                                        ...categorie_, name: document.getElementById(category.name + index).value
                                                      });
                                                    } else {
                                                      categories.push(categorie_);
                                                    }
                                                  })
                                                  groupes.push({ ...group, categories: categories })
                                                } else {
                                                  groupes.push(group);
                                                }
                                              })
                                              data1.push({ ...type, Groups: groupes })
                                            } else {
                                              data1.push(type)
                                            }
                                          })
                                          setIsOnEditeCat("");
                                          setSelecteDataCategories(data1)
                                        }}
                                      />
                                    )
                                  }
                                  {category.name === t("Add New Category") && (
                                    <h4
                                      style={{
                                        cursor: "pointer",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "flex-start",
                                        color: COLORS.primary
                                      }}
                                      onClick={() => {
                                        const data1 = [];
                                        selecteDataCategories.forEach((type, index22) => {
                                          if (index22 === index2) {
                                            const groupes = []
                                            type.Groups.forEach((group, index11) => {
                                              if (index11 === index1) {
                                                groupes.push(
                                                  {
                                                    ...group,
                                                    categories:
                                                      [...group.categories.filter((cates) => cates.name !== t("Add New Category")),
                                                      {
                                                        name: 'New Category',
                                                        isSelected: true
                                                      }, {
                                                        name: 'Add New Category',
                                                        isSelected: false
                                                      }]
                                                  })
                                              } else {
                                                groupes.push(group);
                                              }
                                            })
                                            data1.push({ ...type, Groups: groupes })
                                          } else {
                                            data1.push(type)
                                          }
                                        })
                                        setSelecteDataCategories(data1);
                                        setIsOnEditeCat("New Category" + index)
                                      }}
                                    >
                                      <span>
                                        <i className="far fa-plus-square mr-1"></i>
                                      </span>
                                      <span>
                                        {t(category.name)}
                                      </span>
                                    </h4>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                    </Collapse>
                  </li>

                ))}
            </ul>
          </Col>
        </Row>
        <Row className="my-4">
          <Col sm="6" xs="6" md="6" lg="6" xl="6" className="d-flex justify-content-start">
            <StandardButton
              onClick={() => {
                setShowAddBudgetModal(false);
                //setOpenAddAccountModal(true);
                setChooseCurrencyModal(true);
              }}
              text={StringManipulator.capitalize(t("global.previous._"))}
            />
          </Col>
          <Col sm="6" xs="6" md="6" lg="6" xl="6" className="d-flex justify-content-end">
            <StandardButton
              onClick={async () => {
                await insertTheUserSelectedCategories(selecteDataCategories);
                await getBudget(moment(new Date()).format("YYYY-MM-01"), sessionStorage.getItem("SESSION_SPACE"));
                setShowAddBudgetModal(false);
                setShowDoneModal(true);
              }}
              text={t("Save")}
            />
          </Col>
        </Row>
      </div>
    </DefaultModal>

    {/* ================================================================================================= */}
    <Modal
      className="modal-dialog-start w-auto"
      isOpen={false}
      // isOpen={showStartBudgetModal}
      centered
      backdrop={"static"}
      toggle={async () => {
        setShowStartBudgetModal(false);
      }}
    >
      <div className="modal-body">
        <Row>
          <Col sm="12" xs="12" md="12" lg="12" xl="12" className="d-flex justify-content-center text-default">
            <h2 style={{
              fontWeight: "bold"
            }}>
              <i className="fas fa-coins mr-1"></i>
              {t("Quick guideline")}{" "}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col sm="12" xs="12" md="12" lg="12" xl="12" className="d-flex justify-content-center">
            <img alt="..."
              width="300px"
              height="290px"
              src={Searching}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="12" xs="12" md="12" lg="12" xl="12" className="d-flex justify-content-center text-default">
            <h4 style={{
              fontWeight: "bold"
            }}>
              {t("A Quick guideline to improve your user experience and for better use and understanding of the different  budget view tools")}{" "}
            </h4>
          </Col>
        </Row>
        <Row className="my-4">
          <Col sm="12" xs="12" md="12" lg="12" xl="12" className="d-flex justify-content-center">

            <StandardButton
              onClick={() => {
                setShowStartBudgetModal(false);
                setTimeout(() => {
                  runOnBoardingBudget(true);
                }, 1000);
              }}
              text={t("Let's start !")}
            />
            <StandardButton
              onClick={() => {
                setShowStartBudgetModal(false);
                setShowDoneModal(true);
              }}
              text={t("No, thanks.") + t("I can handle this")}
            />
          </Col>
        </Row>
      </div>
    </Modal>

    {/* ================================================================================================= */}
    {
      <DefaultModal
        btnText={t("First_Login_Done")}
        isOpen={showDoneModal}
        title={t("Setup completed")}
        onClick={handleSuccessfulConfiguration}
        toggle={handleSuccessfulConfiguration}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <i className="fa fa-check fa-4x"></i>
          <br />
          <div>
            {t("First_Login_You_have_completed_your_setup")}
          </div>
        </div>
      </DefaultModal>
    }
  </>
}

FirstUserLogin.prototype = {
  authState: PropTypes.object.isRequired,
  profilState: PropTypes.object.isRequired,
  accountState: PropTypes.object.isRequired,
  getCurrency: PropTypes.func.isRequired,
  updateCurrency: PropTypes.func.isRequired,
  getUserAccountInfo: PropTypes.func.isRequired,
  addNewAccount: PropTypes.func.isRequired,
  getAccountTypes: PropTypes.func.isRequired,
  getAccessToken: PropTypes.func.isRequired,
  setFirstLoginState: PropTypes.func.isRequired,
  getBudget: PropTypes.func.isRequired,
  runOnBoardingBudget: PropTypes.func.isRequired,
  getAccounts: PropTypes.func.isRequired,
  setAddOrImportAccountMoadl: PropTypes.func.isRequired,
  insertTheUserSelectedCategories: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authState: state.auth,
  profilState: state.profile,
  accountState: state.account,
});

export default connect(mapStateToProps, {
  getCurrency,
  updateCurrency,
  getUserAccountInfo,
  addNewAccount,
  getAccessToken,
  getAccountTypes,
  getBudget,
  getAccounts,
  insertTheUserSelectedCategories,
  runOnBoardingBudget,
  setFirstLoginState,
  setAddOrImportAccountMoadl
})(FirstUserLogin);
