import React, { useState } from "react";
import { Card, CardBody, Row, Collapse, Col } from "reactstrap";
import FormatCurrency from "../Tools/Currency/Currency";
import Image1 from "../../assets/img/Views/You have no accounts.png"
import StandardButton from "components/Buttons/standardButton/StandardButton";
import DefaultModal from "components/Modals/DefaultModal";
import formatDate from "helpers/formatDate";
import { COLORS } from "assets/constants/theme";
import CustomTag from "components/Randoms/CustomTag";
import { StringManipulator } from "@fineo-io/main-javascript-library";

export default function AccountCard({
  dataSource = [],
  changeDetailMode,
  authState,
  translate,
}) {
  const [openedCollapses, setopenedCollapses] = useState(["collapseOne"]);
  const [hide, setHide] = useState(false);
  const [showTakeAttenionForNordigenModal, setShowTakeAttenionForNordigenModal] = useState(false);
  const [selectedAccountToGetAuthAgain, setSelectedAccountToGetAuthAgain] = useState({});
  const collapsesToggle = (collapse) => {
    setHide(!hide);
    let openedCollapsess = openedCollapses;
    if (openedCollapsess.includes(collapse)) {
      setopenedCollapses([]);
    } else {
      setopenedCollapses([collapse]);
    }
  };

  const renderNormaleAccounts = (bankAccount) =>
  (
    <Col key={Math.random() * 9} xl="4" lg="4" md="6" sm="12" xs="12" className="p-3">
      <Card>
        <CardBody>
          <Row style={{ color: COLORS.glyphPrimary }}>
            <Col xl="12" lg="12" md="12" sm="12" xs="12" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }} >
              <div className="h3 mb-0" style={{
                fontWeight: "200",
                flex: 1,
                width: "90%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}>
                <span title={bankAccount.account === "Compte importé" ? translate("Compte importé") : bankAccount.account}>
                  {bankAccount.account === "Compte importé" ? translate("Compte importé") : bankAccount.account}
                </span>
              </div>
              {bankAccount.is_it_imported ? (
                <span style={{ marginTop: "10px" }}>
                  <CustomTag
                    onClick={() => {
                      if (bankAccount.status !== "READY") {
                        setShowTakeAttenionForNordigenModal(true);
                        setSelectedAccountToGetAuthAgain(bankAccount);
                      }
                    }}
                    children={<span><i className="fas fa-sync mr-1"></i> {" "} {bankAccount.status === "READY" ? translate("Account_Linked") : bankAccount.status === "ERROR" ? translate("ERROR") : translate("Disconnected")}</span>}
                    color={bankAccount.status !== "READY" ? COLORS.warning : COLORS.secondary}
                  />
                  {" "}
                  <CustomTag
                    children={translate(bankAccount.accountType)}
                    color={bankAccount.accountTypeId === 1 ? COLORS.success : bankAccount.accountTypeId === 2 ? COLORS.cash : COLORS.danger}
                  />
                </span>
              ) : (
                <span style={{ marginTop: "10px" }}>
                  <CustomTag
                    children={translate("Account_Manual")}
                    color={COLORS.primary}
                  />
                  {" "}
                  <CustomTag
                    children={translate(bankAccount.accountType)}
                    color={bankAccount.accountTypeId === 1 ? COLORS.success : bankAccount.accountTypeId === 2 ? COLORS.cash : COLORS.danger}
                  />
                </span>
              )}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Row>
                <Col xl="12" lg="12" md="12" sm="12" xs="12">
                  <div
                    className="h3 mb-0 text-grayF"
                    style={{
                      fontWeight: "normal",
                    }}
                  >
                    {translate("Account_Solde")}:
                  </div>
                </Col>
                <Col xl="12" lg="12" md="12" sm="12" xs="12">
                  <div className="h3 mb-0" style={{
                    fontWeight: "200",
                  }}>
                    {FormatCurrency(
                      bankAccount.solde,
                      authState.user.currency_code,
                      authState.user.currency_locales
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row >
                <Col xl="12" lg="12" md="12" sm="12" xs="12">
                  <div
                    className="h3 mb-0 text-grayF"
                    style={{
                      fontWeight: "normal",
                    }}
                  >
                    {translate("Account_Date_ouverture")}:
                  </div>
                </Col>
                <Col xl="12" lg="12" md="12" sm="12" xs="12">
                  <div className="h4 mb-0">
                    {formatDate(bankAccount.creatingDate)}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", marginTop: "20px" }}>
            {bankAccount.is_it_imported && (
              <h6 style={{ marginRight: "auto", marginBottom: 0 }}>
                {translate("Last Sync")}: {formatDate(new Date(bankAccount.synchronizing_latest_date), true)}
              </h6>
            )}
            <StandardButton
              outline
              type="button"
              size="sm"
              style={{ marginLeft: "auto", marginTop: 0, marginBottom: 0 }}
              title="modifier"
              onClick={() => { changeDetailMode(bankAccount.key) }}
              value={bankAccount.key}
              text=""
              children={<i className="fas fa-edit"></i>}
            />
          </div>

        </CardBody>
      </Card>
    </Col>
  )

  return (
    <div style={{
      fontWeight: "200"
    }}>
      <Row>
        {dataSource.map(
          (bankAccount) =>
            !bankAccount.is_it_closed && renderNormaleAccounts(bankAccount)
        )}
      </Row>
      <Row className="mb-3">
        <Col xl="12">
          {dataSource.filter((x) => x.is_it_closed).length > 0 ? (
            <span
              key={Math.random() * 9}
              title="reduire"
              style={{ cursor: "pointer" }}
              onClick={() => {
                collapsesToggle("collapseOne");
              }}
              className="h2"
            >
              <i className="fas fa-door-closed fa-2x"></i> &nbsp;{" "}
              {translate("Account_Comptes_Fermése")}
              &nbsp;
              {hide ? (
                <i
                  className="fas fa-caret-down"
                  title="afficher"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    collapsesToggle("collapseOne");
                  }}
                  aria-expanded={openedCollapses.includes("collapseOne")}
                ></i>
              ) : (
                <i className="fas fa-caret-up"></i>
              )}
            </span>
          ) : (
            <div></div>
          )}
        </Col>
      </Row>
      <Collapse
        role="tabpanel"
        isOpen={openedCollapses.includes("collapseOne")}
      >
        <Row className="mb-4">
          {dataSource.map((bankAccount) =>
            bankAccount.is_it_closed ? (
              <Col key={Math.random() * 9} xl="4" lg="4" md="6" sm="12" xs="12" className="p-3">
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl="12" lg="12" md="12" sm="12" xs="12" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}  >
                        <div className="h3 mb-0"
                          style={{
                            fontWeight: 200,
                            flex: 1,
                            width: "90%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          }}
                        >
                          <span title={bankAccount.account}>
                            {bankAccount.account}
                          </span>
                        </div>
                        {bankAccount.is_it_imported ? (
                          <span style={{ marginTop: "10px" }}>
                            <CustomTag
                              children={<span><i className="fas fa-sync mr-1"></i> {" "} {translate("Account_Linked")}</span>}
                              color={COLORS.success}
                            />{" "}
                            <CustomTag
                              children={translate(bankAccount.accountType)}
                              color={bankAccount.accountTypeId === 1 ? COLORS.success : bankAccount.accountTypeId === 2 ? COLORS.cash : COLORS.danger}
                            />
                          </span>
                        ) : (
                          <span style={{ marginTop: "10px" }}>
                            <CustomTag
                              children={translate("Account_Manual")}
                              color={COLORS.primary}
                            />
                            {" "}
                            <CustomTag
                              children={translate(bankAccount.accountType)}
                              color={bankAccount.accountTypeId === 1 ? COLORS.success : bankAccount.accountTypeId === 2 ? COLORS.cash : COLORS.danger}
                            />
                          </span>
                        )}
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col>
                        <Row >
                          <Col xl="12" lg="12" md="12" sm="12" xs="12">
                            <div
                              className="h3 mb-0 text-grayF"
                              style={{
                                fontWeight: "normal",
                              }}
                            >
                              {translate("Account_Solde")}:
                            </div>
                          </Col>
                          <Col xl="12" lg="12" md="12" sm="12" xs="12">
                            <div className="h4 mb-0">
                              {FormatCurrency(
                                bankAccount.solde,
                                authState.user.currency_code,
                                authState.user.currency_locales
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row >
                          <Col xl="12" lg="12" md="12" sm="12" xs="12">
                            <div
                              className="h3 mb-0 text-grayF"
                              style={{
                                fontWeight: "normal",
                              }}
                            >
                              {translate("Account_Date_ouverture")}:
                            </div>
                          </Col>
                          <Col xl="12" lg="12" md="12" sm="12" xs="12">
                            <div className="h4 mb-0">
                              {formatDate(bankAccount.creatingDate)}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                      <StandardButton
                        outline
                        type="button"
                        size="sm"
                        style={{ marginLeft: "auto", marginTop: 0, marginBottom: 0 }}
                        title="modifier"
                        onClick={() => { changeDetailMode(bankAccount.key) }}
                        value={bankAccount.key}
                        text=""
                        children={<i className="fas fa-edit"></i>}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              <div key={Math.random() * 9}></div>
            )
          )}
        </Row>
      </Collapse>
      <DefaultModal
        title={selectedAccountToGetAuthAgain.status === "SUSPENDED" || selectedAccountToGetAuthAgain.status === "EXPIRED" ? translate("Re-authentication required") : translate("Failed synchronization")}
        hasTwoButtons={selectedAccountToGetAuthAgain.status === "SUSPENDED" || selectedAccountToGetAuthAgain.status === "EXPIRED"}
        isOpen={showTakeAttenionForNordigenModal}
        btnText={selectedAccountToGetAuthAgain.status === "SUSPENDED" || selectedAccountToGetAuthAgain.status === "EXPIRED" ? translate("Re Connect") : translate("Try later")}
        onClick={async () => {
          if (selectedAccountToGetAuthAgain.status === "SUSPENDED" || selectedAccountToGetAuthAgain.status === "EXPIRED") {
            window.location.href = selectedAccountToGetAuthAgain.new_auth_link;
          }
          setShowTakeAttenionForNordigenModal(false);
        }}
        secondBtnText={StringManipulator.capitalize(translate("global.cancel._"))}
        secondOnClick={() => {
          setShowTakeAttenionForNordigenModal(false);
        }}
        toggle={() => {
          setShowTakeAttenionForNordigenModal(false);
        }}
      >
        <div>
          {selectedAccountToGetAuthAgain.status === "SUSPENDED" || selectedAccountToGetAuthAgain.status === "EXPIRED" ? translate("Access to account has expired please click on the button to reconnected it again") : translate("There was an error while syncing this account please try later")}
          {translate("")}
        </div>
      </DefaultModal>



      {dataSource.length == 0 && (<Row>
        <Col md="12" xl="12" xs="12" lg="12" sm="12" className="text-gray text-center mb--1 mt--1">
          <img
            alt="..."
            width="100%"
            style={{
              margin: "auto",
              maxHeight: "180px",
              maxWidth: "180px",
            }}
            src={Image1}
            className="mr-1"
          />
          <div>{translate("You have no accounts")}</div>
        </Col>
      </Row>)}
    </div >
  );
}
