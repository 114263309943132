import React from "react";

export default function FormatCurrency(amount=0, code, locales='fr-FR', suffix) {
  return (
    <div>
      {new Intl.NumberFormat(locales, {
        style: "currency",
        currency: code || "EUR",
      }).format(amount)}
      {suffix}
    </div>
  );
}
