import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import Chart from "chart.js";
import { Line } from "react-chartjs-2";
import { Helmet } from 'react-helmet-async';
import DashboardAccount from "../components/Widgets/dashboardAccount.js";
import {
  Card,
  CardTitle,
  CardBody,
  Row,
  Badge,
  Col,
  Progress,
} from "reactstrap";
import SecBudgettWidget from "../components/Widgets/SecBudgetWidget";
import "react-datetime/css/react-datetime.css";
import { connect } from "react-redux";
import { getSituationCompte, dashboardSemestreDepense, dashboardSemestreEntries } from "../actions/Dashboard";
import { getBudget } from "../actions/Budget";
import { getAccounts, getAccountTypes } from "../actions/Account";
import { getTransaction } from '../actions/Transaction'
import PropTypes from "prop-types";
import FormatCurrency from "../components/Tools/Currency/Currency";
import { useTranslation } from "react-i18next";
import Image1 from "../assets/img/Views/You have no upcoming bill.png"
import { chartOptions, parseOptions } from "../components/Tools/charts.js";
import Icons from "../components/Randoms/Icons";
import { COLORS } from "assets/constants/theme.js";
import hexToRgba from "helpers/hexToRgba.js";
import { StringManipulator } from "@fineo-io/main-javascript-library";
import AccountSelect from "components/inputs/AccountSelect.jsx";
import MonthPicker from "components/Randoms/MonthPicker.jsx";
import moment from "moment";
import formatDate from "helpers/formatDate.js";
import MainHeader from "components/Headers/MainHeader.jsx";

const { TransactionIcon, MoneyWallet, SavingIcon, MoneyGraph } = Icons;

function Dashboard({
  accountState,
  budgetState,
  getBudget,
  getSituationCompte,
  dashboardSemestreEntries,
  dashboardSemestreDepense,
  dashboardState,
  getAccountTypes,
  getTransaction,
  transactionState,
  getAccounts,
  authState,
}) {
  const [bDate, setDate] = useState(budgetState.bDate || moment(new Date()).format("YYYY-MM-01"));
  const [selectedAccountTypes, setSelectedAccountTypes] = useState(-1);
  const [totalEparnee, setTotalEparnee] = useState(0);
  const [config, setConfig] = useState({});
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  ReactGA.set({ userId: authState?.userGA_id })

  //gets the summary(total of every types in a group)
  const getSummary = (dataset) => {
    let available = 0;
    let budgetAmounts = 0;
    let totalmouvement = 0;
    dataset.forEach(({ disponible, activities, budgetAmount }) => {
      available += parseFloat(disponible);
      budgetAmounts += parseFloat(budgetAmount);
      totalmouvement += parseFloat(activities);
    });
    return [
      {
        key: -2,
        disponible: available,
        budgetAmount: budgetAmounts,
        activities: totalmouvement,
      },
    ];
  };

  const total_Epargne = async () => {
    var x = 0;
    if (budgetState.budgets.budgetData != undefined) {
      await Object.keys(budgetState.budgets.budgetData["3"]).forEach(
        (category) => {
          // set the max and the value of every group
          x = x + getSummary(budgetState.budgets.budgetData["3"][category])[0].disponible
        }
      );
      setTotalEparnee(x);
    }
    return x;
  };

  useEffect(() => {
    total_Epargne();
  }, [budgetState.budgets.budgetData])

  useEffect(() => {
    getAccountTypes();
    getTransaction(true, t);
    getAccounts();
  }, [])


  useEffect(() => {
    var dateFrom = new Date(bDate);
    dateFrom.setMonth(dateFrom.getMonth() - 5);
    const startDate_ = moment(dateFrom).format("YYYY-MM-DD");
    const load = async (startDate_) => {
      await dashboardSemestreDepense(
        authState.user.currency_code,
        authState.user.currency_locales,
        t,
        startDate_,
        sessionStorage.getItem("SESSION_SPACE")
      );
      await dashboardSemestreEntries(
        authState.user.currency_code,
        authState.user.currency_locales,
        t,
        startDate_,
        sessionStorage.getItem("SESSION_SPACE")
      );
      await getSituationCompte(bDate, sessionStorage.getItem("SESSION_SPACE"));
      await getBudget(moment(bDate).format("YYYY-MM-01"), sessionStorage.getItem("SESSION_SPACE"));

      setLoading(false)
    }
    load(startDate_);
  }, [sessionStorage.getItem("SESSION_SPACE"), localStorage.getItem("SPACE"), bDate])

  useEffect(() => {
    if (!loading) {
        const datasets = [];
        const cashFlow = [];
        for (let i = 0; i < dashboardState.dashboardSemestreEntries.data.datasets[0].data.length; i++) {
          const element = dashboardState.dashboardSemestreEntries.data.datasets[0].data[i] - dashboardState.dashboardSemestreDepense.data.datasets[0].data[i];
          cashFlow.push(parseFloat(element).toFixed(2));
        }

        if (dashboardState.dashboardSemestreEntries.data !== undefined) {
          datasets.push({
            label: t("Cash Flow"),
            data: cashFlow,
            fill: true,
            backgroundColor: hexToRgba(COLORS.primary),
            borderColor: COLORS.primary,
          })
        }
        setConfig({
          ...config, datasets: datasets, labels: dashboardState.dashboardSemestreDepense.data.labels.map((val) => val = val.toString()[0] + val.toString()[1] + val.toString()[2])
        })
    }
  }, [dashboardState.dashboardSemestreDepense.data, dashboardState.dashboardSemestreEntries.data, loading])

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const onDateChange = async (date) => {
    await getBudget(date, sessionStorage.getItem("SESSION_SPACE"));
    await getSituationCompte(date, sessionStorage.getItem("SESSION_SPACE"));
  };

  const totalUpcomingTransactions = () => {
    var x = 0;
    if (transactionState.futurTransactions && transactionState.futurTransactions.length !== 0)
      transactionState.futurTransactions.forEach((tr) => {
        if (tr.transaction_amount < 0)
          x = x + Math.abs(tr.transaction_amount)
      })
    return x / 100;
  }

  return (
    <>
      <MainHeader title={StringManipulator.capitalize(t("global.dashboard._"))} />
      <Row className="ml-3">
        <Col xl="12" lg="12" md="12" sm="12" xs="12">
          <Row>
            <Col xs="12" sm="12" md="12" lg="7" xl="7">
              <Row>
                <Col md="12" xl="6" lg="6" sm="12" xs="12">
                  <div style={{ color: COLORS.glyphPrimary, fontWeight: "800", fontSize: "18px", position: 'relative', marginTop: '12px' }}>
                    {StringManipulator.capitalize(t("Dashboard_Summary"))}
                  </div>
                </Col>
                <Col md="12" xl="6" lg="6" sm="12" xs="12">
                  <MonthPicker date={bDate} setDate={setDate} />
                </Col>
                <Col className="mb-4" md="12" xl="12" lg="12" sm="12" xs="12">
                  <div style={{ width: "100%", borderBottom: "1px solid lightgray" }}></div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="12" md="6" lg="6" xl="6" className="p-2">
                  <Card className="card-stats" style={{ borderRadius: "10px" }}>
                    <CardBody className="p-4" style={{ borderRadius: "10px" }}>
                      <Row>
                        <div className="col" style={{ position: "relative" }}>
                          <CardTitle
                            tag="h5"
                            style={{
                              color: COLORS.glyphSecondary,
                              fontWeight: "600"
                            }}
                            className="mb-0"
                          >
                            {t("Budgété ce mois")}
                          </CardTitle>
                          <span className="h2 mb-0" style={{
                            color: `${dashboardState.globalValues.availableCurrentMonth >= 0 ? COLORS.glyphPrimary : COLORS.danger}`,
                            fontWeight: "200",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}>
                            {FormatCurrency(
                              dashboardState.globalValues.budgetedCurrentMonth,
                              authState.user.currency_code,
                              authState.user.currency_locales
                            )}
                          </span>

                          <span style={{
                            position: "absolute",
                            top: "-5px",
                            right: "10px",
                            backgroundColor: COLORS.primary
                          }}
                            className="icon icon-shape text-white rounded-circle shadow">
                            <img src={MoneyWallet} width="18px" height="18px" />
                          </span>
                        </div>
                      </Row>
                      <Row>
                        <Col>
                          {dashboardState.globalValues.budgetedCurrentMonth !== 0 ? <Progress
                            className="progress-bar-animated progress-label mb-0"
                            color={dashboardState.globalValues.availableCurrentMonth >= 0 ? "blue" : "red"}
                            style={{
                              height: "8px",
                            }}
                            max={dashboardState.globalValues.availableCurrentMonth + dashboardState.globalValues.budgetedCurrentMonth}
                            value={dashboardState.globalValues.budgetedCurrentMonth}
                          >
                          </Progress>
                            : (<Progress
                              className="progress-bar-animated progress-label mb-0"
                              color="blue"
                              style={{
                                height: "8px",
                              }}
                              max={100}
                              value={0}
                            >
                            </Progress>)}
                        </Col>
                      </Row>
                      <span className="text-sm d-flex justify-content-between mt-3 mb--2" style={{
                        color: COLORS.glyphSecondary,
                        fontWeight: "400"
                      }}>
                        <span>{t("Reste à budgéter")}</span><span className="ml-1" style={{
                          color: `${dashboardState.globalValues.availableCurrentMonth >= 0 ? COLORS.glyphSecondary : COLORS.danger}`,
                          color: COLORS.glyphPrimary,
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}>
                          {FormatCurrency(
                            dashboardState.globalValues.availableCurrentMonth,
                            authState.user.currency_code,
                            authState.user.currency_locales
                          )}
                        </span>
                      </span>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs="12" sm="12" md="6" lg="6" xl="6" className="p-2">
                  <Card className="card-stats" style={{ borderRadius: "10px" }}>
                    <CardBody className="p-4" style={{ borderRadius: "10px" }}>
                      <Row>
                        <div className="col" style={{ position: "relative" }}>
                          <CardTitle
                            tag="h5"
                            style={{
                              color: COLORS.glyphSecondary,
                              fontWeight: "600"
                            }}
                            className="mb-0"
                          >
                            {t("Monthly Expenses")}
                          </CardTitle>
                          <span className="h2 mb-0" style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}>
                            {FormatCurrency(
                              dashboardState.globalValues.expensesCurrentMonth < 0 ?
                                dashboardState.globalValues.expensesCurrentMonth * -1 :
                                dashboardState.globalValues.expensesCurrentMonth,
                              authState.user.currency_code,
                              authState.user.currency_locales
                            )}
                          </span>
                          <span style={{
                            position: "absolute",
                            top: "-5px",
                            right: "10px",
                            backgroundColor: COLORS.primary
                          }}
                            className="icon icon-shape text-white rounded-circle shadow">
                            <img src={TransactionIcon} width="18px" height="18px" />
                          </span>
                        </div>
                      </Row>
                      <Row>
                        <Col>
                          {dashboardState.globalValues.expensesCurrentMonth !== 0 ? <Progress
                            className="progress-bar-animated progress-label mb-0"
                            color="blue"
                            style={{
                              height: "8px",
                            }}
                            max={Math.abs(dashboardState.globalValues.availableCurrentMonth) + Math.abs(dashboardState.globalValues.budgetedCurrentMonth)}
                            value={Math.abs(dashboardState.globalValues.expensesCurrentMonth)}
                          >
                          </Progress>
                            : (<Progress
                              className="progress-bar-animated progress-label mb-0"
                              color="blue"
                              style={{
                                height: "8px",
                              }}
                              max={100}
                              value={0}
                            >
                            </Progress>)}
                        </Col>
                      </Row>
                      <span className="text-sm d-flex justify-content-between mt-3 mb--2" style={{
                        color: COLORS.glyphSecondary,
                        fontWeight: "400"
                      }}>
                        <span>{t("Available")}</span><span className="ml-1" style={{
                          color: COLORS.glyphPrimary,
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}>
                          {FormatCurrency(
                            dashboardState.globalValues.availableAmountToSpent - Math.abs(dashboardState.globalValues.savingUpToSelectedMonth),
                            authState.user.currency_code,
                            authState.user.currency_locales
                          )}
                        </span>
                      </span>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="mt--4">
                <Col xs="12" sm="12" md="6" lg="6" xl="6" className="p-2 mt--2">
                  <Card className="card-stats" style={{ borderRadius: "10px" }}>
                    <CardBody className="p-4" style={{ borderRadius: "10px" }}>
                      <Row className="mb-2" style={{
                        position: "relative"
                      }}>
                        <Col xs="8" sm="8" md="8" lg="8" xl="8">
                          <div className="mb-3">
                            <span style={{
                              color: COLORS.glyphSecondary,
                              fontWeight: "400",
                              fontSize: "12px"
                            }}>{t("Épargne totale")}</span>
                            <div className="d-flex" style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}>
                              <span>+</span>
                              <span>
                                {FormatCurrency(
                                  dashboardState.globalValues.savingUpToSelectedMonth,
                                  authState.user.currency_code,
                                  authState.user.currency_locales
                                )}
                              </span>
                            </div>
                          </div>
                          <span style={{
                            color: COLORS.glyphSecondary,
                            fontWeight: "400",
                            fontSize: "12px"
                          }}>{t("Epargné ce mois")}</span>
                          <div>
                            <span className="h5 d-flex" style={{
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}>
                              <span> {dashboardState.globalValues.EpargneCurrentMonth > 0 && "+"}</span>
                              {FormatCurrency(
                                dashboardState.globalValues.EpargneCurrentMonth / 100,
                                authState.user.currency_code,
                                authState.user.currency_locales
                              )}
                            </span>
                          </div>
                        </Col>
                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          padding: "5px",
                          textAlign: "center",
                        }}>
                          <div className="d-flex d-flex justify-content-center align-items-center" style={{
                            background: hexToRgba(COLORS.primary),
                            height: "100%",
                            width: "70px",
                            borderRadius: "15px",
                            position: "absolute",
                            right: "15px"
                          }}>
                            <img src={SavingIcon} style={{
                              maxWidth: "32px",
                              minWidth: "15px",
                              maxHeight: "38px",
                              minHeight: "18px",
                            }} />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs="12" sm="12" md="6" lg="6" xl="6" className="p-2 mt--2">
                  <Card className="card-stats" style={{ borderRadius: "10px" }}>
                    <CardBody className="p-4" style={{ borderRadius: "10px" }}>
                      <Row className="mb-2" style={{
                        position: "relative"
                      }}>
                        <Col xs="8" sm="8" md="8" lg="8" xl="8">
                          <div className="mb-3">
                            <span
                              className="transactionTableCellCustomStyle3"
                              style={{
                                color: COLORS.glyphSecondary,
                                fontWeight: "400",
                                fontSize: "12px"
                              }}>{t("Cash Flow")}</span>
                            <div className="d-flex" style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}>
                              <span> {dashboardState.globalValues.entriesCurrentMonth + dashboardState.globalValues.totalExpensesUpToSeloctedMonth > 0 && "+"}</span>
                              <span>
                                {isNaN(dashboardState.globalValues.entriesCurrentMonth + dashboardState.globalValues.totalExpensesUpToSeloctedMonth) !== true &&
                                  (
                                    FormatCurrency(
                                      dashboardState.globalValues.entriesCurrentMonth + dashboardState.globalValues.totalExpensesUpToSeloctedMonth,
                                      authState.user.currency_code,
                                      authState.user.currency_locales
                                    )
                                  )}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <span className="h6">
                              {t("Entries")}
                            </span>
                            <span className="h5 d-flex" >
                              <span>+</span>
                              <span>
                                {FormatCurrency(
                                  dashboardState.globalValues.entriesCurrentMonth,
                                  authState.user.currency_code,
                                  authState.user.currency_locales
                                )}
                              </span>
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <span className="h6">
                              {t("Expenses")}
                            </span>
                            <span className="h5">
                              {FormatCurrency(
                                dashboardState.globalValues.totalExpensesUpToSeloctedMonth,
                                authState.user.currency_code,
                                authState.user.currency_locales
                              )}
                            </span>
                          </div>
                        </Col>
                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          padding: "5px",
                          textAlign: "center",
                        }}>
                          <div className="d-flex d-flex justify-content-center align-items-center" style={{
                            background: hexToRgba(COLORS.primary),
                            height: "100%",
                            width: "70px",
                            borderRadius: "15px",
                            position: "absolute",
                            right: "15px"
                          }}>
                            <img src={MoneyGraph} style={{
                              maxWidth: "32px",
                              minWidth: "15px",
                              maxHeight: "38px",
                              minHeight: "18px",
                            }} />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Col className="mb--3" md="12" xl="6" lg="6" sm="12" xs="12">
                  <div style={{ color: COLORS.glyphPrimary, fontWeight: "800", fontSize: "18px" }}>
                    {t("My Accounts")}
                  </div>
                </Col>
                <Col md="12" xl="6" lg="6" sm="12" xs="12" className="text-center">
                  <AccountSelect
                    data={accountState.accountTypes}
                    onChange={(data) => {setSelectedAccountTypes(data.value)}}
                  />
                </Col>
                <Col className="mt-4" md="12" xl="12" lg="12" sm="12" xs="12">
                  <div style={{ width: "100%", borderBottom: "1px solid lightgray" }}></div>
                </Col>
              </Row>
              <Row className="mt-4">
                {accountState?.accounts?.length !== 0 ? <DashboardAccount t={t} dataSource={accountState.accounts} selectedAccountTypes={selectedAccountTypes} /> : null}
              </Row>
              <Row>
                <Col md="12" xl="6" lg="6" sm="12" xs="12">
                  <div style={{ color: COLORS.glyphPrimary, fontWeight: "800", fontSize: "18px" }}>
                    {t("UpComing Bills")}
                  </div>
                </Col>
                <Col className="mb-2 mt-4" md="12" xl="12" lg="12" sm="12" xs="12">
                  <div style={{ width: "100%", borderBottom: "1px solid lightgray" }}></div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col className="h4 text-sm tex-gray d-flex justify-content-between">
                          <span>{t("Total amount of up coming bills")}</span>
                          <span className="h3 text-dark">
                            {
                              FormatCurrency(
                                totalUpcomingTransactions(),
                                authState.user.currency_code,
                                authState.user.currency_locales
                              )
                            }
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        {transactionState.futurTransactions ? transactionState.futurTransactions.map((tr) => (
                          <Col xl="12" lg="12" md="12" sm="12" xs="12" className="mb-1">
                            <Row>
                              <Col xl="2" lg="2" md="2" sm="2" xs="2">
                                <Badge bg={tr.transaction_amount > 1000 ? "danger" : "secondary"}>{formatDate(tr.date)}</Badge>
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="3" xs="3">
                                <span>{tr.categorie !== "-" ? tr.categorie : t("Transaction_Categorie_à_définir")}</span>
                              </Col>
                              <Col xl="3" lg="3" md="3" sm="3" xs="3">
                                <span> {
                                  FormatCurrency(
                                    Math.abs(tr.transaction_amount) / 100,
                                    authState.user.currency_code,
                                    authState.user.currency_locales
                                  )
                                }</span>
                              </Col>
                              <Col xl="4" lg="4" md="4" sm="4" xs="4">
                                <Badge className="ml-2 col" bg="primary">{tr.account}</Badge>
                              </Col>
                            </Row>
                            <br />
                          </Col>
                        )) : (
                          <Col md="12" xl="12" xs="12" lg="12" sm="12" className="mb--1 text-black text-center">
                            <img
                              alt="..."
                              width="100%"
                              height="100%"
                              style={{
                                margin: "auto",
                                maxHeight: "140px",
                                maxWidth: "140px",
                              }}
                              src={Image1}
                              className="mr-1"
                            />
                            <div style={{
                              fontSize: "10px",
                              fontWeight: "bold"
                            }}>{t("Good news! You have no upcoming bills")}</div>
                          </Col>
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs="12" sm="12" md="12" lg="5" xl="5">
              <Row className="mb-2">
                <Col className="text-start ml-4 h3">
                  {t("My Monthly CashFlow")}
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                  <Card style={{ borderRadius: "10px" }}>
                    <CardBody style={{ borderRadius: "10px" }}>
                      <Row>
                        <Col className="text-center h3 font-weight-bold">
                          <Line data={config ?? config} options={{
                            tooltips: {
                              callbacks: {
                                label: function (t, d) {
                                  return new Intl.NumberFormat(authState.user.currency_locales, {
                                    style: "currency",
                                    currency: authState.user.currency_code !== undefined ? authState.user.currency_code : "EUR",
                                  }).format(d['datasets'][0]['data'][t['index']]);
                                },
                              },
                            },
                            scales: {
                              yAxes: [
                                {
                                  display: true,
                                  gridLines: {
                                    display: true,
                                    zeroLineColor: "lightgray",
                                    color: "lightgray",
                                  },
                                  ticks: {
                                    beginAtZero: true,
                                    padding: 80,
                                    fontFamily: "'Poppins', sans-serif",
                                    stepSize: config?.datasets !== undefined && isNaN(config?.datasets[0]?.data[0]) === false ?
                                      parseInt(Math.abs(Math.max(...config?.datasets[0]?.data)) + Math.abs(Math.min(...config?.datasets[0]?.data))) / 3 : 1000,
                                    callback: value => {
                                      return new Intl.NumberFormat(authState.user.currency_locales, {
                                        style: "currency",
                                        notation: "compact",
                                        compactDisplay: "short",
                                        currency: authState.user.currency_code !== undefined ? authState.user.currency_code : "EUR",
                                      }).format(value)
                                    }
                                  },
                                }
                              ],
                              xAxes: [
                                {
                                  display: true,
                                  ticks: {
                                    padding: 1,
                                    fontFamily: "'Poppins', sans-serif",
                                  },
                                  gridLines: {
                                    display: false,
                                    zeroLineWidth: 1
                                  }
                                }
                              ]
                            },
                            legend: {
                              display: false
                            }
                          }} />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                  <SecBudgettWidget />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

Dashboard.prototype = {
  authState: PropTypes.object.isRequired,
  getSituationCompte: PropTypes.func.isRequired,
  budgetState: PropTypes.object.isRequired,
  dashboardSemestreDepense: PropTypes.func.isRequired,
  dashboardSemestreEntries: PropTypes.func.isRequired,
  transactionState: PropTypes.object.isRequired,
  getAccounts: PropTypes.func.isRequired,
  getAccountTypes: PropTypes.func.isRequired,
  getTransaction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  accountState: state.account,
  dashboardState: state.dashboard,
  authState: state.auth,
  budgetState: state.budget,
  transactionState: state.transaction,
});

export default connect(mapStateToProps, {
  getBudget,
  getSituationCompte,
  dashboardSemestreDepense,
  getAccounts,
  dashboardSemestreEntries,
  getTransaction,
  getAccountTypes
})(Dashboard);
