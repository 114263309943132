import React from "react";
import FormatCurrency from "./Currency";
import { COLORS } from "../../../assets/constants/theme";

export default function FormatCurrencyColored(amount, currencyCode, locale) {
  const color = amount >= 0 ? COLORS.success : COLORS.danger;
  return (
    <div style={{ color: color }}>
      {FormatCurrency(amount, currencyCode, locale)}
    </div>
  );
}
