import {
 UPDATE_PAYEE_API,
 DELETE_PAYEE_API,
} from "../api/payee";

import apiHelpers from "../helpers/api";

const { callApiHelper, isStillLogedIn } = apiHelpers;

// Action: Edit/Update an existing payee:
export const updatePayee = ({ payeeId, name }) => async (dispatch) => {
 try {
  const response = await callApiHelper(UPDATE_PAYEE_API, "put", {
   payee_id: payeeId,
   name: name
  }, true);

  await isStillLogedIn(response, dispatch);

 } catch (err) { }
};


// Action: Delete payee:
export const deletePayee = (payee) => async (dispatch) => {
 try {
  const response = await callApiHelper(DELETE_PAYEE_API, "delete", {
   payee_id: payee.payeeId,
  }, true);

  await isStillLogedIn(response, dispatch);

 } catch (err) { }
};
