import SavingBank from '../../assets/2x/money-bank@2x.png';
import MoneyWallet from '../../assets/2x/money-wallet-1@2x.png';
import DashboardIcon from '../../assets/2x/interface-setting-gauge-dashboard-1@2x.png';
import TransactionIcon from '../../assets/2x/interface-arrows-data-transfer-horizontal@2x.png';
import ReportIcon from '../../assets/2x/money-graph@2x.png';
import HelpeMeIcon from '../../assets/2x/interface-help-question-circle@2x.png';
import SettingsIcon from '../../assets/2x/interface-setting-cog@2x.png';
import TranslateIcon from '../../assets/2x/interface-text-formatting-translate@2x.png';
import EditeUserIcon from '../../assets/2x/interface-user-edit@2x.png';
import CurrencyIcon from '../../assets/2x/money-currency-euro@2x.png';
import PasswordIcon from '../../assets/2x/interface-lock-shield@2x.png';
import LogoutIcon from '../../assets/2x/interface-logout@2x.png';
import SynchronizeIcon from '../../assets/2x/interface-arrows-synchronize@2x.png';
import DeleteIcon from '../../assets/2x/interface-delete-bin-2@2x.png';
import CloseEyeIcon from '../../assets/2x/interface-edit-view-off@2x.png';
import OpenEyeIcon from '../../assets/2x/interface-edit-view@2x.png';
import SavingIcon from '../../assets/2x/money-cash-coins-stack@2x.png';
import MoneyGraph from '../../assets/2x/money-graph@4x.png';
import AddIcon from '../../assets/2x/interface-add-square@2x.png';
import SpaceIcon from '../../assets/2x/interface-arrows-vertical-expand-1@2x.png';
import BlackTransaction from '../../assets/2x/black/interface-arrows-data-transfer-horizontal@2x.png';
import BlackDateSelector from '../../assets/2x/black/interface-calendar-date@2x.png';
import BlackBudgetCategoriesSelector from '../../assets/2x/black/interface-dashboard-layout-3@2x.png';
import externalLink from '../../assets/2x/2x/share-external-link-1@2x.png';
import AccountingBills from '../../assets/2x/accounting-bills-1@2x.png';


const Icons = {
  SavingBank,
  MoneyWallet,
  DashboardIcon,
  TransactionIcon,
  ReportIcon,
  HelpeMeIcon,
  SettingsIcon,
  TranslateIcon,
  EditeUserIcon,
  CurrencyIcon,
  PasswordIcon,
  LogoutIcon,
  SynchronizeIcon,
  SpaceIcon,
  DeleteIcon,
  SynchronizeIcon,
  CloseEyeIcon,
  OpenEyeIcon,
  SavingIcon,
  AddIcon,
  MoneyGraph,
  BlackTransaction,
  BlackDateSelector,
  BlackBudgetCategoriesSelector,
  externalLink,
  AccountingBills
}

export default Icons;