import React from "react";
import { useHistory } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import sideImage from "../assets/img/Views/Delete User Account.png"
import Logo from "../assets/img/brand/fineo-login-logo.png";
import StandardButton from "components/Buttons/standardButton/StandardButton";
import { COLORS } from "assets/constants/theme";

export default function Confirm_delete(props) {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Row className="d-flex align-items-center" style={{
      height: "100vh"
    }}>
      <Col xl="6" lg="6" md="6" xs="12" sm="12" className="d-flex align-items-center" style={{
        height: "100%",
        textAlign: "center",
        justifyContent: "center"
      }}>
        <div style={{
          backgroundColor: COLORS.glyphLight,
          height: "100%",
          borderRadius: "8px",
          width: "100%",
          textAlign: "center",
        }}>
          <div style={{
            height: "auto",
            marginTop: "10%",
            marginBottom: "10%",
          }}>
            <img
              alt="..."
              style={{
                minHeight: "150px",
                minWidth: "150px",
                maxWidth: "450px",
                maxHeight: "400px"
              }}
              src={sideImage}
            />
            <div style={{
              fontWeight: "bolder"
            }}>
              <h1 style={{
                fontWeight: "bolder"
              }}>
                {t("Join Us At Fineo !")}
              </h1>
              <h1 style={{
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
                fontWeight: "bolder"
              }}>
                {t("Manage all your money from one single app and take back control of your finances")}
              </h1>
            </div>
          </div>
        </div>
      </Col>
      <Col xl="6" lg="6" md="6" xs="12" sm="12">
        <div style={{
          width: "60%",
          marginLeft: "auto",
          marginRight: "auto"
        }}>
          <img src={Logo} style={{
            height: "65px",
            width: "190px",
            marginBottom: "15px",
            marginTop: "10px",
            marginLeft: "-20px"
          }} />
          <h1 className="mb-4">
            {t("Your account has been deleted successfully")}
          </h1>
          <div>
            <div className="my-2">
              {t(
                "We're sorry to see you go"
              )}
            </div>
            <div>
              {t(
                "Your account has been deleted and the data associated with it is no longer in our system"
              )}
            </div>
            <StandardButton
              className="my-4"
              type="button"
              id="btnConfirm"
              onClick={() => history.push(`/auth/login`)}
              style={{
                fontSize: "1rem"
              }}
              text={t("Return to login page")}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}
