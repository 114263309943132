import React from "react";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/creatable";
import { COLORS } from "assets/constants/theme";
import { STYLES } from "assets/constants/theme";

class SelectPayee extends React.Component {
  constructor(props) {
    super(props);
    this.value = props.value;
  }

  static propTypes = {
    value: PropTypes.string,
    onUpdate: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: "",
  };

  getValue(option) {
    try {
      if (option !== undefined && option !== null) {
        if (this.props.row.key !== "-1") {
          const isItAnAccount = this.props.accountsData.filter(
            (account) => account.account === option.value
          );
          if (isItAnAccount.length !== 0) {
            this.props.handleSave({
              ...this.props.row,
              payee: isItAnAccount[0].key,
              transaction_amount: parseFloat(this.props.row.transaction_amount) / 100,
            });
          }
          if (isItAnAccount.length === 0) {
            this.props.handleSave({
              ...this.props.row,
              payee: option.value,
              transaction_amount:
                parseFloat(this.props.row.transaction_amount) / 100,
            });
          }
          return option.value;
        }
        return option.value
      }
    } catch (error) {
    }
  }

  cancel(){
    return '';
  }

  render() {
    const { value, row, onUpdate, beneficiaryData, accountsData, t, setOpenBeneficiaireModal, ...rest } = this.props;

    const mainOptions = [];
    const accountOptions = [];
    accountsData
      .filter((account) => account.is_it_closed === false)
      .forEach((account) => {
        accountOptions.push({
          key: account.key,
          value: account.account,
          label: `${account.account}`,
        });
      });

    mainOptions.push({
      key: 1,
      label: t("Transfertvers"),
      options: accountOptions,
    });

    if (row.is_transfer === false) {
      const payeeOptions = [];
      beneficiaryData.forEach((payee) => {
        payeeOptions.push({
          key: `${payee.value} ${payee.key}`,
          value: payee.value,
          label: `${payee.value}`,
        });
      });

      mainOptions.push({
        key: Math.random() * Math.random(),
        label: <span className="ml-1"
          style={{
            cursor: "pointer",
            display: "flex",
            "justifyContent": "space-between"
          }}
          onClick={() => {
            setOpenBeneficiaireModal(true);
          }}>
          <span>{t("Beneficiaire")}</span>
          <i class="fas fa-cog"></i>
        </span>,
        options: payeeOptions,
      });
    }

    sessionStorage.setItem("payeeObjectHanler", JSON.stringify({ key: value, value: value, label: value }))
    return [
      <CreatableSelect
        {...rest}
        autoFocus
        isClearable={true}
        id="payeeSelect"
        styles={
          {
            control: (provided, state) => ({
              ...provided,
              ...STYLES.selectStyle,
              borderColor: state.isFocused ? COLORS.glyphSecondary : COLORS.glyphSecondary,
              "&:hover": {
                borderColor: COLORS.glyphSecondary, // Customize the border color on hover
              },
            }),
            option: (provided, state) => ({
              ...provided,
              textAlign: 'left'
            }),
            groupHeading: (provided) => ({
              ...provided,
              textAlign: 'left', // Align the options group to the left
            }),
          }
        }
        key={Math.random()}
        options={mainOptions}
        placeholder={this.props.t("Transaction_Sélectionner")}
        formatCreateLabel={(inputValue) => row.is_transfer === false && <span>{t("Create")}: {inputValue}</span>}
        defaultInputValue={row.is_transfer === false ? value : value.split(": ")[1]}
        ref={(node) => (this.value = node)}
        onInputChange={(value) => {
          if (value === "" || value === null) {
            sessionStorage.setItem("payeeObjectHanler", JSON.stringify({ key: value, value: value, label: value }))
          }
        }}
        onChange={(option) => {
          sessionStorage.setItem("payeeObjectHanler", JSON.stringify({ key: value, value: value, label: value }))
          onUpdate(this.getValue(option));
        }}
        onBlur={() => {
          onUpdate(this.getValue(JSON.parse(sessionStorage.getItem("payeeObjectHanler"))))
        }}
        onKeyDown={(val) => {
          if (val.key === "Enter") {
            onUpdate(this.getValue(JSON.parse(sessionStorage.getItem("payeeObjectHanler"))))
          }
          if (val.key === "Escape") {
            onUpdate(this.cancel())
          }
        }}
      />,
    ];
  }
}

export default SelectPayee;
