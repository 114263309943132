import moment from "moment";

const adjustDateFormatForView = (date) => {
  if (moment(date).isValid()) {
    return moment(date).format('DD/MM/YYYY');
  }

  return date;
};

const adjustDateFormatForApi = (date) => {
  if (moment(date).isValid()) return moment(date).format("YYYY-MM-DD hh:mm:ss");
  const newDate = date.toString().split("/").reverse().join("-");
  return moment(newDate).format("YYYY-MM-DD hh:mm:ss")
};

const dateHelpers = {
  adjustDateFormatForView,
  adjustDateFormatForApi
}
export default dateHelpers;