import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardTitle, Table, Row, Col } from "reactstrap";
import StandardButton from "components/Buttons/standardButton/StandardButton";
import EmptyTableImage from "../../assets/img/Views/You have no accounts.png"
import formatDate from "helpers/formatDate";
import { COLORS } from "assets/constants/theme";
import CustomTag from "./../tags/CustomTag"
import { StringManipulator } from "@fineo-io/main-javascript-library";
import moment from "moment";

export default function BillingC({
  t,
  authState,
  stripeState,
  getHistoryPaiment,
  createCustomerPortalSession,
}) {
  const history = useHistory();

  useEffect(() => {
    getHistoryPaiment();
  }, []);

  const CurrentPlanCard = () => {
    let startDate = formatDate(stripeState?.historyPaiment[0]?.created_at);
    let subscriptionStart = moment(startDate, 'YYYY/MM/DD');
    return (
      <Card className="bg-gradient-default">
        <CardBody
          style={{
            height: "150px",
          }}
        >
          <Row>
            <div className="col">
              <CardTitle className="text-uppercase text-muted mb-0 text-white">
                {t("Current plan I")}
              </CardTitle>
              <span className="h1 text-muted  mb-0 mt-2 text-white">
                {authState.user.subscription_id === 1 && stripeState.historyPaiment[0] ?
                  `Plus ${t(stripeState.historyPaiment[0]?.interval)}` :
                  authState?.user?.subscription_id === 2 ? t("Free") : authState?.user?.subscription_id === 2 ?
                    t("Super User") : ""}
              </span>
              <div className="h4 text-muted  mb-0 mt-2 text-white">
                {authState?.user?.subscription_id === 1 && moment().diff(subscriptionStart, "days") < 0 && (t("renews_on") + ' ' + formatDate(stripeState?.historyPaiment[0]?.created_at))}
                {authState?.user?.subscription_id !== 1 && t("Lifetime")}
              </div>
            </div>
            <Col className="col-auto">
              <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                <i className="fas fa-credit-card"></i>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  const SubscribeCallToActionCard = () =>
    <>
      <CardTitle className="text-uppercase font-weight-bold text-muted mb-1">
        <Row>
          <Col>
            {t(
              "Subscribe and take back control of your finances."
            )}
          </Col>
          <Col className="col-auto">
            <div className="icon icon-shape bg-yellowF text-dark rounded-circle shadow">
              <i className="far fa-credit-card"></i>
            </div>
          </Col>
        </Row>
      </CardTitle>
      <Row>
        <Col>
          <StandardButton
            text={t("Subscribe Now")}
            className="w-100 mr-1 border rounded float-right mt-2"
            style={{ borderRadius: 8, float: "right", fontSize: "11px" }}
            size="md"
            onClick={() => {
              history.push("/app/payment");
            }}
          >
            <span>
              <i className="far fa-star"></i>{" "}
            </span>
          </StandardButton>
        </Col>
      </Row>
    </>

  const ManageSubscribtionCard = () => {
    if (stripeState?.historyPaiment[0] === undefined) return <></>
    const { UpComing, amount, created_at, currencySymbol, interval } = stripeState?.historyPaiment[0];
    return <>
      <Row>
        <div className="col">
          <>
            <CardTitle className="text-uppercase text-muted mb-0">
              {t("Next payment") + t("colon")}
              <span className="font-weight-bold text-nowrap">
                {UpComing === true ? formatDate(created_at) : '-'}
              </span>
            </CardTitle>
            <p className=" mb-0">
              <span className=" font-weight-bold text-nowrap">
                {StringManipulator.capitalize(t("global.recurrence._")) + t("colon") + (UpComing === true ? t(interval) : '-')}
              </span>
            </p>
            <span className="h2 font-weight-bold mb-0">
              {UpComing === true && ((amount / 100) + " " + String(currencySymbol).toUpperCase())}
              {UpComing === false && (<span className="text-red">{t("canceled")}</span>)}
            </span>
          </>
        </div>
        <Col className="col-auto">
          <div className="icon icon-shape bg-yellowF text-dark rounded-circle shadow">
            <i className="far fa-credit-card"></i>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <StandardButton
            text={t("Edit Subscription")}
            className="w-100 mr-1 border rounded float-right mt-2"
            style={{ borderRadius: 8, float: "right", fontSize: "11px" }}
            size="md"
            onClick={() => {
              createCustomerPortalSession();
            }}
          >
            <span>
              <i className="far fa-star"></i>{" "}
            </span>
          </StandardButton>
        </Col>
      </Row>
    </>
  }

  const PayemntHistoryTable = () =>
    <Row>
      <Col>
        <div className="h2">{t("Payment history")}</div>
        <Table className="align-items-center mt-3">
          <thead className="thead-light">
            <tr>
              {["Date", "Subscription", "Service Start", "Service End", "Amount", "Status"].map((n) => <th scope="col" className="text-center">{t(n)} </th>)}
            </tr>
          </thead>
          <tbody>
            {authState.user.subscription_id === 1 && (stripeState?.historyPaiment?.map((element) => (
              <tr key={Math.random()}>
                <td style={{ borderTop: "none" }} className="text-center">
                  {formatDate(element.date)}
                </td>
                <td style={{ borderTop: "none" }} className="text-center">
                  {t(element.interval)}
                </td>
                <td style={{ borderTop: "none" }} className="text-center">
                  {formatDate(element.created_at)}
                </td>
                <td style={{ borderTop: "none" }} className="text-center">
                  {formatDate(element.end_at)}
                </td>
                <th style={{ borderTop: "none" }} className="text-center">
                  <span className="mb-0 text-sm">{element.amount / 100 + " " + String(stripeState.historyPaiment[0]?.currencySymbol).toUpperCase()}</span>
                </th>
                <td style={{ borderTop: "none" }} className="text-center">
                  <CustomTag
                    onClick={() => { }}
                    children={<span>{StringManipulator.titleize(t(element.status))}</span>}
                    color={element.status === "scheduled" ? COLORS.glyphSecondary : COLORS.success}
                  />
                </td>
              </tr>
            )))}
          </tbody>
        </Table>
        {authState.user.subscription_id !== 1 && (
          <Row>
            <Col md="12" xl="12" xs="12" lg="12" sm="12" className="text-gray text-center mb--1 mt--1">
              <img
                alt={t("no_history")}
                width="100%"
                style={{
                  margin: "auto",
                  maxHeight: "180px",
                  maxWidth: "180px",
                }}
                src={EmptyTableImage}
                className="mr-1"
              />
              <div>{t("no_history")}</div>
            </Col>
          </Row>
        )}
      </Col>
    </Row>

  return (
    <>
      <Card className="mb-6">
        <CardBody>
          <Row>
            <Col>
              <h1 className="h1 font-weight-bold">{t("Billing details")}</h1>
            </Col>
          </Row>
          <Row className="mb-4 mt-4 mx-2">
            <Col lg="6">
              {CurrentPlanCard()}
            </Col>
            <Col lg="6">
              <Card className="bg-gradient-white">
                <CardBody
                  style={{
                    height: "150px",
                  }}
                >
                  {authState.user.subscription_id === 1 && ManageSubscribtionCard()}
                  {authState.user.subscription_id === 2 && SubscribeCallToActionCard()}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <PayemntHistoryTable />
        </CardBody>
      </Card>
    </>
  );
}
