import React, { useEffect, useState } from "react";
import { isBrowser } from 'react-device-detect';
import { Helmet } from 'react-helmet-async';
import { StringManipulator } from "@fineo-io/main-javascript-library";
import { message } from "antd";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { resendValidationEmail } from "../actions/Auth";
import { useTranslation } from "react-i18next";
import Logo from "../assets/img/brand/fineo-login-logo.png";
import sideImage from "../assets/img/Views/User Email Verification.png"
import StandardButton from "components/Buttons/standardButton/StandardButton";
import { COLORS } from "assets/constants/theme";
function EmailVerification({ resendValidationEmail }) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  useEffect(() => {
    setEmail(sessionStorage.getItem("email"));
    sessionStorage.removeItem("email");
  }, []);
  return (
    <Row className="d-flex align-items-center" style={{
      height: "100vh"
    }}>
      <Helmet>
        <title>Fineo | {StringManipulator.capitalize(t("email_verification_title"))}</title>
      </Helmet>
      {isBrowser && (<Col xl="6" lg="6" md="6" xs="12" sm="12" className="d-flex align-items-center" style={{
        height: "100%",
        textAlign: "center",
        justifyContent: "center"
      }}>
        <div style={{
          backgroundColor: COLORS.glyphLight,
          height: "90%",
          borderRadius: "8px",
          width: "90%",
          textAlign: "center",
        }}>
          <img
            alt="..."
            style={{
              minHeight: "150px",
              minWidth: "150px",
              maxWidth: "450px",
              maxHeight: "400px"
            }}
            src={sideImage}
          />
          <div style={{
            fontWeight: "bolder"
          }}>
            <h1 style={{
              fontWeight: "bolder"
            }}>
              {t("Join Us At Fineo !")}
            </h1>
            <h1 style={{
              width: "90%",
              marginLeft: "auto",
              marginRight: "auto",
              fontWeight: "bolder"
            }}>
              {t("Manage all your money from one single app and take back control of your finances")}
            </h1>
          </div>
        </div>
      </Col>)}

      <Col xl="6" lg="6" md="6" xs="12" sm="12">
        <div style={{
          width: isBrowser ? "60%" : "80%",
          marginLeft: "auto",
          marginRight: "auto"
        }}>
          <img src={Logo} style={{
            height: "65px",
            width: "190px",
            marginBottom: "15px",
            marginLeft: "-20px"
          }} />
          <h1 className="mb-4">
            {t("Email_Verification_Vérification_de_votre_Email")}
          </h1>
          <div>
            <b className="my-2">
              {t("Email_Verification_Nous_avons_envoyé_un_mail_de_vérification_à_l’adresse")}
            </b>
            <h2 style={{
              textAlign: "center"
            }}
              className="my-2"
            >
              {email}
            </h2>
            <p style={{
              fontWeight: "bolder"
            }}>
              {t("Email_Verification_Si_vous_ne_le_recevez_pas_dans_les_prochaines_minutes_,_Vérifiez_votre_courrier_indésirable_ou")}
              <a
                href="#"
                onClick={() => {
                  resendValidationEmail(email, message, t);
                }}
              >
                {" "}
                {t("Email_Verification_cliquez_ici_pour_renvoyer_le_mail")}.
              </a>
            </p>
            <Link to="/auth/login">
              <StandardButton
                className="my-4"
                style={{
                  width: "100%"
                }}
                type="button"
                text={t("Email_Verification_Continuez")}
              />
            </Link>
          </div>
        </div>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => ({});
EmailVerification.prototype = {
  resendValidationEmail: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, {
  resendValidationEmail,
})(EmailVerification);
