import { COLORS } from 'assets/constants/theme';
import hexToRgba from 'helpers/hexToRgba';
import React from 'react';

const CustomTag = ({ children, color, onClick = undefined }) => {
  const backgroundStyle = {
    background: hexToRgba(color, 0.8),
    border: `1px solid ${color}`,
    borderRadius: '10px',
    padding: '0px 7px',
    display: 'inline-block',
    textAlign: 'center',
    color: COLORS.neutral,
    fontWeight: '600',
    cursor: onClick ? "pointer" : "default"
  };

  return <div onClick={onClick} style={backgroundStyle}>{children}</div>;
};

export default CustomTag;
