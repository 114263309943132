import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logOut, cleanAuth } from "../../actions/Auth";
import { cleanBudget } from "../../actions/Budget";
import { cleanTransaction } from "../../actions/Transaction";
import { cleanReport } from "../../actions/Reports";
import { cleanAccount } from "../../actions/Account";
import { cleanProfile } from "../../actions/Profile";
import { useTranslation } from "react-i18next";
import { COLORS } from "assets/constants/theme";
import {
  Popover,
  PopoverBody,
} from "reactstrap";
import hexToRgba from "helpers/hexToRgba";
const { REACT_APP_COMMENCER_LINK, REACT_APP_DOCS_FAQ } =
  process.env;

function AdminInfo({
  history,
  authState,
  logOut,
  cleanAccount,
  cleanAuth,
  cleanBudget,
  cleanReport,
  toggleBugModal,
  cleanTransaction,
  cleanProfile,
}) {
  const { t } = useTranslation();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const outerRef = useRef(null);
  const innerRef = useRef(null);
  const popoverRef = useRef(null);
  const handleClickOutside = (event) => {
    // Close the popover if a click occurs outside of it
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setPopoverOpen(false);
    }
  };

  useEffect(() => {
    // Attach the click event listener when the popover is open
    if (popoverOpen) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      // Clean up the click event listener when the component unmounts or popover is closed
      document.removeEventListener("click", handleClickOutside);
    };
  }, [popoverOpen]);

  const togglePopOverOpen = () => {
    setPopoverOpen(!popoverOpen);
  }
  const OurAvatar = () => {
    return (
      <span className="col-sm avatar avatar-sm rounded-circle" style={
        {
          fontWeight: "bolder", fontFamily: "poppins",
          fontSize: "12px",
          backgroundColor: COLORS.secondary
        }
      }>
        {authState.user.name !== undefined &&
          authState.user.name.toString().split(" ").length > 0 ?
          authState.user.name.toString().split(" ")[0].charAt(0) : ""
        }
      </span>
    )
  }

  const MenuButton = ({ onClick, className, text }) => {
    return (
      <div
        onClick={onClick}
        style={{ color: COLORS.glyphPrimary, paddingBottom: "10px", cursor: "pointer", display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "10px", marginBottom: "10px", borderBottom: `1px solid ${hexToRgba(COLORS.glyphPrimary)}` }}>
        <div>
          <i className={`fa ${className} fa-2x`} ></i>
        </div>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", flex: 1, marginLeft: "20px", maxWidth: "160px", overflow: "hidden", whiteSpace: "nowrap" }}>
          <span style={{ overflow: "hidden", textOverflow: "ellipsis", fontSize: "14px" }}>{text}</span>
        </div>
      </div>
    )
  }


  const handleLogout = async () => {
    await logOut(history);
    await cleanAccount();
    await cleanAuth();
    await cleanProfile();
    await cleanBudget();
    await cleanTransaction();
    await cleanReport();
    history.push('/auth/login');
    document.getElementById("logoutlink").click();
  }
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center", cursor: "pointer" }}>
      <div
        onClick={() => {
          if (innerRef.current) {
            innerRef.current.click();
          }
        }}
        ref={outerRef}
        className="row text-white mt-4" style={{
          background: "transparent",
          border: `1px solid ${COLORS.neutral}`,
          borderRadius: "5px",
          width: "100%",
          display: "flex",
          alignItems: "center"
        }}>
        <div className="p-2" style={{ display: "flex", minWidth:"88%", maxWidth: "88%" }}>
          <div>
            <OurAvatar />
          </div>
          <span
            className="col-sm py-2"
            style={{
              color: COLORS.glyphPrimary,
              fontSize: "13px",
              fontWeight: "bolder",
              fontFamily: "poppins",
              cursor: "pointer",
              color: COLORS.neutral,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            title={authState?.user?.name}
          >
            {authState?.user?.name}
          </span>
        </div>
        <button
          type="button"
          id="logoutPopover"
          ref={innerRef}
          style={{
            border: 'none', padding: '0px', margin: '0',
            background: 'none', cursor: 'pointer', display: "flex",
            minWidth: "12%", height: "100%",
            borderRadius: "5px",
            borderLeft: `1px solid ${COLORS.neutral}`,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <i className="fa fa-caret-up fa-1x" style={{marginLeft:"auto", marginRight:"auto"}} ></i>
        </button>
      </div>
      <Popover
        style={{ width: "270px" }}
        placement="top-end"
        isOpen={popoverOpen}
        target="logoutPopover"
        toggle={togglePopOverOpen}
        innerRef={popoverRef}
      >
        <PopoverBody className="bg-white" style={{ display: "flex", flexDirection: "column", borderRadius: "15px" }}>
          <div style={{
            display: "flex", flexDirection: "row", justifyContent: "center",
            marginTop: "10px", marginBottom: "10px", background: COLORS.glyphLight,
            paddingTop: "5px",
            paddingBottom: "5px",
            paddingLeft: "5px",
            borderRadius: "5px"
          }}>
            <div>
              <OurAvatar />
            </div>
            <div style={{ display: "flex", flexDirection: "column", flex: 1, marginLeft: "10px", maxWidth: "200px", overflow: "hidden", whiteSpace: "nowrap" }}>
              <span style={{ overflow: "hidden", textOverflow: "ellipsis", fontSize: "14px" }}>{authState?.user?.name}</span>
              <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{authState?.user?.email}</span>
            </div>
          </div>
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            {/* <MenuButton className={"fa-bug"} text={t("provide_feedback")} onClick={toggleBugModal} /> */}
            <MenuButton className={"fas fa-sign-out-alt"} text={t("NavBar_Se_deconnecter")} onClick={handleLogout} />
          </div>
        </PopoverBody>
      </Popover>
    </div>
  );
}

AdminInfo.prototype = {
  authState: PropTypes.object.isRequired,
  logOut: PropTypes.func.isRequired,
  cleanAccount: PropTypes.func.isRequired,
  cleanAuth: PropTypes.func.isRequired,
  cleanBudget: PropTypes.func.isRequired,
  cleanReport: PropTypes.func.isRequired,
  cleanTransaction: PropTypes.func.isRequired,
  cleanProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authState: state.auth,
});

export default connect(mapStateToProps, {
  logOut,
  cleanAccount,
  cleanAuth,
  cleanBudget,
  cleanReport,
  cleanTransaction,
  cleanProfile,
})(AdminInfo);
