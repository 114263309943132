import { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { message } from "antd";
import Icons from "../Randoms/Icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getUserAccountInfo } from "../../actions/Auth";
import { getBudget, getGlobalValues } from "../../actions/Budget";
import { getAccounts, getAccountHeader } from "../../actions/Account";
import {
  getTransaction,
  getCategories,
  getPayees,
} from "../../actions/Transaction";
import SubscriptionPlan from "../Stripe/SubscriptionPlan";
import apiHelpers from "../../helpers/api";
import StandardButton from 'components/Buttons/standardButton/StandardButton';
import DefaultModal from 'components/Modals/DefaultModal';
import { COLORS } from 'assets/constants/theme';
import { StringManipulator } from '@fineo-io/main-javascript-library';
const { callApiHelper } = apiHelpers;
const { SpaceIcon } = Icons;

function Spaces({
  t,
  history,
  location,
  getTransaction,
  getPayees,
  getAccounts,
  getAccountHeader,
  getUserAccountInfo,
  authState
}) {
  const [selectedSpace, setSelectedSpace] = useState("");
  const [refersh, setRefersh] = useState(false);
  const [addNewSpaceName, setaddNewSpaceName] = useState("")
  const [isInAddMode, setIsInAddMode] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [targetSpace, setTargetSpace] = useState({ editSpaceName: "", editSpaceId: ""});

  const getNewestSpace = () => authState?.user?.space_ids[authState?.user?.space_ids?.length - 1];
  const loadActiveSpace = async () => {
    setRefersh(false);
    sessionStorage.setItem("SESSION_SPACE", getNewestSpace().space_id);
    sessionStorage.setItem("SESSION_SPACE_NAME", getNewestSpace().space_name);
    setSelectedSpace(getNewestSpace().space_name);
    localStorage.setItem("SPACE", Math.random());
    await getTransaction(true, t);
    await getPayees();
    await getAccounts(getNewestSpace().space_id);
    await getAccountHeader();
    history.push(location.pathname);
  }

  useEffect(() => {
    if (refersh) {
      loadActiveSpace();
    }
  }, [refersh])

  return (
    <div style={{ width: "100%" }}>
      <UncontrolledDropdown
        title={sessionStorage.getItem("SESSION_SPACE_NAME") != "" ? sessionStorage.getItem("SESSION_SPACE_NAME") : selectedSpace}
        style={{
          width: "100%",
          border: `1px solid ${COLORS.neutral}`,
          borderRadius: "3px",
          position: "relative"
        }}
        className="mt--1 mb-5 flex-column" group direction="down">
        <DropdownToggle
          style={{
            backgroundColor: "transparent",
            border: "none",
            color: COLORS.neutral,
            marginRight: "8px",
            fontWeight: "600",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", fontSize: "14px", color: COLORS.neutral, width: "100%" }} >
            <span
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "calc(100% - 18px)", // Adjust the width to accommodate the icon
              }}
            >
              {sessionStorage.getItem("SESSION_SPACE_NAME") != "" ? sessionStorage.getItem("SESSION_SPACE_NAME") : selectedSpace}
            </span>
            <span style={{ marginLeft: "auto" }}>
              <img src={SpaceIcon} height="18px" width="18px" />
            </span>
          </div>
        </DropdownToggle>
        <DropdownMenu style={{ width: "100%" }}>
          {authState?.user?.space_ids?.map((space, index) => (
            <DropdownItem
              key={index}
              className="d-flex flex-row w-100"
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              <div
                style={{ paddingLeft: "6px", paddingRight: "12px" }}
                onClick={() => {
                  setTargetSpace({
                    editSpaceName: space.space_name,
                    editSpaceId: space.space_id,
                  })
                  setIsInEditMode(true)
                }}
              >
                <i className="fas fa-ellipsis-v"></i>
              </div>
              <div
                className="bg-transparent border-0"
                style={{
                  width: "90%",
                  textAlign: "start",
                  paddingRight: "10px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                onClick={async () => {
                  if (space.space_id !== -1) {
                    sessionStorage.setItem("SESSION_SPACE", space.space_id);
                    sessionStorage.setItem("SESSION_SPACE_NAME", space.space_name);
                    setSelectedSpace(space.space_name);
                    localStorage.setItem("SPACE", Math.random());
                    await getTransaction(true, t);
                    await getPayees();
                    await getAccounts(space.space_id);
                    await getAccountHeader();
                    history.push(location.pathname);
                  }
                }}
              >
                {space.space_name}
              </div>
            </DropdownItem>
          ))}
          <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
          <DropdownItem>
            <div
              style={{ display: "flex", width: "100%", textAlign: "start" }}
              onClick={async () => {
                const response = await callApiHelper('account/checkuseraccountplan', "post", { space_id: targetSpace.editSpaceId }, true);
                setaddNewSpaceName("");
                if (response.success === true) {
                  if (
                    response.messages === "is in free mode" ||
                    response.messages === "is a plus user" ||
                    response.messages === "is a super user"
                  ) {
                    setIsInAddMode(!isInAddMode)
                  }
                } else {
                  if (response.messages.workspace) {
                    setShowPlanModal(true);
                  } else {
                    setIsInAddMode(!isInAddMode);
                  }
                }
              }}
            >
              <div className="bg-transparent border-0" style={{ width: "10%" }}>
                <i className="fas fa-plus-circle text-black"></i>
              </div>
              <div style={{ width: "90%" }}>
                {StringManipulator.capitalize(t("global.new.workspace"))}
              </div>
            </div>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>

      {/* Add Space */}
      <DefaultModal
        size={"modal-md"}
        isOpen={isInAddMode}
        title={StringManipulator.titleize(t("global.new.workspace"))}
        toggle={() => {
          setIsInAddMode(!isInAddMode);
        }}
        disabled={addNewSpaceName.trim() == "" ? true : false}
        btnText={t("Save")}
        onClick={async () => {
          const response = await callApiHelper("space/addspace", "post", {
            space_name: addNewSpaceName.trim(),
            lang: ""
          }, true);

          if (response.messages.payload == true) {
            await getUserAccountInfo();
            message.success(t("Space added successfully"), 7);
            setIsInAddMode(false);
            localStorage.setItem("SPACE", Math.random());
            setRefersh(true);
          } else if (response.messages.payload == false) {
            message.success(t("Space name already exists"), 7);
          }
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Row>
            <Col className="justify-content-sm-between text-default mt-3">
              <h4 className="text-center h4">
                {t(
                  "This space will have new accounts, budgets, transactions, this action won't affect your current space."
                )}{" "}
              </h4>
            </Col>
          </Row>
          <Row className="mt-5" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Col className="col-4">
              <h4 className="text-center h4">{t("Space name")}: </h4>
            </Col>
            <Col className="col-8">
              <Input
                className="m-auto w-100 ourCustomInputStyle"
                onChange={(e) => {
                  setaddNewSpaceName(e.target.value)
                }}
              />
            </Col>
          </Row>
        </div>

      </DefaultModal>

      {/* Edit Space */}
      <DefaultModal
        size={"modal-md"}
        isOpen={isInEditMode}
        toggle={async () => {
          setIsInEditMode(false)
        }}
        title={t("Space Settings")}
        hasNoButtons={true}
        bodyStyle={{
          justifyContent: "flex-start"
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <hr style={{ borderColor: COLORS.glyphSecondary, width: "100%" }} />
          <div className="input-group mb-2">
            <span className="input-group text-gray">{t("Space name")}</span>
          </div>
          <div className="input-group mb-3">
            <input
              value={targetSpace.editSpaceName}
              onChange={(e) => {
                setTargetSpace({
                  ...targetSpace,
                  editSpaceName: e.target.value
                })
              }}
              type="text"
              className="ourCustomInputStyle form-control mr-2 flex-1"
              aria-label="Default"
              aria-describedby="inputGroup-sizing-default"
            />
            <StandardButton
              type="button"
              outline
              onClick={async () => {
                if (targetSpace.editSpaceName === "") return;
                const response = await callApiHelper("space/editspace", "post", {
                  space_id: targetSpace.editSpaceId,
                  space_name: targetSpace.editSpaceName
                }, false);
                if (response.messages.payload == true) {
                  await getUserAccountInfo();
                  setSelectedSpace(targetSpace?.editSpaceName)
                  message.success(t("space edited Successfully"), 7);
                  if (sessionStorage.getItem("SESSION_SPACE") == targetSpace?.editSpaceId) {
                    sessionStorage.setItem("SESSION_SPACE_NAME", targetSpace?.editSpaceName)
                  }
                  setIsInEditMode(false);
                  setTargetSpace({
                    editSpaceName: "",
                    editSpaceId: ""
                  })
                } else {
                  setIsInEditMode(false);
                  message.error(t("Something went wrong"), 10);
                }
              }}
              text={t("change space name")}
            />
          </div>
          <hr style={{ borderColor: COLORS.glyphSecondary, width: "100%" }} />
          <div className="input-group mb-1">
            <StandardButton
              text=""
              color={COLORS.danger}
              onClick={() => {
                setIsInEditMode(false)
                setIsDeleteMode(true);
              }}
            >
              <i className="fas fa-trash-alt" />
              <span>{StringManipulator.capitalize(t("global.delete._"))}</span>
            </StandardButton>
          </div>
        </div>

      </DefaultModal>

      {/* Delete Space */}
      <DefaultModal
        title={t("global.warning._")}
        size={"modal-md"}
        isOpen={isDeleteMode}
        toggle={() => {
          setIsDeleteMode(false)
        }}
        color={COLORS.danger}
        hasTwoButtons={true}
        onClick={async () => {
          const response = await callApiHelper("space/deletespace", "post", {
            space_id: targetSpace.editSpaceId
          }, false);
          if (response.messages.payload == true) {
            message.success(t("space deleted Successfully"), 7);
            setIsDeleteMode(false);
            setIsInEditMode(false);
            sessionStorage.removeItem("SESSION_SPACE");
            await getUserAccountInfo();
            await getTransaction(true, t);
            await getPayees();
            await getAccounts(authState?.user?.space_ids[0].space_id);
            await getAccountHeader();
            return history.push("/");
          } else {
            setIsDeleteMode(false);
            setIsInEditMode(false);
            message.error(
              t("you can't delete a space when you only have one."),
              10
            );
          }
        }}
        btnText={StringManipulator.capitalize(t("global.delete._"))}
        secondBtnText={StringManipulator.capitalize(t("global.cancel._"))}
        secondOnClick={() => {
          setIsDeleteMode(false)
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>
            {t("Accounts, transactions, budgets of this space will be removed. this action can't be undone.") + ' '}
            {StringManipulator.capitalize(t("prompts.are.you.sure.you.want.to.proceed.?"))}
          </span>
        </div>
      </DefaultModal>

      <SubscriptionPlan
        showModal={showPlanModal}
        setShowModal={() => {
          setShowPlanModal(false);
        }}
      />
    </div>
  )
}

Spaces.prototype = {
  getCategories: PropTypes.func.isRequired,
  getPayees: PropTypes.func.isRequired,
  getAccounts: PropTypes.func.isRequired,
  getAccountHeader: PropTypes.func.isRequired,
  getBudget: PropTypes.func.isRequired,
  getGlobalValues: PropTypes.func.isRequired,
  getTransaction: PropTypes.func.isRequired,
  getUserAccountInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authState: state.auth,
});

export default connect(mapStateToProps, {
  getCategories,
  getPayees,
  getAccounts,
  getAccountHeader,
  getUserAccountInfo,
  getBudget,
  getGlobalValues,
  getTransaction,
})(Spaces);
