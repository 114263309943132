import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Card,
  Col,
  Badge,
  CardBody,
  UncontrolledCollapse,
  Row,
  Progress,
} from "reactstrap";
import FormatCurrency from "../Tools/Currency/Currency";
import { getBudget } from "../../actions/Budget";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { StringManipulator } from "@fineo-io/main-javascript-library";
import { COLORS } from "assets/constants/theme";

function BudgettWidget({ BUDGETWIDGETDATA, authState }) {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Card style={{ padding: "-25px", borderRadius: "16px" }}>
      <CardBody className="budget-amount-to-budget" style={{ padding: "-25px", borderRadius: "16px" }}>
        <Row className="mt--3 mb-1">
          <Col lg="6" xl="6" md="6" sm="6" xs="6">
            <div className="text-uppercase">
              <span className="h3 font-weight-bold ml-2  budget-how-to-budget-2">
                {FormatCurrency(
                  BUDGETWIDGETDATA.budgetedCurrentMonth,
                  authState.user.currency_code,
                  authState.user.currency_locales
                )}
              </span>
            </div>
          </Col>
          <Col
            id="linkToggler"
            href="#collapseExample"
            className="justify-content-end mt-4 mb--3 col"
            lg="6" xl="6" md="6" sm="6" xs="6"
            style={{ cursor: "pointer" }}
            onClick={() => { setCollapsed(!collapsed) }}
          >
            <span className="text-center h5 float-right">
              {collapsed ?
                <span>{t("Less details")} <i className="fas fa-caret-up ml-1 text-default"></i></span> :
                <span>{t("More details")} <i className="fas fa-caret-down ml-1 text-default"></i></span>
              }
            </span>
          </Col>
        </Row>
        <Row style={{ paddingBottom: "8px" }}>
          <Col>
            {BUDGETWIDGETDATA.budgetedCurrentMonth !== 0 ? (
              <Progress
                className="progress-bar-animated progress-label mb-0"
                style={{ height: "15px" }}
                max={BUDGETWIDGETDATA.totalToBudgetCurrentMonth}
                value={BUDGETWIDGETDATA.budgetedCurrentMonth}
              >
              </Progress>
            ) : (
              <Progress
                className="progress-bar-animated progress-label mb-0"
                color="default"
                style={{ height: "15px" }}
                max={100}
                value={0}
              >
              </Progress>
            )}
          </Col>
        </Row>
        <UncontrolledCollapse toggler="#linkToggler,#buttonToggler">
          <div className="mt-2" style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
            <div style={{ margin: 0, padding: 0 }} className="h4 justify-content-left">
              {StringManipulator.capitalize(t("global.carried.balance"))}
            </div>
            <h4 style={{ marginLeft: "auto", fontSize: "16px", marginTop: "-3px" }}>
              <Badge>
                {authState.user.currency_code !== undefined ? (
                  FormatCurrency(
                    BUDGETWIDGETDATA.availableFromMonthBefore,
                    authState.user.currency_code,
                    authState.user.currency_locales
                  )
                ) : (
                  <div></div>
                )}
              </Badge>
            </h4>
          </div>
          <div className="mt-2" style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
            <div style={{ margin: 0, padding: 0 }} className="h4 justify-content-left">
              {StringManipulator.capitalize(t("global.income.for.this.month"))}
            </div>
            <h4 style={{ marginLeft: "auto", fontSize: "16px", marginTop: "-3px" }}>
              <Badge>
                {authState.user.currency_code !== undefined ? (
                  FormatCurrency(
                    BUDGETWIDGETDATA.entriesCurrentMonth,
                    authState.user.currency_code,
                    authState.user.currency_locales
                  )
                ) : (
                  <div></div>
                )}
              </Badge>
            </h4>
          </div>
          <div className="mt-2" style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
            <div style={{ margin: 0, padding: 0 }} className="h4 justify-content-left">
              {StringManipulator.capitalize(t("global.total.balance.to.budget"))}
            </div>
            <h4 style={{ marginLeft: "auto", fontSize: "16px", marginTop: "-3px" }}>
              <Badge>
                {authState.user.currency_code !== undefined ? (
                  FormatCurrency(
                    BUDGETWIDGETDATA.totalToBudgetCurrentMonth,
                    authState.user.currency_code,
                    authState.user.currency_locales
                  )
                ) : (
                  <div></div>
                )}
              </Badge>
            </h4>
          </div>
        </UncontrolledCollapse>
        <div className="mt-2" style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
          <div style={{ fontWeight: "bold", margin: 0, padding: 0 }} className="h4 justify-content-left">
            {StringManipulator.capitalize(t("global.available.balance.to.budget"))}
          </div>
          <h4 style={{ marginLeft: "auto", fontSize: "16px", marginTop: "-3px" }}>
            <Badge>
              {FormatCurrency(
                BUDGETWIDGETDATA.availableCurrentMonth,
                authState.user.currency_code,
                authState.user.currency_locales
              )}
            </Badge>
          </h4>
        </div>
      </CardBody>
    </Card>
  );
}

BudgettWidget.prototype = {
  authState: PropTypes.object.isRequired,
  getBudget: PropTypes.func.isRequired,
  budgetState: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authState: state.auth,
  budgetState: state.budget,
});

export default connect(mapStateToProps, {
  getBudget,
})(BudgettWidget);
