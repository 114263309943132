import { useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet-async';
import { useTranslation } from "react-i18next";
import { Card, Row } from "reactstrap";
import {
  getHistoryPaiment,
  createCustomerPortalSession,
} from "../actions/Stripe";
import PropTypes from "prop-types";
import BillingC from "../components/Stripe/BillingC";
import { message } from "antd";
import { StringManipulator } from "@fineo-io/main-javascript-library";
import MainHeader from "components/Headers/MainHeader.jsx";

function Billing({
  authState,
  stripeState,
  getHistoryPaiment,
  createCustomerPortalSession
}) {
  const { t } = useTranslation();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('payment')) {
      const payload = params.get('payment');

      params.delete('payment');
      const newURL = window.location.pathname + (params.toString() ? `?${params.toString()}` : '');
      window.history.replaceState({}, '', newURL);

      if (payload === 'success') {
        message.success(t('success_subscription'));
      } else if (payload === 'cancel' || payload === 'error') {
        message.error(t('error_subscription'));
      } else {
        // Handle unexpected cases
        message.warning(t('unexpected_case_subscription'));
      }
    }
  }, []);

  return (
    <>
      <MainHeader title={StringManipulator.capitalize(t("global.billing._"))} />
      <Row>
        <Card style={{ width: "90%", marginRight: "auto", marginLeft: "auto" }}>
          <BillingC
            t={t}
            authState={authState}
            stripeState={stripeState}
            getHistoryPaiment={getHistoryPaiment}
            createCustomerPortalSession={createCustomerPortalSession}
          />
        </Card>
      </Row>
    </>
  );
}

Billing.prototype = {
  authState: PropTypes.object.isRequired,
  stripeState: PropTypes.object.isRequired,
  getHistoryPaiment: PropTypes.func.isRequired,
  createCustomerPortalSession: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authState: state.auth,
  stripeState: state.Stripe,
});

export default connect(mapStateToProps, {
  getHistoryPaiment,
  createCustomerPortalSession,
})(Billing);
