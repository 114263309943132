import { Card, CardBody, CardTitle, Input } from "reactstrap";
import helpers from "../../helpers/money.js";

export default function PriceCard({
  style,
  title,
  currencySymbol,
  unitAmountAecimal,
  currencyOptions,
  t,
  hideCurrencies = false,
  onClick = () => {},
  onChange = () => {},
}) {
  return (
    <Card
      className="card-stats w-auto"
      style={style}
      onClick={() => onClick()}
    >
      <CardBody style={{
        minWidth: "200px",
        padding: "auto",
        display: "flex",
        justifyContent: "center"
      }}>
        <div md="12">
          <CardTitle className="text-uppercase text-muted h4">
            {t(title)}
          </CardTitle>
          <span className="h1 font-weight-bold" style={{
          }}>
            <span style={{
              display: "flex"
            }}>
              <span>{parseFloat(unitAmountAecimal / 100).toFixed(2)}</span>
              {!hideCurrencies ? <span>
                <Input
                  style={{
                    border: "none",
                    cursor: "pointer",
                    color: "black",
                    background: "transparent"
                  }}
                  type="select"
                  defaultValue={helpers.getDefaultCurrency(Object.keys(currencyOptions))}
                  onChange={onChange}
                >
                  {Object.keys(currencyOptions)?.map(currency =>
                    <option value={currency}>{String(currency).toUpperCase()}</option>
                  )}
                </Input></span> : <span className="ml-1">{currencySymbol}</span>}
            </span>
          </span>
        </div>
      </CardBody>
    </Card >
  )
}