import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col, CardTitle } from "reactstrap";
import FormatCurrency from "../Tools/Currency/Currency";
import AccountIcons from "../Randoms/AccountIcons";
import { COLORS } from "assets/constants/theme";

const {
  moneyBankBig,
  moneyCashCoinsStackBig,
  moneySafeVaultBig,
  moneyWalletBig
} = AccountIcons;
function AcccountHeader({ accountState, authState, translate }) {
  // accountState: All account types and it's total amount, Array.
  // authState: The current user infos, Object.
  return (
    <Row className="justify-content-center p-4 pb--3 mb--5">
      {accountState.accountHeader.map((type) => (
        <Col md="6" xl="4" key={Math.random()}>
          <Card className="card-stats">
            <CardBody>
              <Row>
                <Col md="3" lg="3" sm="3" xl="3">
                  <div
                    className="icon icon-shape text-white rounded-circle shadow"
                    style={{
                      background: parseInt(type.typeId) === 1 ? COLORS.success : parseInt(type.typeId) === 2 ? COLORS.cash : COLORS.danger,
                    }}
                  >
                    <img src={
                      parseInt(type.typeId) === 1 ? moneyBankBig : parseInt(type.typeId) === 2 ? moneyCashCoinsStackBig : moneySafeVaultBig
                    } style={{
                      maxWidth: "32px",
                      minWidth: "15px",
                      maxHeight: "38px",
                      minHeight: "18px",
                    }} />
                  </div>
                </Col>
                <Col md="9" lg="9" sm="9" xl="9">
                  <CardTitle
                    className="mb-0"
                    style={
                      {
                        color: COLORS.glyphPrimary
                      }
                    }
                  >
                    Total {" "}
                    {translate(`${type.typeName}`)}
                  </CardTitle>
                  <span className="h2 mb-0" style={{
                    fontWeight: "200",
                    fontSize: "22px"
                  }}>
                    {FormatCurrency(
                      type.totalAmount,
                      authState.user.currency_code,
                      authState.user.currency_locales
                    )}
                  </span>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
}
AcccountHeader.prototype = {
  authState: PropTypes.object.isRequired,
  accountState: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  authState: state.auth,
  accountState: state.account,
});
export default connect(mapStateToProps, {})(AcccountHeader);
