import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Row, Col, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { StringManipulator } from "@fineo-io/main-javascript-library";
import {
  addNewAccount,
  importTheSelectedAccountsFromNordigen,
  setSelectedToImportAccountIdsNordigen,
  setNewNameToImportAccountsNordigen,
} from "actions/Account";
import { getTransaction } from 'actions/Transaction';
import DefaultModal from './DefaultModal';
import ImportLinkedAccountsModal from './ImportLinkedAccountsModal';
import WidgetNewAccount from 'components/Widgets/WidgetNewAccount';
import StandardButton from 'components/Buttons/standardButton/StandardButton';
import { COLORS } from 'assets/constants/theme';
import apiHelpers from 'helpers/api';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getListOfBanks } from 'actions/Account';
import countries from "../Randoms/Countries";
import { getAccountTypes } from 'actions/Account';
import { getAccountHeader } from 'actions/Account';
import { getAccounts } from 'actions/Account';
import LoadingOverlay from 'react-loading-overlay';
import { getListOfNordigenAccounts } from 'actions/Account';
import SubscriptionPlan from 'components/Stripe/SubscriptionPlan';

function AddAccountModal({
  authState,
  needToAddAccountModal = false,
  setNeedToAddAccountModal = () => { },
  accountState,
  addOrImportAcountModal = false,
  setAddOrImportAcountModal = () => { },
  loadAccounts,
  setLoadAccounts,
  ...props
}) {
  const { REACT_APP_SERVER_URL, REACT_APP_NORDIGEN_ENV } = process.env;
  const { callApiHelper } = apiHelpers;
  const { t } = useTranslation();
  const [showAddAcountModal, setShowAddAcountModal] = useState(false);
  const [renderImportAcountBanksCountries, setRenderImportAcountBanksCountries] = useState(false);
  const [showImportNordigenListOfBanks, setShowImportNordigenListOfBanks] = useState(false);
  const [internalAddOrImportAcountModal, setInternalAddOrImportAcountModal] = useState(false);
  const [banks, setBanks] = useState([]);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showNordigenAccounts, setShowNordigenAccounts] = useState(false);
  const [nordigenListOfCountries, setNordigenListOfCountries] = useState(countries);
  const [nordigenSelectedCountry, setNordigenSelectedCountry] = useState(nordigenListOfCountries[nordigenListOfCountries?.length - 1]?.att);
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreC, setHasMoreC] = useState(true);

  useEffect(() => {
    if (authState.firstLogin === true) {
      setRenderImportAcountBanksCountries(authState.firstLogin)
    }
  }, [authState.firstLogin])

  useEffect(() => {
    if (accountState.add_or_import_account_modal === true) {
      setRenderImportAcountBanksCountries(true);
    }
  }, [accountState.add_or_import_account_modal])


  useEffect(() => {
    if (loadAccounts) {
      props.getListOfNordigenAccounts(localStorage.getItem("aspsp_id"), async (isRestoringAccount) => {
        setLoadAccounts(false)
        if (isRestoringAccount === true) {
          await syncImportedAccountsFromNordigen(async (res) => {
            await getAccounts();
          })
        } else {
          setShowNordigenAccounts(true);
        }
      });
      localStorage.removeItem("ref");
      localStorage.removeItem("space_id");
    }
  }, [loadAccounts])



  useEffect(() => {
    getAccountTypes();
    getAccountHeader();
    getAccounts();
    setTimeout(() => {
      const data = [];
      const data1 = [];
      for (let i = 0; i < 10; i++) {
        data.push(accountState.nordigen_list_of_banks[i]);
        data1.push(countries[i]);
      }
      setBanks(data);
      setNordigenListOfCountries(data1);
    }, 500);
  }, [
    getAccountTypes,
    getAccountHeader,
    getAccounts,
    accountState.nordigen_list_of_banks,
    sessionStorage.getItem("SESSION_SPACE")
  ]);

  const fetchMoreBanks = () => {
    if (banks.length >= accountState.nordigen_list_of_banks.length) {
      setHasMore(false);
      return;
    }
    setTimeout(() => {
      let data = [];
      if (banks.length + 4 <= accountState.nordigen_list_of_banks.length) {
        for (let i = 0; i < banks.length + 4; i++) {
          data.push(accountState.nordigen_list_of_banks[i]);
        }
        setBanks(data);
      }
    }, 1000);
  };

  const fetchMoreCountries = () => {
    if (nordigenListOfCountries.length >= countries.length) {
      setHasMoreC(false);
      return;
    }
    setTimeout(() => {
      let data = [];
      if (nordigenListOfCountries.length + 6 <= countries.length) {
        for (let i = 0; i < nordigenListOfCountries.length + 6; i++) {
          data.push(countries[i]);
        }
        setNordigenListOfCountries(data);
      } else {
        setHasMoreC(false);
        return;
      }
    }, 800);
  };

  return (
    <LoadingOverlay
      active={loadAccounts}
      spinner
      text={t("Loading your accounts, please wait...")}
    >
      {/* Need to add account first modal */}
      <DefaultModal
        title={StringManipulator.capitalize(t("global.notice._"))}
        btnText={StringManipulator.capitalize(t("global.new.account"))}
        hasTwoButtons={true}
        secondBtnText={StringManipulator.capitalize(t("global.cancel._"))}
        secondOnClick={() => {
          setNeedToAddAccountModal(false);
        }}
        isOpen={needToAddAccountModal}
        onClick={async () => {
          setInternalAddOrImportAcountModal(true);
          setNeedToAddAccountModal(false);
        }}
        toggle={() => setNeedToAddAccountModal(false)}
      >
        {t("You need to have at least one account to be able to add new transactions.")}
      </DefaultModal>

      {/* Add Account inputs modal */}
      <DefaultModal
        title={t("global.new.account")}
        size={"modal-md"}
        style={{ paddingTop: "100px" }}
        hasNoButtons={true}
        bodyStyle={{ textAlign: "start" }}
        className="modal-dialog-start w-auto"
        isOpen={showAddAcountModal}
        toggle={async () => setShowAddAcountModal(false)}
      >
        <div className="modal-body">
          <WidgetNewAccount
            allAccountType={accountState.accountTypes}
            handleAddNewAccountToDb={async (newAccount) => {
              if (newAccount.accountName !== "" || newAccount.accountInitalSold) {
                setShowAddAcountModal(false);
                setAddOrImportAcountModal(false);
                setInternalAddOrImportAcountModal(false);
                await props.addNewAccount(newAccount);
                await props.getTransaction(true, t)
              }
            }}
            translate={t}
            onCancel={() => {
              setShowAddAcountModal(false);
              setAddOrImportAcountModal(false);
              setInternalAddOrImportAcountModal(false);
            }}
            onBack={() => {
              setShowAddAcountModal(false)
              setAddOrImportAcountModal(false);
              setInternalAddOrImportAcountModal(false);
            }}
          />
        </div>
      </DefaultModal>

      {/* Add or import modal */}
      <DefaultModal
        title={t("SideBar_Comptes")}
        isOpen={addOrImportAcountModal || internalAddOrImportAcountModal}
        hasNoButtons={true}
        toggle={async () => {
          setInternalAddOrImportAcountModal(false);
          setAddOrImportAcountModal(false);
        }}
      >
        <div className="modal-body p-4 py-3">
          <Row>
            <Col className="justify-content-sm-between text-default">
              <h4 className="text-center h4">
                {t("You can add a new manual account or connect your bank to import your accounts with your last 90 days transaction")}.&nbsp;
              </h4>
            </Col>
          </Row>
          <Row className="mt-5 mx-1 p-2" style={{ display: "flex", justifyContent: "center" }}>
            <StandardButton
              color={COLORS.glyphPrimary}
              onClick={() => {
                setShowAddAcountModal(true);
                setAddOrImportAcountModal(false);
                setInternalAddOrImportAcountModal(false);
              }}
              text={t("Account_Nouveau_compte")}
            />
            <StandardButton
              onClick={async () => {
                const response = await callApiHelper('account/checkuseraccountplan', "post");
                if (response.success === true) {
                  if (
                    response.messages === "is in free mode" ||
                    response.messages === "is a plus user" ||
                    response.messages === "is a super user"
                  ) {
                    setRenderImportAcountBanksCountries(true);
                    setInternalAddOrImportAcountModal(false);
                    setAddOrImportAcountModal(false);
                  }
                } else {
                  if (response.messages.syncAccount) {
                    setShowPlanModal(true);
                    setAddOrImportAcountModal(false);
                    setInternalAddOrImportAcountModal(false);
                  } else {
                    setRenderImportAcountBanksCountries(true);
                    setAddOrImportAcountModal(false);
                    setInternalAddOrImportAcountModal(false);
                  }
                }
              }}
              text={t("Account_Connecter_ma_banque_Using_Nordigen")}
            />
          </Row>
        </div>
      </DefaultModal>

      {/* nordigen list of banks modal */}
      <DefaultModal
        backdrop={true}
        isOpen={showImportNordigenListOfBanks}
        toggle={async () => {
          setShowImportNordigenListOfBanks(!showImportNordigenListOfBanks);
          setRenderImportAcountBanksCountries(true);
        }}
        hasNoButtons={true}
        title={t("Account_Select_Your_Bank")}
        style={{ paddingTop: "100px" }}
      >
        <div className="modal-body">
          <Row className="mb-3">
            <Col>
              <InputGroup
                className="input-group-alternative"
                style={{
                  backgroundColor: COLORS.neutral,
                  width: "auto",
                }}
              >
                <InputGroupAddon
                  addonType="prepend"
                  style={{
                    width: "10%",
                  }}
                >
                  <InputGroupText>
                    <i className="fas fa-search" style={{ color: COLORS.glyphPrimary }} />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="m-auto w-75"
                  onChange={(e) => {
                    if (e.target.value == "") {
                      setBanks(accountState.nordigen_list_of_banks);
                    }
                    const data = [];
                    accountState.nordigen_list_of_banks.forEach((bk) => {
                      if (
                        bk.name
                          .toString()
                          .toUpperCase()
                          .includes(e.target.value.toString().toUpperCase())
                      ) {
                        data.push(bk);
                      }
                    });
                    setBanks(data);
                  }}
                />
              </InputGroup>
            </Col>
          </Row>
          <InfiniteScroll
            dataLength={banks.length}
            hasMore={hasMore}
            style={{ textAlign: "start" }}
            scrollThreshold="50px"
            height={"300px"}
            next={fetchMoreBanks}
            loader={<h4>{banks.length > 0 ? t("Loading") : t("None")}</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>{t("You have seen it all")}</b>
              </p>
            }
          >
            {banks.map((bank, index) => (
              <div
                style={{
                  cursor: "pointer",
                  borderRadius: "8px",
                }}
                className="mb-4 py-1"
                key={index}
                id={`bank${index}`}
                onClick={async () => {
                  localStorage.setItem("ref", authState.user.id);
                  if (REACT_APP_NORDIGEN_ENV === "development") {
                    localStorage.setItem("aspsp_id", "SANDBOXFINANCE_SFIN0000");
                  } else {
                    localStorage.setItem("aspsp_id", bank.id);
                  }
                  localStorage.setItem("space_id", sessionStorage.getItem("SESSION_SPACE"));
                  document.getElementById(
                    `bank${index}`
                  ).style.backgroundColor = COLORS.success;
                  return await fetch(
                    `https://${REACT_APP_SERVER_URL}/nordigen/buildalink`,
                    {
                      method: "post",
                      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
                      credentials: "include",
                      body: JSON.stringify({
                        refresh_token:
                          localStorage.getItem("REFRESH_TOKEN"),
                        space_id: sessionStorage.getItem("SESSION_SPACE"),
                        access_token:
                          sessionStorage.getItem("ACCESS_TOKEN"),
                        aspsp_id: REACT_APP_NORDIGEN_ENV === "development" ? "SANDBOXFINANCE_SFIN0000" : bank.id,
                      }),
                    }
                  )
                    .then((response) => response.json())
                    .then((response) => {
                      console.log('response', response)
                      if (response.success == true) {
                        window.location.href = response.messages.link;
                        localStorage.setItem("initiate", "initiate")
                      }
                    });
                }}
              >
                <span className="mx-2 px-2">
                  <img
                    src={bank !== undefined && bank.logo}
                    className="mr--2"
                    style={{ width: "50px", height: "40px" }}
                  />
                </span>
                <span className="h4">{bank !== undefined && bank.name}</span>
              </div>
            ))}
          </InfiniteScroll>

        </div>
      </DefaultModal>

      {/* nordigen list of countries modal */}
      <DefaultModal
        isOpen={renderImportAcountBanksCountries}
        toggle={async () => {
          setRenderImportAcountBanksCountries(false);
        }}
        style={{ paddingTop: "100px" }}
        title={t("Account_Select_Your_Country")}
        hasNoButtons={true}
      >
        <div className="modal-body">
          <Row className="mb-3">
            <Col>
              <InputGroup
                className="input-group-alternative"
                style={{
                  backgroundColor: COLORS.neutral,
                  width: "auto",
                }}
              >
                <InputGroupAddon
                  addonType="prepend"
                  style={{
                    width: "10%",
                  }}
                >
                  <InputGroupText>
                    <i className="fas fa-search" style={{ color: COLORS.glyphPrimary }} />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="m-auto w-75"
                  onChange={(e) => {
                    if (e.target.value == "") {
                      setNordigenListOfCountries(countries);
                    }
                    const data = [];
                    countries.forEach((ct) => {
                      if (
                        ct.name
                          .toString()
                          .toUpperCase()
                          .includes(e.target.value.toString().toUpperCase())
                      ) {
                        data.push(ct);
                      }
                    });
                    setNordigenListOfCountries(data);
                  }}
                />
              </InputGroup>
            </Col>
          </Row>
          <InfiniteScroll
            dataLength={nordigenListOfCountries.length}
            hasMore={hasMoreC}
            scrollThreshold="50px"
            height={"300px"}
            next={fetchMoreCountries}
            loader={<h4>{t("Loading")}...</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>{t("You have seen it all")}</b>
              </p>
            }
            style={{ textAlign: "start" }}
          >
            {nordigenListOfCountries.map((country, index) => (
              <div
                style={{
                  cursor: "pointer",
                  borderRadius: "8px",
                  width: "100%"
                }}
                className="mb-4"
                key={index}
                id={`country${index}`}
                onClick={async () => {
                  document.getElementById(
                    `country${index}`
                  ).style.backgroundColor = COLORS.success;
                  await props.getListOfBanks(
                    country !== undefined && country.code
                  );
                  setNordigenSelectedCountry(
                    country !== undefined && country.att
                  );
                  setShowImportNordigenListOfBanks(true);
                  setRenderImportAcountBanksCountries(false);
                }}
              >
                <span className="mx-2 px-2">
                  <img
                    src={country.att}
                    className="mr-2 ml-2"
                    style={{ width: "50px", height: "40px" }}
                  />
                </span>
                {country !== undefined && t(country.name)}
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </DefaultModal>

      <ImportLinkedAccountsModal
        listOfAccounts={accountState?.nordigen_list_of_accounts}
        showNordigenAccounts={showNordigenAccounts}
        setShowNordigenAccounts={setShowNordigenAccounts}
        importTheSelectedAccountsFromNordigen={props.importTheSelectedAccountsFromNordigen}
        setNewNameToImportAccountsNordigen={props.setNewNameToImportAccountsNordigen}
        user={authState.user}
        setSelectedToImportAccountIdsNordigen={props.setSelectedToImportAccountIdsNordigen}
      />
      <SubscriptionPlan
        showModal={showPlanModal}
        setShowModal={() => {
          setShowPlanModal(false);
        }}
      />
    </LoadingOverlay >
  )
}

const mapStateToProps = (state) => ({
  transactionState: state.transaction,
  accountState: state.account,
  authState: state.auth,
  budgetState: state.budget,
});

export default connect(mapStateToProps, {
  getTransaction,
  getListOfBanks,
  getListOfNordigenAccounts,
  addNewAccount,
  importTheSelectedAccountsFromNordigen,
  setSelectedToImportAccountIdsNordigen,
  setNewNameToImportAccountsNordigen,
})(AddAccountModal);
