import React from 'react'
import DefaultSelect from './DefaultSelect'
import { useTranslation } from "react-i18next";


function AccountSelect({ data, onChange, ...props }) {
  const { t } = useTranslation();

  return (
    <DefaultSelect
      {...props}
      placeholder={t("All accounts")}
      onChange={onChange}
      options={[
        { value: -1, label: t("All accounts") },
        ...data.map((account) => ({
          value: account?.id,
          label: t(`${account?.accountType}`),
        }))
      ]}
    />
  )
}

export default AccountSelect;
