// Transaction Actions:
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_TRANSACTIONS_WITHOUT_CAT = "GET_TRANSACTIONS_WITHOUT_CAT";
export const GET_PAYEES = "GET_PAYEES";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_TR_ACCOUNTS = "GET_TR_ACCOUNTS";
export const SET_TRANSACTION = "SET_TRANSACTION";
export const SET_TRANSACTION_LIMIT = "SET_TRANSACTION_LIMIT";
export const UPDATE_TRANSACTION = "UPDATE_TRANSACTION";
export const DELETE_TRANSACTIONS = "DELETE_TRANSACTIONS";
export const SET_TRANSACTION_TO_DB = "SET_TRANSACTION_TO_DB";
export const GET_TRANSACTIONS_NOT_FUTURS = "GET_TRANSACTIONS_NOT_FUTURS";
export const GET_TRANSACTIONS_AUTO_PERIODIQUE =
  "GET_TRANSACTIONS_AUTO_PERIODIQUE";
export const GET_PERIODICITE_TYPE = "GET_PERIODICITE_TYPE";
export const GET_PERIODICITE = "GET_PERIODICITE";

//plaid
export const GET_PLAID_TRANSACTIONS = "GET_PLAID_TRANSACTIONS";
export const CLEAR_PLAID_TRANSACTIONS = "CLEAR_PLAID_TRANSACTIONS";
export const SELECTED_PLAID_ACCOUNT = "SELECTED_PLAID_ACCOUNT";

// Account Actions:
export const SET_ACCOUNT = "SET_ACCOUNT";
export const GET_ACCOUNT = "GET_ACCOUNT";
export const GET_ACCOUNT_HEADER = "GET_ACCOUNT_HEADER";
export const CHANGE_ACCOUNTS_TYPE = "CHANGE_ACCOUNTS_TYPE";
export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const GET_ACCOUNT_TYPES = "GET_ACCOUNT_TYPES";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const GET_PLAID_ACCOUNTS = "GET_PLAID_ACCOUNTS";
export const SET_TO_IMPORT_ACCOUNT = "SET_TO_IMPORT_ACCOUNT";
export const SET_NAME_TO_IMPORT_ACCOUNT = "SET_NAME_TO_IMPORT_ACCOUNT";
export const SET_ADD_OR_IMPORT_ACCOUNT_MODAL = "SET_ADD_OR_IMPORT_ACCOUNT_MODAL";
export const GET_ACCOUNTS_FOR_SELECT = "GET_ACCOUNTS_FOR_SELECT";

// Budget Actions:
export const GET_BUDGETS = "GET_BUDGETS";
export const FETCH_BUDGET_REQUEST = "FETCH_BUDGET_REQUEST";
export const GET_BUDGET_HEADER = "GET_BUDGET_HEADER";
export const SET_BUDGET_GROUP = "SET_BUDGET_GROUP";
export const SET_BUDGET_CATEGORIE = "SET_BUDGET_CATEGORIE";
export const UPDATE_BUDGET_CATEGORIE = "UPDATE_BUDGET_CATEGORIE";
export const DELETE_BUDGET_CATEGORIE = "DELETE_BUDGET_CATEGORIE";
export const DELETE_BUDGET_GROUP = "DELETE_BUDGET_GROUP";
export const UPDATE_BUDGET_CATEGORIE_ORDER = "UPDATE_BUDGET_CATEGORIE_ORDER";
export const HIDE_BUDGET_CATEGORIE = "HIDE_BUDGET_CATEGORIE";
export const RUN_BUDGET_ONBOARDING = "RUN_BUDGET_ONBOARDING";
export const HIDE_BUDGET_GROUP = "HIDE_BUDGET_GROUP";
export const SHOW_BUDGET_CATEGORIE = "SHOW_BUDGET_CATEGORIE";
export const SHOW_BUDGET_GROUP = "SHOW_BUDGET_GROUP";
export const RESET_BUDGET_CATEGORIE = "RESET_BUDGET_CATEGORIE";
export const DEFINE_BUDGET_CATEGORIE = "DEFINE_BUDGET_CATEGORIE";
export const DEFINE_BUDGET_TRANSACTIONS = "DEFINE_BUDGET_TRANSACTIONS";
export const DEFINE_BUDGET_CATEGORY_ORDER = "DEFINE_BUDGET_CATEGORY_ORDER";
export const DEFINE_BUDGET_GROUP_ORDER = "DEFINE_BUDGET_GROUP_ORDER";
export const SET_CATEGORY_ROW = "SET_CATEGORY_ROW";
export const HANDEL_SAVE_CATEGORY_ROW = "HANDEL_SAVE_CATEGORY_ROW";
export const UPDATE_BUDGET_CATEGORY_GROUP = "UPDATE_BUDGET_CATEGORY_GROUP ";
export const RUN_TRANSACTION_VIEW_ONBOARDING = "RUN_TRANSACTION_VIEW_ONBOARDING";
export const HIDE_TRANSACTIONS_WITHOUT_CATS = "HIDE_TRANSACTIONS_WITHOUT_CATS";
export const SET_CATEGORY_FILTER = "SET_CATEGORY_FILTER";

// Auth Actions:
export const SET_LOGIN = "SET_LOGIN";
export const SET_USER_ACCOUNT_INFO = "SET_USER_ACCOUNT_INFO";
export const SET_LOGOUT = "SET_LOGOUT";
export const UPDATE_USER_ACCOUNT_INFO = "SET_LOGOUT";
export const SET_FIRST_LOGIN = "SET_FIRST_LOGIN"
// Rapport Actions:
export const GET_ACCOUNT_REPORT = "GET_ACCOUNT_REPORT";
export const GET_CHART_ENTRIES = "GET_CHART_ENTRIES";
export const GET_CHART_DEPENSE = "GET_CHART_DEPENSE";
export const GET_CHART_BUDGET = "GET_CHART_BUDGET";

// Currency Actions:
export const GET_CURRENCY = "GET_CURRENCY";
export const UPDATE_CURRENCY = "UPDATE_CURRENCY";

// Dashboard Actions:
export const GET_DASHBOARD_BUDGET_SITUATION = "GET_DASHBOARD_BUDGET_SITUATION";
export const GET_DASHBOARD_ENTRIES = "GET_DASHBOARD_ENTRIES";
export const GET_DASHBOARD_DEPENSE = "GET_DASHBOARD_DEPENSE";

// Cleaning actions:
export const CLEAN_BUDGET = "CLEAN_BUDGET";
export const CLEAN_TRANSACTION = "CLEAN_TRANSACTION";
export const CLEAN_ACCOUNT = "CLEAN_ACCOUNT";
export const CLEAN_AUTH = "CLEAN_AUTH";
export const CLEAN_PROFILE = "CLEAN_PROFILE";
export const CLEAN_RAPPORT = "CLEAN_RAPPORT";

//NORDIGEN
export const GET_NORDIGEN_BANKS = "GET_NORDIGEN_BANKS";
export const GET_NORDIGEN_ACCOUNTS = "GET_NORDIGEN_ACCOUNTS";
export const SET_TO_IMPORT_ACCOUNT_NORDIGEN = "SET_TO_IMPORT_ACCOUNT_NORDIGEN";
export const SET_NAME_TO_IMPORT_ACCOUNT_NORDIGEN =
  "SET_NAME_TO_IMPORT_ACCOUNT_NORDIGEN";
export const SET_STARTING_DATE_TO_IMPORT_ACCOUNT_NORDIGEN =
  "SET_STARTING_DATE_TO_IMPORT_ACCOUNT_NORDIGEN";

// STRIPE
export const STRIPE_GET_HISTORY_PAIMENT = "STRIPE_GET_HISTORY_PAIMENT";
export const STRIPE_GET_LIST_PRICE = "STRIPE_GET_LIST_PRICE";

// Globals
export const SET_GLOBAL_VALUES = "SET_GLOBAL_VALUES";
export const GLOBAL_OPEN_ADD_TR_MODAL = "GLOBAL_OPEN_ADD_TR_MODAL";
export const GLOBAL_SYNC_MY_ACCOUNTS = "GLOBAL_SYNC_MY_ACCOUNTS";
export const SET_BDATE = "SET_BDATE";