import React, { useEffect, useState, useMemo } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import { Doughnut } from "react-chartjs-2";
import { REPORT_COLORS } from "assets/constants/theme";

export default function RepportCategoriesWidget({
  authState,
  budgetState,
  setShowBudgetCategoryDetails,
  setBudgetCategoryDetailsObject,
  labels = [],
  data = [],
  selectDoughnot,
  budgetGroup = "",
  group = true
}) {
  const { t } = useTranslation();
  const [dataSet, setDataSet] = useState(data)
  const [dataSetLabel, setDataSetLabel] = useState(data);
  let chartInstance = null;

  useEffect(() => {
    setShowBudgetCategoryDetails(false);
  }, [budgetState.budgets]);

  useEffect(() => {
    if (group === false) {
      let dataV = [];
      let dataW = [];
      Object.keys(budgetState.budgets.budgetData).forEach((element) => {
        Object.keys(budgetState.budgets.budgetData[element]).forEach((element1) => {
          if (element1.startsWith(budgetGroup.split(" ")[0])) {
            budgetState.budgets.budgetData[element][element1].forEach((element3) => {
              dataW.push(element3.budget_category_name)
              if (selectDoughnot === "budgetedChart") {
                dataV.push(Math.abs(element3.budgetAmount));
              } else if (selectDoughnot === "availbleChart") {
                dataV.push(Math.abs(element3.disponible));
              } else {
                dataV.push(Math.abs(element3.activities));
              }
            })
          }
        })
      });

      let total = 0;
      let newData = [];
      dataV.forEach((item) => total = total + item);
      dataW.forEach((item1, index1) => {
        dataV.forEach((item2, index2) => {
          if (index1 === index2) {
            newData.push(`${item1} ${new Intl.NumberFormat(authState.user.currency_locales, {
              style: "currency",
              currency: authState.user.currency_code !== undefined ? authState.user.currency_code : "EUR",
            }).format(item2)} ( ${parseFloat((item2 * 100) / total).toFixed(2)}% )`)
          }
        })
      })
      setDataSet(dataV);
      setDataSetLabel(newData);
    }
  }, [group, budgetGroup]);

  useEffect(() => {
    if (group === true) {
      if (selectDoughnot === "activitiesChart") {
        const expenData = [];
        const expenLabel = [];
        budgetState.budgets.groupeExpenses?.forEach((item) => {
          expenData.push(Math.abs(item.expenses));
          expenLabel.push(item.groupeName);
        });

        let total = 0;
        const newData = [];
        expenData.forEach((item) => {total = total + item});
        expenLabel.forEach((item1, index1) => {
          expenData.forEach((item2, index2) => {
            if (index1 === index2) {
              newData.push(`${item1} ${new Intl.NumberFormat(authState.user.currency_locales, {
                style: "currency",
                currency: authState.user.currency_code !== undefined ? authState.user.currency_code : "EUR",
              }).format(item2)} ( ${parseFloat((Math.abs(item2) * 100) / Math.abs(total)).toFixed(0)}% )`)
            }
          })
        })
        setDataSet(expenData);
        setDataSetLabel(newData);
      } else {
        let total = 0;
        const newData = [];
        data.forEach((item) => {total = total + item});
        labels.forEach((item1, index1) => {
          data.forEach((item2, index2) => {
            if (index1 === index2) {
              newData.push(`${item1} ${new Intl.NumberFormat(authState.user.currency_locales, {
                style: "currency",
                currency: authState.user.currency_code !== undefined ? authState.user.currency_code : "EUR",
              }).format(item2)} ( ${parseFloat((Math.abs(item2) * 100) / Math.abs(total)).toFixed(0)}% )`)
            }
          })
        })
        setDataSet(data);
        setDataSetLabel(newData);
      }
    }
  }, [selectDoughnot, group, budgetState.budgets.labels])

  return (
    <div style={{ height: "300px", width: "100%" }}>
      {
        dataSetLabel.length !== 0 ? (
          <Doughnut
            onElementsClick={(element) => {
              if (group === true && element[0] !== undefined) {
                setShowBudgetCategoryDetails(true);
                setBudgetCategoryDetailsObject({
                  Name: element[0]["_model"]["label"].split(" ")[0]
                });
              }
            }}
            data={{
              maintainAspectRatio: false,
              responsive: false,
              labels: data !== undefined ? dataSetLabel : [],
              datasets: [
                {
                  data: data !== undefined ? dataSet : [],
                  backgroundColor: chartColors,
                  hoverBackgroundColor: chartColors,
                  hoverBorderWidth: 5,
                }
              ],
            }}
            options={{
              tooltips: {
                callbacks: {
                  label: function (t, d) {
                    return new Intl.NumberFormat(authState.user.currency_locales, {
                      style: "currency",
                      currency: authState.user.currency_code !== undefined ? authState.user.currency_code : "EUR",
                    }).format(d['datasets'][0]['data'][t['index']]);
                  }
                }
              },
              legend: {
                display: true,
                position: "right",
              },
              elements: {
                arc: {
                  borderWidth: 0
                }
              }
            }}
            ref={input => {
              chartInstance = input;
            }}
          />
        ) : (
          <Doughnut
            data={{
              maintainAspectRatio: false,
              responsive: false,
              labels: [t("No Data For this month")],
              datasets: [
                {
                  data: [100],
                  backgroundColor: chartColors,
                  hoverBackgroundColor: chartColors,
                  hoverBorderWidth: 5,
                }
              ],
            }}
            options={{
              tooltips: {
                size: "75%",
                callbacks: {
                  label: function (t, d) {
                    return new Intl.NumberFormat(authState.user.currency_locales, {
                      style: "currency",
                      currency: authState.user.currency_code !== undefined ? authState.user.currency_code : "EUR",
                    }).format(0);
                  }
                }
              },
              legend: {
                display: true,
                position: "right",
              },
              elements: {
                arc: {
                  borderWidth: 0
                }
              }
            }}
            ref={input => {
              chartInstance = input;
            }}
          />
        )
      }
    </div>
  );
}

const chartColors = REPORT_COLORS;