import { useHistory } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import DefaultModal from "components/Modals/DefaultModal";
import { COLORS } from "assets/constants/theme";
import { StringManipulator } from "@fineo-io/main-javascript-library";

export default function SubscriptionPlan({ showModal, setShowModal }) {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <DefaultModal
      isOpen={showModal}
      toggle={setShowModal}
      size={"modal-lg"}
      height="40vh"
      title="global.available.plans"
      btnText="Upgrade"
      onClick={() => history.push('/app/payment')}
    >
      <div className="modal-body"
        style={{
          textAlign: "start",
          justifyContent: "center",
          height: "auto",
          alignItems: "center",
          alignContent: "center"
        }}>
        <Row className="h1 text-center">
          <Col md="4" sm="4" xl="4" lg="4" className="text-default text-bold h1">
            {t("Features")}
          </Col>
          <Col md="4" sm="4" xl="4" lg="4">
            {t("Free")}
          </Col>
          <Col md="4" sm="4" xl="4" lg="4" style={{ color: COLORS.primary }}>
            {t("Plus")}
          </Col>
        </Row>
        <hr className="text-center my-3" style={{
          width: "90%"
        }} />
        <Row className="h2 text-center">
          <Col md="4" sm="4" xl="4" lg="4">
            {t("Synced accounts")}
          </Col>
          <Col md="4" sm="4" xl="4" lg="4">
            1
          </Col>
          <Col md="4" sm="4" xl="4" lg="4">
            {t("Illimited")}
          </Col>
        </Row>
        <hr className="my-3" style={{
          width: "80%"
        }} />
        <Row className="h2 text-center">
          <Col md="4" sm="4" xl="4" lg="4">
            {StringManipulator.capitalize(t("global.workspace.s"))}
          </Col>
          <Col md="4" sm="4" xl="4" lg="4">
            2
          </Col>
          <Col md="4" sm="4" xl="4" lg="4">
            {t("Illimited")}
          </Col>
        </Row>
        <hr className="my-3" style={{
          width: "90%"
        }} />
        <Row className="h2 text-center">
          <Col md="4" sm="4" xl="4" lg="4">
            {t("Categories")}
          </Col>
          <Col md="4" sm="4" xl="4" lg="4">
            {t("Illimited")}
          </Col>
          <Col md="4" sm="4" xl="4" lg="4">
            {t("Illimited")}
          </Col>
        </Row>
        <hr className="my-3" style={{
          width: "80%"
        }} />
        <Row className="h2 text-center">
          <Col md="4" sm="4" xl="4" lg="4">
            {StringManipulator.capitalize(t("global.recurring.transactions"))}
          </Col>
          <Col md="4" sm="4" xl="4" lg="4">
            <i className="fas fa-times text-gray fa-1x"></i>
          </Col>
          <Col md="4" sm="4" xl="4" lg="4">
            <i className="fas fa-check text-green fa-1x"></i>
          </Col>
        </Row>
        <hr className="my-3" style={{
          width: "90%"
        }} />
      </div>
    </DefaultModal>
  );
}
