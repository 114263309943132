import React from "react";
import PropTypes from "prop-types";
import DefaultSelect from "components/inputs/DefaultSelect";

class SelectBudget extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    onUpdate: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    BudgetsData: PropTypes.array.isRequired,
  };

  static defaultProps = {
    value: "",
  };

  getValue(option, value) {
    try {
      if (option === undefined || option === null) {
        this.props.handleSave({
          ...this.props.row,
          categorie: -1,
          transaction_amount: parseFloat(this.props.row.transaction_amount) / 100,
        });
        return value;
      };

      if (this.props.row.key !== "-1") {
        if (option.value === "") return value;
        this.props.handleSave({
          ...this.props.row,
          categorie: option.value,
          transaction_amount: parseFloat(this.props.row.transaction_amount) / 100,
        });
        return option.label;
      }

      return value;
    } catch (error) {
      return value
    }
  }

  cancel(value){
    return value;
  }

  render() {
    const { row, value, t, onUpdate, BudgetsData, authState, ...rest } = this.props;
    let options = [];
    BudgetsData.forEach((group) => {
      if (group.label !== t("to_budget")) {
        let obj = {};
        let tab = [];
        group.options.forEach((val) => {
          let varb = `${val.label} ${new Intl.NumberFormat(
            authState.user.currency_locales,
            {
              style: "currency",
              currency: authState.user.currency_code,
            }
          ).format(val.amount)}`;

          tab.push({
            key: val.key,
            value: val.value,
            label: varb,
          });
        });

        obj = {
          key: Math.random() * Math.random(),
          label: group.label,
          options: tab,
        };
        options.push(obj);
      }
    });

    options.unshift({
      label: t("to_budget"),
      options: [
        {
          key: -1,
          value: -1,
          label: t(`to_budget`),
        },
      ],
    });

    options = options.filter(
      (group) => group.label !== t("budget_get_all") && group.label !== undefined
    );


    return [
      <DefaultSelect
        key={Math.random()}
        id="budgetSelect"
        {...rest}
        placeholder={this.props.t("Transaction_Sélectionner")}
        defaultInputValue={value === "-" ? " " : value}
        isClearable
        isSearchable
        autoFocus
        options={options}
        ref={(node) => (this.value = node)}
        onKeyDown={(val) => {
          if (val.key === "Enter") {
            onUpdate(this.getValue(null, value));
          }
          if (val.key === "Escape") {
            onUpdate(this.cancel(value));
          }
        }}
        onChange={(option) => {
          if (option === null) return onUpdate(this.getValue(null, value));
          onUpdate(this.getValue(option, value));
        }}
        onInputChange={(val) => {
          if (val === "null") return;
        }}
        onBlur={() => {
          onUpdate(this.getValue(null, value));
        }}
      />
    ];
  }
}

export default SelectBudget;
