import React, { useState, useEffect } from "react";
import i18next from "i18next";
import { FormGroup } from "reactstrap";
import { useTranslation } from "react-i18next";
import DefaultSelect from "components/inputs/DefaultSelect";
const { REACT_APP_SERVER_URL } = process.env;


const languageMap = {
  en: { label: "EN", dir: "ltr", active: true },
  fr: { label: "FR", dir: "ltr", active: false },
};

const LanguageSelect = () => {
  const selected = localStorage.getItem("i18nextLng") || "en";
  const { t } = useTranslation();

  const [selectedLang, setSelectedLang] = useState(selected);
  let selectedLangLabel = selectedLang === "en" || selectedLang === "en-US" ? `${t("Porfile_English")}` : `${t("Porfile_French")}`;
  const [langList, setLangList] = useState(Object.keys(languageMap)?.map((item) => ({
    value: item,
    label:
      item == "en" ? t("Porfile_English") : t("Porfile_French"),
    key: item,
  })));
  const [menuAnchor, setMenuAnchor] = React.useState(null);


  useEffect(() => {
    document.body.dir = languageMap[selected == "en" ? selected : selected == "fr" ? selected : "en"].dir;
    setLangList(Object.keys(languageMap)?.map((item) => ({
      value: item,
      label:
        item == "en" ? t("Porfile_English") : t("Porfile_French"),
      key: item,
    })))
  }, [menuAnchor, selected]);

  async function changeLanguage(lang) {
    await fetch(`https://${REACT_APP_SERVER_URL}/language/changelanguage`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN"),
        access_token: sessionStorage.getItem("ACCESS_TOKEN"),
        lang
      }),
      credentials: "include",
    })
  };

  return (
    <FormGroup>
      <DefaultSelect
        options={langList}
        value={selectedLang}
        onChange={async (item) => {
          setSelectedLang(item.value);
          await changeLanguage(item.value);
          i18next.changeLanguage(item.value);
          setMenuAnchor(null);
        }}
        placeholder={selectedLangLabel}
      />

    </FormGroup>
  );
};

export default LanguageSelect;
