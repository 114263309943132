import {
  STRIPE_GET_HISTORY_PAIMENT,
  STRIPE_GET_LIST_PRICE,
} from "../actions/types";
const initialState = {
  currency: "eur",
  historyPaiment: [],
  listPrice: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case STRIPE_GET_HISTORY_PAIMENT:
      return {
        ...state,
        historyPaiment: payload,
      };
    case STRIPE_GET_LIST_PRICE:
      return {
        ...state,
        listPrice: payload.data,
      };
    default:
      return state;
  }
}
