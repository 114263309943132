import React, { useEffect, useState } from "react";
import { isBrowser } from 'react-device-detect';
import { Helmet } from 'react-helmet-async';
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import sideImage from "../assets/img/Views/User Email Verification.png"
import Logo from "../assets/img/brand/fineo-login-logo.png";
import StandardButton from "components/Buttons/standardButton/StandardButton";
import { COLORS } from "assets/constants/theme";
import { StringManipulator } from "@fineo-io/main-javascript-library";

function PasswordVerification() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");

  useEffect(() => {
    setEmail(sessionStorage.getItem("email"));
    sessionStorage.removeItem("email");
  }, []);
  return (
    <Row className="d-flex align-items-center" style={{
      height: "100vh"
    }}>
      <Helmet>
        <title>Fineo | {StringManipulator.capitalize(t("password_verification_title"))}</title>
      </Helmet>
      {isBrowser && (<Col xl="6" lg="6" md="6" xs="12" sm="12" className="d-flex align-items-center" style={{
        height: "100%",
        textAlign: "center",
        justifyContent: "center"
      }}>
        <div style={{
          backgroundColor: COLORS.glyphLight,
          height: "100%",
          borderRadius: "8px",
          width: "100%",
          textAlign: "center",
        }}>
          <div style={{
            height: "auto",
            marginTop: "10%",
            marginBottom: "10%",
          }}>
            <img
              alt="..."
              style={{
                minHeight: "150px",
                minWidth: "150px",
                maxWidth: "450px",
                maxHeight: "400px"
              }}
              src={sideImage}
            />
            <div style={{
              fontWeight: "bolder"
            }}>
              <h1 style={{
                fontWeight: "bolder"
              }}>
                {t("Join Us At Fineo !")}
              </h1>
              <h1 style={{
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
                fontWeight: "bolder"
              }}>
                {t("Manage all your money from one single app and take back control of your finances")}
              </h1>
            </div>
          </div>
        </div>
      </Col>)}

      <Col xl="6" lg="6" md="6" xs="12" sm="12" className="mt-2">
        <div style={{
          width: isBrowser ? "60%" : "80%",
          marginLeft: "auto",
          marginRight: "auto"
        }}>
          <img src={Logo} style={{
            height: "65px",
            width: "190px",
            marginBottom: "15px",
            marginTop: "10px",
            marginLeft: "-20px"
          }} />
          <h1 className="mb-4">
            {t("Reset your password")}
          </h1>
          <div>
            <b className="my-2">
              {t("Please check the inbox of your email:")}
            </b>
            <h2 style={{
              textAlign: "center"
            }}
              className="my-2"
            >
              {email}
            </h2>
            <p style={{
              fontWeight: "bolder"
            }}>
              {t("Password_Verification_Si_vous_ne_le_recevez_pas_dans_les prochaines_minutes_,_vérifiez_votre_dossier_de_courrier_indésirable_ou")}
              <Link to="/reg/forgotpassword">
                <a className="text-blue ml-1">{t("Password_Verification_saisissez-le_à_nouveau.")}</a>
              </Link>
            </p>
            <Link to="/auth/login">
              <StandardButton
                className="my-4"
                style={{
                  width: "100%"
                }}
                type="button"
                text={t("Password_Verification_Continuez")}
              />
            </Link>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default PasswordVerification;
