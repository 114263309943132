import React, { useState, useEffect } from "react";
import moment from "moment";
import "antd/dist/antd.css";
import FormatCurrency from "../Tools/Currency/Currency";
import FormatCurrencyColored from "../Tools/Currency/FormatCurrencyColored";
import {
  Card,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { connect } from "react-redux";
import {
  setCategorie,
  getBudget,
  getGlobalValues,
  updateCategorie,
  setNewBudgetRow,
  updateGroupName,
  deleteCategorie,
  updateCategorieOrder,
  hideBudgetCategorie,
  defineBudgetTransactions,
  handelSaveNewBudgetRow,
  deleteGroup,
  hideBudgetGroup,
  UpdateCategoryGroup,
  setGlobalStateCategoryFitler,
} from "../../actions/Budget";
import Icons from "../Randoms/Icons";
import { useHistory } from 'react-router-dom';
import { DropdownSubmenu } from "react-bootstrap-submenu";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReactGAEvent from "../Tools/ReactGAEvent";
import DefaultModal from "components/Modals/DefaultModal";
import formatDate from "helpers/formatDate";
import { COLORS } from "assets/constants/theme";
import { StringManipulator } from '@fineo-io/main-javascript-library';
import { filterTransaction } from "actions/Transaction";
import noTransactionsIllustration from '../../assets/4x/4x/no-files-1.svg';
import DefaultSelect from "components/inputs/DefaultSelect";
import moenyHelpers from "helpers/money";

const { externalLink } = Icons;

function BudgetTable({
  datasource,
  groupId,
  groupB,
  typeB,
  setCategorie,
  updateGroupName,
  getBudget,
  getGlobalValues,
  updateCategorie,
  deleteCategorie,
  handelSaveNewBudgetRow,
  setGlobalStateCategoryFitler,
  date,
  BudgetGroupName,
  updateCategorieOrder,
  hideBudgetCategorie,
  authState,
  budgetState,
  defineBudgetTransactions,
  provided,
  getSummaryData,
  deleteGroup,
  hideBudgetGroup,
  UpdateCategoryGroup,
  index,
  t,
}) {
  const history = useHistory();
  const [dataSource, setdataSource] = useState(datasource);
  const [state, setState] = useState({ show: false, id: -1 });
  const [showGroup, setShowGroup] = useState(false);
  const [valid, setValid] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [hideColumns, setHide] = useState(false);
  const [refrech, setRefrech] = useState(true);
  const [openedCollapses, setopenedCollapses] = useState(["collapseOne"]);
  const [getModel, setGetModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recordTitle, setRecordTitle] = useState("");
  const [showDragIcon, setShowDragIcon] = useState(true);
  const [showDragIconCategory, setShowDragIconCategory] = useState(true);
  const [refrechBudgetData, setRefrechBudgetData] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [transferAmount, setTransferAmount] = useState('');
  const [updateInputs, setUpdateInputs] = useState({ group: false, category: false, budget: false });

  const transfertAmountToCategory = async (dataFrom, dataTo) => {
    try {
      if (typeof parseFloat(transferAmount) === 'number' && !isNaN(transferAmount)) {
        const amountFrom = parseFloat(dataFrom.budgetAmount) - parseFloat(transferAmount);
        const amountTo = parseFloat(dataTo.budgetAmount) + parseFloat(transferAmount);
        await updateCategorie({ ...dataFrom, budgetAmount: amountFrom }, dataFrom.key, date, sessionStorage.getItem("SESSION_SPACE"));
        await updateCategorie({ ...dataTo, budgetAmount: amountTo }, dataTo.key, date, sessionStorage.getItem("SESSION_SPACE"));
        await getGlobalValues(date);
        await getBudget(date, sessionStorage.getItem("SESSION_SPACE"));
        await setRefrech(true);
        setTransferAmount('');
      }
    } catch (error) {
      console.log('Transfert category error: ', error)
    }
  }

  //  ***************get transaction from a budget category*******************
  const getMouvement = async (recordKey, recordTitle) => {
    await defineBudgetTransactions(
      recordKey,
      date,
      moment(date).endOf("month").format("YYYY-MM-DD"),
      sessionStorage.getItem("SESSION_SPACE")
    );
    setRecordTitle(recordTitle);
    setGetModel(true);
  };

  //hide a budget category
  const HandleHide = (key) => {
    var category_id = key;
    hideBudgetCategorie(
      category_id,
      date,
      sessionStorage.getItem("SESSION_SPACE")
    );
    ReactGAEvent("Hide", "Hide a budget category");

    setdataSource(dataSource.filter((item) => item.key !== key));
    setRefrech(true);
    getBudget(date, sessionStorage.getItem("SESSION_SPACE"));
  };

  //Delete a budget category
  const handleDelete = async (key) => {
    await deleteCategorie(key, async () => {
      await getBudget(date, sessionStorage.getItem("SESSION_SPACE"));
      ReactGAEvent("Delete", "Delete a budget category");
      const newData = [...datasource];
      const index = newData.findIndex((item) => key === item.key);
      newData.splice(index, 1);
      getGlobalValues(date);
      if (newData.length === 0) {
        getBudget(date);
        return;
      }
      setdataSource(newData);
      setRefrech(true);
    });
  };

  //------------add budget category--------------
  const handleAdd = async () => {
    const count = datasource.length + 1;
    await handelSaveNewBudgetRow({
      key: -1,
      budget_category_name: StringManipulator.capitalize(t("global.new.category")),
      budgetAmount: 0,
      groupId: dataSource[0].groupId,
      budget_group_name: groupB,
      budget_type_id: typeB,
      is_it_active: true,
      activities: 0,
      disponible: 0,
      user_id: -3,
      monthly_budget_date: date,
    });
  };

  const handleAjt = async () => {
    handleAdd();
    const newData = [...datasource];
    const index = newData.findIndex((item) => item.key === -1);
    const item = newData[index];
    await setCategorie(item);
    ReactGAEvent("Add", "Add a budget category");
    await setHidden(true);
    await getBudget(date, sessionStorage.getItem("SESSION_SPACE"));
    await setRefrech(true);
  };
  //------------end add budget category--------------

  //------------list budget category dropdown items--------------
  const sortedBudgetCategories = budgetState.budgets.budgetData
    .map((type) => Object.keys(type).map((group) => type[group].map((item) => item))).flat(2)
    .sort((a, b) => a.budget_category_name?.localeCompare(b.budget_category_name));
  const listBudgetCategoryDropdownItems = (data) => {
    return sortedBudgetCategories.map((item) => (
      <DropdownItem
        key={item.key}
        onClick={async (e) => {
          e.stopPropagation();
          e.preventDefault();
          await transfertAmountToCategory(data, item);
        }}
      >
        {`${item.budget_category_name} (${item.budget_group_name})`}
      </DropdownItem>
    ));
  };

  //update for budget category
  const handleSave = async (row) => {
    const newData = [...datasource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    row.disponible =
      parseFloat(row.disponible) +
      parseFloat(row.budgetAmount) -
      parseFloat(item.budgetAmount);
    await updateCategorie(
      { ...item, ...row },
      item.key,
      date,
      sessionStorage.getItem("SESSION_SPACE")
    );
    await getGlobalValues(date);
    await getBudget(date, sessionStorage.getItem("SESSION_SPACE"));
    await setRefrech(true);
  };

  const collapsesToggle = (collapse) => {
    let openedCollapsess = openedCollapses;
    if (openedCollapsess.includes(collapse)) {
      setopenedCollapses([]);
    } else {
      setopenedCollapses([collapse]);
    }
  };

  useEffect(() => {
    if (refrech === true) {
      getSummaryData(datasource);
      setLoading(true);
      setRefrech(false);
    }
  }, [refrech, date]);

  useEffect(() => {
    if (budgetState.budgets.budgetData.lenght !== 0) {
      setRefrechBudgetData(true);
    }
    if (budgetState.budgets.budgetData.lenght === 0) {
      setRefrechBudgetData(false);
    }
  }, [budgetState.budgets.budgetData]);

  // ************ View Rendring Handlers ************
  const renderEditView = () => {
    return (
      loading === true && (
        <span
          onMouseEnter={() => setShowDragIcon(false)}
          onMouseLeave={() => {setShowDragIcon(true)}}
          style={{ color: "drakF" }}
        >
          <span className="mr-2">
            <i
              className="fas fas fa-arrows-alt"
              style={{ color: showDragIcon === true ? COLORS.neutral : COLORS.glyphSecondary }}
              {...provided}
            />
          </span>
          <span>
            <UncontrolledDropdown>
              <DropdownToggle tag="span" onClick={(event) => event.stopPropagation()}>
                <i className="fas fa-ellipsis-v fa-sm cursor-pointer" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem key="1" onClick={() => setShowGroup(true)}>
                  {StringManipulator.capitalize(t("global.delete._"))}
                </DropdownItem>
                <DropdownItem key="2"
                  onClick={() => {
                    hideBudgetGroup(groupId, date, sessionStorage.getItem("SESSION_SPACE"));
                    ReactGAEvent("Hide", "Hide an budget group");
                  }}
                >
                  {StringManipulator.capitalize(t("global.hide._"))}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </span>
        </span>
      )
    );
  };

  //--------------------------------drag&drop------------------------------------
  const reorder = async (list, startIndex, endIndex) => {
    await updateCategorieOrder(
      {
        id_bud1: list[startIndex].key,
        order1: list[startIndex].order,
      },
      {
        id_bud2: list[endIndex].key,
        order2: list[endIndex].order,
      },
      {
        startIndex,
        endIndex,
      },
      {
        budget_type_id: typeB,
        budget_group_name: groupB,
      },
      date,
      sessionStorage.getItem("SESSION_SPACE")
    );
    ReactGAEvent("Move", "Move a budget categorie");

    await getBudget(date, sessionStorage.getItem("SESSION_SPACE"));
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      datasource,
      result.source.index,
      result.destination.index
    );
  };
  //-------------------------------FinDrag&drop-------------------------------

  const getStyle = (condition) => {
    if (condition !== null && (new Date() - new Date(condition)) / 1000 < 10) {
      return {
        backgroundColor: COLORS.emptiness,
        borderBottom: `1px solid ${COLORS.emptiness}`,
      };
    }
    return {
      borderBottom: `1px solid ${COLORS.glyphLight}`,
    };
  };

  return (
    <div>
      <Card className="mx-3">
        {refrechBudgetData === true ? (
          <ListGroup className="list" flush>
            <ListGroupItem
              className="d-flex justify-content-between align-items-center"
              style={{
                borderBottom: "0 none",
                backgroundColor: COLORS.glyphLight
              }}
            >
              {hideColumns === false ? (
                <>
                  <h3
                    className={`w-50 text-black ${index === 0 && "budget-group"}`}
                    onClick={() => {
                      setUpdateInputs({ ...updateInputs, group: true });
                      document.getElementById(`InputGroupName${BudgetGroupName}`).style.display = "inline";
                      document.getElementById(`InputGroupName${BudgetGroupName}`).focus();
                      document.getElementById(`InputGroupName${BudgetGroupName}`).select();
                      document.getElementById(`SpanGroupName${BudgetGroupName}`).style.display = "none";
                    }}
                  >
                    <span id={`SpanGroupName${BudgetGroupName}`}>
                      <span className="h4 mr-1">{renderEditView()}</span>
                      {BudgetGroupName}
                    </span>
                    <Input
                      type="text"
                      style={{ display: "none" }}
                      defaultValue={BudgetGroupName}
                      id={`InputGroupName${BudgetGroupName}`}
                      invalid={valid}
                      onKeyDown={(event) => {
                        if (event.key == "Enter") {
                          var name = document.getElementById(`InputGroupName${BudgetGroupName}`).value;
                          if (document.getElementById(`InputGroupName${BudgetGroupName}`).value.length === 0) {
                            setValid(true);
                          } else {
                            updateGroupName(groupId, name);
                            ReactGAEvent("Update", "Update an budget group name");
                            document.getElementById(`SpanGroupName${BudgetGroupName}`).style.display = "inline";
                            document.getElementById(`InputGroupName${BudgetGroupName}`).style.display = "none";
                            getBudget(date, sessionStorage.getItem("SESSION_SPACE"));
                            setValid(false);
                          }
                        }
                        if (event.key == 'Escape') {
                          setUpdateInputs({ ...updateInputs, group: false });
                          document.getElementById(`SpanGroupName${BudgetGroupName}`).style.display = "inline";
                          document.getElementById(`InputGroupName${BudgetGroupName}`).style.display = "none";
                        }
                      }}
                      onBlur={() => {
                        if (updateInputs.group) {
                          var name = document.getElementById(`InputGroupName${BudgetGroupName}`).value;
                          if (document.getElementById(`InputGroupName${BudgetGroupName}`).value.length === 0) {
                            setValid(true);
                          } else {
                            updateGroupName(groupId, name);
                            ReactGAEvent("Update", "Update an budget group name");
                            document.getElementById(`SpanGroupName${BudgetGroupName}`).style.display = "inline";
                            document.getElementById(`InputGroupName${BudgetGroupName}`).style.display = "none";
                            getBudget(date, sessionStorage.getItem("SESSION_SPACE"));
                            setValid(false);
                          }
                        }
                      }}
                    />
                  </h3>
                </>
              ) : (
                <h3
                  className="w-50 text-grayF"
                  onClick={() => {
                    document.getElementById(`InputGroupName${BudgetGroupName}`).style.display = "inline";
                    document.getElementById(`InputGroupName${BudgetGroupName}`).focus();
                    document.getElementById(`InputGroupName${BudgetGroupName}`).select();
                    document.getElementById(`SpanGroupName${BudgetGroupName}`).style.display = "none";
                  }}
                >
                  <span id={`SpanGroupName${BudgetGroupName}`}>
                    <span className="h4 mr-1">{renderEditView()}</span>
                    {BudgetGroupName}
                  </span>
                  <Input
                    type="text"
                    style={{ display: "none" }}
                    defaultValue={BudgetGroupName}
                    id={`InputGroupName${BudgetGroupName}`}
                    invalid={valid}
                    onKeyPress={(target) => {
                      if (target.charCode == 13) {
                        var name = document.getElementById(`InputGroupName${BudgetGroupName}`).value;
                        if (document.getElementById(`InputGroupName${BudgetGroupName}`).value.length === 0) {
                          setValid(true);
                        } else {
                          updateGroupName(groupId, name);
                          ReactGAEvent("Update", "Update an budget group name");
                          document.getElementById(`SpanGroupName${BudgetGroupName}`).style.display = "inline";
                          document.getElementById(`InputGroupName${BudgetGroupName}`).style.display = "none";
                          getBudget(date, sessionStorage.getItem("SESSION_SPACE"));
                          setValid(false);
                        }
                      }
                    }}
                    onBlur={() => {
                      var name = document.getElementById(`InputGroupName${BudgetGroupName}`).value;
                      if (document.getElementById(`InputGroupName${BudgetGroupName}`).value.length === 0) {
                        setValid(true);
                      } else {
                        updateGroupName(groupId, name);
                        ReactGAEvent("Update", "Update an budget group name");
                        document.getElementById(`SpanGroupName${BudgetGroupName}`).style.display = "inline";
                        document.getElementById(`InputGroupName${BudgetGroupName}`).style.display = "none";
                        getBudget(date, sessionStorage.getItem("SESSION_SPACE"));
                        setValid(false);
                      }
                    }}
                  />
                </h3>
              )}
              <h5 className="text-center w-25 text-gray">
                {StringManipulator.capitalize(t("global.carried._"))}
              </h5>
              <h5 className="text-center w-25 text-gray">
                {StringManipulator.capitalize(t("global.budgeted._"))}
              </h5>
              <h5 className="text-center w-25 text-gray">
                {StringManipulator.capitalize(t("global.consumed._"))}
              </h5>
              <h5 className="text-center w-25 text-gray">
                {StringManipulator.capitalize(t("global.available._"))}
              </h5>
              {hideColumns === false ? (
                <i
                  className="fas fa-chevron-up"
                  style={{
                    color: COLORS.glyphPrimary,
                    cursor: "pointer",
                    fontSize: "15px",
                    position: "absolute",
                    top: "12px",
                    right: "10px"
                  }}
                  role="tab"
                  onClick={() => {
                    collapsesToggle("collapseOne");
                    setHide(!hideColumns);
                  }}
                  aria-expanded={openedCollapses.includes("collapseOne")}
                />
              ) : (
                <i
                  className="fas fa-chevron-down"
                  style={{
                    color: COLORS.glyphPrimary,
                    cursor: "pointer",
                    fontSize: "15px",
                    position: "absolute",
                    top: "12px",
                    right: "10px"
                  }}
                  onClick={() => {
                    collapsesToggle("collapseOne");
                    setHide(!hideColumns);
                  }}
                />
              )}
            </ListGroupItem>
            <Collapse role="tabpanel" isOpen={openedCollapses.includes("collapseOne")}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {datasource.map((data, index1) => (
                        <Draggable
                          key={data.key}
                          draggableId={JSON.stringify({
                            id: data.budget_category_name,
                          })}
                          index={index1}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <ListGroupItem
                                className="d-flex justify-content-between align-items-center"
                                style={getStyle(data.has_group_changed)}
                                id={`ListGroupItem${data.key}`}
                                onMouseEnter={() =>
                                  setShowDragIconCategory(false)
                                }
                                onMouseLeave={() => {
                                  setShowDragIconCategory(true);
                                }}
                              >
                                <span
                                  className={`w-50 ${index === 0 && index1 === 0 && "budget-cetgories"}`}
                                  style={{
                                    color: COLORS.glyphPrimary,
                                  }}
                                  onClick={() => {
                                    setUpdateInputs({ ...updateInputs, category: true });
                                    document.getElementById(`InputCategoryName${data.key}`).classList.remove("d-none");
                                    document.getElementById(`InputCategoryName${data.key}`).focus();
                                    document.getElementById(`InputCategoryName${data.key}`).select();
                                    document.getElementById(`ListGroupItem${data.key}`).classList.add("zoomIn");
                                    document.getElementById(`SpanCategoryName${data.key}`).style.display = "none";
                                  }}
                                >
                                  {data.key !== null ? (
                                    <span id={`SpanCategoryName${data.key}`}>
                                      <span className="mr-2">
                                        <i
                                          {...provided.dragHandleProps}
                                          className="fas fas fa-arrows-alt"
                                          style={{
                                            color: showDragIconCategory === true ? COLORS.neutral : COLORS.glyphSecondary,
                                          }}
                                        />
                                      </span>
                                      <UncontrolledDropdown>
                                        <DropdownToggle tag="span" className="bg-transparent shadow-none border-0" onClick={(event) => event.stopPropagation()}>
                                          <span>
                                            <i className="fas fa-ellipsis-v fa-md mr-1 cursor-pointer" />
                                          </span>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <DropdownItem key="1" onClick={() => setState({ show: true, id: data.key })}>
                                            {StringManipulator.capitalize(t("global.delete._"))}
                                          </DropdownItem>
                                          <DropdownItem key="2" onClick={() => HandleHide(data.key)}>
                                            {StringManipulator.capitalize(t("global.hide._"))}
                                          </DropdownItem>
                                          <DropdownSubmenu
                                            key="4"
                                            title={StringManipulator.capitalize(t("reallocate"))}
                                            onToggle={() => { setTransferAmount('') }}
                                            onClick={(event) => event.stopPropagation()}
                                          >
                                            <div
                                              style={{
                                                backgroundColor: COLORS.glyphLight,
                                                color: COLORS.glyphSecondary,
                                                paddingLeft: "10px",
                                                paddingTop: "5px",
                                                paddingBottom: "5px",
                                                marginBottom: '10px'
                                              }}
                                              onClick={(event) => event.stopPropagation()}
                                            >
                                              {StringManipulator.capitalize(t("global.amount._"))}
                                            </div>
                                            <input
                                              value={transferAmount}
                                              placeholder={moenyHelpers.adjustCurrencyFormat(0, authState.user.currency_code, authState.user.currency_locales)}
                                              onChange={(event) => setTransferAmount(event.target.value)}
                                              onClick={(event) => event.stopPropagation()}
                                              style={{
                                                alignSelf: "center",
                                                borderRadius: '2px',
                                                borderColor: COLORS.primary,
                                                width: "90%",
                                                height: '30px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                                paddingLeft: "10px",
                                                display: 'block'
                                              }}
                                            />
                                            <div
                                              style={{
                                                backgroundColor: COLORS.glyphLight,
                                                color: COLORS.glyphSecondary,
                                                paddingLeft: "10px",
                                                paddingTop: "5px",
                                                paddingBottom: "5px",
                                              }}
                                              onClick={(event) => event.stopPropagation()}
                                            >
                                              {StringManipulator.capitalize(t("to"))}
                                            </div>
                                            {listBudgetCategoryDropdownItems(data)}
                                          </DropdownSubmenu>
                                          <DropdownSubmenu key="3" title={StringManipulator.capitalize(t("global.move.to._"))}>
                                            {refrech === false ? (
                                              Object.keys(budgetState.budgets.budgetData[data.budget_type_id]).map((element) =>
                                                element !== data.budget_group_name ? (
                                                  <DropdownItem
                                                    key={Math.random()}
                                                    onClick={() => {
                                                      UpdateCategoryGroup(
                                                        data.key,
                                                        budgetState.budgets.budgetData[data.budget_type_id][element][0].groupId,
                                                        date,
                                                        sessionStorage.getItem("SESSION_SPACE")
                                                      );
                                                      ReactGAEvent("Move", "Move a category to another group");
                                                    }}
                                                  >
                                                    {element}
                                                  </DropdownItem>
                                                ) : (
                                                  <div key={Math.random()}></div>
                                                )
                                              )
                                            ) : (
                                              <div></div>
                                            )}
                                          </DropdownSubmenu>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                      <span>{data.budget_category_name}</span>
                                    </span>
                                  ) : (
                                    <div></div>
                                  )}
                                  <Input
                                    className="d-none mt-1"
                                    type="text"
                                    defaultValue={data.budget_category_name}
                                    name={data.budget_category_name}
                                    id={`InputCategoryName${data.key}`}
                                    style={{
                                      height: "30px",
                                    }}
                                    onKeyDown={(event) => {
                                      if (event.key == 'Enter') {
                                        handleSave({ ...data, budget_category_name: document.getElementById(`InputCategoryName${data.key}`).value });
                                        getBudget(date, sessionStorage.getItem("SESSION_SPACE"));
                                        document.getElementById(`ListGroupItem${data.key}`).classList.remove("zoomIn");
                                        document.getElementById(`SpanCategoryName${data.key}`).style.display = "block";
                                        document.getElementById(`InputCategoryName${data.key}`).classList.add("d-none");
                                      }
                                      if (event.key == 'Escape') {
                                        setUpdateInputs({ ...updateInputs, category: false });
                                        document.getElementById(`ListGroupItem${data.key}`).classList.remove("zoomIn");
                                        document.getElementById(`SpanCategoryName${data.key}`).style.display = "block";
                                        document.getElementById(`InputCategoryName${data.key}`).classList.add("d-none");
                                      }
                                    }}
                                    onBlur={() => {
                                      if (updateInputs.category) {
                                        handleSave({ ...data, budget_category_name: document.getElementById(`InputCategoryName${data.key}`).value });
                                        getBudget(date, sessionStorage.getItem("SESSION_SPACE"));
                                        document.getElementById(`ListGroupItem${data.key}`).classList.remove("zoomIn");
                                        document.getElementById(`SpanCategoryName${data.key}`).style.display = "block";
                                        document.getElementById(`InputCategoryName${data.key}`).classList.add("d-none");
                                      }
                                    }}
                                  />
                                </span>
                                <h5 className="text-center w-25">
                                  {data.key !== null &&
                                    FormatCurrencyColored(
                                      parseFloat(data.carried),
                                      authState.user.currency_code,
                                      authState.user.currency_locales
                                    )}
                                </h5>
                                <h5
                                  className={`text-center w-25 hoverCatName ${index === 0 && index1 === 0 && "budget-how-to-budget-1"}`}
                                  onClick={() => {
                                    setUpdateInputs({ ...updateInputs, budget: true });
                                    document.getElementById(`InputAmount${data.key}`).classList.remove("d-none");
                                    document.getElementById(`InputAmount${data.key}`).focus();
                                    document.getElementById(`InputAmount${data.key}`).value = data.budgetAmount;
                                    document.getElementById(`InputAmount${data.key}`).select();
                                    document.getElementById(`SpanAmount${data.key}`).style.display = "none";
                                    document.getElementById(`ListGroupItem${data.key}`).classList.add("zoomIn");
                                  }}
                                >
                                  <span id={`SpanAmount${data.key}`}>
                                    {data.key !== null &&
                                      FormatCurrency(
                                        parseFloat(data.budgetAmount),
                                        authState.user.currency_code,
                                        authState.user.currency_locales,
                                        <i className="fas fa-sharp fa-solid fa-pen" style={{ paddingLeft: "3px", color: COLORS.primary }} />,
                                      )}
                                  </span>
                                  <Input
                                    className="d-none"
                                    type="text"
                                    defaultValue={data.budgetAmount}
                                    name={data.budgetAmount}
                                    id={`InputAmount${data.key}`}
                                    style={{ height: "30px" }}
                                    onKeyDown={(even) => {
                                      if (even.key == 'Enter') {
                                        getBudget(date, sessionStorage.getItem("SESSION_SPACE"));
                                        document.getElementById(`ListGroupItem${data.key}`).classList.remove("zoomIn");
                                        document.getElementById(`SpanAmount${data.key}`).style.display = "block";
                                        document.getElementById(`InputAmount${data.key}`).classList.add("d-none");
                                      }
                                      if (even.key == 'Escape') {
                                        setUpdateInputs({ ...updateInputs, budget: false });
                                        document.getElementById(`ListGroupItem${data.key}`).classList.remove("zoomIn");
                                        document.getElementById(`SpanAmount${data.key}`).style.display = "block";
                                        document.getElementById(`InputAmount${data.key}`).classList.add("d-none");
                                      }
                                    }}
                                    onBlur={() => {
                                      if (updateInputs.budget) {
                                        handleSave({ ...data, budgetAmount: document.getElementById(`InputAmount${data.key}`).value });
                                        getBudget(date, sessionStorage.getItem("SESSION_SPACE"));
                                        document.getElementById(`ListGroupItem${data.key}`).classList.remove("zoomIn");
                                        document.getElementById(`SpanAmount${data.key}`).style.display = "block";
                                        document.getElementById(`InputAmount${data.key}`).classList.add("d-none");
                                      }
                                    }}
                                  />
                                </h5>
                                <h5 className="text-center w-25 cursor-pointer">
                                  {
                                    <div
                                      title={StringManipulator.capitalize(t("global.see.transaction.s"))}
                                      onClick={() => {
                                        setSelectedOption(data.key);
                                        getMouvement(data.key, data.budget_category_name);
                                      }}
                                    >
                                      {data.key !== null &&
                                        FormatCurrency(
                                          parseFloat(data.activities),
                                          authState.user.currency_code,
                                          authState.user.currency_locales,
                                          (
                                            <>
                                              {"  "}
                                              <img width={"8px"} style={{ display: "inline-block" }} src={externalLink} />
                                            </>
                                          )
                                        )
                                      }
                                    </div>
                                  }
                                </h5>
                                <h5
                                  className={`text-center w-25 mt--2 ${index === 0 && index1 === 0 && "budget-how-to-use-last-month-budget"}`}
                                  style={{
                                    color: COLORS.glyphPrimary,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {data.key !== null &&
                                    FormatCurrencyColored(
                                      parseFloat(data.disponible),
                                      authState.user.currency_code,
                                      authState.user.currency_locales
                                    )}
                                </h5>
                              </ListGroupItem>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div className="col text-right mt-2 mb-3">
                <i
                  className="far fa-plus-square mr-1 mt-2 cursor-pointer" onClick={handleAjt}
                  style={{ color: COLORS.glyphPrimary }}
                  size="lg"
                  hidden={!hidden}
                />
                <span
                  style={{
                    color: COLORS.glyphPrimary,
                    fontFamily: "poppins",
                    fontWeight: "200",
                    fontSize: "14px"
                  }}
                  className="cursor-pointer"
                  onClick={handleAjt}
                >
                  {StringManipulator.capitalize(t("global.new.category"))}
                </span>
              </div>
            </Collapse>
          </ListGroup>
        ) : (
          <div></div>
        )}
      </Card>
      <hr className="my-2 mx-4 mb-4" />
      <DefaultModal
        title={t("global.warning._")}
        hasTwoButtons={true}
        btnText={StringManipulator.capitalize(t("global.delete._"))}
        secondBtnText={StringManipulator.capitalize(t("global.cancel._"))}
        color={COLORS.danger}
        isOpen={state.show}
        onClick={() => {
          handleDelete(state.id);
          setState({ show: false, id: -1 });
        }}
        secondOnClick={() => setState({ show: false, id: -1 })}
        toggle={() => setState({ show: false, id: -1 })}
      >
        {StringManipulator.capitalize(t("prompts.this.action.will.remove.the.category.from.all.existing.transactions"))}.&nbsp;
        {StringManipulator.capitalize(t("prompts.are.you.sure.you.want.to.proceed.?"))}
      </DefaultModal>
      <DefaultModal
        title={t("global.warning._")}
        hasTwoButtons={true}
        btnText={StringManipulator.capitalize(t("global.delete._"))}
        secondBtnText={StringManipulator.capitalize(t("global.cancel._"))}
        color={COLORS.danger}
        isOpen={showGroup}
        onClick={async () => {
          await deleteGroup(groupId);
          ReactGAEvent("Delete", "Delete a budget group");
          getBudget(date, sessionStorage.getItem("SESSION_SPACE"));
          setShowGroup(false);
        }}
        secondOnClick={() => setShowGroup(false)}
        toggle={() => setShowGroup(false)}
      >
        {StringManipulator.capitalize(t("prompts.this.action.will.remove.all.the.categories.of.this.group.from.all.existing.transactions"))}.&nbsp;
        {StringManipulator.capitalize(t("prompts.are.you.sure.you.want.to.proceed.?"))}
      </DefaultModal>
      <DefaultModal
        title={t("global.category.transaction.s")}
        style={{ paddingTop: "100px" }}
        isOpen={getModel}
        hasNoButtons={true}
        size='modal-xl'
        toggle={() => setGetModel(false)}
      >
        <div className="modal-body" style={{ maxHeight: "400px", height: "400px", overflow: "hidden", marginBottom: "20px", width: "100%", zIndex: 1, color: COLORS.glyphPrimary }} >
          <div style={{ position: "sticky", top: 0, width: "100%", display: "flex", justifyContent: "center", zIndex: 3 }} >
            <span
              style={{ position: "absolute", left: 0, top: '50%', transform: 'translate(0%, -50%)' }}>
              {
                FormatCurrency(
                  budgetState?.mouvementTotalAmount,
                  authState.user.currency_code,
                  authState.user.currency_locales
                )
              }
            </span>
            <DefaultSelect
              id="mySelect"
              value={selectedOption}
              onChange={(selected) => {
                setSelectedOption(selected);
                getMouvement(selected.value, selected.title);
              }}
              placeholder={recordTitle}
              controlStyles={{
                minWidth: window.innerWidth >= 992 ? '300px' : '100%',
              }}
              options={Object.keys(budgetState.budgets.budgetData).reduce((acc, type) => {
                const groups = Object.keys(budgetState.budgets.budgetData[type]).reduce((groupAcc, group) => {
                  const categories = budgetState.budgets.budgetData[type][group].map((category) => ({
                    value: category?.key,
                    label: category?.budget_category_name,
                    title: category?.budget_category_name,
                  }));
                  groupAcc.push({ label: group, options: categories });
                  return groupAcc;
                }, []);
                acc.push(...groups);
                return acc;
              }, [])}
            />


            {budgetState?.budgetMouvement?.length !== 0 &&
              <img
                title={StringManipulator.capitalize(t("global.view.and.edit.these.transactions.in.the.main.table"))}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: 0,
                  top: '50%',
                  transform: 'translate(0%, -50%)',
                  transition: 'opacity 0.3s'
                }}
                onClick={async () => {
                  const endDate = moment(date).endOf("month").format("YYYY-MM-DD");
                  await setGlobalStateCategoryFitler(budgetState.budgetCategoryId, [date, endDate]);
                  history.push(`/app/transactions?category=${budgetState.budgetCategoryId}&start_date=${date}&end_date=${endDate}`);
                }}
                onMouseEnter={(event) => { event.target.style.opacity = 0.5, event.target.style.scale = 1.1 }}
                onMouseLeave={(event) => { event.target.style.opacity = 1, event.target.style.scale = 1 }}
                src={externalLink} width={"18px"}
              />
            }
          </div>
          <div style={{ maxHeight: "100%", overflowY: "auto", width: "100%", marginTop: "20px", zIndex: 1, }}>
            <table className={"table"} style={{ width: "100%" }}>
              <thead style={{ position: "sticky", top: 0, zIndex: 1 }} >
                <tr style={{ backgroundColor: COLORS.glyphLight }}>
                  <th scope="col">
                    {t("global.date._")}
                  </th>
                  <th scope="col">
                    {t("global.account._")}
                  </th>
                  <th scope="col">
                    {t("global.counterparty._")}
                  </th>
                  <th scope="col">
                    {t("global.amount._")}
                  </th>
                  <th scope="col">
                    {t("global.note._")}
                  </th>
                </tr>
              </thead>
              <tbody id="transactionTbody" style={{ overflowY: 'auto', paddingBottom: "200px", maxHeight: "calc(100% -40px)", marginTop: "50px" }}>
                {budgetState?.budgetMouvement?.length !== 0 &&
                  budgetState?.budgetMouvement != [] ? (
                  budgetState?.budgetMouvement?.map((element) => {
                    return (
                      <tr key={Math.random() * Math.random()}>
                        <td>
                          {formatDate(moment(element.transaction_date).utc())}
                        </td>
                        <td>{element.account_name}</td>
                        <td>{element.payee_name}</td>
                        <td style={{ textAlign: "center" }}>
                          {
                            FormatCurrency(element.transaction_amount,
                              authState.user.currency_code,
                              authState.user.currency_locales)
                          }
                        </td>
                        <td>{element.transaction_note}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="5">
                      <img src={noTransactionsIllustration} width={"270px"} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </DefaultModal>
    </div>
  );
}

BudgetTable.prototype = {
  authState: PropTypes.object.isRequired,
  budgetState: PropTypes.object.isRequired,
  setCategorie: PropTypes.func.isRequired,
  handelSaveNewBudgetRow: PropTypes.func.isRequired,
  getBudget: PropTypes.func.isRequired,
  getGlobalValues: PropTypes.func.isRequired,
  updateCategorie: PropTypes.func.isRequired,
  updateGroupName: PropTypes.func.isRequired,
  setNewBudgetRow: PropTypes.func.isRequired,
  deleteCategorie: PropTypes.func.isRequired,
  updateCategorieOrder: PropTypes.func.isRequired,
  hideBudgetCategorie: PropTypes.func.isRequired,
  defineBudgetTransactions: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  hideBudgetGroup: PropTypes.func.isRequired,
  UpdateCategoryGroup: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authState: state.auth,
  budgetState: state.budget,
});

export default connect(mapStateToProps, {
  setCategorie,
  getBudget,
  setNewBudgetRow,
  handelSaveNewBudgetRow,
  getGlobalValues,
  updateCategorie,
  updateGroupName,
  deleteCategorie,
  setGlobalStateCategoryFitler,
  updateCategorieOrder,
  hideBudgetCategorie,
  defineBudgetTransactions,
  filterTransaction,
  deleteGroup,
  hideBudgetGroup,
  UpdateCategoryGroup,
})(BudgetTable);
