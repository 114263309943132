import { CHART_COLORS } from "assets/constants/theme";
import {
  GET_DASHBOARD_BUDGET_SITUATION,
  GET_DASHBOARD_ENTRIES,
  SET_LOGOUT,
  GET_DASHBOARD_DEPENSE,
  SET_BDATE,
} from "../actions/types";
import moment from "moment";
import FormatCurrency from "../components/Tools/Currency/Currency";
import { COLORS } from "assets/constants/theme";

const { REACT_APP_SERVER_URL } = process.env;

//colors for charts---------
const colors = CHART_COLORS;

//array with months of year
var months = [
  "Dashboard_Janvier",
  "Dashboard_Fevrier",
  "Dashboard_Mars",
  "Dashboard_Avril",
  "Dashboard_Mai",
  "Dashboard_Juin",
  "Dashboard_Juillet",
  "Dashboard_Aout",
  "Dashboard_Septembre",
  "Dashboard_Octobre",
  "Dashboard_Novembre",
  "Dashboard_Decembre",
];

//dates
var dateFrom = new Date();
dateFrom.setMonth(dateFrom.getMonth() - 5);
var year = dateFrom.getFullYear();
const startDate = moment(dateFrom).format("YYYY-MM-DD");
const this_month_date = moment(new Date()).format("YYYY-MM-01");
const endDate = moment(new Date()).format("YYYY-MM-DD");

//get the start month
var start = parseInt(new Date(startDate).getMonth()) + 1;
var yearIndex = 1;
var semestreMonths = [];
var semestreData = [];



// Action: Get dashboard calculation widgets for the current user.
export const getSituationCompte =
  (date = null, space_id) =>
    async (dispatch) => {
      dispatch({
        type: SET_BDATE,
        payload: date
      })
      try {
        await fetch(`https://${REACT_APP_SERVER_URL}/globalvalues`, {
          method: "post",
          headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
          credentials: "include",
          body: JSON.stringify({
            refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
            access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
            space_id,
            b_date: date !== null ? date : this_month_date,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            isStillLogedIn(response, dispatch);
            if (response.messages === undefined) return;
            dispatch({
              type: GET_DASHBOARD_BUDGET_SITUATION,
              payload: {
                // The total amount the user can budget this month
                totalToBudgetCurrentMonth: response.messages.payload.totalToBudgetCurrentMonth,
                // The available amount the user can still budget
                availableCurrentMonth: response.messages.payload.availableToBudgetCurrentMonth,
                //
                availableAmountToSpent: response.messages.payload.cashflowUpToSelectedMonth,
                budgetedCurrentMonth: response.messages.payload.budgetedSelectedMonth,
                entriesCurrentMonth: response.messages.payload.totalEntriesUpToSelectedMonth,
                totalExpensesUpToSeloctedMonth: response.messages.payload.totalExpensesUpToSeloctedMonth,
                expensesCurrentMonth: response.messages.payload.totalExpensesSelectedMonth,
                availableFromMonthBefore: response.messages.payload.availableFromMonthBefore,
                EpargneCurrentMonth: response.messages.payload.savingSelectedMonth,
                savingUpToSelectedMonth: response.messages.payload.savingUpToSelectedMonth
              },
            });
          });
      } catch (err) { }
    };

//get the exepenses of the last 5 months + the current month
export const dashboardSemestreDepense =
  (currecyCode, locales, t,
    startDate_,
    space_id) => async (dispatch) => {
      try {

        fetch(`https://${REACT_APP_SERVER_URL}/chart/depenses`, {
          method: "post",
          headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
          credentials: "include",
          body: JSON.stringify({
            refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
            access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
            start_date: startDate_,
            category: [-2],
            end_date: endDate,
            account_id: [-1],
            space_id
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            semestreMonths.map((month) =>
              month.split("_")[0] !== "Dashboard"
                ? (month = t(`Dashboard_${month}`))
                : month
            );
            isStillLogedIn(response, dispatch);
            if (response.success) {
              dispatch({
                type: GET_DASHBOARD_DEPENSE,
                payload: {
                  data: {
                    labels: [],
                    datasets: [
                      {
                        label: "",
                        backgroundColor: "white",
                        data: [],
                        maxBarThickness: 7,
                      },
                    ],
                  },
                  options: {},
                },
              });
              var chartDepense = {};
              var depenseMonth = new Date(startDate_);
              var Empty = Object.keys(
                response.messages.payload
              ).length;
              semestreData = [0, 0, 0, 0, 0, 0];

              if (Empty > 0) {
                semestreMonths.forEach((day, index) => {
                  response.messages.payload.forEach(
                    (element) => {
                      if (
                        new Date(element.transaction_date).getMonth() ===
                        depenseMonth.getMonth()
                      ) {
                        if (
                          new Date(element.transaction_date).getFullYear() ===
                          depenseMonth.getFullYear()
                        )
                          semestreData[index] =
                            semestreData[index] +
                            Math.abs(parseFloat(element.ta) / 100);
                      }
                    }
                  );
                  depenseMonth.setMonth(depenseMonth.getMonth() + 1);
                });
              }

              //fill ChartEntries with Data and Days
              chartDepense = {
                options: {
                  scales: {
                    yAxes: [
                      {
                        gridLines: {
                          color: colors.black,
                          zeroLineColor: colors.gray[700],
                        },
                        ticks: {
                          callback: function (value) {
                            if (!(value % 10)) {
                              const val = FormatCurrency(
                                parseFloat(value),
                                currecyCode,
                                locales
                              );
                              return val.props.children;
                            }
                          },
                        },
                      },
                    ],
                  },
                  tooltips: {
                    callbacks: {
                      label: function (item, data) {
                        var label = data.datasets[item.datasetIndex].label || "";
                        const lbl = FormatCurrency(
                          item.yLabel,
                          currecyCode,
                          locales
                        );
                        var yLabel = lbl.props.children;
                        var content = "";
                        if (data.datasets.length > 1) {
                          content += label;
                        }
                        content += yLabel;
                        return content;
                      },
                    },
                  },
                },
                data: {
                  labels: semestreMonths,
                  datasets: [
                    {
                      label: "Sales",
                      data: semestreData,
                      maxBarThickness: 12,
                    },
                  ],
                },
              };
              dispatch({
                type: GET_DASHBOARD_DEPENSE,
                payload: chartDepense,
              });
            }
          });
      } catch (err) { }
    };

//get the entries of the last 5 months + the current month
export const dashboardSemestreEntries =
  (currecyCode, locales, t, startDate_, space_id) => async (dispatch) => {
    try {
      semestreMonths = [];
      start = parseInt(new Date(startDate_).getMonth()) + 1;

      for (var i = 0; i < 6; i++) {
        semestreData.push(0);
        semestreMonths.push(t(months[start - 1]) + " " + year);
        if (start != 12) {
          start = start + 1;
        } else {
          start = 1;
          year = year + yearIndex;

        }
      }

      fetch(`https://${REACT_APP_SERVER_URL}/chart/entries`, {
        method: "post",
        headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
        credentials: "include",
        body: JSON.stringify({
          refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
          access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
          start_date: startDate_,
          end_date: endDate,
          account_id: [-1],
          category: [-2],
          space_id
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          semestreMonths.map((month) =>
            month.split("_")[0] !== "Dashboard"
              ? (month = t(`Dashboard_${month}`))
              : month
          );
          isStillLogedIn(response, dispatch);
          if (response.success) {
            dispatch({
              type: GET_DASHBOARD_ENTRIES,
              payload: {
                data: {
                  labels: [],
                  datasets: [
                    {
                      label: "",
                      backgroundColor: "white",
                      data: [],
                      maxBarThickness: 7,
                    },
                  ],
                },
                options: {},
              },
            });
            var chartEntries = {};
            var entriesMonth = new Date(startDate_);
            var Empty = Object.keys(
              response.messages.payload
            ).length;
            semestreData = [0, 0, 0, 0, 0, 0];

            if (Empty > 0) {
              semestreMonths.forEach((day, index) => {
                response.messages.payload.forEach(
                  (element) => {
                    if (
                      new Date(element.transaction_date).getMonth() ===
                      entriesMonth.getMonth()
                    ) {
                      if (
                        new Date(element.transaction_date).getFullYear() ===
                        entriesMonth.getFullYear()
                      )
                        semestreData[index] =
                          semestreData[index] + parseFloat(element.ta) / 100;
                    }
                  }
                );
                entriesMonth.setMonth(entriesMonth.getMonth() + 1);
              });
            }

            chartEntries = {
              options: {
                scales: {
                  yAxes: [
                    {
                      gridLines: {
                        color: colors.black,
                        zeroLineColor: colors.gray[700],
                      },
                      ticks: {
                        callback: function (value) {
                          if (!(value % 10)) {
                            const val = FormatCurrency(
                              parseFloat(value),
                              currecyCode,
                              locales
                            );
                            return val.props.children;
                          }
                        },
                      },
                    },
                  ],
                },
                tooltips: {
                  callbacks: {
                    label: function (item, data) {
                      var label = data.datasets[item.datasetIndex].label || "";
                      const lbl = FormatCurrency(
                        item.yLabel,
                        currecyCode,
                        locales
                      );
                      var yLabel = lbl.props.children;
                      var content = "";
                      if (data.datasets.length > 1) {
                        content += label;
                      }
                      content += yLabel;
                      return content;
                    },
                  },
                },
              },
              data: {
                labels: semestreMonths,
                datasets: [
                  {
                    label: "Sales",
                    backgroundColor: COLORS.success,
                    stack: 2,
                    data: semestreData,
                    maxBarThickness: 10,

                  },
                ],
              },
            };
            dispatch({
              type: GET_DASHBOARD_ENTRIES,
              payload: chartEntries,
            });
          }
        });
    } catch (err) { }
  };

const isStillLogedIn = async (response, dispatch) => {
  if (response.success === false && response.isSessionHasExpired) {
    await dispatch({ type: SET_LOGOUT, payload: {} });
  }
};
