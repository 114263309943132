import React, { useState, useEffect, useRef } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AddTransactionWidget from "../components/Modals/AddTransactionModal";
import Sidebar from "../components/Sidebar/Sidebar.js";
import AdminNavbar from '../components/Navbars/AdminNavbar'
import routes from "../routes.js";
import { connect } from "react-redux";
import IdleTimer from "react-idle-timer";
import Logo from "../assets/img/brand/fineo-logo-new.png";
import { COLORS } from "assets/constants/theme";
import RouteChangeTracker from "RouteChangeTracker";
import 'moment/locale/fr';
import { getUserSession } from "actions/Auth";
import { getUserAccountInfo } from "actions/Auth";

function App(props) {
  const [sidenavOpen, setSidenavOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [successGetSession, setSuccessGetSession] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [openAddModal, setOpenAddModal] = useState(false);
  const [startSync, setStartSync] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const idleTimer = useRef(null);

  useEffect(() => {
    props.getUserAccountInfo();
    if (isLoading) {
      const isMobile = window.innerWidth <= 767;
      setIsMobile(isMobile);
      getSession();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getSession = async () => {
    const { history } = props;
    await getUserSession((response)=>{
      if (response?.success === true) {
        if (response.newAccessToken !== null) {
          sessionStorage.setItem("ACCESS_TOKEN", `Bearer ${response.newAccessToken}`);
        }
        setIsLoading(false);
        setUserInfo(response.userInfo);
        setSuccessGetSession(true);
      } else {
        localStorage.removeItem("REFRESH_TOKEN");
        sessionStorage.removeItem("ACCESS_TOKEN");
        history.push("/auth/login-page");
      }
    });
  };

  const handleResize = () => {
    const isMobile = window.innerWidth <= 767;
    setIsMobile(isMobile);
  };

  const getAppRoute = (routes) => {
    return routes.filter((route) => route.layout === "/app");
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/app") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            render={() => <prop.component userInfo={userInfo} />}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  const toggleSidenav = () => {
    // if (document.body.classList.contains("g-sidenav-pinned")) {
    //   document.body.classList.remove("g-sidenav-pinned");
    //   document.body.classList.remove("g-sidenav-show");
    //   document.body.classList.add("g-sidenav-hidden");
    // } else {
    //   document.body.classList.add("g-sidenav-pinned");
    //   document.body.classList.remove("g-sidenav-hidden");
    //   document.body.classList.add("g-sidenav-show");
    // }
    if (isMobile) {
      setSidenavOpen(!sidenavOpen);
    }
  };

  const getNavbarTheme = () => {
    return props.location.pathname.indexOf("app/alternative-dashboard") === -1 ? "dark" : "light";
  };

  const handleOnIdle = (event) => {
    const { history } = props;
    if (event === undefined) {
      if ((new Date() - new Date(idleTimer.current.getLastActiveTime())) / 1000 > 1000 * 60 * 30) {
        localStorage.removeItem("REFRESH_TOKEN");
        sessionStorage.removeItem("ACCESS_TOKEN");
        history.push("/auth/login-page");
      }
    }
  };

  return (
    successGetSession && (
      <div style={{ width: "100vw" }}>
        <RouteChangeTracker className="d-none" />
        <IdleTimer
          ref={idleTimer}
          timeout={1000 * 60 * 30}
          onIdle={handleOnIdle}
          debounce={250}
        />
        <AddTransactionWidget history={props.history} cb={() => { }} />
        {isMobile && <div className="backdrop d-none" onClick={toggleSidenav} />}
        {isMobile && (
          <i
            onClick={toggleSidenav}
            style={{
              position: "absolute",
              left: "20px",
              top: "20px",
              zIndex: 10,
              color: COLORS.primary,
              cursor: "pointer",
            }}
            className="fa fa-bars fa-4x"
          ></i>
        )}
        <div
          style={{
            display: "flex",
            width: "100vw",
            minHeight: "100vh",
          }}
        >
          <div
            style={{
              position: "fixed",
              zIndex: 1000,
              top: 0,
              left: isMobile && !sidenavOpen ? "-250px" : 0,
              height: "100vh",
              flex: 1,
              overflowY: "auto",
              overflowX: "hidden",
              background: `linear-gradient(0deg, ${COLORS.primary} 0%, ${COLORS.primary} 100%)`,
            }}
          >
            <Sidebar
              key={Math.random() * Math.random()}
              {...props}
              routes={getAppRoute(routes)}
              sidenavOpen={true}
              userInfo={userInfo}
              openAddModalFun={() => {
                setOpenAddModal(!openAddModal);
              }}
              syncAccountFun={() => {
                setStartSync(!startSync);
              }}
              logo={{
                innerLink: "/",
                imgSrc: Logo,
                imgAlt: "Fineo",
              }}
            />
          </div>
          <div
            style={{
              flex: 1,
              overflowY: "auto",
              marginLeft: isMobile ? 0 : "250px",
              marginTop: isMobile ? "50px" : 0,
            }}
            onClick={() => {
              if (isMobile && sidenavOpen) {
                setSidenavOpen(false);
              }
            }}
          >
            <AdminNavbar
              {...props}
              theme={getNavbarTheme()}
              brandText={getBrandText(props.location.pathname)}
            />
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/app/dashboard" />
            </Switch>
          </div>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  getUserAccountInfo,
})(App);
