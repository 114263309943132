import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { message } from "antd";
import { Input, Form, Label, FormGroup, ModalBody } from "reactstrap";
import CreatableSelect from "react-select/creatable";
import ReactDateTime from "react-datetime";
import { getBudget } from "../../actions/Budget";
import {
  setTransaction,
  getTransaction,
  getPayees,
  getCategories,
  getAllCategories,
} from "../../actions/Transaction";
import { openAddTransactionModal } from '../../actions/Global'
import { useTranslation } from "react-i18next";
import { COLORS } from "assets/constants/theme";
import DefaultModal from "components/Modals/DefaultModal";
import TwoButtonSet from "components/Buttons/TwoButtonSet";
import DefaultSelect from "components/inputs/DefaultSelect";
import { STYLES } from "assets/constants/theme";
import { StringManipulator } from "@fineo-io/main-javascript-library";

function AddTransaction({
  transactionState,
  accountState,
  budgetState,
  getPayees,
  authState,
  budgetDate,
  setTransaction,
  getBudget,
  getTransaction,
  getCategories,
  getAllCategories,
  GlobalState,
  openAddTransactionModal,
  cb,
}) {
  const transactionBody = {
    date: new Date(),
    account: null,
    payee: "",
    categorie: "",
    amount: 0,
    memo: "",
    isIncome: false
  };

  const { t } = useTranslation();
  const [visible, setisVisible] = useState(false);
  const [disableAdd, setDisableAdd] = useState(false);
  const [disabelOutTransactionRadio, setDisabelOutTransactionRadio] = useState(false);
  const [categories, setCategories] = useState([]);
  const [payees, setPayees] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [newTransaction, setNewTransaction] = useState(transactionBody);

  useEffect(() => {
    if(GlobalState.isAddTrModalOpened){
      loadData();
    }
  }, [
    transactionState?.payees?.length,
    accountState.selectAccounts?.length,
    transactionState?.categories?.length,
    budgetState?.budgets?.budgetData?.length,
  ]);

  const loadData = async () => {
    await getPayees();
    await getCategories(t);
    await getAllCategories(t);
    await getTransaction(true, t);
    await getBudget(budgetDate)
  }

  useEffect(() => {
    let options = [];
    if (authState.user.currency_locales === undefined) return;
    transactionState.categories.forEach((group) => {
      let obj = {};
      let tab = [];
      group.options.forEach((val) => {
        let varb = `${val.label} ${new Intl.NumberFormat(
          authState.user.currency_locales,
          {
            style: "currency",
            currency: authState.user.currency_code,
          }
        ).format(val.amount)}`;

        tab.push({
          key: val.key,
          value: val.value,
          label: varb,
        });
      });

      obj = {
        label: group.label,
        options: tab,
      };
      options.push(obj);
    });

    let newOptions = options.filter((group) => group.label !== t("budget_get_all") && group.label !== t("to_budget") && group.label !== undefined);

    newOptions.unshift({
      label: t("to_budget"),
      options: [
        {
          key: -1,
          value: -1,
          label: t(`to_budget`),
        },
      ],
    });

    setCategories(newOptions);

    const tempAccounts = [];
    accountState.selectAccounts
      .filter((account) => account?.is_it_closed === false)
      .forEach((account) => {
        tempAccounts.push({
          key: account.key,
          value: account.key,
          label: account.account,
        });
      });
    setAccounts(tempAccounts);

    const mainOptions = [];
    const accountOptions = [];
    accountState.selectAccounts
      .filter((account) => account?.is_it_closed === false)
      .forEach((account) => {
        accountOptions.push({
          key: account.key,
          value: account.account,
          label: `${account.account}`,
        });
      });

    mainOptions.push({
      key: 1,
      label: t("Transfertvers"),
      options: accountOptions,
    });

    const payeeOptions = [];
    transactionState.payees.forEach((payee) => {
      payeeOptions.push({
        key: `${payee.value} ${payee.key}`,
        value: payee.value,
        label: `${payee.value}`,
      });
    });
    mainOptions.push({
      key: Math.random() * Math.random(),
      label: `${t("Beneficiaire")}`,
      options: payeeOptions,
    });

    setPayees(mainOptions);
  }, [
    transactionState.payees,
    accountState.selectAccounts,
    transactionState.categories,
    authState.user.currency_locales,
    authState.user.currency_code,
  ]);

  useEffect(() => {
    if (newTransaction.account) {
      setDisableAdd(false);
    } else {
      setDisableAdd(true);
    }
  }, [
    newTransaction.account
  ]);

  const handleOk = async () => {
    if (!newTransaction.date || !newTransaction.account) return;
    setDisableAdd(true);
    await setTransaction(newTransaction, message, t);
    await cb();
    await loadData();
    setisVisible(false);
    openAddTransactionModal(false);
    setNewTransaction(transactionBody);
  };

  return (
    <>
      <DefaultModal
        size="modal-md"
        style={{ paddingTop: "100px", scale: '0.9' }}
        bodyStyle={{ textAlign: "start", }}
        isOpen={visible || GlobalState.isAddTrModalOpened}
        toggle={() => {
          setisVisible(false);
          openAddTransactionModal(false);
          setisVisible(false);
          setDisableAdd(true);
          openAddTransactionModal(false)
          setNewTransaction(transactionBody);
        }}
        disabled={disableAdd}
        hasTwoButtons={true}
        title={t("AddTrModal_Ajouter_une_transaction")}
        btnText={t("Transaction_Enregistrer")}
        onClick={handleOk}
        secondBtnText={StringManipulator.capitalize(t("global.cancel._"))}
        secondOnClick={() => {
          setisVisible(false);
          setDisableAdd(true);
          openAddTransactionModal(false)
          setNewTransaction(transactionBody);
        }}
      >
        <ModalBody>
          <Form>
            <FormGroup>
              <Label>{t("AddTrModal_Date")}:</Label>
              <ReactDateTime
                inputProps={{
                  style: {...STYLES.selectStyle},
                }}
                onChange={(value) => {
                  setNewTransaction({
                    ...newTransaction,
                    date: value.utc(),
                  });
                }}
                onBlur={(value) => {
                  newTransaction = {
                    ...newTransaction,
                    date: value.utc(),
                  };
                }}
                locale={t("AddTrModal_Selected_Lang")}
                dateFormat="YYYY/MM/DD"
                value={newTransaction.date}
                timeFormat={false}
              />
            </FormGroup>

            <FormGroup style={{ marginTop: "-10px" }}>
              <Label>{t("AddTrModal_Compte")}:</Label>
              <DefaultSelect
                onChange={(option) => setNewTransaction({ ...newTransaction, account: option.value })}
                placeholder={t("AddTrModal_Sélectionner")}
                options={accounts}
                value={accounts.find((option) => option.value === newTransaction.account || null)}
              />

            </FormGroup>

            <FormGroup style={{ marginTop: "-10px" }}>
              <Label>{t("AddTrModal_Bénéficiaire")}:</Label>
              <CreatableSelect
                className=" w-100"
                placeholder={t("AddTrModal_Sélectionner")}
                options={payees}
                isClearable
                styles={
                  {
                    control: (provided, state) => ({
                      ...provided,
                      ...STYLES.selectStyle,
                      borderColor: state.isFocused ? COLORS.glyphSecondary : COLORS.glyphSecondary,
                      "&:hover": {
                        borderColor: COLORS.glyphSecondary, // Customize the border color on hover
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      textAlign: 'left'
                    }),
                    groupHeading: (provided) => ({
                      ...provided,
                      textAlign: 'left', // Align the options group to the left
                    }),
                  }
                }
                onChange={(option) => {
                  if (option === undefined || option === null) {
                    document.getElementById(`enableSelectCategorie`).style.display = "inline";
                    document.getElementById(`disabledSelectCategorie`).style.display = "none";
                    return setNewTransaction({
                      ...newTransaction,
                      payee: "",
                    });
                  };
                  let value = option.value
                  if (value === "") return;
                  const isItAnAccount = accountState.selectAccounts.filter(
                    (account) => account.account === value
                  );
                  if (isItAnAccount.length > 0) {
                    document.getElementById(`enableSelectCategorie`).style.display =
                      "none";
                    document.getElementById(`disabledSelectCategorie`).style.display =
                      "inline";
                    return setNewTransaction({
                      ...newTransaction,
                      payee: isItAnAccount[0].key,
                    });
                  }
                  document.getElementById(`enableSelectCategorie`).style.display =
                    "inline";
                  document.getElementById(`disabledSelectCategorie`).style.display =
                    "none";
                  setNewTransaction({
                    ...newTransaction,
                    payee: value,
                  })
                }}
              />
            </FormGroup>

            <FormGroup style={{
              marginTop: "-10px"
            }}>
              <Label>{t("AddTrModal_Categorie")}:</Label>
              <Input
                id="disabledSelectCategorie"
                disabled={true}
                className="customInputStyle"
                style={{ display: "none" }}
              />
              <span id="enableSelectCategorie">
                <DefaultSelect
                  onChange={(option) => {
                    if (option === undefined || option === null) {
                      return setNewTransaction({
                        ...newTransaction,
                        categorie: "",
                      });
                    }
                    setNewTransaction({
                      ...newTransaction,
                      categorie: parseInt(option.value),
                    });
                    if (option.value === -1) setDisabelOutTransactionRadio(true);
                    if (option.value !== -1) setDisabelOutTransactionRadio(false);
                  }}
                  placeholder={t("AddTrModal_Sélectionner")}
                  options={categories.flatMap((category) => category.options)}
                  isClearable
                  value={categories.flatMap((category) => category.options).find((option) => option.value === String(newTransaction.categorie)) || null}
                />
              </span>
            </FormGroup>

            <FormGroup style={{
              marginTop: "-10px"
            }}>
              <Label>{t("AddTrModal_Montant")}:</Label>
              <Input
                defaultValue="0.00"
                placeholder="0.00"
                type="number"
                step="0.01"
                onChange={(event) => {
                  setNewTransaction({
                    ...newTransaction,
                    amount: event.target.value,
                  });
                }}
                min={0}
                value={newTransaction.amount}
               className="ourCustomInputStyle"
              />
            </FormGroup>

            <FormGroup className="mt--1">
              <Label>{t("AddTrModal_Type")}:</Label>
              <TwoButtonSet
                activeButton={newTransaction?.isIncome}
                setActiveButton={(value) => setNewTransaction({ ...newTransaction, isIncome: value })}
                button1Text={t("AddTrModal_Dépensé")}
                button2Text={t("AddTrModal_Entrée")}
              />
            </FormGroup>

            <FormGroup>
              <Label>{t("AddTrModal_Mémo")}</Label>
              <Input
                rows="3"
                type="textarea"
                value={newTransaction.memo}
                onChange={(value) => {
                  setNewTransaction({
                    ...newTransaction,
                    memo: value.target.value,
                  });
                }}
                className="ourCustomTextAreaStyle"
              />
            </FormGroup>
          </Form>
        </ModalBody>
      </DefaultModal>
    </>
  );
}

AddTransaction.prototype = {
  GlobalState: PropTypes.object.isRequired,
  transactionState: PropTypes.object.isRequired,
  accountState: PropTypes.object.isRequired,
  authState: PropTypes.func.isRequired,
  getTransaction: PropTypes.func.isRequired,
  getPayees: PropTypes.func.isRequired,
  getBudget: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  getAllCategories: PropTypes.func.isRequired,
  setTransaction: PropTypes.func.isRequired,
  openAddTransactionModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  transactionState: state.transaction,
  accountState: state.account,
  authState: state.auth,
  budgetState: state.budget,
  budgetDate: state.budget.bDate,
  GlobalState: state.GlobalState,
});

export default connect(mapStateToProps, {
  getTransaction,
  getPayees,
  getBudget,
  getCategories,
  getAllCategories,
  setTransaction,
  openAddTransactionModal,
})(AddTransaction);
