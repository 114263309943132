import moment from "moment";
import FormatCurrency from "../components/Tools/Currency/Currency";
import {
  GET_CHART_BUDGET,
  GET_ACCOUNT_REPORT,
  GET_CHART_DEPENSE,
  GET_CHART_ENTRIES,
  CLEAN_RAPPORT,
  SET_LOGOUT,
} from "../actions/types";
import { CHART_COLORS } from "assets/constants/theme";

const { REACT_APP_SERVER_URL } = process.env;

//colors for charts---------
const colors = CHART_COLORS;

//get account to fill the select (to filter by account)
export const getAccountReport = (space_id) => async (dispatch) => {
  try {
    fetch(`https://${REACT_APP_SERVER_URL}/chart/getcompte`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      credentials: "include",
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
        access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
        space_id: space_id
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        isStillLogedIn(response, dispatch);
        if (response.success) {
          dispatch({
            type: GET_ACCOUNT_REPORT,
            payload: response.messages.payload,
          });
        }
      });
  } catch (err) { }
};

export const getEntriesReport = (
  space_id,
  start_Entries_Date,
  end_Entries_Date,
  entries_Selected_Compte,
  entries_Selected_Category,
  entriesDays,
  entriesData,
  currecyCode,
  locales,
) => async (dispatch) => {
  try {
    fetch(`https://${REACT_APP_SERVER_URL}/chart/entries`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      credentials: "include",
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
        access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
        start_date: start_Entries_Date,
        category: entries_Selected_Category,
        end_date: end_Entries_Date,
        account_id: entries_Selected_Compte,
        space_id
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        isStillLogedIn(response, dispatch);
        if (response.success) {
          var chartEntries = {};
          var targetDate = moment(start_Entries_Date).utc();
          if (Object.keys(response.messages.payload).length > 0) {
            entriesDays.forEach((day, index) => {
              response.messages.payload.forEach((element) => {
                const date = moment(element.transaction_date).utc();
                if (date.month() === targetDate.month() && date.year() === targetDate.year()) {
                  entriesData[index] = entriesData[index] + parseFloat(element.ta) / 100;
                }
              });
              targetDate.add(1, 'months');
            });
          }

          // Fill ChartEntries with Data
          chartEntries = {
            options: {
              scales: {
                yAxes: [
                  {
                    gridLines: {
                      zeroLineColor: "lightgray",
                      color: "white",
                    },
                    ticks: {
                      callback: value => {
                        return new Intl.NumberFormat(locales, {
                          style: "currency",
                          notation: "compact",
                          compactDisplay: "short",
                          currency: currecyCode !== undefined ? currecyCode : "EUR",
                        }).format(value)
                      }
                    },
                  },
                ],
              },
              legend: {
                display: false
              },
              tooltips: {
                callbacks: {
                  label: function (item, data) {
                    var label = data.datasets[item.datasetIndex].label || "";
                    const lbl = FormatCurrency(
                      item.yLabel,
                      currecyCode,
                      locales
                    );
                    var yLabel = lbl.props.children;
                    var content = "";
                    if (data.datasets.length > 1) {
                      content += label;
                    }
                    content += yLabel;
                    return content;
                  },
                },
              },
            },
            data: {
              labels: entriesDays,
              datasets: [
                {
                  label: "entries",
                  data: entriesData,
                  backgroundColor: "rgba(0, 252, 67, 0.1)",
                  borderWidth: 1,
                  borderColor: colors.theme["success"],
                  barThickness: 20,
                  borderRadius: {
                    topLeft: 0
                  }
                }
              ],
            },
          };
          dispatch({
            type: GET_CHART_ENTRIES,
            payload: chartEntries,
          });
        }
      });
  } catch (err) { }
};

export const getDepenseReport = (
  space_id,
  start_Depense_Date,
  end_Depense_Date,
  depense_Selected_Compte,
  depense_Selected_Category,
  depenseDays,
  depenseData,
  currecyCode,
  locales,
  authState
) => async (dispatch) => {
  try {
    fetch(`https://${REACT_APP_SERVER_URL}/chart/depenses`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      credentials: "include",
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
        access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
        start_date: start_Depense_Date,
        category: depense_Selected_Category,
        end_date: end_Depense_Date,
        account_id: depense_Selected_Compte,
        space_id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          var chartDepense = {};
          var targetDate = moment(start_Depense_Date).utc();
          if (Object.keys(response.messages.payload).length > 0) {
            depenseDays.forEach((day, index) => {
              response.messages.payload.forEach((element) => {
                const date = moment(element.transaction_date).utc();
                if (date.month() === targetDate.month() && date.year() === targetDate.year()) {
                  depenseData[index] = depenseData[index] + Math.abs(parseFloat(element.ta) / 100);
                }
              });
              targetDate.add(1, 'months');
            });
          }

          // Fill ChartEntries with Data
          chartDepense = {
            options: {
              scales: {
                yAxes: [
                  {
                    gridLines: {
                      color: "white",
                      zeroLineColor: "lightgray",
                    },
                    ticks: {
                      callback: value => {
                        return new Intl.NumberFormat(locales, {
                          style: "currency",
                          notation: "compact",
                          compactDisplay: "short",
                          currency: currecyCode !== undefined ? currecyCode : "EUR",
                        }).format(value)
                      }
                    },
                  },
                ],
              },
              tooltips: {
                callbacks: {
                  label: function (item, data) {
                    var label = data.datasets[item.datasetIndex].label || "";
                    const lbl = FormatCurrency(
                      item.yLabel,
                      currecyCode,
                      locales
                    );
                    var yLabel = lbl.props.children;
                    var content = "";
                    if (data.datasets.length > 1) {
                      content += label;
                    }
                    content += yLabel;
                    return content;
                  },
                },
              },
            },
            data: {
              labels: depenseDays,
              datasets: [
                {
                  label: "Expnes",
                  data: depenseData,
                  backgroundColor: "rgba(255, 62, 48, 0.1)",
                  borderWidth: 1,
                  borderColor: colors.theme["danger"],
                  barThickness: 20,
                  borderRadius: {
                    topLeft: 0
                  }
                },
              ],
            },
          };
          dispatch({
            type: GET_CHART_DEPENSE,
            payload: chartDepense,
          });
        }
      });
  } catch (err) { }
};

export const getBudgetReport = (
  space_id,
  start_Budgete_Date,
  end_budgete_Date,
  amount,
  newMonths,
  moment,
  currecyCode,
  locales
) => async (dispatch) => {
  try {
    fetch(`https://${REACT_APP_SERVER_URL}/chart/budget`, {
      method: "post",
      headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
      credentials: "include",
      body: JSON.stringify({
        refresh_token: localStorage.getItem("REFRESH_TOKEN").toString(),
        access_token: sessionStorage.getItem("ACCESS_TOKEN").toString(),
        space_id
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        isStillLogedIn(response, dispatch);
        if (response.success) {
          var chartBubget = {
            data: {
              labels: newMonths,
              datasets: [
                {
                  label: "Dépenses mensuelles",
                  backgroundColor: colors.theme["danger"],
                  data: [],
                  maxBarThickness: 10,
                },
                {
                  label: "Provisions pour dépenses ponctuelles",
                  backgroundColor: colors.theme["primary"],
                  data: [],
                  maxBarThickness: 10,
                },
                {
                  label: "Épargne",
                  backgroundColor: colors.theme["success"],
                  data: [],
                  maxBarThickness: 10,
                },
              ],
            },
            options: {
              tooltips: {
                mode: "index",
                intersect: true,
                callbacks: {
                  label: function (item, data) {
                    var label = data.datasets[item.datasetIndex].label || "";
                    const lbl = FormatCurrency(
                      item.yLabel,
                      currecyCode,
                      locales
                    );
                    var yLabel = lbl.props.children;
                    var content = "";
                    if (data.datasets.length > 1) {
                      content += label;
                    }
                    content += yLabel;
                    return content;
                  },
                },
              },
              responsive: true,
              scales: {
                xAxes: [
                  {
                    stacked: true,
                  },
                ],
                yAxes: [
                  {
                    stacked: true,
                  },
                ],
              },
            },
          };
          //decrement by monthsCount after 12 24 36 ...
          var monthsCount = 12;
          //store year to increment it if needed
          var year = new Date(start_Budgete_Date).getFullYear();
          amount.forEach((month, index) => {
            if (
              index + parseInt(new Date(start_Budgete_Date).getMonth()) + 1 <=
              12
            ) {
              response.messages.yearlybudgetReport.forEach((element) => {
                //if the response month is the month in the array , we display the
                //sum of the groups budget to get the total of the budget type
                if (
                  moment(new Date(element.monthly_budget_date)).format(
                    "YYYY-MM"
                  ) >= moment(new Date(start_Budgete_Date)).format("YYYY-MM") &&
                  moment(new Date(element.monthly_budget_date)).format(
                    "YYYY-MM"
                  ) <= moment(new Date(end_budgete_Date)).format("YYYY-MM")
                ) {
                  if (
                    parseInt(new Date(element.monthly_budget_date).getMonth()) +
                    1 ===
                    parseInt(index) +
                    (parseInt(new Date(start_Budgete_Date).getMonth()) +
                      1) &&
                    parseInt(
                      new Date(element.monthly_budget_date).getFullYear()
                    ) === parseInt(year)
                  ) {
                    element.sum = parseInt(element.sum) / 100;
                    if (element.budget_type_id === 1) {
                      month.depensesMensuelles = parseFloat(element.sum);
                    }
                    if (element.budget_type_id === 2) {
                      month.provisionDepensePonctuel = parseFloat(element.sum);
                    }
                    if (element.budget_type_id === 3) {
                      month.epargne = parseFloat(element.sum);
                    }
                  }
                }
              });
            } else {
              if (
                index + parseInt(new Date(start_Budgete_Date).getMonth()) + 1 >
                parseInt(monthsCount) + 12
              ) {
                /*when there's two years, monthscount must be 24 to affect to know the month that correspond
                each (parseInt(index) + (parseInt(new Date(start_Budgete_Date).getMonth()) + 1)- parseInt(monthsCount) */
                monthsCount = monthsCount + 12;
                //add a year to compare years
                year = parseInt(year) + 1;
              }
              response.messages.yearlybudgetReport.forEach((element) => {
                if (
                  parseInt(new Date(element.monthly_budget_date).getMonth()) +
                  1 ===
                  parseInt(index) +
                  (parseInt(new Date(start_Budgete_Date).getMonth()) + 1) -
                  parseInt(monthsCount) &&
                  parseInt(
                    new Date(element.monthly_budget_date).getFullYear()
                  ) ===
                  parseInt(year) + 1
                ) {
                  element.sum = parseInt(element.sum) / 100;
                  if (element.budget_type_id === 1) {
                    month.depensesMensuelles = parseFloat(element.sum);
                  }
                  if (element.budget_type_id === 2) {
                    month.provisionDepensePonctuel = parseFloat(element.sum);
                  }
                  if (element.budget_type_id === 3) {
                    month.epargne = parseFloat(element.sum);
                  }
                }
              });
            }
          });
          //end amount.foreach---------------------------------------

          chartBubget = {
            ...chartBubget,
            data: {
              labels: [],
              datasets: [
                {
                  ...chartBubget.data.datasets[0],
                  data: [],
                },
                {
                  ...chartBubget.data.datasets[1],
                  data: [],
                },
                {
                  ...chartBubget.data.datasets[2],
                  data: [],
                },
              ],
            },
          };

          for (var i = 0; i < newMonths.length; i++) {
            chartBubget = {
              ...chartBubget,
              data: {
                ...chartBubget.data,
                labels: newMonths,
                datasets: [
                  {
                    ...chartBubget.data.datasets[0],
                    data: [
                      ...chartBubget.data.datasets[0].data,
                      amount[i].depensesMensuelles,
                    ],
                  },
                  {
                    ...chartBubget.data.datasets[1],
                    data: [
                      ...chartBubget.data.datasets[1].data,
                      amount[i].provisionDepensePonctuel,
                    ],
                  },
                  {
                    ...chartBubget.data.datasets[2],
                    data: [
                      ...chartBubget.data.datasets[2].data,
                      amount[i].epargne,
                    ],
                  },
                ],
              },
            };
          }
          newMonths = [];
          dispatch({
            type: GET_CHART_BUDGET,
            payload: chartBubget,
          });
        }
      });
  } catch (err) { }
};

export const cleanReport = () => (dispatch) => {
  return dispatch({
    type: CLEAN_RAPPORT,
    payload: {},
  });
};

const isStillLogedIn = async (response, dispatch) => {
  if (response.success === false && response.isSessionHasExpired) {
    await dispatch({ type: SET_LOGOUT, payload: {} });
  }
};
