import { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  InputGroupAddon
} from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { deletePayee, updatePayee } from "../../actions/Payee";
import { getPayees, getTransaction } from "../../actions/Transaction";
import { connect } from "react-redux";
import DefaultModal from 'components/Modals/DefaultModal';
import { COLORS } from 'assets/constants/theme';
import { StringManipulator } from '@fineo-io/main-javascript-library';

function EditPayeeList({
  t,
  openBeneficiaireModal,
  setOpenBeneficiaireModal,
  beneficiaries,
  deletePayee,
  getPayees,
  getTransaction,
  updatePayee,
  handleFilter,
  props
}) {
  const [beneficiaryData, setBeneficiaryData] = useState(beneficiaries.map((payee) => {
    return {
      ...payee,
      selected: false
    }
  }));
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmDeleteAllModal, setConfirmDeleteAllModal] = useState(false);

  const [disabledRemoveAllBtn, setDisabledRemoveAllBtn] = useState(true);
  const [selectedPayee, setSelectedPayee] = useState({
    key: -1,
    value: ""
  });
  const [selectedPayeeToBeDeleted, setSelectedPayeeToBeDeleted] = useState({
    key: -1,
    value: ""
  });

  const renderSelectInput = (payee, index) => <input
    className='mr-2'
    type={"checkbox"}
    id={`payee_${index}`}
    value={payee.selected}
    onChange={(e) => {
      setBeneficiaryData((prev) => Object.assign([], {
        ...prev,
        [index]: {
          ...prev[index],
          selected: e.target.checked
        }
      }));
    }} />

  useEffect(() => {
    getPayees()
    setBeneficiaryData(beneficiaries.map((payee) => {
      return {
        ...payee,
        selected: false
      }
    }))
  }, [getPayees])

  useEffect(() => {
    setDisabledRemoveAllBtn(true);
    beneficiaryData.forEach(element => {
      if (element.selected) setDisabledRemoveAllBtn(false);
    });
  }, [beneficiaryData])

  return (
    <div>
        <DefaultModal
        size={"modal-md"}
        style={{paddingTop:"100px"}}
        hasNoButtons={true}
        title={t("Payee Settings")}
        isOpen={openBeneficiaireModal}
        toggle={async () => {
          setOpenBeneficiaireModal(false);
        }}
        >
<div className='w-100'>
<Row className="mb-3">
            <Col>
              <InputGroup
                className="input-group-alternative"
              >
                <InputGroupAddon
                  addonType="prepend"
                  style={{
                    width: "10%",
                  }}
                >
                  <InputGroupText>
                    <i className="fas fa-search" style={{ color: COLORS.glyphPrimary }} />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="m-auto w-75 ourCustomInputStyle"
                  onChange={async (e) => {
                    if (e.target.value == "") {
                      setBeneficiaryData(beneficiaries);
                      return
                    }
                    const data = [];
                    beneficiaries.forEach((payee) => {
                      if (
                        payee.value
                          .toString()
                          .toUpperCase()
                          .includes(e.target.value.toString().toUpperCase())
                      ) {
                        data.push(payee);
                      }
                    });
                    setBeneficiaryData(data);
                  }}
                />
              </InputGroup>
            </Col>
          </Row>
          {
            !disabledRemoveAllBtn && <Row>
              <Col style={{
                display: "flex",
                justifyContent: "center"
              }}>
                <button
                  type="button"
                  className="btn btn-outline-danger my-3 w-100"
                  disabled={disabledRemoveAllBtn}
                  onClick={() => {
                    setOpenBeneficiaireModal(false);
                    setConfirmDeleteAllModal(true);
                  }}
                >
                  <i className="fas fa-trash-alt mr-2"></i>
                  {t("Delete Selected")}
                </button>
              </Col>
            </Row>
          }
              <InfiniteScroll
                dataLength={beneficiaryData.length}
                scrollThreshold="50px"
                width={"100%"}
                height={"300px"}
              >
                {beneficiaryData?.map((payee, index) => (
                  <>
                    <div
                      style={{
                        cursor: "pointer",
                        borderRadius: "4px",
                        width: "100%",
                        justifyContent: "space-between",
                        display: "flex",
                        backgroundColor: index % 2 == 0 ? COLORS.emptiness : COLORS.neutral
                      }}
                      className="mb-2 p-3"
                      key={index}
                      id={`payee_${index}`}
                    >
                      {
                        payee.key !== selectedPayee.key
                          ?
                          (<span className="py-2" style={{ alignSelf: "center" }}>
                            <span>{renderSelectInput(payee, index)}</span>
                            <span className='mt-1'>{payee?.value}</span>
                          </span>)
                          :
                          (<span className="w-100 mr-2 py-2" style={{
                            display: "flex"
                          }}>
                            <input
                              style={{
                                width: "100%",
                                height: "5vh"
                              }}
                              value={selectedPayee.value} onChange={(event) => {
                                const value = event.target.value;
                                setSelectedPayee({
                                  key: payee.key,
                                  value
                                });
                              }} />
                          </span>
                          )
                      }
                      {
                        payee.key !== selectedPayee.key
                          ?
                          (<span style={{
                            display: "flex",
                            justifyContent: "space-between"
                          }}>
                            <button
                              type="button"
                              className="btn btn-outline-info"
                              onClick={() => {
                                setSelectedPayee({
                                  key: payee?.key,
                                  value: payee?.value
                                })
                              }}
                            >
                              <i className="fas fa-edit"></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline-danger"
                              onClick={() => {
                                setSelectedPayeeToBeDeleted({
                                  key: payee.key,
                                  value: payee.value,
                                })
                                setOpenBeneficiaireModal(false);
                                setConfirmModal(true);
                              }}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          </span>)
                          :
                          (<span style={{
                            display: "flex",
                            justifyContent: "space-between"
                          }}>
                            <button
                              type="button"
                              className="btn btn-outline-success"
                              onClick={async () => {
                                const newData = [];
                                beneficiaryData?.forEach((item, index_) => {
                                  if (parseInt(item.key) === parseInt(selectedPayee.key)) {
                                    newData.push({
                                      key: item.key,
                                      value: selectedPayee.value
                                    })
                                  } else {
                                    newData.push(item);
                                  }
                                  if (parseInt(index_) === parseInt(beneficiaryData.length - 1)) {
                                    setSelectedPayee({
                                      key: -1,
                                      value: ""
                                    })
                                    setBeneficiaryData(newData);
                                    setConfirmModal(false);
                                  };
                                })
                                await updatePayee({
                                  payeeId: selectedPayee.key,
                                  name: selectedPayee.value
                                });
                                await getPayees();
                                await handleFilter();
                              }}
                            >
                              <i className="fas fa-check"></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline-info"
                              onClick={() => {
                                setSelectedPayee({
                                  key: -1,
                                  value: ""
                                })
                              }}
                            >
                              <i className="fas fa-times"></i>
                            </button>
                          </span>)
                      }
                    </div>
                  </>
                ))}
              </InfiniteScroll>
</div>


        </DefaultModal>


      {/* Confirm the deletion of a payee */}
      <DefaultModal
        title={t("global.warning._")}
        hasTwoButtons={true}
        isOpen={confirmModal}
        color={COLORS.danger}
        toggle={() => {
          setConfirmModal(false);
          setOpenBeneficiaireModal(true);
          setSelectedPayeeToBeDeleted({
            key: -1,
            value: ""
          })
        }}
        onClick={async () => {
          setBeneficiaryData(beneficiaryData?.filter((item) => parseInt(item.key) !== parseInt(selectedPayeeToBeDeleted.key)))
          await deletePayee({ payeeId: selectedPayeeToBeDeleted.key });
          setSelectedPayeeToBeDeleted({
            key: -1,
            value: ""
          })
          await getPayees();
          await handleFilter();
          setConfirmModal(false);
          setOpenBeneficiaireModal(true);
        }}
        btnText={StringManipulator.capitalize(t("global.delete._"))}
        secondBtnText={StringManipulator.capitalize(t("global.cancel._"))}
        secondOnClick={
          () => {
            setConfirmModal(false);
            setOpenBeneficiaireModal(true);
            setSelectedPayeeToBeDeleted({
              key: -1,
              value: ""
            })
          }
        }
      >
        {t("By deleting this payee, every single transaction with this last one will have an empty payee filed") + ' '}
        {StringManipulator.capitalize(t("prompts.are.you.sure.you.want.to.proceed.?"))}
      </DefaultModal>

      {/* Confirm the deletion of all selected payees */}
      <DefaultModal
        title={t("global.warning._")}
        hasTwoButtons={true}
        isOpen={confirmDeleteAllModal}
        color={COLORS.danger}
        toggle={() => {
          setDisabledRemoveAllBtn(true);
          setConfirmDeleteAllModal(false);
          setOpenBeneficiaireModal(true);
        }}
        onClick={async () => {
          setBeneficiaryData(beneficiaryData?.filter((payee) => !payee.selected));
          beneficiaryData.forEach(async (payee) => {
            if (payee?.selected) {
              await deletePayee({ payeeId: payee?.key });
            }
          })
          await getPayees();
          await handleFilter();
          setDisabledRemoveAllBtn(true);
          setConfirmDeleteAllModal(false);
          setOpenBeneficiaireModal(true);
        }}
        btnText={StringManipulator.capitalize(t("global.delete._"))}
        secondBtnText={StringManipulator.capitalize(t("global.cancel._"))}
        secondOnClick={() => {
          setDisabledRemoveAllBtn(true);
          setConfirmDeleteAllModal(false);
          setOpenBeneficiaireModal(true);
        }}
      >
        {t("multi_counterparty_deletion")}.&nbsp;
        {StringManipulator.capitalize(t("prompts.are.you.sure.you.want.to.proceed.?"))}
      </DefaultModal>
    </div>
  )
}

EditPayeeList.prototype = {
  deletePayee: PropTypes.func.isRequired,
  updatePayee: PropTypes.func.isRequired,
  getPayees: PropTypes.func.isRequired,
  getTransaction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {
  deletePayee,
  updatePayee,
  getPayees,
  getTransaction
})(EditPayeeList);
