import { SET_GLOBAL_VALUES, GLOBAL_OPEN_ADD_TR_MODAL, GLOBAL_SYNC_MY_ACCOUNTS } from "../actions/types";
const initialState = {
  globalValues: {
    // The budget amount
    budgetedCurrentMonth: 0,
    // The total amount the user can budget this month
    totalToBudgetCurrentMonth: 0,
    // The available amount the user can still budget
    availableCurrentMonth: 0,
    // The entries of the current month
    entriesCurrentMonth: 0,
    // The expenses of the current month
    expensesCurrentMonth: 0,
    // The available amount from the last month that the user can still budget
    availableFromMonthBefore: 0,
    // The Available amount the user can spent (From all entries - all expenses)
    availableAmountToSpent: 0,
    // Saving this month
    EpargneCurrentMonth: 0,
  },
  isAddTrModalOpened: false,
  isSyncingMyAccounts: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {

    case SET_GLOBAL_VALUES:
      return {
        ...state,
        globalValues: payload
      }

    case GLOBAL_OPEN_ADD_TR_MODAL:
      return {
        ...state,
        isAddTrModalOpened: payload,
      };

    case GLOBAL_SYNC_MY_ACCOUNTS:
      return {
        ...state,
        isSyncingMyAccounts: payload,
      };
    default:
      return state;
  }
}
