import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Card,
  CardBody,
  Row,
  Input,
  Button,
  Col,
} from "reactstrap";
import DeleteAccount from "components/Account/DeleteAccount";
import ReactDatetime from "react-datetime";
import FormatCurrency from "../Tools/Currency/Currency";
import useKeyboardShortcut from "use-keyboard-shortcut";
import { message } from "antd";
import StandardButton from "components/Buttons/standardButton/StandardButton";
import DefaultModal from "components/Modals/DefaultModal";
import formatDate from "helpers/formatDate";
import { COLORS } from "assets/constants/theme";
import DefaultSelect from "components/inputs/DefaultSelect";
import { STYLES } from "assets/constants/theme";
import { StringManipulator } from "@fineo-io/main-javascript-library";

const { REACT_APP_SERVER_URL } = process.env;

export default function AccountViewWidget({
  authState,
  detailAccount,
  allAccount,
  handleEdits,
  handleDelete,
  allAccountType,
  toggle,
  changeDetailViewMode,
  syncImportedAccountsFromNordigen,
  getAccount,
  getAccounts,
  closeAccount,
  getTransaction,
  translate,
  transactionState
}) {
  // Set all inputs and select to read only mode. (default: true)

  // Object to handle the current account infos.
  const [targetAccount, setTargetAccount] = useState({
    accountId: detailAccount.account[0].account_id,
    accountName: detailAccount.account[0].account,
    accountType: detailAccount.account[0].accountType,
    accountDate: detailAccount.account[0].creatingDate,
    accountInitalSold: detailAccount.initial_balance,
    accountNote: detailAccount.account[0].account_note,
    is_it_closed: detailAccount.account[0].is_it_closed,
    accountABudgeter: detailAccount.account[0].is_it_to_budget,
    status: detailAccount.account[0].status,
    synchronizing_latest_date: detailAccount.account[0].synchronizing_latest_date,
    new_auth_link: detailAccount.account[0].new_auth_link,
    institution_id: detailAccount.account[0].institution_id,
  });

  const [reactivateModal, setReactivateModal] = useState(false);

  //********* While Closing this account **********/
  // state to handle the current id;
  const [state, setState] = useState({ show: false, id: -1 });
  /*************************************************/

  //********* While Closing this account **********/
  // All account can handle a transaction transfer from the current account while a close.
  const [allAccounts, setAllAccounts] = useState([]);
  // The Target account Id, account into will transafer all the transactions of the Current account
  const [transferToAccountId, setTransferToAccountId] = useState("");
  // Show the close modal or not (default: false)
  const [showModal, setShowModal] = useState(false);
  /*************************************************/

  const [showRefershTransactionsWait, setShowRefershTransactionsWait] = useState(false)
  const [showTakeAttenionForNordigenModal, setShowTakeAttenionForNordigenModal] = useState(false);

  const [loading, setLoading] = useState(true);
  const [
    showTakeAttenionModalSuccessSyncFalse,
    setShowTakeAttenionModalSuccessSyncFalse,
  ] = useState(false);
  const [
    showTakeAttenionModalSuccessSync,
    setShowTakeAttenionModalSuccessSync,
  ] = useState(false);

  const [showInfoSycModal, setShowInfoSycModal] = useState(false)

  //keyboard shortcuts ---------------------------
  useKeyboardShortcut(
    ["Enter"],
    () => {
      handleEdits({
        ...targetAccount,
        accountId: detailAccount.account[0].account_id,
      });
    },
    { overrideSystem: false }
  );

  useKeyboardShortcut(
    ["Escape"],
    () => {
      setTargetAccount({
        accountId: "",
        accountName: detailAccount.account[0].account,
        accountType: "",
        accountDate: detailAccount.account[0].creatingDate,
        accountInitalSold: detailAccount.initial_balance,
        accountNote: detailAccount.account[0].account_note,
        accountABudgeter: detailAccount.account[0].is_it_to_budget,
      });
    },
    { overrideSystem: false }
  );

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleEdits({
        ...targetAccount,
        accountId: detailAccount.account[0].account_id,
      });
    }
  };

  useEffect(() => {
    setState({
      show: false,
      id: -1
    });
  }, [])
  useEffect(() => {
    if (transactionState.length === 0) getTransaction(true, translate);
  }, [transactionState.length])
  //-----------------------------
  useEffect(() => {
    setTargetAccount({
      accountId: detailAccount.account[0].account_id,
      accountName: detailAccount.account[0].account,
      accountType: detailAccount.account[0].accountType,
      accountDate: detailAccount.account[0].creatingDate,
      accountInitalSold: detailAccount.initial_balance,
      is_it_closed: detailAccount.account[0].is_it_closed,
      accountNote: detailAccount.account[0].account_note,
      accountABudgeter: detailAccount.account[0].is_it_to_budget,
    });

    // Filter the accounts list: to not include the current account:
    setAllAccounts(
      allAccount.filter(
        (account) =>
          parseInt(account.key) !==
          parseInt(detailAccount.account[0].account_id)
      )
    );
  }, [detailAccount, allAccount]);

  // Send The changes to Account View
  const handleEdit = (e) => {
    if (targetAccount.accountName === "") {
      return message.warning(
        translate("Account_The_name_field_must_not_be_empty"),
        5
      );
    }
    handleEdits({ ...targetAccount, accountId: detailAccount.account[0].account_id });
    toggle();
  };

  // Handle on inputs text change : editing the account data
  const ChnageHanlder = (e) => {
    const { name, value } = e.target;
    if (name === "accountName")
      setTargetAccount({ ...targetAccount, accountName: value });
    if (name === "accountInitailSold")
      setTargetAccount({ ...targetAccount, accountInitalSold: value });
    if (name === "accountNote")
      setTargetAccount({ ...targetAccount, accountNote: value });
    if (name === "toBudget") {
      const { checked } = e.target;
      if (checked === true)
        setTargetAccount({ ...targetAccount, accountABudgeter: true });
      if (checked === false)
        setTargetAccount({ ...targetAccount, accountABudgeter: false });
    }
    if (name === "archive") {
      const { checked } = e.target;
      if (checked === true)
        setTargetAccount({ ...targetAccount, is_it_closed: true });
      if (checked === false)
        setTargetAccount({ ...targetAccount, is_it_closed: false });
    }
  };

  // Handle on select change a new type for the account
  const changeAccountTypeHandler = (accountType) => {
    setTargetAccount({ ...targetAccount, accountType: accountType.value });
  };

  // Handle on select change a new date for the account
  const changeDateHandler = (accountDate) => {
    setTargetAccount({
      ...targetAccount,
      accountDate: moment(accountDate).format("YYYY-MM-DD"),
    });
  }

  return (
    <div>
      {detailAccount.account[0].is_it_imported && (
        <>
          <div className="mb-2" style={{ display: "flex", alignItems: "center" }}>
            <Row>
              <Col className="form-control-label mb-2" md="12" xs="12" xl="12" lg="12" sm="12">
                {translate("Account Status")}:
              </Col>
              <Col className="h3 ml-2" md="12" xs="12" xl="12" lg="12" sm="12">
                {detailAccount.account[0].status === "SUSPENDED" || detailAccount.account[0].status === "EXPIRED" ?
                  translate("Disconnected") : detailAccount.account[0].status === "ERROR" ? translate("Error") : translate("Connected")}
              </Col>
            </Row>
            <Row>
              <Col className="form-control-label mb-2" md="12" xs="12" xl="12" lg="12" sm="12">
                {translate("Last Sync")}:
              </Col>
              <Col className="h3 ml-2" md="12" xs="12" xl="12" lg="12" sm="12">
                {formatDate(new Date(detailAccount.account[0].synchronizing_latest_date), true)}
              </Col>
            </Row>
          </div>
          {detailAccount.account[0].status === "SUSPENDED" || detailAccount.account[0].status === "EXPIRED" ? (
            <Row className="mt-2">
              <Col className="d-flex justify-content-center" md="12" xs="12" xl="12" lg="12" sm="12">
                <StandardButton
                  style={{
                    fontSize: "15px",
                  }}
                  className="w-100"
                  value={detailAccount.account[0].account_id}
                  onClick={async () => {
                    if (detailAccount.account[0].status === "SUSPENDED" || detailAccount.account[0].status === "EXPIRED" || detailAccount.account[0].status === "ERROR") {
                      localStorage.setItem("aspsp_id", detailAccount.account[0].institution_id);
                      localStorage.setItem("initiate", "initiate")
                      localStorage.setItem("reloadAfterReconnect", "true")
                      window.location.href = detailAccount.account[0].new_auth_link;
                    }
                  }}
                  text={translate("Re Connect")}
                />
              </Col>
            </Row>
          ) : (<>
            <Row className="mt-2">
              <Col className="d-flex justify-content-center" md="12" xs="12" xl="12" lg="12" sm="12">
                <Button
                  color="transparent"
                  style={{
                    border: "none",
                    backgroundColor: COLORS.primary,
                    color: COLORS.neutral,
                    borderRadius: "3px",
                    fontSize: "15px",
                  }}
                  className="w-100"
                  value={detailAccount.account[0].account_id}
                  onClick={async () => {
                    if (detailAccount.account[0].status !== "SUSPENDED" && detailAccount.account[0].status !== "EXPIRED") {
                      await fetch(
                        `https://${REACT_APP_SERVER_URL}/nordigen/checkspaceaccounts`,
                        {
                          method: "post",
                          headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
                          credentials: "include",
                          body: JSON.stringify({
                            refresh_token: localStorage.getItem("REFRESH_TOKEN"),
                            access_token: sessionStorage.getItem("ACCESS_TOKEN"),
                            space_id: sessionStorage.getItem("SESSION_SPACE"),
                          }),
                        }
                      )
                        .then((response) => response.json())
                        .then(async (data) => {
                          if (data.messages === false) {
                            setLoading(false)
                            await syncImportedAccountsFromNordigen({ account_id: detailAccount.account[0].account_id }, async (data) => {
                              setLoading(true);
                              await getAccount(detailAccount.account[0].account_id);
                              await getAccounts();
                              if (data.success == true && data.messages.message == "Success Sync" && data.messages.status == true) {
                                setShowTakeAttenionModalSuccessSync(true);
                                return
                              }

                              if (data?.messages?.message !== undefined && data?.messages?.message?.newLinkRequisetion !== undefined && data?.messages?.message?.newLinkRequisetion === true) {
                                //Show re connect modal:
                                setShowTakeAttenionForNordigenModal(true)
                                return;
                              } else if (data?.messages?.message !== undefined && data.messages?.message?.newError !== undefined && data.messages?.message?.newError === true) {
                                //Show error modal:
                                setShowTakeAttenionForNordigenModal(true)
                                return;
                              }
                              if (data?.success && data?.message?.message === "refreshTiming") {
                                setShowRefershTransactionsWait(true);
                              } else {
                                setShowTakeAttenionModalSuccessSyncFalse(true);
                              }
                            });
                          } else {
                            setShowInfoSycModal(true);
                          }
                        });
                    }
                  }}
                >
                  <span hidden={!loading}>
                    <i className="fas fa-sync-alt mr-1"></i>
                    {translate("Synchronize  Account")}
                  </span>
                  <span hidden={loading} style={{ marginRight: 3 }}>{translate("NavBar_Synchronisation")}</span>
                  <span style={{
                    color: "white"
                  }} className="spinner_ text-white" hidden={loading}>
                    <div style={{
                      color: "white"
                    }} className="bounce1_"></div>
                    <div style={{
                      color: "white"
                    }} className="bounce2_"></div>
                    <div style={{
                      color: "white"
                    }} className="bounce3"></div>
                  </span>
                </Button>
              </Col>
            </Row>
          </>)}
          <hr className="mt--1" />
        </>
      )}
      <Row>
        <Col className="form-control-label mb-1" md="12" xs="12" xl="12" lg="12" sm="12">
          {translate("Account_Nom_du_compte")}
        </Col>
        <Col md="12" xs="12" xl="12" lg="12" sm="12">
          <Input
            name="accountName"
            id="accountName"
            type="text"
            className="ourCustomInputStyle"
            value={targetAccount.accountName}
            onKeyPress={handleKeyPress}
            onChange={ChnageHanlder}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col
          className="form-control-label mb-1" md="12" xs="12" xl="12" lg="12" sm="12"
        >
          {translate("Account_Type_de_compte")}
        </Col>
        <Col md="12" xs="12" xl="12" lg="12" sm="12">
          <DefaultSelect
            name="accountType"
            id="accountType"
            isDisabled={detailAccount.account[0].is_it_imported}
            placeholder={translate(`${targetAccount.accountType}`)}
            onChange={changeAccountTypeHandler}
            options={allAccountType.map((account) => ({
              value: account.id,
              label: translate(`${account.accountType}`),
            }))}
            value={targetAccount?.accountType}
          />

        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="form-control-label mb-1" md="12" xs="12" xl="12" lg="12" sm="12">
          {translate("Account_Date_ouverture")}
        </Col>
        <Col md="12" xs="12" xl="12" lg="12" sm="12">
          <Input
            name="accountViewDate"
            id="accountViewDate"
            hidden={!detailAccount.account[0].is_it_imported}
            type="text"
            className="ourCustomInputStyle"
            onKeyPress={handleKeyPress}
            value={formatDate(detailAccount.account[0].creatingDate)}
            readOnly={detailAccount.account[0].is_it_imported}
            disabled={detailAccount.account[0].is_it_imported}
            onChange={ChnageHanlder}
          />
          <div hidden={detailAccount.account[0].is_it_imported}>
            <ReactDatetime
              value={formatDate(targetAccount.accountDate)}
              onChange={changeDateHandler}
              inputProps={{
                placeholder: translate("Select a date"),
                style: { ...STYLES.selectStyle }
              }}
              timeFormat={false}
              closeOnSelect={true}
              dateFormat="YYYY/MM/DD"
              locale={translate("Account_lang")}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="form-control-label" >
          <div className="mb-1">
            {translate("Account_Solde_de_départ")}
          </div>
          <Input
            name="accountInitailSold"
            id="accountInitailSold"
            type="text"
            className="ourCustomInputStyle"
            onKeyPress={handleKeyPress}
            onChange={ChnageHanlder}
            value={detailAccount.account[0].is_it_imported ? new Intl.NumberFormat(authState.user.currency_locales, {
              style: "currency",
              currency: authState.user.currency_code !== undefined ? authState.user.currency_code : "EUR",
            }).format(targetAccount.accountInitalSold) : targetAccount.accountInitalSold}
            readOnly={detailAccount.account[0].is_it_imported || detailAccount.account[0].is_it_closed}
            disabled={detailAccount.account[0].is_it_imported || detailAccount.account[0].is_it_closed}
          />
        </Col>
        <Col className="form-control-label">
          <div className="mb-1">
            {translate("Account_Solde_actuel")}
          </div>
          <Input
            name="accountBalance"
            id="accountBalance"
            type="text"
            onKeyPress={handleKeyPress}
            value={new Intl.NumberFormat(authState.user.currency_locales, {
              style: "currency",
              currency: authState.user.currency_code !== undefined ? authState.user.currency_code : "EUR",
            }).format(detailAccount.updatedBalance)}
            readOnly={true}
            disabled={true}
            className="ourCustomInputStyle"
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col
          className="form-control-label mb-1" md="12" xs="12" xl="12" lg="12" sm="12">
          <label
            className="form-control-label"
            htmlFor="exampleFormControlTextarea1"
          >
            Notes
          </label>
        </Col>
        <Col md="12" xs="12" xl="12" lg="12" sm="12">
          <Input
            rows="2"
            id="id"
            name="accountNote"
            type="textarea"
            defaultValue={detailAccount.account[0].account_note}
            className="bg-transparent ourCustomTextAreaStyle"
            onChange={ChnageHanlder}
          />
        </Col>
      </Row>

      <Row>
        <Col md="12" xs="12" xl="4" lg="4" sm="6" className="mt-4 justify-content-center">
          <StandardButton
            style={{
              fontSize: "15px",
            }}
            className="w-100"
            value={detailAccount.account[0].account_id}
            onClick={handleEdit}
            text={translate("Transaction_Enregistrer")}
          />
        </Col>
        <Col md="12" xs="12" xl="4" lg="4" sm="6" className="mt-4 justify-content-center">
          <StandardButton
            color={detailAccount.account[0].is_it_closed ? COLORS.secondary : COLORS.danger}
            style={{
              fontSize: "15px",
            }}
            className="w-100"
            value={detailAccount.account[0].account_id}
            onClick={() => {
              detailAccount.account[0].is_it_closed ?
                (setTargetAccount({ ...targetAccount, is_it_closed: false }), setReactivateModal(true)) :
                setShowModal(!showModal)
            }}
            text={detailAccount.account[0].is_it_closed ? translate("Reopen") : translate("Account_Clore_le_compte")}
          />
        </Col>
        <Col md="12" xs="12" xl="4" lg="4" sm="6" className="mt-4 justify-content-center">
          <StandardButton
            color={COLORS.danger}
            style={{
              fontSize: "15px",
            }}
            className="w-100"
            value={detailAccount.account[0].account_id}
            onClick={() => {
              setState({
                show: true,
                id: detailAccount.account[0].account_id,
              });
            }}
            disabled={detailAccount.account[0].is_it_closed}
            text={translate("Delete account")}
          />
        </Col>
      </Row>

      <DefaultModal
        title={"account_reactivation"}
        isOpen={reactivateModal}
        toggle={() => setReactivateModal(!reactivateModal)}
        content={"account_reactivation_confirmation"}
        hasTwoButtons={true}
        style={{ paddingTop: "100px" }}
        onClick={() => {
          handleEdit();
          toggle();
        }}
        btnText={translate("OK")}
        secondBtnText={StringManipulator.capitalize(translate("global.cancel._"))}
        secondOnClick={() => setReactivateModal(!reactivateModal)}
      >
      </DefaultModal>

      <DefaultModal
        title={translate("Account_Clore")}
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        hasTwoButtons={true}
        style={{ paddingTop: "100px" }}
        disabled={parseFloat(detailAccount.updatedBalance) !== 0 && transferToAccountId === "" ? true : false}
        onClick={() => {
          if (parseFloat(detailAccount.updatedBalance) !== 0) {
            closeAccount(detailAccount.account[0].account_id, transferToAccountId);
          } else {
            handleEdits({
              accountInitalSold: detailAccount.initial_balance,
              accountId:detailAccount.account[0].account_id,
              accountName:detailAccount.account[0].account,
              accountType:detailAccount.account[0].accountType,
              accountNote:detailAccount.account[0].account_note,
              accountDate:detailAccount.account[0].account_creation_date,
              accountABudgeter:detailAccount.account[0].is_it_to_budget,
              is_it_closed: true
            })
          }
          setShowModal(false);
          changeDetailViewMode();
        }}
        color={COLORS.danger}
        btnText={translate("Transaction_Enregistrer")}
        secondBtnText={StringManipulator.capitalize(translate("global.cancel._"))}
        secondOnClick={() => setShowModal(!showModal)}
      >
        {parseFloat(detailAccount.updatedBalance) === 0 &&
          <span>{translate("account_deactivation_confirmation")}</span>
        }
        {parseFloat(detailAccount.updatedBalance) !== 0 &&
          <div className="modal-body">
            <Row>
              <Card className="w-100 h-25">
                <CardBody>
                  <h4>
                    <span className="text-danger">NB: </span>
                    {translate("Account_Avant_de_clore_ce_compte,_assurez-vous_de_transférer_son_solde_vers_un_autre_compte")}.
                  </h4>
                </CardBody>
              </Card>
            </Row>
            <Row>
              <Col className="w-50 text-center">
                <h4 className="text-center w-100">
                  {translate("Account_Le_solde_du_compte")}{" "}
                  {targetAccount.accountName} {translate("Account_est")} :{" "}
                  {parseFloat(detailAccount.updatedBalance) < 0 ? (
                    <span className="text-danger display-3" pill>
                      {FormatCurrency(
                        detailAccount.updatedBalance,
                        authState.user.currency_code,
                        authState.user.currency_locales
                      )}
                    </span>
                  ) : (
                    <span className="text-success display-3" pill>
                      {FormatCurrency(
                        detailAccount.updatedBalance,
                        authState.user.currency_code,
                        authState.user.currency_locales
                      )}
                    </span>
                  )}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <h4>{translate("Account_Transférer_ce_solde_vers")}: </h4>
                <DefaultSelect
                  value={allAccounts.find((account) => account.key === transferToAccountId)?.account}
                  onChange={(value) => setTransferToAccountId(value.value)}
                  options={allAccounts
                    .filter((account) => account.is_it_closed === false)
                    .map((account) => ({ value: account.key, label: account.account}))
                  }
                />
              </Col>
            </Row>
          </div>
        }
      </DefaultModal>

      <DefaultModal
        title={translate("Successful synchronization")}
        btnText={translate("OK")}
        isOpen={showTakeAttenionModalSuccessSync}
        toggle={() => {setShowTakeAttenionModalSuccessSync(false)}}
        onClick={() => {setShowTakeAttenionModalSuccessSync(false)}}
      >
        {translate("Account_Success_Sync_Message_1")}
      </DefaultModal>

      <DefaultModal
        title={translate("Failed synchronization")}
        btnText={translate("Ok")}
        isOpen={showTakeAttenionModalSuccessSyncFalse}
        toggle={() => {
          setShowTakeAttenionModalSuccessSyncFalse(false);
        }}
        onClick={async () => {
          setShowTakeAttenionModalSuccessSyncFalse(false);
          await getAccount(detailAccount.account[0].account_id);
          await getAccounts();
        }}
      >
        {translate("Account_Success_Sync_False")}
      </DefaultModal>

      <DefaultModal
        title={translate("SideBar_Comptes")}
        btnText={translate("OK")}
        isOpen={showInfoSycModal}
        toggle={() => {setShowInfoSycModal(false)}}
        onClick={async () => {
          setShowInfoSycModal(false);
          await getAccount(detailAccount.account[0].account_id);
          await getAccounts();
        }}
      >
        {translate("Account_Success_Sync_Info")}
      </DefaultModal>

      <DefaultModal
        title={translate("SideBar_Comptes")}
        isOpen={showRefershTransactionsWait}
        btnText={translate("OK")}
        toggle={() => {setShowRefershTransactionsWait(false)}}
        onClick={async () => {
          await getAccount(detailAccount.account[0].account_id);
          await getAccounts();
          setShowRefershTransactionsWait(false);
        }}
      >
        {translate("We have just got the last transaction you made for, 15 min until you can sync your accounts again")}
      </DefaultModal>

      <DefaultModal
        title={detailAccount.account[0].status === "SUSPENDED" || detailAccount.account[0].status === "EXPIRED" ? translate("Re-authentication required") : translate("Failed synchronization")}
        hasTwoButtons={detailAccount.account[0].status === "SUSPENDED" || detailAccount.account[0].status === "EXPIRED"}
        isOpen={showTakeAttenionForNordigenModal}
        btnText={detailAccount.account[0].status === "SUSPENDED" || detailAccount.account[0].status === "EXPIRED" ? translate("Re Connect") : translate("Try later")}
        onClick={async () => {
          if (detailAccount.account[0].status === "SUSPENDED" || detailAccount.account[0].status === "EXPIRED") {
            window.location.href = detailAccount.account[0].new_auth_link;
            localStorage.setItem("initiate", "initiate")
          }
          setShowTakeAttenionForNordigenModal(false);
        }}
        secondBtnText={StringManipulator.capitalize(translate("global.cancel._"))}
        secondOnClick={async () => {
          setShowTakeAttenionForNordigenModal(false);
        }}
      >
        {detailAccount.account[0].status === "SUSPENDED" || detailAccount.account[0].status === "EXPIRED" ?
          translate("Access to account has expired please click on the button to reconnected it again") : translate("There was an error while syncing this account please try later")}
        {translate("")}
      </DefaultModal>

      <DeleteAccount
        changeDetailViewMode={changeDetailViewMode}
        handleDelete={handleDelete}
        totalNumberOfTransactions={detailAccount.lastOperations.length}
        authState={authState}
        detailAccount={detailAccount}
        targetAccount={targetAccount}
        state={state} translate={translate}
        showConfirmDeletenAccountModal={state.show}
        setShowConfirmDeletenAccountModal={setState} />
    </div>
  );
}
