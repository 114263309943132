import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Row, Col, Card } from "reactstrap";
import CardBody from "reactstrap/lib/CardBody";
import Image1 from "../../assets/img/Views/You have no accounts.png"
import AccountIcons from "../Randoms/AccountIcons";
import { COLORS } from "assets/constants/theme";

const {
  moneyBankBig,
  moneyCashCoinsStackBig,
  moneySafeVaultBig,
} = AccountIcons;

function DashboardAccountDonut({ dataSource, t, selectedAccountTypes, ...props }) {

  const Accounts = (accountElement) => {
    return (
      <Col key={accountElement.key} sm="12" xs="12" md="12" lg="6" xl="6" className="p-2">
        <Card className="card-stats" style={{ borderRadius: "10px" }}>
          <CardBody className="p-4" style={{ borderRadius: "10px" }}>
            <Row className="mb--2 mt--2" style={{
              position: "relative"
            }}>
              <Col xs="3" sm="3" md="3" lg="3" xl="3" className="ml-2">
                <div className="d-flex d-flex justify-content-center align-items-center" style={{
                  background: accountElement.accountTypeId === 1 ? COLORS.success : accountElement.accountTypeId === 2 ? COLORS.cash : COLORS.danger,
                  height: "100%",
                  width: "60px",
                  borderRadius: "15px",
                }}>
                  <img src={
                    accountElement.accountTypeId === 1 ? moneyBankBig : accountElement.accountTypeId === 2 ? moneyCashCoinsStackBig : moneySafeVaultBig
                  } style={{
                    maxWidth: "32px",
                    minWidth: "15px",
                    maxHeight: "38px",
                    minHeight: "18px",
                  }} />
                </div>
              </Col>
              <Col xs="8" sm="8" md="8" lg="8" xl="8">
                <div className="pl-4">
                  <div className="d-flex" style={{
                    fontSize: "16px",
                    fontWeight: "bold"
                  }}>
                    <span className="transactionTableCellCustomStyle">
                      {accountElement.account}
                    </span>
                  </div>
                  <span style={{
                    color: COLORS.glyphSecondary,
                    fontWeight: "100",
                    fontSize: "10px"
                  }}>
                    {accountElement.is_it_imported === true ? t("Imported Account") : t("Manual Account")}</span>
                </div>
                <div style={{
                  marginTop: "25px",
                  marginRight: "-5px",
                  fontSize: "20px",
                  fontWeight: "bold",
                  float: "right"
                }}>
                  {props.authState.user.currency_locales && new Intl.NumberFormat(props.authState.user.currency_locales, {
                    style: "currency",
                    currency: props.authState.user.currency_code
                  }).format(accountElement.solde)}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  };

  return (
    dataSource.length > 0 ? (
      selectedAccountTypes === -1 ? (
        dataSource.map((accountElement) =>
          !accountElement.is_it_closed && Accounts(accountElement)
        )
      ) : dataSource.filter((acc) => acc.accountTypeId === selectedAccountTypes).length === 0 ?
        (
          <Col md="12" xl="12" xs="12" lg="12" sm="12" className="text-gray text-center mb--1 mt--1">
            <img
              alt="..."
              width="100%"
              style={{
                margin: "auto",
                maxHeight: "150px",
                maxWidth: "150px",
              }}
              src={Image1}
              className="mr-1"
            />
            <div>{t("You have no accounts")}</div>
          </Col>
        )
        :
        (dataSource.map((accountElement) =>
          !accountElement.is_it_closed && accountElement.accountTypeId === selectedAccountTypes && Accounts(accountElement)
        )
        )
    ) : (
      <Col md="12" xl="12" xs="12" lg="12" sm="12" className="text-gray text-center mb--1 mt--1">
        <img
          alt="..."
          width="100%"
          style={{
            margin: "auto",
            maxHeight: "150px",
            maxWidth: "150px",
          }}
          src={Image1}
          className="mr-1"
        />
        <div>{t("You have no accounts")}</div>
      </Col>
    )
  );
}

DashboardAccountDonut.prototype = {
  authState: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  dashboardState: state.dashboard,
  authState: state.auth,
});

export default connect(mapStateToProps, {})(DashboardAccountDonut);
