// Import all required account acction types:
import {
  SET_LOGOUT,
} from "../actions/types";

const { REACT_APP_SERVER_URL } = process.env;

const callApiHelper = async (endpoint = "", method = "post", body, spaceId = true) => {
  const payload = await fetch(
    `https://${REACT_APP_SERVER_URL}/${endpoint}`,
    {
      method: method,
      credentials: "include",
      headers: {
        "content-Type": "application/json",
        origin: `https://${REACT_APP_SERVER_URL}`
      },
      body: JSON.stringify(spaceId ? {
        ...body,
        refresh_token: localStorage.getItem("REFRESH_TOKEN"),
        access_token: sessionStorage.getItem("ACCESS_TOKEN"),
        space_id: sessionStorage.getItem("SESSION_SPACE")
      } : {
        ...body,
        refresh_token: localStorage.getItem("REFRESH_TOKEN"),
        access_token: sessionStorage.getItem("ACCESS_TOKEN"),
      })
    }
  );
  return await payload.json();
}

const callApiHelperForAuthLayout = async (endpoint = "", method = "post", body) => {
  const payload = await fetch(
    `https://${REACT_APP_SERVER_URL}/${endpoint}`,
    {
      method: method,
      credentials: "include",
      headers: {
        "content-Type": "application/json",
        origin: `https://${REACT_APP_SERVER_URL}`
      },
      body: JSON.stringify(body)
    }
  );
  return await payload.json();
}

const isStillLogedIn = async (response, dispatch) => {
  if (response.success === false && response.isSessionHasExpired) {
    await dispatch({ type: SET_LOGOUT, payload: {} });
  }
};

const cleanState = () => (CLEAN_ACCOUNT, dispatch) => {
  return dispatch({
    type: CLEAN_ACCOUNT,
    payload: {},
  });
};

const apiHelpers = {
  callApiHelper,
  callApiHelperForAuthLayout,
  isStillLogedIn,
  cleanState
}
export default apiHelpers;