import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet-async';
import { useTranslation } from "react-i18next";
import { getUserAccountInfo } from "../actions/Auth";
import { getListPrice, createSubscription } from "../actions/Stripe";
import { useHistory } from "react-router-dom";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import Logo from "../assets/img/brand/fineo-login-logo.png";
import PaymentC from "../components/Stripe/PaymentC";
import PropTypes from "prop-types";
import apiHelpers from "../helpers/api";
import { COLORS } from "assets/constants/theme";
import { StringManipulator } from "@fineo-io/main-javascript-library";

const { callApiHelperForAuthLayout } = apiHelpers;

function Payment({
  stripeState,
  getListPrice,
  location
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const [isValid, setIsValid] = useState(false);
  const [listOfPrices, setListOfPrices] = useState([]);
  const [activePlan, setActivePlan] = useState({
    index: 0,
    currencySymbol: "EUR",
    unit_amount_decimal: 1
  });
  const [authState, setAuthState] = useState({
    user: {
      subscription_id: 2,
      default_language: "en",
    }
  })

  const load = async (token) => {
    const payload = await callApiHelperForAuthLayout("auth/loginfromredirection", "post", { token });
    try {
      if (payload?.user) {
        setIsValid(true);
        await getListPrice("eur", { refresh_token: "Bearer " + payload?.refereshToken, access_token: "Bearer " + payload?.accessToken });
        setAuthState({
          refresh_token: "Bearer " + payload?.refereshToken,
          access_token: "Bearer " + payload?.accessToken,
          user: {
            id: payload?.user?.id,
            subscription_id: payload?.user?.subscription_id,
            default_language: payload?.user?.default_language,
            email: payload?.user?.email,
          }
        })
      } else {
        setTimeout(() => {
          history.push('/')
        }, 3000);
      }
    } catch (error) {
      setTimeout(() => {
        history.push('/')
      }, 3000);
    }

  }

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    if (token) load(token);
  }, []);

  const subscribe = async () => {
    try {
      const subscriptionResponse = await createSubscription({
        price: activePlan?.id,
        currency: activePlan?.currency,
      }, authState);
      if (subscriptionResponse) {
        window.location.href = subscriptionResponse.url
      } else {
        setShowErrorModal(true);
      }
    } catch (error) {
      setPayemntProcessing(false);
      setShowErrorModal(true);
    }
  };

  return authState?.user?.subscription_id !== undefined && (
    <div>
      <Helmet>
        <title>Fineo | {StringManipulator.capitalize(t("payment_title"))}</title>
      </Helmet>
      {isValid ?
        <Card>
          <CardBody>
            <div
              className="d-flex align-items-center mb--4 mt--6"
              style={{
                minHeight: "200px",
                color: COLORS.secondary,
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto"
              }}
            >
              <Container className="align-items-center mb-4" fluid>
                <Row>
                  <Col lg="10" md="10">
                    <img src={Logo} style={{
                      height: "65px",
                      width: "190px",
                      marginBottom: "15px",
                      cursor: "pointer",
                      marginTop: "10px",
                      marginLeft: "-20px"
                    }} onClick={() => history.push("app/login")} />
                    <p className="mt-0 text-dark">
                      <h5 className="display-4" style={{ color: COLORS.secondary, fontWeight: "bolder" }}>
                        {t("Subscription")}
                      </h5>
                      {t("Unlock access to premium features of Fineo and get everything you need to take back the control of your finances.")}
                    </p>
                  </Col>
                </Row>
              </Container>
            </div>
            <PaymentC
              t={t}
              activePlan={activePlan}
              setActivePlan={setActivePlan}
              listOfPrices={listOfPrices}
              setListOfPrices={setListOfPrices}
              authState={authState}
              stripeState={stripeState}
              getListPrice={getListPrice}
              subscribe={subscribe}
            />
          </CardBody>

        </Card> :
        <div>Loading...</div>
      }
    </div>
  );
}

Payment.prototype = {
  stripeState: PropTypes.object.isRequired,
  getUserAccountInfo: PropTypes.func.isRequired,
  getListPrice: PropTypes.func.isRequired,
  createSubscription: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  stripeState: state.Stripe,
});

export default connect(mapStateToProps, {
  getUserAccountInfo,
  getListPrice,
})(Payment);
