import React, { useEffect, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import AddTransactionWidget from "../Modals/AddTransactionModal";
import { connect } from "react-redux";
import FirstUserLogin from "../Widgets/FirstUserLogin";
import i18next from "i18next";
import {
  Collapse,
  UncontrolledDropdown,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";
import {
  getTransaction,
  getCategories,
  getPayees,
  getPeriodicite,
  refreshBankTransactions,
  getAllCategories,
} from "../../actions/Transaction";
import { logOut, cleanAuth } from "../../actions/Auth";
import { cleanBudget } from "../../actions/Budget";
import { cleanTransaction } from "../../actions/Transaction";
import { cleanReport } from "../../actions/Reports";
import { cleanAccount, getAccounts } from "../../actions/Account";
import { cleanProfile, getCurrency } from "../../actions/Profile";
import { useTranslation } from "react-i18next";
const { REACT_APP_SERVER_URL } = process.env;
function AdminNavbar({
  authState,
  toggleSidenav,
  sidenavOpen,
  getAccounts,
  getPayees,
  getTransaction,
  getCategories,
  getAllCategories,
  accountState,
}) {
  const { t } = useTranslation();
  useEffect(() => {
    async function load() {
      if (authState.isLogin === false) return;
      await getCategories(t);
      await getAllCategories(t);
      await getTransaction(true, t);
      await getPayees();
      getAccounts();
    }
    load();
  }, [
    authState.isLogin,
    sessionStorage.getItem("SESSION_SPACE")
  ]);


  useEffect(() => {
    async function getLanguage() {
      await fetch(`https://${REACT_APP_SERVER_URL}/language/selectedlanguage`, {
        method: "post",
        headers: { "content-Type": "application/json", origin: `https://${REACT_APP_SERVER_URL}` },
        body: JSON.stringify({
          refresh_token: localStorage.getItem("REFRESH_TOKEN"),
          access_token: sessionStorage.getItem("ACCESS_TOKEN"),
        }),
        credentials: "include",
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            localStorage.setItem("i18nextLng", response.messages.selectedLang)
            i18next.changeLanguage(response.messages.selectedLang);
          }
        });
    };
    getLanguage();
  }, []);



  return (
    <>
      <Navbar
        className={classnames("navbar-top navbar-expand mb--5")}
        style={{ backgroundColor: "transparent", }}
      >
        <Container fluid style={{
          height: "2px"
        }}>
          <Nav className="align-items-center ml-md-auto mr-2" navbar>
            <NavItem className="d-xl-none">
              {!sidenavOpen &&
                <div onClick={toggleSidenav} style={{ cursor: "pointer", zIndex: "1000" }} className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line bg-black" />
                  <i className="sidenav-toggler-line bg-black" />
                  <i className="sidenav-toggler-line bg-black" />
                </div>
              }
            </NavItem>
            <UncontrolledDropdown nav></UncontrolledDropdown>
          </Nav>
          <Collapse navbar isOpen={true}>
            {/* <AddTransactionWidget t={t} cb={async () => {
              await getTransaction(true);
            }} /> */}
          </Collapse>
        </Container>
      </Navbar>

      <FirstUserLogin />
    </>
  );
}

AdminNavbar.prototype = {
  authState: PropTypes.object.isRequired,
  accountState: PropTypes.object.isRequired,
  getTransactions: PropTypes.func.isRequired,
  getPayees: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  getAllCategories: PropTypes.func.isRequired,
  getAccounts: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
  cleanAccount: PropTypes.func.isRequired,
  getPeriodicite: PropTypes.func.isRequired,
  cleanAuth: PropTypes.func.isRequired,
  cleanBudget: PropTypes.func.isRequired,
  cleanReport: PropTypes.func.isRequired,
  cleanTransaction: PropTypes.func.isRequired,
  cleanProfile: PropTypes.func.isRequired,
  refreshBankTransactions: PropTypes.func.isRequired,
  getCurrency: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authState: state.auth,
  accountState: state.account
});

export default connect(mapStateToProps, {
  getTransaction,
  getPayees,
  getCategories,
  getAllCategories,
  getPeriodicite,
  getAccounts,
  logOut,
  cleanAccount,
  cleanAuth,
  cleanBudget,
  cleanReport,
  cleanTransaction,
  cleanProfile,
  refreshBankTransactions,
  getCurrency,
})(AdminNavbar);
