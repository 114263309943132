import { STYLES } from 'assets/constants/theme';
import { COLORS } from 'assets/constants/theme';
import React from 'react';
import Select from 'react-select';

const DefaultSelect = ({
  options,
  value = " ",
  onKeyDown,
  onChange,
  placeholder,
  controlStyles = {color: 'red'},
  optionSyles,
  defaultValue,
  isClearable = false,
  ...restProps
}) => {
  const styles = {
    control: (provided, state) => ({
      ...provided,
      ...STYLES.selectStyle,
      borderColor: state.isFocused ? COLORS.glyphSecondary : COLORS.glyphSecondary,
      "&:hover": {
        borderColor: COLORS.glyphSecondary, // Customize the border color on hover
      },
    }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      ...optionSyles
    }),
    groupHeading: (provided) => ({
      ...provided,
      textAlign: 'left', // Align the options group to the left
    }),
  };

  return (
    <Select
      styles={styles}
      onKeyDown={onKeyDown}
      options={options}
      defaultValue={defaultValue}
      value={options ? options.find((option) => option.value === value) : null}
      onChange={onChange}
      placeholder={placeholder}
      isClearable={isClearable}
      {...restProps}
    />
  );
};

export default DefaultSelect;
