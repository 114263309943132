import React, { useState } from "react";
import { isBrowser } from 'react-device-detect';
import { Helmet } from 'react-helmet-async';
import { message } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  FormGroup,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Row,
  Col,
} from "reactstrap";
import { connect } from "react-redux";
import { register } from "../actions/Auth";
import useKeyboardShortcut from "use-keyboard-shortcut";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../components/Randoms/SelectLan";
import sideImage from "../assets/img/Views/Create Account.png"
import Logo from "../assets/img/brand/fineo-login-logo.png";
import StandardButton from "components/Buttons/standardButton/StandardButton";
import { COLORS } from "assets/constants/theme";
import { StringManipulator } from "@fineo-io/main-javascript-library";

function Register({ register, history }) {
  const { t } = useTranslation();
  const [newAccount, setNewAccount] = useState({
    name: "",
    email: "",
    password: "",
    rePassword: "",
  });
  //styleState to draw the red border
  const [stylenameState, setStylenameState] = useState({
    name: {
      input: {
        paddingLeft: "15px"
      }, icon: {}
    },
  });
  const [styleEmailState, setStyleEmailState] = useState({
    email: { input: {}, icon: {} },
  });
  const [stylePasswordState, setStylePasswordState] = useState({
    password: { input: {}, icon: {} },
  });
  const [styleRePasswordState, setStyleRePasswordState] = useState({
    rePassword: { input: {}, icon: {} },
  });
  //if the input is incorect then we set state to true
  const [invalidName, setInvalidName] = useState({ name: false });
  const [invalidEmail, setInvalidEmail] = useState({ email: false });
  const [invalidPassword, setInvalidPassword] = useState({ password: false });
  const [invalidRePassword, setInvalidRePassword] = useState({
    rePassword: false,
  });

  //to display messages for each case
  const [messageName, setMessageName] = useState("");
  const [messageEmail, setMessageEmail] = useState("");
  const [messagePassword, setMessagePassword] = useState("");
  const [messageRePassword, setMessageRePassword] = useState("fd");
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] =
    useState(false);
  const [Privacy_Policy, setPrivacy_Policy] = useState(false);
  //mail format regex
  var mailformat =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  //password regex
  var passwordFormat =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,30}$/;

  const [inputType1, setInputType1] = useState("password")
  const [inputType2, setInputType2] = useState("password")

  //handle keyboard shortcuts
  useKeyboardShortcut(
    ["Enter"],
    () => {
      onSubmitSignIn();
    },
    { overrideSystem: true }
  );

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onSubmitSignIn();
    }
  };
  //fields validation
  const onSubmitSignIn = () => {
    const { name, email, password, rePassword } = newAccount;
    var validNameReg = 0;
    var validEmailReg = 0;
    var validPasswordReg = 0;
    var validRePasswordReg = 0;
    if (
      name === "" ||
      email === "" ||
      password === "" ||
      rePassword === "" ||
      Privacy_Policy === false
    ) {
      if (password === "") {
        setMessagePassword(t("Register_Veuillez_Entrer_un_mot_de_passe"));
        setInvalidPassword({ password: true });
      } else {
        setInvalidPassword({ password: false });
      }
      if (rePassword === "") {
        setMessageRePassword(t("Register_Veuillez_re_entrer_le_mot_de_passe"));
        setInvalidRePassword({ rePassword: true });
      } else {
        setInvalidRePassword({ rePassword: false });
      }
      if (email === "") {
        setMessageEmail(t("Register_Veuillez_Entrer_une_adresse_email_valide"));
        setInvalidEmail({ email: true });
      } else {
        setInvalidEmail({ email: false });
      }
      if (name === "") {
        setMessageName(t("Register_Veuillez_entrer_votre_nom"));
        setInvalidName({ name: true });
      } else {
        setInvalidName({ name: false });
      }
    } else {
      const nameSpaces = name.replace(/\s+/g, "");

      if (nameSpaces.length < 3) {
        setStylenameState({
          name: {
            icon: { border: `${COLORS.warning} solid 2px`, borderRight: "none" },
            input: { border: `${COLORS.warning} solid 2px`, borderLeft: "none" },
          },
        });
        setMessageName(t("Register_Doit_contenir_au_moins_3_caracteres"));
        setInvalidName({ name: true });
        validNameReg = 0;
      } else {
        setStylenameState({
          name: {
            icon: { border: `${COLORS.neutral} solid 1px` },
            input: { border: `${COLORS.neutral} solid 1px` },
          },
        });
        setInvalidName({ name: false });
        validNameReg = 1;
      }
      if (email.match(mailformat) == null) {
        setStyleEmailState({
          email: {
            icon: { border: `${COLORS.warning} solid 2px`, borderRight: "none" },
            input: { border: `${COLORS.warning} solid 2px`, borderLeft: "none" },
          },
        });
        setMessageEmail(t("Register_Adresse_email_invalide"));
        setInvalidEmail({ email: true });
        validEmailReg = 0;
      } else {
        setStyleEmailState({
          email: {
            icon: { border: `${COLORS.neutral} solid 1px` },
            input: { border: `${COLORS.neutral} solid 1px` },
          },
        });
        setInvalidEmail({ email: false });
        validEmailReg = 1;
      }
      if (password.match(passwordFormat) == null) {
        setStylePasswordState({
          password: {
            icon: { border: `${COLORS.warning} solid 2px`, borderRight: "none" },
            input: { border: `${COLORS.warning} solid 2px`, borderLeft: "none" },
          },
        });
        setMessagePassword(
          t(
            "Register_Entrer_plus_de_6_caracteres_alphanumeriques_un_majuscule_un_minuscule_et_au_moins_un_caractere_special"
          )
        );
        setInvalidPassword({ password: true });
        validPasswordReg = 0;
      } else {
        setStylePasswordState({
          password: {
            icon: { border: `${COLORS.neutral} solid 1px` },
            input: { border: `${COLORS.neutral} solid 1px` },
          },
        });
        setInvalidPassword({ password: false });
        validPasswordReg = 1;
      }

      if (rePassword !== password) {
        setStyleRePasswordState({
          rePassword: {
            icon: { border: `${COLORS.warning} solid 2px`, borderRight: "none" },
            input: { border: `${COLORS.warning} solid 2px`, borderLeft: "none" },
          },
        });
        setMessageRePassword(t("Register_Mot_de_passe_non_identique"));
        setInvalidRePassword({ rePassword: true });
        validRePasswordReg = 0;
      } else {
        setStyleRePasswordState({
          rePassword: {
            icon: { border: `${COLORS.neutral} solid 1px` },
            input: { border: `${COLORS.neutral} solid 1px` },
          },
        });
        setInvalidRePassword({ rePassword: false });
        validRePasswordReg = 1;
      }

      //si tout les champs sont verifier en permet l'enregistrement
      if (
        validEmailReg === 1 &&
        validNameReg === 1 &&
        validPasswordReg === 1 &&
        validRePasswordReg === 1
      ) {
        register(
          {
            ...newAccount,
            Privacy_Policy: Privacy_Policy,
            acceptTermsAndConditions: acceptTermsAndConditions,
          },
          message,
          history,
          t
        );
      }
      sessionStorage.setItem("email", newAccount.email);
    }
  };
  //recover field values of field
  const onChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    if (name === "name") setNewAccount({ ...newAccount, name: value });
    if (name === "email")
      setNewAccount({ ...newAccount, email: value.toLowerCase() });
    if (name === "password") setNewAccount({ ...newAccount, password: value });
    if (name === "rePassword")
      setNewAccount({ ...newAccount, rePassword: value });
  };

  return (
    <Row className="d-flex align-items-center" style={{
      height: "100vh",
    }}>
      <Helmet>
        <title>Fineo | {StringManipulator.capitalize(t("registration"))}</title>
      </Helmet>
      {isBrowser && (
        <Col xl="6" lg="6" md="6" xs="12" sm="12" className="d-flex align-items-center" style={{
          height: "100%",
          textAlign: "center",
          justifyContent: "center"
        }}>
          <div style={{
            backgroundColor: COLORS.glyphLight,
            height: "100%",
            borderRadius: "8px",
            width: "100%",
            textAlign: "center",
          }}>
            <div style={{
              height: "auto",
              marginTop: "10%",
              marginBottom: "10%",
            }}>
              <img
                alt="..."
                style={{
                  minHeight: "150px",
                  minWidth: "150px",
                  maxWidth: "450px",
                  maxHeight: "400px"
                }}
                src={sideImage}
              />
              <div style={{
                fontWeight: "bolder"
              }}>
                <h1 style={{
                  fontWeight: "bolder"
                }}>
                  {t("Join Us At Fineo !")}
                </h1>
                <h1 style={{
                  width: "90%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  fontWeight: "800"
                }}>
                  {t("Manage all your money from one single app and take back control of your finances")}
                </h1>
              </div>
            </div>
          </div>
        </Col>
      )}

      <Col xl="6" lg="6" md="12" xs="12" sm="12">
        <div style={{
          width: isBrowser ? "60%" : "80%",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "15px"
        }}>
          <img src={Logo} style={{
            height: "65px",
            width: "190px",
            marginBottom: "15px",
            marginLeft: "-20px"
          }} />
          <h2>
            {t("Create an account")}
          </h2>
          <h3>
            {t("Already have an account ?")}
            <Link to="/auth/login">
              <span style={{ fontWeight: "bold", color: COLORS.primary }} className="ml-1">
                {t("Login")}
              </span>
            </Link>
          </h3>
        </div>
        <div style={{
          width: isBrowser ? "60%" : "80%",
          marginLeft: "auto",
          marginRight: "auto"
        }}>
          <Form role="form">
            <FormGroup className="mb--1">
              <LanguageSelect />
            </FormGroup>
            <FormGroup>
              <Label for="name" style={{
                color: COLORS.glyphSecondary,
                fontWeight: "300"
              }}>{t("Register_Nom")}</Label>
              <InputGroup className="input-group-merge input-group-alternative mb-3">
                <Input
                  invalid={invalidName.name}
                  name="name"
                  type="text"
                  onKeyPress={handleKeyPress}
                  onChange={onChange}
                  style={stylenameState.name.input}
                />
                <FormFeedback style={{ fontSize: "inherit" }}>
                  {messageName}
                </FormFeedback>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label for="email" style={{
                color: COLORS.glyphSecondary,
                fontWeight: "300"
              }}>{t("Email")}</Label>
              <InputGroup className="input-group-merge input-group-alternative mb-3">
                <Input
                  invalid={invalidEmail.email}
                  name="email"
                  onKeyPress={handleKeyPress}
                  type="email"
                  onChange={onChange}
                  style={styleEmailState.email.input}
                />
                <FormFeedback style={{ fontSize: "inherit" }}>
                  {messageEmail}
                </FormFeedback>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label for="password" style={{
                color: COLORS.glyphSecondary,
                fontWeight: "300"
              }}>{t("Login_Mot_de_passe")}</Label>
              <InputGroup className="input-group-merge input-group-alternative">
                <Input
                  invalid={invalidPassword.password}
                  name="password"
                  type={inputType1}
                  onKeyPress={handleKeyPress}
                  onChange={onChange}
                  style={stylePasswordState.password.input}
                />
                {
                  inputType1 === "password" ? (<span style={{
                    padding: "12px",
                    alignContent: "center",
                    alignItems: "center",
                    cursor: "pointer"
                  }}
                    onClick={() => {
                      setInputType1("text")
                    }}>
                    <i className="fas fa-eye fa-1x" ></i>
                  </span>) : (<span style={{
                    padding: "12px",
                    alignContent: "center",
                    alignItems: "center",
                    cursor: "pointer"
                  }}
                    onClick={() => {
                      setInputType1("password")
                    }}
                  >
                    <i className="fas fa-eye-slash fa-1x" ></i>
                  </span>)
                }
                <FormFeedback style={{ fontSize: "inherit" }}>
                  {messagePassword}
                </FormFeedback>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              {/* <Label for="rePassword" style={{
                color: COLORS.glyphSecondary,
                fontWeight: "300"
              }}>{t("Register_Entrer_votre_mot_de_passe_a_nouveau")}</Label> */}
              <InputGroup className="input-group-merge input-group-alternative">
                <Input
                  invalid={invalidRePassword.rePassword}
                  name="rePassword"
                  placeholder={t("Register_Entrer_votre_mot_de_passe_a_nouveau")}
                  type={inputType2}

                  onChange={onChange}
                  onKeyPress={handleKeyPress}
                  style={styleRePasswordState.rePassword.input}
                />
                {
                  inputType2 === "password" ? (<span style={{
                    padding: "12px",
                    alignContent: "center",
                    alignItems: "center",
                    cursor: "pointer"
                  }}
                    onClick={() => {
                      setInputType2("text")
                    }}>
                    <i className="fas fa-eye fa-1x" ></i>
                  </span>) : (<span style={{
                    padding: "12px",
                    alignContent: "center",
                    alignItems: "center",
                    cursor: "pointer"
                  }}
                    onClick={() => {
                      setInputType2("password")
                    }}
                  >
                    <i className="fas fa-eye-slash fa-1x" ></i>
                  </span>)
                }
                <FormFeedback style={{ fontSize: "inherit" }}>
                  {messageRePassword}
                </FormFeedback>
              </InputGroup>
            </FormGroup>
            <FormGroup className="d-flex flex-row mt--1">
              <span className="mr-1" style={{ marginTop: "2px", cursor: "pointer" }}>
                <input
                  id="customCheckLogin"
                  value={Privacy_Policy}
                  type="checkbox"
                  onChange={() => {
                    setPrivacy_Policy(!Privacy_Policy);
                    setAcceptTermsAndConditions(
                      !acceptTermsAndConditions
                    );
                  }}
                />
              </span>
              <span>
                <label>
                  <span style={{
                    color: COLORS.glyphSecondary,
                    fontWeight: "300"
                  }}>
                    {t("Register_I_agree_with_the")}{" "}
                    <a
                      className="text-defult"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://fineo.io/terms-conditions/"
                    >
                      {t("First_Login_Terms_&_Conditions")}
                    </a>
                    {t("Register_I_agree_with_the2")}{" "}
                    <a
                      className="text-defult"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://fineo.io/privacy-policy/"
                    >
                      {t("Register_Privacy_Policy")}
                    </a>
                  </span>
                </label>
              </span>
            </FormGroup>
            <div className="text-center mt--3">
              <StandardButton
                disabled={
                  Privacy_Policy === false ||
                  acceptTermsAndConditions === false
                }
                type="button"
                style={{
                  width: "100%"
                }}
                onClick={() => onSubmitSignIn()}
                text={t("Register_Creer_mon_compte")}
              />
            </div>
          </Form>
        </div>
      </Col>
    </Row>
  );
}

Register.prototype = {
  register: PropTypes.func.isRequired,
};

export default connect(null, {
  register,
})(Register);
